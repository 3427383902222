import { Anchor, Box, Grid, Image, ResponsiveContext } from "grommet";
import {
    Globe,
    Group,
    List,
    Login as LoginIcon,
    Logout as LogoutIcon,
} from "grommet-icons";
import React from "react";
import { connect } from "react-redux";
import {
    BrowserRouter as Router,
    NavLink,
    Route,
    Switch,
} from "react-router-dom";
import "./App.css";
import logo from "./ISM-logo-380.png";
import Campaigns from "./screens/campaigns/Campaigns";
import Customers from "./screens/customers/Customers";
import Login from "./screens/login/Login";
import Logout from "./screens/logout/Logout";
import Map from "./screens/map/Map";
import { rootState } from "./store/rootReducer";

const App: React.FC = (props: any) => {
    return (
        <Router>
            <Grid
                rows={[
                    ["55px", "55px"],
                    ["calc(100vh - 55px)", "calc(100vh - 55px)"],
                ]}
                columns={[["xsmall", "xxsmall"], "2fr 10fr"]}
                fill={true}
                areas={[
                    { name: "header", start: [0, 0], end: [12, 0] },
                    { name: "main", start: [0, 1], end: [12, 1] },
                ]}
            >
                <Box
                    tag="header"
                    direction="row"
                    align="center"
                    justify="between"
                    background="light-1"
                    pad={{ vertical: "small", horizontal: "medium" }}
                    elevation="medium"
                    gridArea="header"
                    width="100%"
                    style={{
                        boxShadow: "unset",
                    }}
                >
                    <Grid
                        rows={["55px"]}
                        columns={["1/2", "1/2"]}
                        areas={[
                            { name: "left", start: [0, 0], end: [1, 0] },
                            { name: "right", start: [1, 0], end: [1, 0] },
                        ]}
                        fill="horizontal"
                    >
                        <Box
                            gridArea="left"
                            background="light-1"
                            direction="row"
                            align="start"
                        >
                            <Box
                                width="120px"
                                height="100%"
                                style={{ cursor: "pointer" }}
                            >
                                <NavLink
                                    to={props.loggedIn ? "/map" : "/"}
                                    style={{ width: "120px", height: "100%" }}
                                >
                                    <Box width="120px" height="100%">
                                        <Image
                                            fit="contain"
                                            src={logo}
                                            margin="xsmall"
                                        />
                                    </Box>
                                </NavLink>
                            </Box>
                        </Box>
                        <Box
                            gridArea="right"
                            background="light-1"
                            align="center"
                            direction="row-reverse"
                        >
                            {props.loggedIn && (
                                <NavLink to="/logout">
                                    <ResponsiveContext.Consumer>
                                        {(size) => (
                                            <Anchor
                                                label={
                                                    size === "small"
                                                        ? ""
                                                        : "Abmelden"
                                                }
                                                margin={{ horizontal: "20px" }}
                                                color="accent-1"
                                                icon={<LogoutIcon />}
                                                style={{ display: "flex" }}
                                                as="span"
                                            />
                                        )}
                                    </ResponsiveContext.Consumer>
                                </NavLink>
                            )}
                            {!props.loggedIn && (
                                <NavLink to="/login">
                                    <ResponsiveContext.Consumer>
                                        {(size) => (
                                            <Anchor
                                                label={
                                                    size === "small"
                                                        ? ""
                                                        : "Anmelden"
                                                }
                                                margin={{ horizontal: "20px" }}
                                                color="accent-1"
                                                icon={<LoginIcon />}
                                                style={{ display: "flex" }}
                                                as="span"
                                            />
                                        )}
                                    </ResponsiveContext.Consumer>
                                </NavLink>
                            )}

                            {props.loggedIn && props.userGroup !== "WUERTH" && (
                                <NavLink to="/campaigns">
                                    <ResponsiveContext.Consumer>
                                        {(size) => (
                                            <Anchor
                                                label={
                                                    size === "small"
                                                        ? ""
                                                        : "Kampagnen"
                                                }
                                                margin={{ horizontal: "20px" }}
                                                color="accent-1"
                                                icon={<List />}
                                                style={{ display: "flex" }}
                                                as="span"
                                            />
                                        )}
                                    </ResponsiveContext.Consumer>
                                </NavLink>
                            )}
                            {props.loggedIn && props.userGroup !== "WUERTH" && (
                                <NavLink to="/customers">
                                    <ResponsiveContext.Consumer>
                                        {(size) => (
                                            <Anchor
                                                label={
                                                    size === "small"
                                                        ? ""
                                                        : "Kunden"
                                                }
                                                margin={{ horizontal: "20px" }}
                                                color="accent-1"
                                                icon={<Group />}
                                                style={{ display: "flex" }}
                                                as="span"
                                            />
                                        )}
                                    </ResponsiveContext.Consumer>
                                </NavLink>
                            )}
                            {props.loggedIn && (
                                <NavLink to="/map">
                                    <ResponsiveContext.Consumer>
                                        {(size) => (
                                            <Anchor
                                                label={
                                                    size === "small"
                                                        ? ""
                                                        : "Karte"
                                                }
                                                margin={{ horizontal: "20px" }}
                                                color="accent-1"
                                                icon={<Globe></Globe>}
                                                style={{ display: "flex" }}
                                                as="span"
                                            />
                                        )}
                                    </ResponsiveContext.Consumer>
                                </NavLink>
                            )}

                            {/* {props.loggedIn && <NavLink to="/">
                                <Anchor
                                    label="Home"
                                    margin={
                                        { "horizontal": "20px" }
                                    }
                                    color="accent-1"
                                    icon={<Home></Home>}
                                    style={{ display: 'flex' }}
                                    as='span'
                                />
                            </NavLink>} */}
                            <span onClick={() => window.location.reload(true)}>
                                v 0.1.2
                            </span>
                        </Box>
                    </Grid>

                    {/* </nav> */}
                </Box>
                {/* <Box gridArea="header" background="brand" /> */}
                <Box gridArea="main">
                    <Switch>
                        <Route path="/map">
                            {props.loggedIn && <Map />}
                            {!props.loggedIn && <Login />}
                        </Route>
                        <Route path="/" exact>
                            {props.loggedIn && <Map />}
                            {!props.loggedIn && <Login />}
                        </Route>
                        <Route path="/campaigns">
                            {props.loggedIn && <Campaigns />}
                            {!props.loggedIn && <Login />}
                        </Route>
                        <Route path="/customers">
                            {props.loggedIn && <Customers />}
                            {!props.loggedIn && <Login />}
                        </Route>
                        {/* <Route path="/users">
                            users
                        </Route> */}
                        <Route path="/logout">
                            <Logout />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                    </Switch>
                </Box>
            </Grid>
        </Router>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        loggedIn: state.usersReducer.loggedIn,
        userGroup: state.usersReducer.user
            ? state.usersReducer.user.authentication.group
            : "",
    };
};

export default connect(mapStateToProps)(App);
