import dayjs, { Dayjs } from "dayjs";
import {
    Box,
    Button,
    Calendar,
    CheckBox,
    DropButton,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    ResponsiveContext,
    Select,
    Text,
    TextInput,
} from "grommet";
import { LinkPrevious } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    addStationForNewCampaign,
    createNewCampaign,
    setFilterContractDuration,
    setFilterDateFrom,
    setNewCampaignRequestStatus,
} from "../../store/campaign/actions";
import {
    resetRequestStatus,
    createNewStation,
} from "../../store/stations/actions";
import { rootState } from "../../store/rootReducer";
import {
    findStationsByRange,
    getAllStations,
} from "../../store/stations/actions";
import { RequestStatus, User, UserRole } from "../../types/Authentication";
import {
    CampaignDuration,
    CampaignPaymentInterval,
    CampaignStatus,
    NewCampaignRequestStatus,
    PartialNewCampaign,
    SalesRep,
    SalesRepArray,
    slotWeightToPackage,
} from "../../types/Campaign";
import Customer from "../../types/Customer";
import { PartialNewAllocation } from "../../types/Resource";
import Station, {
    IndoorScreenVersion,
    Location,
    OutdoorScreenVersion,
    Region,
    StationStatus,
} from "../../types/Station";
import { zip } from "lodash";

export interface NewStationProps {
    shellId: number;
    longitude: number | null;
    latitude: number | null;
    street: string;
    zipCode: string;
    city: string;
    region: Region;
    plannedConstructionDate: number | null;
    activationDate: number | null;
    assignedTo: string | null;
    status: StationStatus;
    additionalInfo: string | null;
    constructionCost: number | null;
    constructionCostOffer: number | null;
    electricityCostsPerMonth: number | null;
    outdoorScreenVersion: OutdoorScreenVersion | null;
    indoorScreenVersion: IndoorScreenVersion | null;
    outdoorScreenCost: number | null;
    indoorScreenCost: number | null;
    otherHardwareCost: number | null;
    measurementFees: number | null;
    approvalFees: number | null;
    smallElectronicsCost: number | null;
    otherCosts: number | null;
    carWashCosts: number | null;
    backShopCosts: number | null;
    additionalCosts: number | null;
    cityApprovalCosts: number | null;
    carWashPlayerAmount: number | null;
    backShopPlayerAmount: number | null;
}

async function getLatitudeLongitude(
    address: string
): Promise<{ latitude: number; longitude: number } | null> {
    const apiKey = "AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
    )}&key=${apiKey}`;

    try {
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();

            if (data.status === "OK") {
                const coordinates = data.results[0].geometry.location;
                return {
                    latitude: coordinates.lat,
                    longitude: coordinates.lng,
                };
            } else {
                console.error("Error:", data.status, data.error_message);
                return null;
            }
        } else {
            console.error(
                "Error fetching data:",
                response.status,
                response.statusText
            );
            return null;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
}

const NewStationForm: React.FC = (props: any) => {
    const [shellId, setShellId] = useState<number | null>(null);
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState<Region>(Region.Baden_Wuerttemberg);
    const [plannedConstructionDate, setPlannedConstructionDate] = useState<
        number | null
    >(null);
    const [activationDate, setActivationDate] = useState<number | null>(null);
    const [status, setStatus] = useState<StationStatus>(
        StationStatus.PLANNING_PHASE
    );
    const [longitude, setLongitude] = useState<number | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [constructionCost, setConstructionCost] = useState(0);
    const [constructionCostOffer, setConstructionCostOffer] = useState(0);
    const [electricityCostsPerMonth, setElectricityCostsPerMonth] = useState(0);
    const [outdoorScreenVersion, setOutdoorScreenVersion] =
        useState<OutdoorScreenVersion | null>(null);
    const [indoorScreenVersion, setIndoorScreenVersion] =
        useState<IndoorScreenVersion | null>(null);
    const [outdoorScreenCost, setOutdoorScreenCost] = useState(0);
    const [indoorScreenCost, setIndoorScreenCost] = useState(0);
    const [otherHardwareCost, setOtherHardwareCost] = useState(0);
    const [measurementFees, setMeasurementFees] = useState(450);
    const [approvalFees, setApprovalFees] = useState(1125);
    const [smallElectronicsCost, setSmallElectronicsCost] = useState(0);
    const [otherCosts, setOtherCosts] = useState(0);
    const [carWashCosts, setCarWashCosts] = useState(0);
    const [backShopCosts, setBackShopCosts] = useState(0);
    const [additionalCosts, setAdditionalCosts] = useState(0);
    const [cityApprovalCosts, setCityApprovalCosts] = useState(0);
    const [carWashPlayerAmount, setCarWashPlayerAmount] = useState(0);
    const [backShopPlayerAmount, setBackShopPlayerAmount] = useState(0);

    const createNewStationObject = () => {
        return {
            shellId,
            longitude,
            latitude,
            street,
            zipCode,
            city,
            region,
            status,
            additionalInfo,
            measurementFees,
            approvalFees,
            assignedTo: "Nicht zugewiesen",
        };
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    pad="small"
                    justify="center"
                    direction="column"
                    align="center"
                    height="auto"
                    background="white"
                >
                    {props.requestStatus !== null && (
                        <Layer
                            onEsc={() => props.resetRequestStatus()}
                            onClickOutside={() => props.requestStatus}
                            full={false}
                        >
                            <Box
                                // pad='large'
                                align="center"
                                justify="center"
                            >
                                {props.requestStatus ===
                                    RequestStatus.SUCCESS && (
                                    <Box
                                        align="center"
                                        direction="column"
                                        justify="center"
                                        pad="medium"
                                    >
                                        <Text
                                            margin={{ vertical: "medium" }}
                                            textAlign="center"
                                        >
                                            Station wurde erfolgreich erstellt.
                                        </Text>
                                    </Box>
                                )}
                                {props.requestStatus ===
                                    RequestStatus.ERROR && (
                                    <Box
                                        align="center"
                                        direction="column"
                                        justify="center"
                                    >
                                        <Text
                                            color="danger"
                                            margin={{ vertical: "medium" }}
                                        >
                                            Das sollte nicht passieren!
                                        </Text>
                                        <Text
                                            textAlign="center"
                                            margin={{ vertical: "medium" }}
                                        >
                                            Ein Fehler ist aufgetreten. Bitte
                                            versuchen Sie es erneuet oder
                                            kontaktieren Sie unseren Service.
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                        </Layer>
                    )}
                    <Box
                        width={"100%"}
                        background="white"
                        pad="medium"
                        overflow="scroll"
                    >
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                            // height="40px"
                        >
                            <TextInput
                                placeholder="OBN"
                                value={shellId || 0}
                                onChange={(event) =>
                                    setShellId(parseInt(event.target.value))
                                }
                                type="number"
                                step={1}
                            />
                        </FormField>
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                        >
                            <TextInput
                                placeholder="Straße"
                                value={street}
                                onChange={(event) => {
                                    setStreet(event.target.value);
                                }}
                            />
                        </FormField>
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                        >
                            <TextInput
                                placeholder="PLZ"
                                value={zipCode}
                                onChange={(event) => {
                                    setZipCode(event.target.value);
                                }}
                            />
                        </FormField>
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                        >
                            <TextInput
                                placeholder="Stadt"
                                value={city}
                                onChange={(event) => {
                                    setCity(event.target.value);
                                }}
                            />
                        </FormField>

                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                            label="Bundesland"
                        >
                            <RadioButtonGroup
                                name="region"
                                options={[
                                    {
                                        label: "Baden-Württemberg",
                                        value: Region.Baden_Wuerttemberg,
                                    },
                                    {
                                        label: "Bayern",
                                        value: Region.Bayern,
                                    },
                                    {
                                        label: "Berlin",
                                        value: Region.Berlin,
                                    },
                                    {
                                        label: "Brandenburg",
                                        value: Region.Brandenburg,
                                    },
                                    {
                                        label: "Bremen",
                                        value: Region.Bremen,
                                    },
                                    {
                                        label: "Hamburg",
                                        value: Region.Hamburg,
                                    },
                                    {
                                        label: "Hessen",
                                        value: Region.Hessen,
                                    },
                                    {
                                        label: "Mecklenburg-Vorpommern",
                                        value: Region.Mecklenburg_Vorpommern,
                                    },
                                    {
                                        label: "Niedersachsen",
                                        value: Region.Niedersachsen,
                                    },
                                    {
                                        label: "Nordrhein-Westfalen",
                                        value: Region.Nordrhein_Westfalen,
                                    },
                                    {
                                        label: "Rheinland-Pfalz",
                                        value: Region.Rheinland_Pfalz,
                                    },
                                    {
                                        label: "Saarland",
                                        value: Region.Saarland,
                                    },
                                    {
                                        label: "Sachsen",
                                        value: Region.Sachsen,
                                    },
                                    {
                                        label: "Sachsen-Anhalt",
                                        value: Region.Sachsen_Anhalt,
                                    },
                                    {
                                        label: "Schleswig-Holstein",
                                        value: Region.Schleswig_Holstein,
                                    },
                                    {
                                        label: "Thüringen",
                                        value: Region.Thueringen,
                                    },
                                ]}
                                value={region}
                                onChange={(option) => {
                                    setRegion(option.value);
                                }}
                            />
                        </FormField>
                        <Button
                            onClick={async () => {
                                const longlat = await getLatitudeLongitude(
                                    `${street}, ${zipCode} ${city}, ${"Germany"}`
                                );
                                if (!longlat) throw new Error("No coordinates");
                                setLatitude(longlat.latitude);
                                setLongitude(longlat.longitude);
                            }}
                            label="Koordinaten ermitteln"
                        />
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                        >
                            <TextInput
                                placeholder="Longitude"
                                type="number"
                                value={longitude || ""}
                                onChange={(event) => {
                                    setLongitude(
                                        parseFloat(event.target.value)
                                    );
                                }}
                            />
                        </FormField>
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            required={true}
                        >
                            <TextInput
                                placeholder="Latitude"
                                type="number"
                                value={latitude || ""}
                                onChange={(event) => {
                                    setLatitude(parseFloat(event.target.value));
                                }}
                            />
                        </FormField>
                        <FormField
                            required={true}
                            style={{ minHeight: "60px" }}
                            label="Status"
                        >
                            <RadioButtonGroup
                                name="status"
                                options={[
                                    {
                                        label: "Planungsphase",
                                        value: StationStatus.PLANNING_PHASE,
                                    },
                                    {
                                        label: "Freigabephase",
                                        value: StationStatus.APPROVAL_PROCESS,
                                    },
                                    {
                                        label: "Aufbauphase",
                                        value: StationStatus.CONSTRUCTION_SCHEDULED,
                                    },
                                    {
                                        label: "Station Live",
                                        value: StationStatus.CONSTRUCTED,
                                    },
                                    {
                                        label: "Aufbau storniert",
                                        value: StationStatus.ABORTED,
                                    },
                                ]}
                                value={status}
                                onChange={(option) => {
                                    setStatus(option.value);
                                }}
                            />
                        </FormField>
                    </Box>
                    <Box
                        align="center"
                        justify={size === "small" ? "center" : "between"}
                        direction={size === "small" ? "column" : "row"}
                        margin={{ top: "large" }}
                    >
                        <Button
                            onClick={() =>
                                props.createNewStation(createNewStationObject())
                            }
                            label="Station erstellen"
                            color="success"
                            margin={"medium"}
                        />
                    </Box>
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        user: state.usersReducer.user,
        requestStatus: state.stationsReducer.requestStatus,
        isFetchingNewCampaing: state.campaignReducer.isFetching,
    };
};

const mapDispatchToProps = {
    addStationForNewCampaign,
    createNewCampaign,
    setFilterDateFrom,
    setFilterContractDuration,
    findStationsByRange,
    getAllStations,
    setNewCampaignRequestStatus,
    resetRequestStatus,
    createNewStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStationForm);
