import {
    Box,
    Button,
    Form,
    FormField,
    InfiniteScroll,
    ResponsiveContext,
    Text,
    TextInput,
} from "grommet";
import { Ascend } from "grommet-icons";
import React, { MouseEvent, useEffect } from "react";
import { connect } from "react-redux";
import {
    addStationToCurrentCampaign,
    removeStationFromCurrentCampaign,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import { findStationsByRange } from "../../store/stations/actions";
import { User, UserRole } from "../../types/Authentication";
import Station from "../../types/Station";
import ListItem from "../MapListItem/MapListItem";

interface GetAllStationsFunc {
    (string: string): void;
}

interface StationListProps {
    findStationsByRange: (spot: string, range: string) => {};
    stations: Station[];
    isFetching: boolean;
    handleSelection(station: Station): any;
    user: User | null;
    stationsForCurrentCampaign: Station[];
    centerAddress: string | null;
    handleAddStationSelect?: (bool: boolean) => void;
    addStationToCurrentCampaign?: any;
    removeStationFromCurrentCampaign?: any;
}

interface ListSearchProps {
    findStationsByRange: (spot: string, range: string) => {};
    user: User | null;
    centerAddress: string;
    stationsForCurrentCampaign: Station[];
    handleAddStationSelect?: (bool: boolean) => void;
    stations: Station[];
}

const SearchArea: React.FC<ListSearchProps> = (props) => {
    const [spot, setSpot] = React.useState("");
    const [range, setRange] = React.useState("20");

    useEffect(() => {
        setSpot(props.centerAddress);
    }, [props.centerAddress]);

    useEffect(() => {
        let timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (spot.length > 2) return null;
        }, 300);
        // const timer = setTimeout(() => {
        //     if (spot.length > 2) console.log('yoyoyo');
        // }, 300);
        // return () => clearTimeout(timer);
    }, [spot]);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    style={{ minHeight: "220px" }}
                    pad="small"
                    border="bottom"
                    width={size === "small" ? "100vw" : ""}
                    flex={false}
                >
                    <Form
                        onSubmit={(event) => {
                            props.findStationsByRange(spot, range);
                            event.stopPropagation();
                        }}
                    >
                        <FormField name="spot" label="Ort">
                            <TextInput
                                value={spot}
                                onChange={(event) => {
                                    const string = event.target.value;
                                    setSpot(string);
                                    // let timer
                                }}
                            />
                        </FormField>
                        <FormField name="range" label="Umkreis in km">
                            <TextInput
                                value={range}
                                onChange={(event) =>
                                    setRange(event.target.value)
                                }
                                type="number"
                                min="1"
                            ></TextInput>
                        </FormField>
                        <Box direction="row" align="center" justify="between">
                            <Button
                                label={
                                    <Text weight={800} color="white">
                                        Suchen
                                    </Text>
                                }
                                primary
                                type="submit"
                                // fill="horizontal"
                                margin={{ vertical: "small" }}
                                // pad='small'
                            />
                            <Text textAlign="end">
                                {props.stations.length}{" "}
                                {props.stations.length === 1
                                    ? " Station "
                                    : " Stationen "}{" "}
                                gefunden
                            </Text>
                        </Box>
                    </Form>
                    {props.stationsForCurrentCampaign.length > 0 &&
                        props.handleAddStationSelect && (
                            <Box
                                direction="row"
                                justify="end"
                                background="white"
                                border="bottom"
                                flex={false}
                            >
                                <Box direction="row" pad="small">
                                    <Text margin="small" weight="bold">
                                        {
                                            props.stationsForCurrentCampaign
                                                .length
                                        }
                                        {props.stationsForCurrentCampaign
                                            .length === 1
                                            ? " Station "
                                            : " Stationen "}
                                        ausgewählt
                                    </Text>
                                    <Button
                                        label="Stationen hinzufügen"
                                        color="success"
                                        primary
                                        disabled={
                                            props.stationsForCurrentCampaign
                                                .length === 0
                                        }
                                        onClick={() => {
                                            props.handleAddStationSelect &&
                                                props.handleAddStationSelect(
                                                    true
                                                );
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const StationList: React.FC<StationListProps> = (props) => {
    const sortedList = props.stationsForCurrentCampaign
        .concat(props.stations)
        .filter(
            (station, index, array) =>
                array.findIndex(
                    (stationIndex) => stationIndex.id === station.id
                ) === index
        )
        .sort((stationA, stationB) => {
            const Aselected = props.stationsForCurrentCampaign.find(
                (station) => stationA.id === station.id
            );
            const Bselected = props.stationsForCurrentCampaign.find(
                (station) => stationB.id === station.id
            );

            if (Aselected && Bselected) {
                if (stationA.shellId > stationB.shellId) return 1;
                return -1;
            }

            if (Aselected) return -1;
            if (Bselected) return 1;

            if (stationA.shellId > stationB.shellId) return 1;
            return -1;
        });

    const mapListRef: any = React.createRef();

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    width={size === "small" ? "100vw" : ""}
                    style={{ maxHeight: "90vh" }}
                    height="90h"
                >
                    {props.user &&
                        props.user.authentication.role !==
                            UserRole.EXTERNAL_SALES && (
                            <SearchArea
                                centerAddress={
                                    props.centerAddress
                                        ? props.centerAddress
                                        : ""
                                }
                                user={props.user}
                                stations={props.stations}
                                findStationsByRange={props.findStationsByRange}
                                stationsForCurrentCampaign={
                                    props.stationsForCurrentCampaign
                                }
                                handleAddStationSelect={
                                    props.handleAddStationSelect
                                }
                            />
                        )}
                    {/* {props.isFetching && <img src={Spinner2} alt="test" />} */}
                    {props.isFetching && (
                        <Box
                            align="center"
                            justify="center"
                            margin={{ top: "4rem" }}
                        >
                            <div className="loadingio-spinner-spinner-g73zrpl2wr5">
                                <div className="ldio-xie7sipo009">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </Box>
                    )}
                    {!props.isFetching && props.stations && (
                        <Box
                            height="90%"
                            overflow="auto"
                            width="100%"
                            style={{ maxHeight: "90%" }}
                        >
                            <Box overflow="scroll" ref={mapListRef}>
                                <InfiniteScroll items={sortedList}>
                                    {(item, index) => {
                                        return (
                                            <Box
                                                flex={false}
                                                key={index}
                                                margin={
                                                    sortedList.length ===
                                                    index + 1
                                                        ? {
                                                              bottom: "2rem",
                                                              horizontal:
                                                                  "small",
                                                              top: "small",
                                                          }
                                                        : "small"
                                                }
                                            >
                                                <Box
                                                    pad={"large"}
                                                    flex={false}
                                                    background="white"
                                                    onClick={() =>
                                                        props.stationsForCurrentCampaign.find(
                                                            (station) =>
                                                                station.shellId ===
                                                                item.shellId
                                                        )
                                                            ? props.removeStationFromCurrentCampaign(
                                                                  item
                                                              )
                                                            : props.addStationToCurrentCampaign(
                                                                  item
                                                              )
                                                    }
                                                    style={
                                                        props.stationsForCurrentCampaign.findIndex(
                                                            (station) =>
                                                                station.id ===
                                                                item.id
                                                        ) !== -1
                                                            ? {
                                                                  boxShadow:
                                                                      "0 0 5px #09b0ac",
                                                                  padding:
                                                                      "small",
                                                                  margin: "1px 1px 1px ",
                                                                  border: "3px solid #09b0ac",
                                                                  cursor: "url(minus_icon_mouse_small.png) 12 12, auto",
                                                              }
                                                            : {
                                                                  border: "grey solid 1px",
                                                                  cursor: "url(plus_icon_mouse_small.png) 12 12, auto",
                                                              }
                                                    }
                                                >
                                                    <ListItem
                                                        lastSelectedItem={
                                                            props
                                                                .stationsForCurrentCampaign
                                                                .length ===
                                                            index + 1
                                                        }
                                                        key={item.shellId}
                                                        station={item}
                                                        onClick={(
                                                            event: MouseEvent<
                                                                | HTMLAnchorElement
                                                                | MouseEvent
                                                            >
                                                        ) => {
                                                            event.stopPropagation();
                                                            props.handleSelection(
                                                                item
                                                            );
                                                        }}
                                                    ></ListItem>
                                                </Box>
                                                {props
                                                    .stationsForCurrentCampaign
                                                    .length ===
                                                    index + 1 && (
                                                    <Box
                                                        border="top"
                                                        margin="medium"
                                                        style={{
                                                            minHeight: "2px",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        );
                                    }}
                                </InfiniteScroll>
                            </Box>
                        </Box>
                    )}

                    <Button
                        // disabled={mapListRef && mapListRef.current && (mapListRef.current.scrollTop < 160)}
                        // icon={<Ascend color='accent-1' />}
                        style={{
                            position: "absolute",
                            bottom: 10,
                            left: 10,
                            backgroundColor: "white",
                            height: "40px",
                        }}
                        // border='2px solid #138587'
                        color="accent-1"
                        label={<Ascend color="accent-1" />}
                        onClick={() => (mapListRef.current.scrollTop = 0)}
                        // myDiv.scrollTop = 0;
                    />
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        // stations: state.stationsReducer.stations,
        isFetching: state.stationsReducer.isFetching,
        user: state.usersReducer.user,
        stationsForCurrentCampaign:
            state.campaignReducer.stationsForCurrentCampaign,
        centerAddress: state.stationsReducer.centerAddress,
    };
};

const mapDispatchToProps = {
    findStationsByRange,
    addStationToCurrentCampaign,
    removeStationFromCurrentCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(StationList);
