import dayjs from "dayjs";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "grommet";
import { Next, Previous } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllocationsForStationByDate } from "../../store/allocations/actions";
import { rootState } from "../../store/rootReducer";
import Campaign from "../../types/Campaign";
import Allocation, {
    PlannerAllocationData,
    ResourcePlannerData,
    ResourcePlannerRow,
} from "../../types/Resource";
import Station from "../../types/Station";
import PlannerRow from "./CalendarRow";

interface ResourcePlannerProps {
    resources: { [key: number]: Allocation[] };
    isFetching: boolean;
    getAllocationsForStationByDate: (params: {
        stationId: number;
        start: number;
        end: number;
    }) => {};
    station: Station;
    campaign?: Campaign;
}

const ResourcePlanner: React.FC<ResourcePlannerProps> = (props) => {
    const [startDate, setStartDate] = useState(
        dayjs().date(1).hour(0).minute(0)
    );

    useEffect(() => {
        if (props.campaign) {
            props.getAllocationsForStationByDate({
                stationId: props.station.id,
                start: props.campaign.start,
                end: dayjs(props.campaign.start).add(6, "month").valueOf(),
            });
        }
    }, []);

    const spotColorMap: Map<number, string> = new Map([
        [1, "#39bff7"],
        [2, "#1FAB9E"],
    ]);

    const baseRowData: ResourcePlannerRow = {
        startRow: startDate.clone().subtract(1, "month"),
        endRow: startDate.clone().add(6, "month"),
        allocations: [],
        slot: 0,
    };

    let plannerData: ResourcePlannerData = {
        1: {
            ...baseRowData,
            allocations: [],
            slot: 1,
        },
        2: {
            ...baseRowData,
            allocations: [],
            slot: 2,
        },
        3: {
            ...baseRowData,
            allocations: [],
            slot: 3,
        },
        4: {
            ...baseRowData,
            allocations: [],
            slot: 4,
        },
        5: {
            ...baseRowData,
            allocations: [],
            slot: 5,
        },
    };

    const checkWhichSubSlot = (
        resource: Allocation,
        slotResources: PlannerAllocationData[]
    ) => {
        const intersectingResources = slotResources
            .filter((slotResource: PlannerAllocationData) => {
                const slotResourceAllocation = slotResource.allocation
                    ? slotResource.allocation.id
                    : null;
                return (
                    resource.slotNumber === slotResource.slot &&
                    resource.id !== slotResourceAllocation
                );
            })
            .filter((slotResource: PlannerAllocationData) => {
                const allocation = slotResource.allocation
                    ? slotResource.allocation
                    : null;
                if (
                    allocation &&
                    (resource.start > allocation.end ||
                        resource.end < allocation.start)
                ) {
                    return false;
                }
                return true;
            });

        if (
            intersectingResources.length > 0 &&
            (intersectingResources[0].subSlot !== null ||
                intersectingResources[0].subSlot !== undefined)
        ) {
            return intersectingResources[0].subSlot === 1 ? 0 : 1;
        }

        return 0;
    };

    if (props.resources[props.station.id]) {
        props.resources[props.station.id].forEach((resource) => {
            let allocationColor: string | undefined;

            if (spotColorMap.get(resource.slotWeight)) {
                allocationColor = spotColorMap.get(resource.slotWeight);
            }

            const alreadyParsed = plannerData[
                resource.slotNumber
            ].allocations.slice();

            plannerData[resource.slotNumber].allocations.push({
                startAllocation: dayjs(resource.start),
                endAllocation: dayjs(resource.end),
                color: allocationColor ? allocationColor : "orange",
                slot: resource.slotNumber,
                allocation: resource,
                subSlot: checkWhichSubSlot(resource, alreadyParsed),
            });
        });
    }
    const tableHeader: any[] = [];

    for (
        let i = 0;
        i < baseRowData.endRow.diff(baseRowData.startRow, "month");
        i++
    ) {
        tableHeader.push({
            name: baseRowData.startRow.add(i, "month").format("MMM - YYYY"),
        });
    }

    return (
        <Box>
            {props.resources[props.station.id] && (
                <Box>
                    <Box
                        direction="row"
                        align="center"
                        justify="between"
                        // margin={
                        //     { bottom: 'large' }
                        // }
                    >
                        <Button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                const newStartDate = startDate
                                    .clone()
                                    .subtract(1, "month");
                                setStartDate(newStartDate);
                                props.getAllocationsForStationByDate({
                                    stationId: props.station.id,
                                    start: newStartDate
                                        .clone()
                                        .subtract(1, "month")
                                        .valueOf(),
                                    end: newStartDate
                                        .clone()
                                        .add(6, "month")
                                        .valueOf(),
                                });
                            }}
                            icon={<Previous />}
                        />
                        <Button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                const newStartDate = startDate
                                    .clone()
                                    .add(1, "month");
                                setStartDate(newStartDate);
                                props.getAllocationsForStationByDate({
                                    stationId: props.station.id,
                                    start: newStartDate
                                        .clone()
                                        .subtract(1, "month")
                                        .valueOf(),
                                    end: newStartDate
                                        .clone()
                                        .add(6, "month")
                                        .valueOf(),
                                });
                            }}
                            icon={<Next />}
                        />
                    </Box>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell>Slot</TableCell>
                                {tableHeader.map((month) => {
                                    return (
                                        <TableCell key={month.name}>
                                            {month.name}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {Object.keys(plannerData).map((rowKey) => (
                                <PlannerRow
                                    key={rowKey}
                                    rowResource={plannerData[rowKey]}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        isFetching: state.allocationReducer.isFetching,
        resources: state.allocationReducer.allocations,
    };
};

const mapDispatchToProps = {
    getAllocationsForStationByDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePlanner);
