import { CheckBox, ResponsiveContext } from "grommet";
import React, { useEffect, useState } from "react";

interface CheckBoxWithOwnStateProps {
    checked?: boolean;
    label: string;
    onChangeFunction: (partialObject: any) => void;
}

export const CheckBoxWithOwnState: React.FC<CheckBoxWithOwnStateProps> = (
    props
) => {
    const [checked, setChecked] = useState<boolean>();

    useEffect(() => {
        props.checked && setChecked(props.checked);
    }, [props.checked]);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <CheckBox
                    label={props.label}
                    checked={checked ? checked : false}
                    onChange={(event) => {
                        props.onChangeFunction(event.target.checked);
                        setChecked(event.target.checked);
                    }}
                />
            )}
        </ResponsiveContext.Consumer>
    );
};

export default CheckBoxWithOwnState;
