import {
    BusinessesActionTypes,
    BusinessState,
    CLEAR_STATE_AFTER_LOGOUT,
    GET_BUSINESSES,
    IS_FETCHING,
    SET_CENTER,
    SET_CENTER_ADDRESS,
    SET_RANGE,
} from "./types";

const initialState: BusinessState = {
    buisnesses: null,
    isFetching: false,
    currentCenter: {
        lat: 50.877,
        lng: 10.278,
    },
    currentRange: 20,
    searchString: "",
    searchStringDisplay: "",
    locationSearch: null,
    placesNearbySearch: null,
    centerAddress: null,
    requestStatus: null,
};

export function businessReducer(
    state = initialState,
    action: BusinessesActionTypes
): BusinessState {
    switch (action.type) {
        case IS_FETCHING:
            return {
                ...state,
                isFetching: true,
            };
        case GET_BUSINESSES:
            return {
                ...state,
                buisnesses: [
                    // ...state.stations,
                    ...action.payload,
                ],
                isFetching: false,
            };
        case SET_CENTER:
            return {
                ...state,
                currentCenter: action.payload,
            };
        case SET_RANGE:
            return {
                ...state,
                currentRange: action.payload,
            };
        case SET_CENTER_ADDRESS:
            return {
                ...state,
                centerAddress: action.payload,
            };
        case CLEAR_STATE_AFTER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
