import dayjs from "dayjs";
import {
    Box,
    Button,
    Heading,
    Layer,
    Meter,
    TableCell,
    TableRow,
    Text,
} from "grommet";
import React from "react";
import { connect } from "react-redux";
import { rootState } from "../../../store/rootReducer";
import {
    PlannerAllocationData,
    ResourcePlannerRow,
} from "../../../types/Resource";

interface ResourcePlannerRowProps {
    rowResource: ResourcePlannerRow;
    isFetching: boolean;
}

const PlannerRow: React.FC<any> = (props: ResourcePlannerRowProps) => {
    const [showAllocationDetails, setShowAllocationDetails] = React.useState(
        false
    );
    const [allocationDetails, setAllocationDetails] = React.useState({
        campaignName: "ups",
    } as any);

    const cellCount: number = props.rowResource.endRow.diff(
        props.rowResource.startRow,
        "month"
    );

    let cellData: any = [];

    for (let i: number = 0; i < cellCount; i++) {
        const cellStartDate = props.rowResource.startRow
            .add(i, "month")
            .date(1)
            .hour(0)
            .minute(0);
        const cellEndDate = cellStartDate.add(1, "month").subtract(1, "day");

        const cellRangeInMs = cellEndDate.valueOf() - cellStartDate.valueOf();

        let values: Array<{
            value: number;
            label: string;
            color: string;
            style?: { cursor: string };
            onClick?: () => void;
        }> = [];
        let values2: Array<{
            value: number;
            label: string;
            color: string;
            style?: { cursor: string };
            onClick?: () => void;
        }> = [];

        const allocations: PlannerAllocationData[] = props.rowResource.allocations.filter(
            (allocation) => {
                if (
                    (cellEndDate.isAfter(allocation.endAllocation) &&
                        allocation.endAllocation.isAfter(cellStartDate)) ||
                    (cellEndDate.isAfter(allocation.startAllocation) &&
                        allocation.startAllocation.isAfter(cellStartDate)) ||
                    (allocation.endAllocation.isAfter(cellEndDate) &&
                        cellStartDate.isAfter(allocation.startAllocation))
                ) {
                    return true;
                }
                return false;
            }
        );

        const endsInPeriod = (allocation, array, color, secondAllocation?) => {
            const cut =
                ((allocation.endAllocation.valueOf() -
                    cellStartDate.valueOf()) /
                    cellRangeInMs) *
                100;

            array.push({
                value: cut,
                label: "sixty",
                color: color,
                style: { cursor: "pointer" },
                onClick: () => {
                    setAllocationDetails({
                        ...(allocation && allocation.allocation
                            ? allocation.allocation
                            : { campaignName: "ups" }),
                    });
                    setShowAllocationDetails(true);
                },
            });

            array.push({
                value: 100 - cut,
                label: "sixty",
                color: "grey",
            });

            return;
        };

        const startsAndEndsInPeriod = (
            allocation,
            array,
            color,
            secondAllocation?
        ) => {
            const cut =
                ((allocation.startAllocation.valueOf() -
                    cellStartDate.valueOf()) /
                    cellRangeInMs) *
                100;

            const upperCut =
                ((cellEndDate.valueOf() - allocation.endAllocation.valueOf()) /
                    cellRangeInMs) *
                100;

            if (array.length > 0 && array[array.length - 1].color === "grey") {
                array[array.length - 1] = {
                    value: array[array.length - 1].value - (100 - cut),
                    label: "sixty",
                    color: "grey",
                };
            } else if (
                array.length > 0 &&
                array[array.length - 1].color !== "grey"
            ) {
                // array[array.length - 1] = {
                //     value: array[array.length - 1] - (100 - cut),
                //     label: "sixty",
                //     color: "grey",
                // }
                array.push({
                    value: cut,
                    label: "sixty",
                    color: "grey",
                });
            } else {
                array.push({
                    value: cut,
                    label: "sixty",
                    color: "grey",
                });
            }

            array.push({
                value: 100 - cut - upperCut,
                label: "sixty",
                color: color,
                style: { cursor: "pointer" },
                onClick: () => {
                    setAllocationDetails({
                        ...(allocation && allocation.allocation
                            ? allocation.allocation
                            : { campaignName: "ups" }),
                    });
                    setShowAllocationDetails(true);
                },
            });
            array.push({
                value: upperCut,
                label: "something",
                color: "grey",
            });
        };

        const startsInPeriod = (
            allocation,
            array,
            color,
            secondAllocation?
        ) => {
            const cut =
                ((allocation.startAllocation.valueOf() -
                    cellStartDate.valueOf()) /
                    cellRangeInMs) *
                100;
            if (array.length > 0 && array[array.length - 1].color === "grey") {
                array[array.length - 1] = {
                    value: array[array.length - 1].value - (100 - cut),
                    label: "sixty",
                    color: "grey",
                };
            } else if (
                array.length > 0 &&
                array[array.length - 1].color !== "grey"
            ) {
                // array[array.length - 1] = {
                //     value: array[array.length - 1] - (100 - cut),
                //     label: "sixty",
                //     color: "grey",
                // }
                array.push({
                    value: cut,
                    label: "sixty",
                    color: "grey",
                });
            } else {
                array.push({
                    value: cut,
                    label: "sixty",
                    color: "grey",
                });
            }

            array.push({
                value: 100 - cut,
                label: "sixty",
                color: color,
                style: { cursor: "pointer" },
                onClick: () => {
                    setAllocationDetails({
                        ...(allocation && allocation.allocation
                            ? allocation.allocation
                            : { campaignName: "ups" }),
                    });
                    setShowAllocationDetails(true);
                },
            });

            return;
        };

        switch (allocations.length) {
            case 0:
                values.push({
                    value: 100,
                    label: "sixty",
                    color: "grey",
                });
                break;
            case 1:
                if (
                    allocations[0].allocation &&
                    allocations[0].allocation.slotWeight === 2
                ) {
                    if (
                        allocations[0] &&
                        allocations[0].endAllocation.isBefore(cellEndDate) &&
                        allocations[0].endAllocation.isAfter(cellStartDate) &&
                        allocations[0].startAllocation.isBefore(cellStartDate)
                    ) {
                        endsInPeriod(
                            allocations[0],
                            values2,
                            allocations[0].color
                        );
                    } else if (
                        allocations[0] &&
                        allocations[0].startAllocation.isAfter(cellStartDate) &&
                        allocations[0].startAllocation.isBefore(cellEndDate) &&
                        allocations[0].endAllocation.isAfter(cellEndDate)
                    ) {
                        startsInPeriod(
                            allocations[0],
                            values2,
                            allocations[0].color
                        );
                    } else if (
                        allocations[0] &&
                        allocations[0].startAllocation.isAfter(cellStartDate) &&
                        allocations[0].startAllocation.isBefore(cellEndDate) &&
                        allocations[0].endAllocation.isBefore(cellEndDate)
                    ) {
                        startsAndEndsInPeriod(
                            allocations[0],
                            values2,
                            allocations[0].color
                        );
                    } else {
                        values2[0] = {
                            value: 100,
                            label: "sixty",
                            color:
                                allocations[0].subSlot === 0
                                    ? allocations[0].color
                                    : "#2795f7",
                            style: { cursor: "pointer" },
                            onClick: () => {
                                setAllocationDetails({
                                    ...(allocations[0] &&
                                    allocations[0].allocation
                                        ? allocations[0].allocation
                                        : { campaignName: "ups" }),
                                });
                                setShowAllocationDetails(true);
                            },
                        };
                    }
                }

                if (
                    allocations[0] &&
                    allocations[0].allocation &&
                    allocations[0].allocation.slotWeight === 1
                ) {
                    if (allocations[0].subSlot === 0) {
                        values2[0] = {
                            value: 100,
                            label: "sixty",
                            color: "grey",
                        };
                    } else if (allocations[0].subSlot === 1) {
                        values[0] = {
                            value: 100,
                            label: "sixty",
                            color: "grey",
                        };
                    }
                }

                if (
                    allocations[0] &&
                    allocations[0].endAllocation.isBefore(cellEndDate) &&
                    allocations[0].endAllocation.isAfter(cellStartDate) &&
                    allocations[0].startAllocation.isBefore(cellStartDate)
                ) {
                    endsInPeriod(
                        allocations[0],
                        allocations[0].subSlot === 0 ? values : values2,
                        allocations[0].subSlot === 0
                            ? allocations[0].color
                            : "#2795f7"
                    );
                    break;
                }

                if (
                    allocations[0] &&
                    allocations[0].startAllocation.isAfter(cellStartDate) &&
                    allocations[0].endAllocation.isAfter(cellEndDate) &&
                    allocations[0].startAllocation.isBefore(cellEndDate)
                ) {
                    startsInPeriod(
                        allocations[0],
                        allocations[0].subSlot === 0 ? values : values2,
                        allocations[0].subSlot === 0
                            ? allocations[0].color
                            : "#2795f7"
                    );
                    break;
                }

                if (
                    allocations[0] &&
                    allocations[0].startAllocation.isAfter(cellStartDate) &&
                    allocations[0].endAllocation.isBefore(cellEndDate) &&
                    allocations[0].startAllocation.isBefore(cellEndDate)
                ) {
                    startsAndEndsInPeriod(
                        allocations[0],
                        allocations[0].subSlot === 0 ? values : values2,
                        allocations[0].subSlot === 0
                            ? allocations[0].color
                            : "#2795f7"
                    );
                    break;
                }

                if (
                    allocations[0] &&
                    allocations[0].startAllocation.isBefore(cellStartDate) &&
                    allocations[0].endAllocation.isAfter(cellEndDate)
                ) {
                    const valueArray =
                        allocations[0].subSlot === 0 ? values : values2;
                    valueArray[0] = {
                        value: 100,
                        label: "sixty",
                        color:
                            allocations[0].subSlot === 0
                                ? allocations[0].color
                                : "#2795f7",
                        style: { cursor: "pointer" },
                        onClick: () => {
                            setAllocationDetails({
                                ...(allocations[0] && allocations[0].allocation
                                    ? allocations[0].allocation
                                    : { campaignName: "ups" }),
                            });
                            setShowAllocationDetails(true);
                        },
                    };
                    break;
                }

                values[0] = {
                    value: 100,
                    label: "sixty",
                    color: "grey",
                };
                break;
            default:
                allocations
                    .sort((a, b) =>
                        a.allocation &&
                        b.allocation &&
                        a.allocation.start < b.allocation.start
                            ? -1
                            : 1
                    )
                    .forEach((allocation) => {
                        if (
                            allocation &&
                            allocation.endAllocation.isBefore(cellEndDate) &&
                            allocation.endAllocation.isAfter(cellStartDate)
                        ) {
                            endsInPeriod(
                                allocation,
                                allocation.subSlot === 0 ? values : values2,
                                allocation.subSlot === 0
                                    ? allocation.color
                                    : "#2795f7"
                            );
                            return;
                        }
                        if (
                            allocation.allocation &&
                            allocation.allocation.slotWeight === 2
                        ) {
                            if (
                                allocation &&
                                allocation.endAllocation.isBefore(
                                    cellEndDate
                                ) &&
                                allocation.endAllocation.isAfter(cellStartDate)
                            ) {
                                endsInPeriod(
                                    allocation,
                                    values2,
                                    allocation.color
                                );
                            }

                            if (
                                allocation &&
                                allocation.startAllocation.isAfter(
                                    cellStartDate
                                ) &&
                                allocation.startAllocation.isBefore(cellEndDate)
                            ) {
                                startsInPeriod(
                                    allocation,
                                    values2,
                                    allocation.color
                                );
                            }
                        }
                        if (
                            allocation &&
                            allocation.startAllocation.isAfter(cellStartDate) &&
                            allocation.startAllocation.isBefore(cellEndDate)
                        ) {
                            startsInPeriod(
                                allocation,
                                allocation.subSlot === 0 ? values : values2,
                                allocation.subSlot === 0
                                    ? allocation.color
                                    : "#2795f7"
                            );
                            return;
                        }

                        if (
                            allocation &&
                            allocation.startAllocation.isBefore(
                                cellStartDate
                            ) &&
                            allocation.endAllocation.isAfter(cellEndDate)
                        ) {
                            const valueArray =
                                allocation.subSlot === 0 ? values : values2;
                            valueArray[0] = {
                                value: 100,
                                label: "sixty",
                                color:
                                    allocation.subSlot === 0
                                        ? allocation.color
                                        : "#2795f7",
                                style: { cursor: "pointer" },
                                onClick: () => {
                                    setAllocationDetails({
                                        ...(allocation && allocation.allocation
                                            ? allocation.allocation
                                            : { campaignName: "ups" }),
                                    });
                                    setShowAllocationDetails(true);
                                },
                            };
                        }
                    });
                break;
        }

        cellData[i] = {
            start: cellStartDate,
            isInsider: allocations.length > 0,
            values: values,
            values2: values2,
            slot: props.rowResource.slot,
        };
    }

    return (
        <TableRow>
            <TableCell style={{ padding: "1px !important" }}>
                {props.rowResource.slot}
            </TableCell>
            {Object.keys(cellData).map((key) => {
                const cell = cellData[key];
                if (cell.values2.length > 0) {
                    return (
                        <TableCell
                            key={key}
                            style={{ paddingRight: "1px !important" }}
                        >
                            {
                                <Box direction="column">
                                    <Meter
                                        values={[...cell.values]}
                                        size="large"
                                        aria-label="meter"
                                        thickness="medium"
                                        margin={{ bottom: "1px" }}
                                    />
                                    <Meter
                                        values={[...cell.values2]}
                                        size="large"
                                        aria-label="meter"
                                        thickness="medium"
                                    />
                                </Box>
                            }
                        </TableCell>
                    );
                }
                return (
                    <TableCell
                        key={key}
                        style={{ paddingRight: "1px !important" }}
                    >
                        {
                            <Meter
                                values={[...cell.values]}
                                size="large"
                                aria-label="meter"
                                thickness="41px"
                            />
                        }
                    </TableCell>
                );
            })}
            {showAllocationDetails && (
                <Layer
                    onEsc={() => setShowAllocationDetails(false)}
                    onClickOutside={() => setShowAllocationDetails(false)}
                    full={false}
                    style={{ zIndex: 999 }}
                >
                    <Box pad="large" style={{ zIndex: 999 }}>
                        <Box>
                            <Heading level={3}>
                                {allocationDetails.customerName}{" "}
                                {allocationDetails.campaignName
                                    ? "- " + allocationDetails.campaignName
                                    : ""}
                            </Heading>
                        </Box>
                        <Box>
                            <Text margin={{ bottom: "small" }}>
                                Paket:{" "}
                                <b>
                                    {allocationDetails.slotWeight === 1
                                        ? "Comfort"
                                        : "Premium"}
                                </b>
                            </Text>
                            <Text margin={{ bottom: "small" }}>
                                Laufzeit:{" "}
                                <b>
                                    {dayjs(allocationDetails.end).diff(
                                        dayjs(allocationDetails.start),
                                        "month"
                                    )}{" "}
                                    Monate
                                </b>
                            </Text>
                            <Text margin={{ bottom: "small" }}>
                                Start:{" "}
                                <b>
                                    {dayjs(allocationDetails.start).format(
                                        "DD.MM.YYYY"
                                    )}
                                </b>
                                <br />
                                Ende:{" "}
                                <b>
                                    {dayjs(allocationDetails.end).format(
                                        "DD.MM.YYYY"
                                    )}
                                </b>
                            </Text>
                            <br />
                        </Box>
                        <Button
                            label="Schließen"
                            onClick={() => setShowAllocationDetails(false)}
                        />
                    </Box>
                </Layer>
            )}
        </TableRow>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        isFetching: state.allocationReducer.isFetching,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlannerRow);
