import {
    Box,
    Button,
    Heading,
    Keyboard,
    Layer,
    ResponsiveContext,
    Tab,
    Tabs,
} from "grommet";
import {
    Add,
    Close,
    Edit,
    FormPreviousLink,
    Save,
    Subtract,
} from "grommet-icons";
import React, { useEffect, useState } from "react";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import { connect } from "react-redux";
import settings from "../../settings";
import {
    addStationForNewCampaign,
    getCampaignsByStation,
    removeStationForNewCampaign,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import {
    resetRequestStatus,
    updateStation,
} from "../../store/stations/actions";
import { RequestStatus, User, UserRole } from "../../types/Authentication";
import Campaign from "../../types/Campaign";
import Allocation from "../../types/Resource";
import Station, {
    IndoorScreenVersion,
    OutdoorScreenVersion,
} from "../../types/Station";
import StationDetailEditView from "./StationDetailEditView";
import StationDetailsCostsView from "./StationDetailsCostsView";
import StationDetailsOverviewView from "./StationDetailsOverviewView";
import StationDetailsSalesView from "./StationDetailsSalesView";

interface StationDetailsProps {
    station: Station;
    user: User | null;
    stationsForNewCampaign: Station[];
    campaignsForStation: { stationId: number | null; campaigns: Campaign[] };
    allocations: {
        [key: number]: Allocation[];
    };
    setShowStationDetails: any;
    removeStationForNewCampaign: (id: Station) => {};
    addStationForNewCampaign: (id: Station) => {};
    updateStation: (station: Station) => void;
    getCampaignsByStation: (stationId: number) => void;
    resetRequestStatus: () => void;
    requestStatus: null | RequestStatus;
}

const StationDetails: React.FC<any> = (props: StationDetailsProps) => {
    const [stationState, setStationState] = useState({
        ...props.station,
        outdoorScreenCost: props.station.outdoorScreenVersion
            ? settings.FIXED_PRICES.OUTDOOR[props.station.outdoorScreenVersion]
            : settings.FIXED_PRICES.OUTDOOR[
                  OutdoorScreenVersion.SEVENTY_FIVE_INCH
              ],
        indoorScreenCost: props.station.indoorScreenVersion
            ? settings.FIXED_PRICES.INDOOR[props.station.indoorScreenVersion]
            : settings.FIXED_PRICES.INDOOR[IndoorScreenVersion.STANDING_INDOOR],
    });

    const [editable, setEditable] = useState(false);

    const isMarkedForNewCampaign = props.stationsForNewCampaign.find(
        (station) => props.station.shellId === station.shellId
    );

    useEffect(() => {
        setStationState(props.station);
        const requestedStationId = props.campaignsForStation.stationId;
        if (
            requestedStationId === null ||
            requestedStationId !== props.station.id
        )
            props.getCampaignsByStation(props.station.id);
    }, [props.station]);

    useEffect(() => {
        setStationState(props.station);
        props.getCampaignsByStation(props.station.id);
    }, []);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Keyboard
                    onEsc={() => {
                        if (!editable) {
                            props.setShowStationDetails(null);
                            return;
                        } else {
                            setEditable(false);
                        }
                    }}
                >
                    <Box background="white">
                        {props.requestStatus !== null && (
                            <Layer
                                onEsc={() => props.resetRequestStatus()}
                                onClickOutside={() =>
                                    props.resetRequestStatus()
                                }
                            >
                                <Box pad="small" align="center">
                                    {props.requestStatus ===
                                        RequestStatus.SUCCESS &&
                                        "Daten Gespeichert!"}
                                    {props.requestStatus ===
                                        RequestStatus.FETCHING &&
                                        "speichert..."}
                                    {props.requestStatus ===
                                        RequestStatus.ERROR &&
                                        "Ups - ein Fehler!"}
                                    {props.requestStatus !==
                                        RequestStatus.FETCHING && (
                                        <Button
                                            margin={{ top: "small" }}
                                            label="Schließen"
                                            onClick={() =>
                                                props.resetRequestStatus()
                                            }
                                        />
                                    )}
                                </Box>
                            </Layer>
                        )}
                        <Box
                            style={{
                                minHeight: size === "large" ? "72px" : "180px",
                            }}
                        >
                            <Box
                                direction="row"
                                justify="between"
                                fill="horizontal"
                                style={{
                                    minHeight:
                                        size === "large" ? "72px" : "180px",
                                }}
                            >
                                <Box margin="medium">
                                    <Heading margin="none" level={2}>
                                        Shell-OBN: {stationState.shellId}
                                    </Heading>
                                </Box>
                                <Box
                                    margin={{ top: "medium" }}
                                    align={size === "large" ? "start" : "end"}
                                    direction={
                                        size !== "large"
                                            ? "column"
                                            : "row-reverse"
                                    }
                                    height={size !== "large" ? "8rem" : "2rem"}
                                    pad={{ right: "small" }}
                                >
                                    <Button
                                        onClick={() =>
                                            props.setShowStationDetails(null)
                                        }
                                        color="neutral-1"
                                        hoverIndicator={{
                                            color: "neutral-1",
                                            light: "true",
                                        }}
                                        label="Schließen"
                                        type="reset"
                                        icon={<Close />}
                                        margin={
                                            size === "large"
                                                ? { left: "2rem" }
                                                : {
                                                      left: "2rem",
                                                      bottom: "0.7rem",
                                                  }
                                        }
                                    />
                                    {props.user &&
                                        props.user.authentication.role ===
                                            UserRole.ADMIN && (
                                            <Button
                                                onClick={
                                                    !editable
                                                        ? () =>
                                                              setEditable(
                                                                  !editable
                                                              )
                                                        : () => {
                                                              setEditable(
                                                                  !editable
                                                              );
                                                              props.updateStation(
                                                                  stationState
                                                              );
                                                          }
                                                }
                                                color={
                                                    !editable
                                                        ? "warning"
                                                        : "success"
                                                }
                                                label={
                                                    !editable
                                                        ? "Bearbeiten"
                                                        : "Speichern"
                                                }
                                                hoverIndicator={
                                                    !editable
                                                        ? "warning"
                                                        : "success"
                                                }
                                                icon={
                                                    !editable ? (
                                                        <Edit />
                                                    ) : (
                                                        <Save />
                                                    )
                                                }
                                                margin={
                                                    size === "large"
                                                        ? { left: "2rem" }
                                                        : {
                                                              left: "2rem",
                                                              bottom: "0.7rem",
                                                          }
                                                }
                                            />
                                        )}
                                    {props.user &&
                                        props.user.authentication.role ===
                                            UserRole.ADMIN && (
                                            <Box>
                                                {editable && (
                                                    <Button
                                                        onClick={
                                                            !editable
                                                                ? () =>
                                                                      setEditable(
                                                                          !editable
                                                                      )
                                                                : () => {
                                                                      setEditable(
                                                                          !editable
                                                                      );
                                                                      props.updateStation(
                                                                          stationState
                                                                      );
                                                                  }
                                                        }
                                                        color={"warning"}
                                                        label={"Abbrechen"}
                                                        hoverIndicator={
                                                            "warning"
                                                        }
                                                        // primary
                                                        // ={size === 'large' ? {} : { left: '2rem', bottom: '2rem' }}
                                                        icon={
                                                            <FormPreviousLink />
                                                        }
                                                        margin={
                                                            size === "large"
                                                                ? {
                                                                      left:
                                                                          "2rem",
                                                                  }
                                                                : {
                                                                      left:
                                                                          "2rem",
                                                                      bottom:
                                                                          "0.7rem",
                                                                  }
                                                        }
                                                    />
                                                )}
                                                {!editable && (
                                                    <Button
                                                        icon={
                                                            isMarkedForNewCampaign ? (
                                                                <Subtract />
                                                            ) : (
                                                                <Add />
                                                            )
                                                        }
                                                        onClick={
                                                            isMarkedForNewCampaign
                                                                ? () =>
                                                                      props.removeStationForNewCampaign(
                                                                          stationState
                                                                      )
                                                                : () =>
                                                                      props.addStationForNewCampaign(
                                                                          stationState
                                                                      )
                                                        }
                                                        label={
                                                            isMarkedForNewCampaign
                                                                ? "Aus Auftrag entfernen"
                                                                : "Zu Auftrag hinzufügen"
                                                        }
                                                        disabled={editable}
                                                        color={
                                                            isMarkedForNewCampaign
                                                                ? "danger"
                                                                : "success"
                                                        }
                                                        hoverIndicator={
                                                            isMarkedForNewCampaign
                                                                ? "danger"
                                                                : "success"
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        </Box>
                        {editable &&
                            props.user &&
                            props.user.authentication.role ===
                                UserRole.ADMIN && (
                                <StationDetailEditView
                                    station={stationState}
                                    setStationState={setStationState}
                                />
                            )}
                        {!editable && (
                            <Tabs
                                style={{
                                    backgroundColor: "white",
                                    overflow: "hidden",
                                    paddingTop: "10px",
                                }}
                            >
                                <Tab title="Übersicht">
                                    <Box
                                        overflow="scroll"
                                        height="100%"
                                        direction="column"
                                        justify="between"
                                        border="top"
                                        margin={{ top: "small" }}
                                    >
                                        <StationDetailsOverviewView
                                            station={stationState}
                                        />
                                    </Box>
                                </Tab>
                                <Tab title="Vertrieb">
                                    <Box
                                        overflow="scroll"
                                        height="100%"
                                        direction="column"
                                        justify="between"
                                        border="top"
                                        margin={{ top: "small" }}
                                    >
                                        <StationDetailsSalesView
                                            station={props.station}
                                            campaigns={
                                                props.campaignsForStation
                                                    .stationId !==
                                                props.station.id
                                                    ? []
                                                    : props.campaignsForStation
                                                          .campaigns
                                            }
                                        />
                                    </Box>
                                </Tab>
                                {props.user &&
                                    (props.user.authentication.role ===
                                        UserRole.ADMIN ||
                                        props.user.authentication.role ===
                                            UserRole.VIEW_ONLY) && (
                                        <Tab title="Internas">
                                            <Box
                                                overflow="scroll"
                                                height="100%"
                                                direction="column"
                                                justify="between"
                                                border="top"
                                                margin={{ top: "small" }}
                                            >
                                                <StationDetailsCostsView
                                                    station={stationState}
                                                    user={props.user}
                                                />
                                            </Box>
                                        </Tab>
                                    )}
                            </Tabs>
                        )}
                    </Box>
                    {/* <Box 
                        style={{ overflow: "scroll" }}> */}

                    {/* <Box
                            // margin="medium"
                            // direction="row"
                            // justify="between"
                            // height={size !== "large" ? "13rem" : "6rem"}

                            style={{
                                // minHeight: size !== "large" ? "9rem" : "6rem",
                                // width: "100%",
                                // position: "fixed",
                                // zIndex: 20,
                                backgroundColor: "white",
                            }}
                        >
                            
                        </Box> */}

                    {/*                         
                        <Box></Box>
                    </Box>
                        </Tab> */}

                    {/* </Tabs> */}
                </Keyboard>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        user: state.usersReducer.user,
        campaignsForStation: state.campaignReducer.campaignsForStation,
        requestStatus: state.stationsReducer.requestStatus,
    };
};

const mapDispatchToProps = {
    removeStationForNewCampaign,
    addStationForNewCampaign,
    updateStation,
    getCampaignsByStation,
    resetRequestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(StationDetails);
