import dayjs from "dayjs";
import Campaign, {
    CampaignDuration,
    NewCampaignRequestStatus,
    NewCampaignResponse,
} from "../../types/Campaign";
import Station from "../../types/Station";

export interface CampaignState {
    campaigns: Campaign[];
    campaignsForStation: {
        stationId: number | null;
        campaigns: Campaign[];
    };
    stationsForNewCampaign: Station[];
    stationsForCurrentCampaign: Station[];
    isFetching: boolean;
    filterDateFrom: dayjs.Dayjs;
    filterContractDuration: CampaignDuration;
    filterSlotWeight: number;
    newCampaignRequestStatus: NewCampaignRequestStatus;
    putCampaignRequestStatus: NewCampaignRequestStatus;
}

export const ADD_STATION_TO_NEW_CAMPAIGN = "ADD_STATION_TO_NEW_CAMPAIGN";
export const ADD_STATIONS_TO_NEW_CAMPAIGN = "ADD_STATIONS_TO_NEW_CAMPAIGN";
export const ADD_STATION_TO_CURRENT_CAMPAIGN =
    "ADD_STATION_TO_CURRENT_CAMPAIGN";
export const ADD_STATIONS_TO_CURRENT_CAMPAIGN =
    "ADD_STATIONS_TO_CURRENT_CAMPAIGN";
export const REQUEST_CAMPAIGNS = "REQUEST_CAMPAIGNS";
export const RECEIVE_CAMPAIGNS = "RECEIVE_CAMPAIGNS";
export const REQUEST_CAMPAIGNS_BY_STATION = "REQUEST_CAMPAIGNS_BY_STATION";
export const RECEIVE_CAMPAIGNS_BY_STATION = "RECEIVE_CAMPAIGNS_BY_STATION";
export const REQUEST_DELETE_CAMPAIGN = "REQUEST_DELETE_CAMPAIGN";
export const RECEIVE_DELETE_CAMPAIGN = "RECEIVE_DELETE_CAMPAIGN";
export const REQUEST_PUT_CAMPAIGN = "REQUEST_PUT_CAMPAIGN";
export const RECEIVE_PUT_CAMPAIGN = "RECEIVE_PUT_CAMPAIGN";
export const REQUEST_APPROVE_CAMPAIGN = "REQUEST_APPROVE_CAMPAIGN";
export const RECEIVE_APPROVE_CAMPAIGN = "RECEIVE_APPROVE_CAMPAIGN";
export const POST_NEW_CAMPAIGN = "POST_NEW_CAMPAIGN";
export const REQUEST_NEW_CAMPAIGN = "REQUEST_NEW_CAMPAIGN";
export const SET_NEW_CAMPAIGN_REQUEST_STATUS =
    "SET_NEW_CAMPAIGN_REQUEST_STATUS";
export const SET_PUT_CAMPAIGN_REQUEST_STATUS =
    "SET_PUT_CAMPAIGN_REQUEST_STATUS";
export const REMOVE_STATION_TO_NEW_CAMPAIGN = "REMOVE_STATION_TO_NEW_CAMPAIGN";
export const SET_STATION_FOR_CURRENT_CAMPAIGN =
    "SET_STATION_FOR_CURRENT_CAMPAIGN";
export const SET_STATIONS_FOR_NEW_CAMPAIGN = "SET_STATIONS_FOR_NEW_CAMPAIGN";
export const REMOVE_STATION_FROM_CURRENT_CAMPAIGN =
    "REMOVE_STATION_FROM_CURRENT_CAMPAIGN";
export const RECEIVE_NEW_CAMPAIGN = "RECEIVE_NEW_CAMPAIGN";
export const CLEAR_CAMPAIGN_AFTER_LOGOUT = "CLEAR_CAMPAIGN_AFTER_LOGOUT";
export const SET_FILTER_FROM_DATE = "SET_FILTER_FROM_DATE";
export const SET_FILTER_CONTRACT_DURATION = "SET_FILTER_CONTRACT_DURATION";
export const SET_FILTER_SLOT_WEIGHT = "SET_FILTER_SLOT_WEIGHT";

interface RequestCampaignsAction {
    type: typeof REQUEST_CAMPAIGNS;
}

interface RequestCampaignsByStationAction {
    type: typeof REQUEST_CAMPAIGNS_BY_STATION;
}

interface RequestDeleteCampaignsAction {
    type: typeof REQUEST_DELETE_CAMPAIGN;
}

interface RequestPutCampaignsAction {
    type: typeof REQUEST_PUT_CAMPAIGN;
}

interface RequestApproveCampaignsAction {
    type: typeof REQUEST_APPROVE_CAMPAIGN;
}

interface RequestNewCampaignAction {
    type: typeof REQUEST_NEW_CAMPAIGN;
}

interface SetNewCampaignStatusAction {
    type: typeof SET_NEW_CAMPAIGN_REQUEST_STATUS;
    payload: NewCampaignRequestStatus;
}

interface SetPutCampaignStatusAction {
    type: typeof SET_PUT_CAMPAIGN_REQUEST_STATUS;
    payload: NewCampaignRequestStatus;
}

interface ClearCampaignsAction {
    type: typeof CLEAR_CAMPAIGN_AFTER_LOGOUT;
}

interface ReceiveCampaignsAction {
    type: typeof RECEIVE_CAMPAIGNS;
    payload: Campaign[];
}

interface ReceiveCampaignsByStationAction {
    type: typeof RECEIVE_CAMPAIGNS_BY_STATION;
    payload: {
        stationId: number | null;
        campaigns: Campaign[];
    };
}

interface ReceiveDeleteCampaignsAction {
    type: typeof RECEIVE_DELETE_CAMPAIGN;
}

interface ReceivePutCampaignsAction {
    type: typeof RECEIVE_PUT_CAMPAIGN;
    payload: NewCampaignRequestStatus;
}

interface ReceiveApproveCampaignsAction {
    type: typeof RECEIVE_APPROVE_CAMPAIGN;
}

interface PostCampaignAction {
    type: typeof POST_NEW_CAMPAIGN;
    payload: NewCampaignResponse;
}

interface AddStationToNewCampaignAction {
    type: typeof ADD_STATION_TO_NEW_CAMPAIGN;
    payload: Station;
}

interface AddStationsToNewCampaignAction {
    type: typeof ADD_STATIONS_TO_NEW_CAMPAIGN;
    payload: Station[];
}

interface RemoveStationToNewCampaignAction {
    type: typeof REMOVE_STATION_TO_NEW_CAMPAIGN;
    payload: Station;
}

interface SetStationsForCurrentCampaignAction {
    type: typeof SET_STATION_FOR_CURRENT_CAMPAIGN;
    payload: Station[];
}

interface SetStationsForNewCampaignAction {
    type: typeof SET_STATIONS_FOR_NEW_CAMPAIGN;
    payload: Station[];
}

interface AddStationToCurrentCampaignAction {
    type: typeof ADD_STATION_TO_CURRENT_CAMPAIGN;
    payload: Station;
}

interface AddStationsToCurrentCampaignAction {
    type: typeof ADD_STATIONS_TO_CURRENT_CAMPAIGN;
    payload: Station[];
}

interface RemoveStationToCurrentCampaignAction {
    type: typeof REMOVE_STATION_FROM_CURRENT_CAMPAIGN;
    payload: Station;
}

interface SetFilterDateFromAction {
    type: typeof SET_FILTER_FROM_DATE;
    payload: dayjs.Dayjs;
}

interface SetFilterContractDurationAction {
    type: typeof SET_FILTER_CONTRACT_DURATION;
    payload: CampaignDuration;
}

interface SetFilterSlotWeightAction {
    type: typeof SET_FILTER_SLOT_WEIGHT;
    payload: number;
}

export type CampaignsActionType =
    | AddStationToNewCampaignAction
    | AddStationsToNewCampaignAction
    | AddStationToCurrentCampaignAction
    | AddStationsToCurrentCampaignAction
    | RequestCampaignsAction
    | RequestCampaignsByStationAction
    | RequestDeleteCampaignsAction
    | RequestApproveCampaignsAction
    | ReceiveCampaignsAction
    | ReceiveCampaignsByStationAction
    | ReceiveDeleteCampaignsAction
    | ReceiveApproveCampaignsAction
    | PostCampaignAction
    | ClearCampaignsAction
    | RemoveStationToNewCampaignAction
    | RemoveStationToCurrentCampaignAction
    | SetStationsForCurrentCampaignAction
    | SetStationsForNewCampaignAction
    | SetFilterDateFromAction
    | SetFilterContractDurationAction
    | SetFilterSlotWeightAction
    | SetNewCampaignStatusAction
    | SetPutCampaignStatusAction
    | RequestNewCampaignAction
    | RequestPutCampaignsAction
    | ReceivePutCampaignsAction;
