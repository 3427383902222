import dayjs from "dayjs";
import { Box, Button, Grid, Keyboard, ResponsiveContext, Text } from "grommet";
import { Login } from "grommet-icons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ResourcerMap from "../../elements/GoogleMapsContainer";
import MapList from "../../elements/MapList/MapList";
import MapSubHeadline from "../../elements/MapSubHeadline";
import NewCampaignForm from "../../elements/NewCampaignForm";
import StationDetails from "../../elements/StationDetails/StationDetails";
import { getAllocationsForStationByDate } from "../../store/allocations/actions";
import { rootState } from "../../store/rootReducer";
import {
    findStationsByRange,
    getAllStations,
} from "../../store/stations/actions";
import { User } from "../../types/Authentication";
import Station, { Location } from "../../types/Station";

interface MapProps {
    range: number;
    center: Location;
    loggedIn: boolean;
    stations: Station[];
    user: User | null;
    isFetchingAllocations: boolean;
    isFetching: boolean;
    findStationsByRange: (
        address: string | undefined,
        range: string,
        skipGeSearch: boolean,
        center: Location
    ) => {};
    getAllocationsForStationByDate: (paramter: {
        stationId: number;
        start: number;
        end: number;
    }) => {};
    getAllStations: () => void;
    stationsForNewCampaign: Station[];
}

// const AnyReactComponent: React.FC<MapMarker & JSX.IntrinsicElements['div']> = (props) => <img style={{ height: '40px', top: '-40px', left: '-20px', position: 'absolute' }} alt='Map Marker' src={mapMarker} />;

const App: React.FC<MapProps> = (props) => {
    const [showStationDetails, setShowStationDetails] =
        React.useState<Station>();
    const [showNewCampaignForm, setShowNewCampaignForm] = React.useState(false);
    const [showMapOnMobile, setShowMapOnMobile] = React.useState(false);
    const [filterFunctionObject, setFilterFunctionObject] = React.useState({});
    const [regionFilterFunctionObject, setRegionFilterFunctionObject] =
        React.useState({});
    const [filteredStations, setFilteredStations] = React.useState<
        Array<Station>
    >([]);

    useEffect(() => {
        if (props.user) {
            props.findStationsByRange("", "" + props.range, true, props.center);
        }
    }, []);

    useEffect(() => {
        console.log(filterFunctionObject);
        setFilteredStations(
            props.stations.filter((station) => {
                const functionKeyArray = Object.keys(filterFunctionObject);
                return functionKeyArray.reduce((acc, key) => {
                    return acc && filterFunctionObject[key](station);
                }, true);
            })
        );
    }, [props.stations, filterFunctionObject]);

    const handleStationSelect = (station: Station) => {
        props.getAllocationsForStationByDate({
            stationId: station.id,
            start: dayjs()
                .date(1)
                .hour(0)
                .minute(0)
                .subtract(1, "month")
                .valueOf(),
            end: dayjs().date(1).hour(0).minute(0).add(6, "month").valueOf(),
        });
        setShowStationDetails(station);
        setShowNewCampaignForm(false);
    };

    const handleNewCampaignSelect = (select: boolean) => {
        setShowNewCampaignForm(select);
    };

    const handleFilterChange = (functionObject: {
        [key: string]: (station: Station) => boolean;
    }) => {
        setFilterFunctionObject({
            ...regionFilterFunctionObject,
            ...functionObject,
        });
    };

    const handleRegionFilterChange = (functionObject: {
        [key: string]: (station: Station) => boolean;
    }) => {
        setRegionFilterFunctionObject({
            ...functionObject,
        });
        setFilterFunctionObject({
            ...filterFunctionObject,
            ...functionObject,
        });
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Keyboard
                    onEsc={() => {
                        setShowNewCampaignForm(false);
                    }}
                >
                    <div>
                        {size !== "small" && (
                            <Grid
                                rows={[
                                    ["54px", "54px"],
                                    [
                                        "calc(100vh - 54px - 55px)",
                                        "calc(100vh - 54px  - 55px)",
                                    ],
                                ]}
                                columns={[["xsmall", "xxsmall"], "3fr 9fr"]}
                                fill={true}
                                areas={[
                                    {
                                        name: "header",
                                        start: [0, 0],
                                        end: [12, 0],
                                    },
                                    { name: "nav", start: [0, 1], end: [2, 1] },
                                    {
                                        name: "main",
                                        start: [2, 1],
                                        end: [12, 1],
                                    },
                                ]}
                            >
                                <MapSubHeadline
                                    handleNewCampaignSelect={
                                        handleNewCampaignSelect
                                    }
                                    initialFilterObject={filterFunctionObject}
                                    handleFilterChange={handleFilterChange}
                                />
                                <Box gridArea="nav" background="white">
                                    {
                                        <MapList
                                            handleSelection={
                                                handleStationSelect
                                            }
                                            handleNewCampaignSelect={
                                                handleNewCampaignSelect
                                            }
                                            stations={filteredStations}
                                            setShowStationDetails={
                                                setShowStationDetails
                                            }
                                            initialFilterObject={
                                                regionFilterFunctionObject
                                            }
                                            handleFilterChange={
                                                handleRegionFilterChange
                                            }
                                        />
                                    }
                                    {/* {!props.loggedIn && (
                                        <Box pad="small">
                                            Bitte loggen Sie sich ein
                                            <Link to="/login">
                                                <Button
                                                    label="Login"
                                                    color="accent-1"
                                                />
                                            </Link>
                                        </Box>
                                    )} */}
                                </Box>
                                {
                                    <Box
                                        gridArea="main"
                                        background="white"
                                        border="left"
                                    >
                                        {props.loggedIn &&
                                            !showNewCampaignForm &&
                                            showStationDetails && (
                                                <StationDetails
                                                    station={showStationDetails}
                                                    setShowStationDetails={
                                                        setShowStationDetails
                                                    }
                                                />
                                            )}
                                        {!showNewCampaignForm &&
                                            !showStationDetails && (
                                                <Box height="100%">
                                                    <ResourcerMap
                                                        handleStationSelect={
                                                            handleStationSelect
                                                        }
                                                        stations={
                                                            filteredStations
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        {props.loggedIn && showNewCampaignForm && (
                                            <Box height="100%">
                                                <NewCampaignForm
                                                    handleNewCampaignSelect={
                                                        handleNewCampaignSelect
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                }
                            </Grid>
                        )}
                        {size === "small" && (
                            <Grid
                                rows={[
                                    ["3rem", "3rem"],
                                    [
                                        "calc(100vh - 3rem - 55px)",
                                        "calc(100vh - 3rem - 55px)",
                                    ],
                                ]}
                                columns={[["xsmall", "xxsmall"], "100%"]}
                                fill={true}
                                areas={[
                                    {
                                        name: "header",
                                        start: [0, 0],
                                        end: [12, 0],
                                    },
                                    // { name: 'nav', start: [0, 1], end: [2, 1] },
                                    {
                                        name: "main",
                                        start: [0, 1],
                                        end: [12, 1],
                                    },
                                ]}
                            >
                                {props.user &&
                                    props.user.authentication.role !==
                                        "WUERTH" && (
                                        <MapSubHeadline
                                            handleNewCampaignSelect={
                                                handleNewCampaignSelect
                                            }
                                        />
                                    )}

                                {/* <Box gridArea='header' background='brand' /> */}
                                <Box
                                    gridArea="main"
                                    background="white"
                                    border="left"
                                    width={size === "small" ? "100vw" : ""}
                                >
                                    {!showNewCampaignForm &&
                                        showStationDetails &&
                                        props.user &&
                                        props.user.authentication.role !==
                                            "WUERTH" && (
                                            <StationDetails
                                                station={showStationDetails}
                                                setShowStationDetails={
                                                    setShowStationDetails
                                                }
                                            />
                                        )}
                                    {showNewCampaignForm && (
                                        <Box height="100%">
                                            <NewCampaignForm
                                                handleNewCampaignSelect={
                                                    handleNewCampaignSelect
                                                }
                                            />
                                        </Box>
                                    )}
                                    {!showNewCampaignForm &&
                                        !showStationDetails &&
                                        showMapOnMobile && (
                                            <Box height="100%">
                                                <Box
                                                    direction="column"
                                                    align="center"
                                                    height="100%"
                                                    width="100vw"
                                                >
                                                    {/* {props.isFetching} */}
                                                    <Box
                                                        height="100%"
                                                        width="100vw"
                                                    >
                                                        <ResourcerMap
                                                            handleStationSelect={
                                                                handleStationSelect
                                                            }
                                                            handleShowMap={
                                                                setShowMapOnMobile
                                                            }
                                                            stations={
                                                                props.stations
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    {props.loggedIn &&
                                        !showNewCampaignForm &&
                                        !showStationDetails &&
                                        !showMapOnMobile && (
                                            <MapList
                                                handleShowMap={
                                                    setShowMapOnMobile
                                                }
                                                handleSelection={
                                                    handleStationSelect
                                                }
                                                handleNewCampaignSelect={
                                                    handleNewCampaignSelect
                                                }
                                                stations={props.stations}
                                                initialFilterObject={
                                                    regionFilterFunctionObject
                                                }
                                                handleFilterChange={
                                                    handleRegionFilterChange
                                                }
                                            />
                                        )}
                                    {!props.loggedIn && (
                                        <Box pad="small" align="center">
                                            <Text
                                                margin={{ vertical: "medium" }}
                                            >
                                                Bitte melden Sie sich an.
                                            </Text>
                                            <Link to="/login">
                                                <Button
                                                    label="Anmelden"
                                                    color="accent-1"
                                                    icon={<Login />}
                                                />
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </div>
                </Keyboard>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        stations: state.stationsReducer.stations,
        user: state.usersReducer.user,
        loggedIn: state.usersReducer.loggedIn,
        center: state.stationsReducer.currentCenter,
        range: state.stationsReducer.currentRange,
        isFetchingAllocations: state.allocationReducer.isFetching,
        isFetching: state.stationsReducer.isFetching,
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
    };
};

const mapDispatchToProps = {
    findStationsByRange,
    getAllocationsForStationByDate,
    getAllStations,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
