import { Grommet } from "grommet";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./createStore";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

ReactDOM.render(
    <Provider store={store}>
        <Grommet theme={theme}>
            <App />
        </Grommet>
    </Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
