import devSettings from "./settings.dev";
import prodSettings from "./settings.prod";
import stagingSettings from "./settings.staging";

let baseSettings = {
    API: {
        BASE_URL: "http://localhost:8080",
        API_PATH: "/api",
    },
    API_END_POINTS: {
        STATIONS: "/stations",
        STATIONS_BY_RANGE: "/stations/findByRange",
        // STATIONS_BY_RANGE_PUBLIC: "/stations/findByRangePublic",
        ASSIGNED_STATIONS: "/stations/assigned",
        ALLOCATIONS: "/allocations",
        ALLOCATIONS_BY_ID_AND_DATE: "/allocations/findByStationAndPeriod",
        ALLOCATIONS_BY_IDS_AND_DATE: "/allocations/findByStationsAndPeriod",
        OAUTH_TOKEN: "/oauth/token",
        LOGOUT: "/logout",
        CAMPAIGNS: "/campaigns",
        INFO: "/info",
        CUSTOMERS: "/customers",
    },
    MAP_PROXY_URL: {
        BASE_URL: "http://localhost:3030",
    },
    FIXED_PRICES: {
        OUTDOOR: {
            EIGHTY_FIVE_INCH: 15174 + 945 + 1048 + 45 + 194,
            SEVENTY_FIVE_INCH: 7996 + 825 + 988 + 45 + 194,
        },
        INDOOR: {
            HANGING_INDOOR: 2776 + 295,
            STANDING_INDOOR: 2776 + 490,
            OUTDOOR_ONLY: 0,
            INDOOR_ONLY: 0,
            NONE: 0,
        },
        ELECTRICITY: {
            2020: {
                KWH_PER_DAY: {
                    EIGHTY_FIVE_INCH: 9.38,
                    SEVENTY_FIVE_INCH: 7.65,
                },
                EURO_PER_KWH: 0.22,
            },
            2021: {
                KWH_PER_DAY: {
                    EIGHTY_FIVE_INCH: 9.38,
                    SEVENTY_FIVE_INCH: 7.65,
                },
                EURO_PER_KWH: 0.22,
            },
            2022: {
                KWH_PER_DAY: {
                    EIGHTY_FIVE_INCH: 13.058,
                    SEVENTY_FIVE_INCH: 10.258,
                },
                EURO_PER_KWH: 0.2323,
            },
            2023: {
                KWH_PER_DAY: {
                    EIGHTY_FIVE_INCH: 13.058,
                    SEVENTY_FIVE_INCH: 10.258,
                },
                EURO_PER_KWH: 0.2565,
            },
            2024: {
                KWH_PER_DAY: {
                    EIGHTY_FIVE_INCH: 13.058,
                    SEVENTY_FIVE_INCH: 10.258,
                },
                EURO_PER_KWH: 0.2565,
            },
        },
    },
};

let settings;

if (process.env.REACT_APP_CUSTOM_NODE_ENV === "staging") {
    settings = {
        ...baseSettings,
        ...stagingSettings,
    };
} else if (process.env.NODE_ENV === "production") {
    settings = {
        ...baseSettings,
        ...prodSettings,
    };
} else {
    settings = {
        ...baseSettings,
        ...devSettings,
    };
}

export default settings;
