import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";
import { StatusInfo } from "grommet-icons";
import React, { MouseEvent } from "react";
import { connect } from "react-redux";
import {
    addStationForNewCampaign,
    removeStationForNewCampaign,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import { User, UserRole } from "../../types/Authentication";
import Station from "../../types/Station";

interface listItemProps {
    station: Station;
    user: User | null;
    lastSelectedItem: boolean;
    onClick(
        event: MouseEvent<HTMLAnchorElement | HTMLButtonElement | MouseEvent>
    ): void;
    stationsForNewCampaign: Station[];
    addStationForNewCampaign?: any;
    removeStationForNewCampaign?: any;
}

const MapListItem: React.FC<listItemProps> = (props) => {
    const amountFreeSlots = props.station.freeSlotsInPeriodCount * 2;
    let freeSlotsArray: string[] = [];

    for (let i = 1; i < 11; i++) {
        if (i <= amountFreeSlots && 2 < amountFreeSlots && amountFreeSlots < 7)
            freeSlotsArray.push("warning");
        if (i <= amountFreeSlots && amountFreeSlots <= 2)
            freeSlotsArray.push("danger");
        if (i <= amountFreeSlots && amountFreeSlots > 6)
            freeSlotsArray.push("success");
        if (i > amountFreeSlots) freeSlotsArray.push("grey");
    }

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                // style={size !== 'medium' ? { minWidth: "23rem", minHeight: '10.45rem' } : { minHeight: '10.4rem' }}
                // height='auto'
                // width='auto'
                // border={props.lastSelectedItem ? { color: 'black', side: 'bottom', size: '5px' } : undefined}
                >
                    <Box
                        // border={props.lastSelectedItem ? [{ color: 'black', side: 'bottom', size: '3px' }, {
                        //     "color": "light-4",
                        //     "size": "xsmall",
                        //     "side": "all"
                        // }] : {
                        //         "color": "light-4",
                        //         "size": "xsmall",
                        //         "side": "all"
                        //     }}
                        // basis="auto"

                        // pad="small"
                        // height='10.45rem'
                        // border={}
                        // round="xsmall"
                        // elevation="xsmall"
                        direction="row"
                    >
                        <Box
                            align="start"
                            style={{ minWidth: "65%" }}
                            pad={"small"}
                            direction="column"
                            justify="start"
                            width="65%"
                            overflow="dotted"
                            // onClick={() => props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? props.removeStationForNewCampaign(props.station) : props.addStationForNewCampaign(props.station)}
                        >
                            <Heading
                                truncate
                                level="3"
                                margin={{
                                    top: "10px",
                                    bottom: "0",
                                }}
                                style={{ maxWidth: "100%" }}
                            >
                                {props.station.city
                                    ? props.station.city.toUpperCase()
                                    : ""}
                            </Heading>
                            {props.user &&
                                props.user.authentication.role !== "WUERTH" && (
                                    <Heading
                                        level="5"
                                        margin={{
                                            top: "0",
                                            bottom: "5px",
                                        }}
                                    >
                                        Shell-ID: {props.station.shellId}
                                    </Heading>
                                )}
                            <Text textAlign="start">
                                {props.station.street} <br />
                                {props.station.zipCode}, {props.station.city}
                            </Text>
                            {props.user &&
                                (props.user.authentication.role ===
                                    UserRole.ADMIN ||
                                    props.user.authentication.role ===
                                        UserRole.VIEW_ONLY) && (
                                    <Text>
                                        Vertrieb: {props.station.assignedTo}
                                    </Text>
                                )}
                        </Box>
                        <Box
                            gridArea="right"
                            align="center"
                            style={{ textOverflow: "dotted" }}
                            pad="small"
                            direction="column"
                            justify="end"
                            width="35%"
                            margin={{ top: "small" }}
                        >
                            {props.user &&
                                props.user.authentication.role !== "WUERTH" && (
                                    <Button
                                        // style={{ marginBottom: '2rem' }}
                                        onClick={(event) => {
                                            // event.stopPropagation();
                                            return props.onClick(event);
                                        }}
                                        label="Info"
                                        icon={
                                            <StatusInfo
                                                color="neutral-4"
                                                size="medium"
                                            />
                                        }
                                    />
                                )}

                            {props.user &&
                                props.user.authentication.role !== "WUERTH" && (
                                    <Box
                                        direction="column"
                                        justify="end"
                                        align="center"
                                        margin={{ top: "small" }}
                                        onClick={(event: any) => {
                                            // event.stopPropagation();
                                            props.onClick(event);
                                        }}
                                    >
                                        <Box
                                            direction="row"
                                            margin={{ top: "small" }}
                                        >
                                            {freeSlotsArray.map(
                                                (color, index) => (
                                                    <Box
                                                        key={index}
                                                        width="1rem"
                                                        height="1rem"
                                                        background={color}
                                                    />
                                                )
                                            )}
                                        </Box>
                                        <Text>
                                            {
                                                props.station
                                                    .freeSlotsInPeriodCount
                                            }{" "}
                                            / 5 frei
                                        </Text>

                                        {/* <Button
                                    disabled={props.station.freeSlotsInPeriodCount === 0}
                                    // icon={props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? <Subtract color='warning' /> : <Add color='success' />}
                                    onClick={() => props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? props.removeStationForNewCampaign(props.station) : props.addStationForNewCampaign(props.station)}
                                    label='Zu Auswahl hinzufügen'
                                    color='success'
                                /> */}

                                        {/* <Anchor
                                    disabled={props.station.freeSlotsInPeriodCount === 0}
                                    icon={props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? <Subtract color='warning' /> : <Add color='success' />}
                                    onClick={() => props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? props.removeStationForNewCampaign(props.station) : props.addStationForNewCampaign(props.station)}
                                /> */}
                                    </Box>
                                )}
                        </Box>

                        {/* <Grid
                            rows={size !== 'medium' ? ['155px'] : ['auto', 'auto']}
                            columns={size !== 'medium' ? ['60%', '40%'] : ['100%']}
                            areas={size !== 'medium' ? [
                                { name: 'left', start: [0, 0], end: [1, 0] },
                                { name: 'right', start: [1, 0], end: [1, 0] },
                            ] : [
                                    { name: 'left', start: [0, 0], end: [1, 0] },
                                    { name: 'right', start: [0, 1], end: [1, 1] },
                                ]}
                            fill="horizontal"
                        >
                            <Box
                                gridArea="left"
                                align="center"
                                style={{ textOverflow: "dotted" }}
                                pad="small"
                                direction='column'
                                justify='center'
                            >
                                <Heading
                                    truncate
                                    level="3"
                                    margin={{
                                        "top": "10px",
                                        "bottom": "0",
                                    }}
                                    style={{ maxWidth: '100%' }}
                                >
                                    {props.station.city ? props.station.city.toUpperCase() : ''}
                                </Heading>
                                <Heading
                                    level="5"
                                    margin={{
                                        "top": "0",
                                        "bottom": "5px",
                                    }}
                                >
                                    Shell-ID: {props.station.shellId}
                                </Heading>
                                <Text textAlign='start'>
                                    {props.station.street} <br />
                                    {props.station.zipCode}, {props.station.city}
                                </Text>
                                {props.user && props.user.authentication.role === UserRole.ADMIN && <Text >
                                    Vertrieb: {props.station.assignedTo}
                                </Text>}
                            </Box>
                            <Box
                                gridArea="right"
                                align="center"
                                style={{ textOverflow: "dotted" }}
                                pad="small"
                                direction='column'
                                justify='center'
                            // pad="medium"
                            >

                                <Anchor
                                    // style={{ marginBottom: '2rem' }}
                                    onClick={() => { props.onClick() }}
                                >
                                    Stationsdetails
                                </Anchor>
                                <Box
                                    direction='row'
                                    justify='between'
                                    align='center'
                                >
                                    <Text>
                                        {props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? props.station.freeSlotsInPeriodCount - 1 : props.station.freeSlotsInPeriodCount} / 5 frei
                                    </Text>

                                    <Anchor
                                        disabled={props.station.freeSlotsInPeriodCount === 0}
                                        icon={props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? <Subtract color='warning' /> : <Add color='success' />}
                                        onClick={() => props.stationsForNewCampaign.find(station => station.shellId === props.station.shellId) ? props.removeStationForNewCampaign(props.station) : props.addStationForNewCampaign(props.station)}
                                    />
                                </Box>
                            </Box>
                        </Grid> */}
                    </Box>
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        user: state.usersReducer.user,
    };
};

const mapDispatchToProps = {
    addStationForNewCampaign,
    removeStationForNewCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapListItem);
