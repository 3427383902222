import {
    Box,
    Button,
    Form,
    Heading,
    Layer,
    ResponsiveContext,
    Text,
} from "grommet";
import { Close, LinkPrevious, Save, Undo } from "grommet-icons";
import _ from "lodash";
import React, { useState } from "react";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import { connect } from "react-redux";
import {
    changeCustomer,
    setChangeCustomerRequestStatus,
} from "../../store/customers/actions";
import { rootState } from "../../store/rootReducer";
import { User, UserRole } from "../../types/Authentication";
import Customer, { NewCustomerRequestStatus } from "../../types/Customer";
import EditableFormField from "./EditableFormField";

interface CustomerDetailsProps {
    customer: Customer;
    isFetchingCustomer: boolean;
    putCustomerStatus: NewCustomerRequestStatus | null;
    user: User | null;
    closeLayer?: any;
    changeCustomer: (customer: Customer) => any;
    setChangeCustomerRequestStatus: (status: NewCustomerRequestStatus) => any;
    //   putCampaign: (campaign: Campaign) => void;
    //   getStationsById: (stationIds: number[]) => void;
    //   getAllocationsForStationsByDate: (parameter: {
    //     stationIds: number[];
    //     end: number;
    //     start: number;
    //   }) => void;
    //   setPutCampaignRequestStatus: (status: NewCampaignRequestStatus) => void;
    //   setScreenStations: (stations: Station[]) => void;
    //   setStationOfCurrentCampaign: (stations: Station[]) => void;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = (props) => {
    const [
        currentCustomerState,
        setCurrentCustomerState,
    ] = useState<Customer | null>(props.customer);
    const [confirmUpdateWindow, setConfirmUpdateWindow] = useState(false);

    const userDisabled =
        props.user &&
        props.user.authentication.role !== UserRole.ADMIN &&
        props.user.authentication.role !== UserRole.VIEW_ONLY
            ? true
            : false;

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    pad="small"
                    width={"90%"}
                    justify="center"
                    align="center"
                    fill="horizontal"
                    overflow="scroll"
                    style={{ display: "block" }}
                >
                    {currentCustomerState !== null && props.customer !== null && (
                        <Box margin="medium" justify="center" align="center">
                            <Heading level={3}>
                                Kunde {currentCustomerState.companyName}
                            </Heading>
                            <Form
                                onSubmit={() => {
                                    setConfirmUpdateWindow(true);
                                    props.changeCustomer(currentCustomerState);
                                }}
                                style={{ width: "90%" }}
                            >
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.companyName
                                            ? currentCustomerState.companyName
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.companyName
                                            ? props.customer.companyName.toString()
                                            : ""
                                    }
                                    placeholder="Firma"
                                    name="companyName"
                                    label="Firma"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.name
                                            ? currentCustomerState.name
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.name
                                            ? props.customer.name.toString()
                                            : ""
                                    }
                                    placeholder="Ansprechpartner"
                                    name="name"
                                    label="Ansprechpartner"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.phone
                                            ? currentCustomerState.phone
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.phone
                                            ? props.customer.phone.toString()
                                            : ""
                                    }
                                    placeholder="Tel."
                                    name="phone"
                                    label="Tel."
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.email
                                            ? currentCustomerState.email
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.email
                                            ? props.customer.email.toString()
                                            : ""
                                    }
                                    placeholder="E-Mail"
                                    name="email"
                                    label="E-Mail"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.street
                                            ? currentCustomerState.street
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.street
                                            ? props.customer.street.toString()
                                            : ""
                                    }
                                    placeholder="Straße"
                                    name="street"
                                    label="Straße"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />

                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.city
                                            ? currentCustomerState.city
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.city
                                            ? props.customer.city.toString()
                                            : ""
                                    }
                                    placeholder="Stadt"
                                    name="city"
                                    label="Stadt"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />
                                <EditableFormField
                                    type="text"
                                    value={
                                        currentCustomerState.zip
                                            ? currentCustomerState.zip
                                            : ""
                                    }
                                    propsValue={
                                        props.customer.zip
                                            ? props.customer.zip.toString()
                                            : ""
                                    }
                                    placeholder="PLZ"
                                    name="zip"
                                    label="PLZ"
                                    disabled={userDisabled}
                                    onChangeFunction={(partialObject) =>
                                        setCurrentCustomerState({
                                            ...currentCustomerState,
                                            ...partialObject,
                                        })
                                    }
                                    valueParser={(value) => value}
                                />

                                <Box
                                    direction="row"
                                    justify="between"
                                    margin={{ top: "medium" }}
                                >
                                    <Button
                                        label="Undo"
                                        primary
                                        color="warning"
                                        margin={{ vertical: "small" }}
                                        disabled={_.isEqual(
                                            { ...currentCustomerState },
                                            props.customer
                                        )}
                                        icon={<Undo color="white" />}
                                        onClick={() =>
                                            setCurrentCustomerState(
                                                props.customer
                                            )
                                        }
                                    />

                                    <Button
                                        disabled={
                                            currentCustomerState.companyName ===
                                                "" ||
                                            _.isEqual(
                                                { ...currentCustomerState },
                                                props.customer
                                            )
                                        }
                                        label="Speichern"
                                        primary
                                        type="submit"
                                        margin={{ vertical: "small" }}
                                        icon={<Save color="white" />}
                                        color="success"
                                    />
                                </Box>

                                <Button
                                    label="Abbrechen"
                                    color="danger"
                                    primary
                                    icon={<Close color="white" />}
                                    fill="horizontal"
                                    margin={{ vertical: "small" }}
                                    onClick={() =>
                                        props.closeLayer &&
                                        props.closeLayer(null)
                                    }
                                />
                            </Form>
                        </Box>
                    )}
                    {confirmUpdateWindow &&
                        (props.putCustomerStatus !==
                            NewCustomerRequestStatus.WAITING ||
                            props.isFetchingCustomer) && (
                            <Layer
                                onEsc={() => {
                                    setConfirmUpdateWindow(false);
                                    // props.setPutCampaignRequestStatus(
                                    //     NewCampaignRequestStatus.NONE
                                    // );
                                }}
                                onClickOutside={() => {
                                    setConfirmUpdateWindow(false);
                                    // props.setPutCampaignRequestStatus(
                                    //     NewCampaignRequestStatus.NONE
                                    // );
                                }}
                                full={false}
                            >
                                <Box
                                    pad="large"
                                    align="center"
                                    justify="center"
                                >
                                    {props.putCustomerStatus ===
                                        NewCustomerRequestStatus.SUCCESS && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                margin={{ vertical: "medium" }}
                                                textAlign="center"
                                            >
                                                Kampagne wurde erfolgreich
                                                geändert.
                                            </Text>
                                            <Box
                                                align="center"
                                                justify="center"
                                            >
                                                <Button
                                                    label="Zur Kampagnenübersicht"
                                                    icon={<LinkPrevious />}
                                                    onClick={() => {
                                                        props.closeLayer &&
                                                            props.closeLayer(
                                                                null
                                                            );
                                                        // props.setPutCampaignRequestStatus(
                                                        //     NewCampaignRequestStatus.NONE
                                                        // );
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {props.putCustomerStatus ===
                                        NewCustomerRequestStatus.ERROR && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                color="danger"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Das sollte nicht passieren!
                                            </Text>
                                            <Text
                                                textAlign="center"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Ein Fehler ist aufgetreten.
                                                Bitte versuchen Sie es erneuet
                                                oder kontaktieren Sie unseren
                                                Service.
                                            </Text>

                                            <Button
                                                label="Zurück zur Kampagne"
                                                icon={<LinkPrevious />}
                                                onClick={() => {
                                                    setConfirmUpdateWindow(
                                                        false
                                                    );
                                                    props.setChangeCustomerRequestStatus(
                                                        NewCustomerRequestStatus.NONE
                                                    );
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {props.isFetchingCustomer && (
                                        <div className="loadingio-spinner-spinner-g73zrpl2wr5">
                                            <div className="ldio-xie7sipo009">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            </Layer>
                        )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        // customer: state.customerReducer.currentCustomer,
        isFetchingCustomer: state.customerReducer.isFetching,
        putCustomerStatus: state.customerReducer.newCustomerRequestStatus,
        user: state.usersReducer.user,
    };
};

const mapDispatchToProps = {
    changeCustomer,
    setChangeCustomerRequestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
