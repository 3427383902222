import {
    CHANGE_CUSTOMER,
    CHANGE_CUSTOMER_REQUEST_STATUS,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_REQUEST_STATUS,
    CREATE_NEW_CUSTOMER_RESPONSE,
    CustomersActionType,
    CustomerState,
    GET_ALL_CUSTOMERS,
    GET_ALL_CUSTOMERS_RESPONSE,
    GET_CUSTOMER_BY_ID,
    GET_CUSTOMER_BY_ID_RESPONSE,
} from "./types";

const initialState: CustomerState = {
    customers: [],
    currentCustomer: null,
    isFetching: false,
    newCustomerRequestStatus: null,
    changeCustomerRequestStatus: null,
};

export function customerReducer(
    state = initialState,
    action: CustomersActionType
): CustomerState {
    switch (action.type) {
        case CREATE_NEW_CUSTOMER:
            return {
                ...state,
                isFetching: true,
            };
        case CREATE_NEW_CUSTOMER_REQUEST_STATUS:
            return {
                ...state,
                newCustomerRequestStatus: action.payload,
            };
        case CREATE_NEW_CUSTOMER_RESPONSE:
            return {
                ...state,
                isFetching: false,
                customers: state.customers.concat(action.payload),
            };
        case CHANGE_CUSTOMER:
            return {
                ...state,
                isFetching: true,
            };
        case CHANGE_CUSTOMER_REQUEST_STATUS:
            return {
                ...state,
                changeCustomerRequestStatus: action.payload,
            };
        case CREATE_NEW_CUSTOMER_RESPONSE:
            return {
                ...state,
                isFetching: false,
                currentCustomer: action.payload,
            };
        case GET_ALL_CUSTOMERS:
            return {
                ...state,
                isFetching: true,
            };
        case GET_ALL_CUSTOMERS_RESPONSE:
            return {
                ...state,
                isFetching: false,
                customers: action.payload,
            };
        case GET_CUSTOMER_BY_ID:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CUSTOMER_BY_ID_RESPONSE:
            return {
                ...state,
                isFetching: false,
                currentCustomer: action.payload,
            };
        default:
            return state;
    }
}
