import dayjs from "dayjs";
import { Box, Heading, Text } from "grommet";
import React from "react";
import settings from "../../settings";
import { SalesRepArray } from "../../types/Campaign";
import Station, {
    IndoorScreenOptionsArray,
    IndoorScreenVersionTextToEnum,
    IndoorScreenVersionToText,
    OutdoorScreenOptionsArray,
    OutdoorScreenVersionTextToEnum,
    OutdoorScreenVersionToText,
    StationStatus,
    StationStatusArray,
    StationStatusTextToStatus,
    StationStatusToText,
} from "../../types/Station";
import EditableStationFormField from "./EditableStationFormField";

interface StationDetailsEditViewProps {
    station: Station;
    setStationState: (station: Station) => void;
}

const StationDetailEditView: React.FC<StationDetailsEditViewProps> = (
    props: StationDetailsEditViewProps
) => {
    // TODO: USE EFFECT
    if (props.station.outdoorScreenCost === null) {
        if (props.station.outdoorScreenVersion) {
            props.setStationState({
                ...props.station,
                outdoorScreenCost:
                    settings.FIXED_PRICES.OUTDOOR[
                        props.station.outdoorScreenVersion
                    ],
            });
        } else {
            props.setStationState({
                ...props.station,
                outdoorScreenCost: 0,
            });
        }
    }

    if (props.station.indoorScreenCost === null) {
        if (props.station.indoorScreenVersion) {
            props.setStationState({
                ...props.station,
                indoorScreenCost:
                    settings.FIXED_PRICES.INDOOR[
                        props.station.indoorScreenVersion
                    ],
            });
        } else {
            props.setStationState({
                ...props.station,
                indoorScreenCost: 0,
            });
        }
    }

    return (
        <Box
            pad="medium"
            direction="column"
            justify="between"
            overflow="scroll"
            style={{ display: "block" }}
            border="top"
        >
            <Box border="bottom" style={{ minHeight: "160px" }}>
                <Heading level="3">Vertrieb</Heading>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="select"
                        options={SalesRepArray}
                        value={props.station.assignedTo.toString()}
                        propsValue={props.station.assignedTo.toString()}
                        placeholder={"Vertrieb"}
                        name="assignedTo"
                        label="Vertrieb"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => value}
                    />
                    <EditableStationFormField
                        type="select"
                        options={StationStatusArray}
                        value={StationStatusToText(props.station.status)}
                        propsValue={StationStatusToText(props.station.status)}
                        placeholder={"Status"}
                        name="status"
                        label="Status"
                        onChangeFunction={(partialObject) => {
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                                ...((partialObject.status ===
                                    StationStatus.APPROVAL_PROCESS ||
                                    partialObject.status ===
                                        StationStatus.PLANNING_PHASE) && {
                                    plannedConstructionDate: null,
                                }),
                            });
                        }}
                        valueParser={(value) =>
                            StationStatusTextToStatus(value)
                        }
                    />
                    {(props.station.status ===
                        StationStatus.CONSTRUCTION_SCHEDULED ||
                        props.station.status === StationStatus.CONSTRUCTED) && (
                        <EditableStationFormField
                            type="datepicker"
                            value={
                                props.station.plannedConstructionDate &&
                                props.station.plannedConstructionDate > 0
                                    ? props.station.plannedConstructionDate.toString()
                                    : ""
                            }
                            propsValue={
                                props.station.plannedConstructionDate &&
                                props.station.plannedConstructionDate > 0
                                    ? props.station.plannedConstructionDate.toString()
                                    : ""
                            }
                            placeholder={"Status"}
                            name="plannedConstructionDate"
                            label="Aufbaudatum"
                            onChangeFunction={(partialObject) => {
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                });
                            }}
                            valueParser={(value) => {
                                return dayjs(parseInt(value)).valueOf();
                            }}
                        />
                    )}
                    <EditableStationFormField
                        type="textArea"
                        value={props.station.additionalInfo || ""}
                        propsValue={props.station.additionalInfo || ""}
                        placeholder={"Sonstige Information"}
                        name="additionalInfo"
                        label="Sonstige Information"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => value}
                    />
                </Box>
            </Box>
            <Box border="bottom" style={{ minHeight: "160px" }}>
                <Heading level="3">Stationskonfiguration</Heading>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="radio"
                        options={OutdoorScreenOptionsArray}
                        value={OutdoorScreenVersionToText(
                            props.station.outdoorScreenVersion
                        )}
                        propsValue={OutdoorScreenVersionToText(
                            props.station.outdoorScreenVersion
                        )}
                        placeholder={"Außenmonitor"}
                        name="outdoorScreenVersion"
                        label="Monitor außen"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                                outdoorScreenCost:
                                    partialObject.outdoorScreenVersion &&
                                    settings.FIXED_PRICES.OUTDOOR[
                                        partialObject.outdoorScreenVersion
                                    ],
                            })
                        }
                        valueParser={(value) =>
                            OutdoorScreenVersionTextToEnum(value)
                        }
                    />
                    <EditableStationFormField
                        type="radio"
                        options={IndoorScreenOptionsArray}
                        value={IndoorScreenVersionToText(
                            props.station.indoorScreenVersion
                        )}
                        propsValue={IndoorScreenVersionToText(
                            props.station.indoorScreenVersion
                        )}
                        placeholder={"Innenmonitor"}
                        name="indoorScreenVersion"
                        label="Innenmonitor"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                                indoorScreenCost:
                                    partialObject.indoorScreenVersion &&
                                    settings.FIXED_PRICES.INDOOR[
                                        partialObject.indoorScreenVersion
                                    ],
                            })
                        }
                        valueParser={(value) =>
                            IndoorScreenVersionTextToEnum(value)
                        }
                    />
                </Box>
            </Box>
            <Box border="bottom" style={{ minHeight: "160px" }}>
                <Heading level="3">Kosten Aufbau</Heading>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="number"
                        value={props.station.constructionCostOffer || ""}
                        propsValue={props.station.constructionCostOffer || ""}
                        placeholder={0}
                        min={0}
                        name="constructionCostOffer"
                        label="Aufbaukosten Angebot"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <Text>
                        Rechnungsbetrag:{" "}
                        <b>
                            {(
                                (props.station.constructionCost || 0) +
                                (props.station.smallElectronicsCost || 0) +
                                (props.station.otherCosts || 0) +
                                (props.station.carWashCosts || 0) +
                                (props.station.backShopCosts || 0)
                            ).toFixed(2)}
                        </b>
                        <br />
                        Gesamtkosten Station ohne CarWash und BackShop:{" "}
                        <b>
                            {(
                                (props.station.constructionCost || 0) +
                                (props.station.smallElectronicsCost || 0) +
                                (props.station.otherCosts || 0) +
                                (props.station.outdoorScreenCost || 0) +
                                (props.station.indoorScreenCost || 0) +
                                (props.station.otherHardwareCost || 0) +
                                (props.station.measurementFees || 0) +
                                (props.station.approvalFees || 0)
                            ).toFixed(2)}
                        </b>
                    </Text>
                </Box>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="number"
                        value={
                            props.station.outdoorScreenCost ||
                            (props.station.outdoorScreenVersion &&
                                settings.FIXED_PRICES.OUTDOOR[
                                    props.station.outdoorScreenVersion
                                ]) ||
                            ""
                        }
                        propsValue={props.station.outdoorScreenCost || ""}
                        placeholder={0}
                        min={0}
                        name="outdoorScreenCost"
                        label="Kosten Monitor Außen"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={
                            props.station.indoorScreenCost ||
                            (props.station.indoorScreenVersion &&
                                settings.FIXED_PRICES.INDOOR[
                                    props.station.indoorScreenVersion
                                ]) ||
                            ""
                        }
                        propsValue={props.station.outdoorScreenCost || ""}
                        placeholder={0}
                        min={0}
                        name="indoorScreenCost"
                        label="Kosten Monitor Innen"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.otherHardwareCost || ""}
                        propsValue={props.station.otherHardwareCost || ""}
                        placeholder={0}
                        min={0}
                        name="otherHardwareCost"
                        label="Sonstige Hardware"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                </Box>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="number"
                        value={props.station.smallElectronicsCost || ""}
                        propsValue={props.station.smallElectronicsCost || ""}
                        placeholder={0}
                        min={0}
                        name="smallElectronicsCost"
                        label="Elektromaterial"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.constructionCost || ""}
                        propsValue={props.station.constructionCost || ""}
                        placeholder={0}
                        min={0}
                        name="constructionCost"
                        label="Montagekosten"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.otherCosts || ""}
                        propsValue={props.station.otherCosts || ""}
                        placeholder={0}
                        min={0}
                        name="otherCosts"
                        label="Nebenkosten"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                </Box>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="number"
                        value={props.station.measurementFees || ""}
                        propsValue={props.station.measurementFees || ""}
                        placeholder={0}
                        min={0}
                        name="measurementFees"
                        label="Aufmaß"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.approvalFees || ""}
                        propsValue={props.station.approvalFees || ""}
                        placeholder={0}
                        min={0}
                        name="approvalFees"
                        label="Genehmigungs- und sonstige Servicekosten"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.carWashCosts || ""}
                        propsValue={props.station.carWashCosts || ""}
                        placeholder={0}
                        min={0}
                        name="carWashCosts"
                        label="CarWash (wird weiterberechnet)"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                </Box>
                <Box direction="row" justify="between">
                    <EditableStationFormField
                        type="number"
                        value={props.station.backShopCosts || ""}
                        propsValue={props.station.backShopCosts || ""}
                        placeholder={0}
                        min={0}
                        name="backShopCosts"
                        label="BackShop (wird weiterberechnet)"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.cityApprovalCosts || ""}
                        propsValue={props.station.cityApprovalCosts || ""}
                        placeholder={0}
                        min={0}
                        name="cityApprovalCosts"
                        label="Gebühren Bauamt"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                    <EditableStationFormField
                        type="number"
                        value={props.station.additionalCosts || ""}
                        propsValue={props.station.additionalCosts || ""}
                        placeholder={0}
                        min={0}
                        name="additionalCosts"
                        label="Sonstige Kosten"
                        onChangeFunction={(partialObject) =>
                            props.setStationState({
                                ...props.station,
                                ...partialObject,
                            })
                        }
                        valueParser={(value) => parseFloat(value)}
                    />
                </Box>
            </Box>
            <Box>
                {/* <Box direction="row">
                    <Box>
                        <EditableStationFormField
                            type="number"
                            value={props.station.electricityCostsPerMonth || ""}
                            propsValue={
                                props.station.electricityCostsPerMonth || ""
                            }
                            placeholder={0}
                            min={0}
                            name="electricityCostsPerMonth"
                            label="Stromkosten pro Monat"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                    </Box>
                    <Box>
                        <EditableStationFormField
                            type="number"
                            value={props.station.constructionCostOffer || ""}
                            propsValue={
                                props.station.constructionCostOffer || ""
                            }
                            placeholder={0}
                            min={0}
                            name="constructionCostOffer"
                            label="Aufbaukosten Angebot"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="radio"
                            options={OutdoorScreenOptionsArray}
                            value={OutdoorScreenVersionToText(
                                props.station.outdoorScreenVersion
                            )}
                            propsValue={OutdoorScreenVersionToText(
                                props.station.outdoorScreenVersion
                            )}
                            placeholder={"Außenmonitor"}
                            name="outdoorScreenVersion"
                            label="Monitor außen"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                    outdoorScreenCost:
                                        partialObject.outdoorScreenVersion &&
                                        settings.FIXED_PRICES.OUTDOOR[
                                            partialObject.outdoorScreenVersion
                                        ],
                                })
                            }
                            valueParser={(value) =>
                                OutdoorScreenVersionTextToEnum(value)
                            }
                        />
                        <EditableStationFormField
                            type="number"
                            value={
                                props.station.outdoorScreenCost ||
                                (props.station.outdoorScreenVersion &&
                                    settings.FIXED_PRICES.OUTDOOR[
                                        props.station.outdoorScreenVersion
                                    ]) ||
                                ""
                            }
                            propsValue={props.station.outdoorScreenCost || ""}
                            placeholder={0}
                            min={0}
                            name="outdoorScreenCost"
                            label="Kosten Monitor Außen"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="radio"
                            options={IndoorScreenOptionsArray}
                            value={IndoorScreenVersionToText(
                                props.station.indoorScreenVersion
                            )}
                            propsValue={IndoorScreenVersionToText(
                                props.station.indoorScreenVersion
                            )}
                            placeholder={"Innenmonitor"}
                            name="indoorScreenVersion"
                            label="Innenmonitor"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                    indoorScreenCost:
                                        partialObject.indoorScreenVersion &&
                                        settings.FIXED_PRICES.INDOOR[
                                            partialObject.indoorScreenVersion
                                        ],
                                })
                            }
                            valueParser={(value) =>
                                IndoorScreenVersionTextToEnum(value)
                            }
                        />
                        <EditableStationFormField
                            type="number"
                            value={
                                props.station.indoorScreenCost ||
                                (props.station.indoorScreenVersion &&
                                    settings.FIXED_PRICES.INDOOR[
                                        props.station.indoorScreenVersion
                                    ]) ||
                                ""
                            }
                            propsValue={props.station.outdoorScreenCost || ""}
                            placeholder={0}
                            min={0}
                            name="indoorScreenCost"
                            label="Kosten Monitor Innen"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.otherHardwareCost || ""}
                            propsValue={props.station.otherHardwareCost || ""}
                            placeholder={0}
                            min={0}
                            name="otherHardwareCost"
                            label="Sonstige Hardware"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.smallElectronicsCost || ""}
                            propsValue={
                                props.station.smallElectronicsCost || ""
                            }
                            placeholder={0}
                            min={0}
                            name="smallElectronicsCost"
                            label="Elektromaterial"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.constructionCost || ""}
                            propsValue={props.station.constructionCost || ""}
                            placeholder={0}
                            min={0}
                            name="constructionCost"
                            label="Montagekosten"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.otherCosts || ""}
                            propsValue={props.station.otherCosts || ""}
                            placeholder={0}
                            min={0}
                            name="otherCosts"
                            label="Nebenkosten"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.carWashCosts || ""}
                            propsValue={props.station.carWashCosts || ""}
                            placeholder={0}
                            min={0}
                            name="carWashCosts"
                            label="CarWash (wird weiterberechnet)"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.backShopCosts || ""}
                            propsValue={props.station.backShopCosts || ""}
                            placeholder={0}
                            min={0}
                            name="backShopCosts"
                            label="BackShop (wird weiterberechnet)"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.measurementFees || ""}
                            propsValue={props.station.measurementFees || ""}
                            placeholder={0}
                            min={0}
                            name="measurementFees"
                            label="Aufmaß"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                        <EditableStationFormField
                            type="number"
                            value={props.station.approvalFees || ""}
                            propsValue={props.station.approvalFees || ""}
                            placeholder={0}
                            min={0}
                            name="approvalFees"
                            label="Genehmigungs- und sonstige Servicekosten"
                            onChangeFunction={(partialObject) =>
                                props.setStationState({
                                    ...props.station,
                                    ...partialObject,
                                })
                            }
                            valueParser={(value) => parseFloat(value)}
                        />
                    </Box>
                    <Box>
                        Rechnungsbetrag:{" "}
                        {(
                            (props.station.constructionCost || 0) +
                            (props.station.smallElectronicsCost || 0) +
                            (props.station.otherCosts || 0) +
                            (props.station.carWashCosts || 0) +
                            (props.station.backShopCosts || 0)
                        ).toFixed(2)}
                        <br />
                        Gesamtkosten Station ohne CarWash und BackShop:{" "}
                        {(
                            (props.station.constructionCost || 0) +
                            (props.station.smallElectronicsCost || 0) +
                            (props.station.otherCosts || 0) +
                            (props.station.outdoorScreenCost || 0) +
                            (props.station.indoorScreenCost || 0) +
                            (props.station.otherHardwareCost || 0) +
                            (props.station.measurementFees || 0) +
                            (props.station.approvalFees || 0)
                        ).toFixed(2)}
                    </Box>
                </Box> */}
            </Box>
        </Box>
    );
};

export default StationDetailEditView;
