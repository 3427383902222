export const prodSettings = {
    API: {
        BASE_URL: "https://app.ism-hi-light.de",
        API_PATH: "/api",
    },
    MAP_PROXY_URL: {
        BASE_URL: "https://mapproxy.ism-hi-light.de",
    },
};

export default prodSettings;
