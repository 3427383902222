import dayjs from "dayjs";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import settings from "../../settings";
import { CampaignDurationInt } from "../../types/Campaign";
import { Location } from "../../types/Station";
import { fetchAuth, rootState } from "../rootReducer";
import {
    GET_STATIONS,
    SET_SEARCH_STRING,
    SET_SEARCH_STRING_DISPLAY,
    StationsActionTypes,
} from "../stations/types";
import { logoutUser } from "../user/actions";
import {
    BusinessesActionTypes,
    CLEAR_STATE_AFTER_LOGOUT,
    GET_BUSINESSES,
    IS_FETCHING,
    SET_CENTER,
    SET_CENTER_ADDRESS,
    SET_RANGE,
} from "./types";

function fetchBusinesses(): BusinessesActionTypes {
    return {
        type: IS_FETCHING,
    };
}

function setSearchString(string: string): BusinessesActionTypes {
    return {
        type: SET_SEARCH_STRING,
        payload: string,
    };
}

function setSearchStringDisplay(string: string): BusinessesActionTypes {
    return {
        type: SET_SEARCH_STRING_DISPLAY,
        payload: string,
    };
}

export function clearState(): BusinessesActionTypes {
    return {
        type: CLEAR_STATE_AFTER_LOGOUT,
    };
}

function setCenter(center: Location): BusinessesActionTypes {
    return {
        type: SET_CENTER,
        payload: center,
    };
}

function setCenterAddress(centerAddress: string): BusinessesActionTypes {
    return {
        type: SET_CENTER_ADDRESS,
        payload: centerAddress,
    };
}

function setRange(range: string): BusinessesActionTypes {
    return {
        type: SET_RANGE,
        payload: parseInt(range),
    };
}

export function findStationsAndBusinessesByRange(
    address: string = "Berlin",
    range: string,
    businessSearch: string,
    skipGeoSearch?: boolean,
    center?: Location
): ThunkAction<
    Promise<void>,
    {},
    {},
    BusinessesActionTypes | StationsActionTypes
> {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            BusinessesActionTypes | StationsActionTypes
        >,
        getState: () => {}
    ): Promise<void> => {
        dispatch(setRange(range));
        dispatch(fetchBusinesses());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            if (!skipGeoSearch) {
                const mapRequestUrl =
                    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                    encodeURI("deutschland " + address) +
                    "&language=de&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";

                const gmapLocationRespJson = await (
                    await fetch(mapRequestUrl)
                ).json();

                if (gmapLocationRespJson.status === "OK") {
                    const lat =
                            gmapLocationRespJson.results[0].geometry.location
                                .lat,
                        lng =
                            gmapLocationRespJson.results[0].geometry.location
                                .lng,
                        centerAddress =
                            gmapLocationRespJson.results[0].formatted_address;

                    dispatch(setCenter({ lat: lat, lng: lng }));

                    dispatch(setCenterAddress(centerAddress));

                    const stationsData = await fetchAuth(
                        dispatch,
                        settings.API.BASE_URL +
                            settings.API.API_PATH +
                            settings.API_END_POINTS.STATIONS_BY_RANGE +
                            "?" +
                            "latitude=" +
                            encodeURI(lat) +
                            "&longitude=" +
                            encodeURI(lng) +
                            "&range=" +
                            range +
                            "&usagePeriodStart=" +
                            dayjs(
                                state.campaignReducer.filterDateFrom
                            ).valueOf() +
                            "&usagePeriod=" +
                            CampaignDurationInt[
                                state.campaignReducer.filterContractDuration
                            ],
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    state.usersReducer.user.authentication
                                        .access_token,
                            },
                        }
                    );

                    const businessRequestUrl =
                        "https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=" +
                        lat +
                        "," +
                        lng +
                        // "&rankby=distance" +
                        "&radius=" +
                        range +
                        "00" +
                        "&keyword=" +
                        businessSearch +
                        "&language=de&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";

                    const bizLocationRespJson = await (
                        await fetch(
                            settings.MAP_PROXY_URL.BASE_URL +
                                "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                                encodeURIComponent(businessRequestUrl)
                        )
                    ).json();

                    await timeout(
                        bizLocationRespJson.next_page_token ? 200 : 0
                    );

                    let gotResp = false;
                    let biz2LocationRespJson = {
                        results: [],
                        status: "NONE",
                        next_page_token: null,
                    };

                    while (!gotResp && bizLocationRespJson.next_page_token) {
                        biz2LocationRespJson =
                            bizLocationRespJson.next_page_token
                                ? await (
                                      await fetch(
                                          settings.MAP_PROXY_URL.BASE_URL +
                                              "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                                              encodeURIComponent(
                                                  "https://maps.googleapis.com/maps/api/place/nearbysearch/json?key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE&pagetoken=" +
                                                      bizLocationRespJson.next_page_token
                                              )
                                      )
                                  ).json()
                                : { results: [] };

                        if (biz2LocationRespJson.status !== "INVALID_REQUEST")
                            gotResp = true;
                    }

                    await timeout(
                        biz2LocationRespJson.next_page_token ? 200 : 0
                    );

                    gotResp = false;
                    let biz3LocationRespJson = { results: [], status: "NONE" };

                    while (!gotResp && biz2LocationRespJson.next_page_token) {
                        biz3LocationRespJson =
                            biz2LocationRespJson.next_page_token
                                ? await (
                                      await fetch(
                                          settings.MAP_PROXY_URL.BASE_URL +
                                              "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                                              encodeURIComponent(
                                                  "https://maps.googleapis.com/maps/api/place/nearbysearch/json?key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE&pagetoken=" +
                                                      biz2LocationRespJson.next_page_token
                                              )
                                      )
                                  ).json()
                                : { results: [] };

                        if (biz3LocationRespJson.status !== "INVALID_REQUEST")
                            gotResp = true;
                    }
                    // const biz3LocationRespJson = biz2LocationRespJson.next_page_token
                    //     ? await (
                    //           await fetch(
                    //               settings.MAP_PROXY_URL.BASE_URL +
                    //                   "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                    //                   encodeURIComponent(
                    //                       "https://maps.googleapis.com/maps/api/place/nearbysearch/json?key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE&pagetoken=" +
                    //                           biz2LocationRespJson.next_page_token
                    //                   )
                    //           )
                    //       ).json()
                    //     : { results: [] };

                    console.log(
                        bizLocationRespJson.results,
                        biz2LocationRespJson.results,
                        biz3LocationRespJson.results
                    );

                    dispatch({
                        type: GET_BUSINESSES,
                        payload: [
                            ...bizLocationRespJson.results,
                            ...biz2LocationRespJson.results,
                            ...biz3LocationRespJson.results,
                        ],
                    });

                    dispatch({
                        type: GET_STATIONS,
                        payload: stationsData,
                    });
                }

                // if (gmapLocationRespJson.status === "OK") {
                //     const lat =
                //             gmapLocationRespJson.results[0].geometry.location
                //                 .lat,
                //         lng =
                //             gmapLocationRespJson.results[0].geometry.location
                //                 .lng,
                //         centerAddress =
                //             gmapLocationRespJson.results[0].formatted_address;

                //     dispatch(setCenter({ lat: lat, lng: lng }));

                //     dispatch(setCenterAddress(centerAddress));

                //     const stationsData = await fetchAuth(
                //         dispatch,
                //         settings.API.BASE_URL +
                //             settings.API.API_PATH +
                //             settings.API_END_POINTS.STATIONS_BY_RANGE +
                //             "?" +
                //             "latitude=" +
                //             encodeURI(lat) +
                //             "&longitude=" +
                //             encodeURI(lng) +
                //             "&range=" +
                //             range +
                //             "&usagePeriodStart=" +
                //             dayjs(
                //                 state.campaignReducer.filterDateFrom
                //             ).valueOf() +
                //             "&usagePeriod=" +
                //             CampaignDurationInt[
                //                 state.campaignReducer.filterContractDuration
                //             ],
                //         {
                //             headers: {
                //                 Authorization:
                //                     "Bearer " +
                //                     state.usersReducer.user.authentication
                //                         .access_token,
                //             },
                //         }
                //     );

                //     dispatch({
                //         type: GET_STATIONS,
                //         payload: stationsData,
                //     });
                // }

                return;
            }

            // if (skipGeoSearch && center) {
            //     dispatch(setCenter(center));

            //     const stationsData = await fetchAuth(
            //         dispatch,
            //         settings.API.BASE_URL +
            //             settings.API.API_PATH +
            //             settings.API_END_POINTS.STATIONS_BY_RANGE +
            //             "?" +
            //             "latitude=" +
            //             encodeURI("" + center.lat) +
            //             "&longitude=" +
            //             encodeURI("" + center.lng) +
            //             "&range=" +
            //             range +
            //             "&usagePeriodStart=" +
            //             dayjs(state.campaignReducer.filterDateFrom).valueOf() +
            //             "&usagePeriod=" +
            //             CampaignDurationInt[
            //                 state.campaignReducer.filterContractDuration
            //             ],
            //         {
            //             headers: {
            //                 Authorization:
            //                     "Bearer " +
            //                     state.usersReducer.user.authentication
            //                         .access_token,
            //             },
            //         }
            //     );

            //     dispatch({
            //         type: GET_BUSINESSES,
            //         payload: stationsData,
            //     });
            // }
        } catch (e) {
            // dispatch(setAuthError(e.message))
            return;
        }
    };
}

function timeout(ms) {
    console.log("timeout");
    return new Promise((resolve) => setTimeout(resolve, ms));
}
