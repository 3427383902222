import { Box, Button, DataTable, Heading, Layer, Text } from "grommet";
import { Edit, Trash } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomerDetails from "../../elements/CustomerDetails";
import NewCustomerForm from "../../elements/NewCustomerForm";
import { createNewCustomer, getCustomers } from "../../store/customers/actions";
import { rootState } from "../../store/rootReducer";
import Customer, { CreateCustomerRequest } from "../../types/Customer";

export interface CustomerRowObject extends Customer {}

interface CustomersProps {
    customers: Customer[];
    getCustomers: () => any;
    createNewCustomer: (newCustomer: CreateCustomerRequest) => any;
}

const Customers: React.FC<CustomersProps> = (props) => {
    const [showDeleteLayerId, setShowDeleteLayerId] = useState<number | null>();
    const [showEditLayerId, setShowEditLayerId] = useState<number | null>();
    const [showNewCustomerLayer, setShowNewCustomerLayer] = useState<boolean>(
        false
    );
    // const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    useEffect(() => {
        props.getCustomers();
    }, []);

    // const customerObject =
    //     props.customers &&
    //     props.customers.reduce((object, customer) => {
    //         return {
    //             ...object,
    //             [customer.id]: customer,
    //         };
    //     }, {});

    return (
        <Box>
            <Box justify="center" width="medium">
                <Button
                    margin={{ top: "medium" }}
                    label="Neuer Kunde"
                    onClick={() => setShowNewCustomerLayer(true)}
                ></Button>
                {showNewCustomerLayer && (
                    <Layer
                        onEsc={() => setShowNewCustomerLayer(false)}
                        onClickOutside={() => setShowNewCustomerLayer(false)}
                        full={true}
                    >
                        <NewCustomerForm
                            closeLayer={(bool: boolean) =>
                                setShowNewCustomerLayer(bool)
                            }
                            // props.createNewCustomer({
                            //     name: "Test",
                            //     companyName: "test",
                            //     phone: "0101230123",
                            //     email: "string",
                            //     city: "string",
                            //     street: "string",
                            //     zip: "string",
                            // })
                        />
                    </Layer>
                )}
            </Box>
            <Box pad="large">
                {showEditLayerId && (
                    <Layer
                        onEsc={() => setShowEditLayerId(null)}
                        onClickOutside={() => setShowEditLayerId(null)}
                        full={false}
                    >
                        <CustomerDetails
                            customer={
                                props.customers.filter(
                                    (campaign) =>
                                        showEditLayerId === campaign.id
                                )[0]
                            }
                            closeLayer={setShowEditLayerId}
                        />
                    </Layer>
                )}
                {showDeleteLayerId && (
                    <Layer
                        onEsc={() => setShowDeleteLayerId(null)}
                        onClickOutside={() => setShowDeleteLayerId(null)}
                        full={false}
                    >
                        <Box pad="large">
                            <Heading
                                textAlign="center"
                                level={3}
                                margin={{ bottom: "medium" }}
                            >
                                Möchten Sie diese Kampagne wirklich löschen?
                            </Heading>
                            <Box direction="row" justify="between">
                                <Button
                                    color="danger"
                                    icon={<Trash color="danger" />}
                                    label="Löschen"
                                    onClick={() => {
                                        setShowDeleteLayerId(null);
                                    }}
                                />
                                <Button
                                    label="Abbrechen"
                                    onClick={() => setShowDeleteLayerId(null)}
                                />
                            </Box>
                        </Box>
                    </Layer>
                )}

                <Box overflow="scroll">
                    {/* <Button
                        onClick={() => {
                            // CsvExporter.parseCampaignArrayToCSV(props.campaigns);
                        }}
                    >
                        Download
                    </Button> */}
                    <DataTable
                        columns={[
                            {
                                property: "id",
                                header: undefined,
                                primary: true,
                                render: (rowObject: Customer) => null,
                            },

                            {
                                property: "companyName",
                                header: "Firma", //<Text>{(!searchObject || !searchObject.name) ? 'Auftragsnummer' : 'Suche: ' + searchObject.name}</Text>,
                                align: "start",
                                search: true,
                                render: (rowObject: Customer) => (
                                    <Box pad="small">
                                        <Text>{rowObject.companyName}</Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "name",
                                header: "Ansprechpartner",
                                align: "start",
                                search: true,
                                render: (rowObject: Customer) => (
                                    <Box pad="small">
                                        <Text>{rowObject.name}</Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "street",
                                align: "center",
                                header: "Straße",
                                render: (rowObject: Customer) => (
                                    <Box pad="small">
                                        <Text>{rowObject.street}</Text>
                                    </Box>
                                ),
                                search: true,
                            },
                            {
                                property: "city",
                                align: "center",
                                header: "Stadt",
                                render: (rowObject: Customer) => (
                                    <Box pad="small">
                                        <Text>{rowObject.city}</Text>
                                    </Box>
                                ),
                                search: true,
                            },
                            {
                                property: "zip",
                                align: "center",
                                header: "PLZ",
                                render: (rowObject: Customer) => (
                                    <Box pad="small">
                                        <Text>{rowObject.zip}</Text>
                                    </Box>
                                ),
                                search: true,
                            },
                            {
                                property: "actions",
                                header: "Bearbeiten",
                                align: "center",
                                render: (rowObject: Customer) => {
                                    return (
                                        <Box pad="small" direction="row">
                                            {/* {(!rowObject.end ||
                                            rowObject.amountOfAllocations ===
                                                1) && (
                                            <Button
                                                disabled={
                                                    props.userRole !==
                                                        "ADMIN" ||
                                                    campaign.status !==
                                                        CampaignStatus.CREATED
                                                }
                                                onClick={() => {
                                                    setShowApprovalLayerId(
                                                        campaign.id
                                                    );
                                                }}
                                                icon={
                                                    <Checkmark color="success" />
                                                }
                                            />
                                        )} */}

                                            <Button
                                                // disabled={
                                                //     (props.userRole !==
                                                //         "ADMIN" &&
                                                //         campaign.createdBy
                                                //             .email !==
                                                //             props.userName) ||
                                                //     campaign.status ===
                                                //         CampaignStatus.APPROVED
                                                // }
                                                onClick={() => {
                                                    setShowEditLayerId(
                                                        rowObject.id
                                                    );
                                                }}
                                                icon={<Edit color="warning" />}
                                            />
                                        </Box>
                                    );
                                },
                                sortable: false,
                            },
                        ]}
                        data={props.customers}
                        sortable
                        resizeable={true}
                    />
                </Box>
                <a id="download_hidden" />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        customers: state.customerReducer.customers,
    };
};

const mapDispatchToProps = {
    getCustomers,
    createNewCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
