import { combineReducers } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AuthenticationError } from "../types/Authentication";
import { allocationReducer } from "./allocations/reducers";
import { AllocationActionType, AllocationState } from "./allocations/types";
import { businessReducer } from "./businesses/reducers";
import { BusinessesActionTypes, BusinessState } from "./businesses/types";
import { campaignReducer } from "./campaign/reducers";
import { CampaignsActionType, CampaignState } from "./campaign/types";
import { customerReducer } from "./customers/reducers";
import { CustomerState } from "./customers/types";
import { stationsReducer } from "./stations/reducers";
import { StationsActionTypes, StationState } from "./stations/types";
import { setUserLoggedOut } from "./user/actions";
import { usersReducer } from "./user/reducers";
import { UsersActionTypes, UserState } from "./user/types";

export const rootReducer = combineReducers({
    stationsReducer,
    usersReducer,
    allocationReducer,
    campaignReducer,
    customerReducer,
    businessReducer,
});

export interface rootState {
    stationsReducer: StationState;
    usersReducer: UserState;
    allocationReducer: AllocationState;
    campaignReducer: CampaignState;
    customerReducer: CustomerState;
    businessReducer: BusinessState;
}

export const fetchAuth = async (
    dispatch: ThunkDispatch<
        {},
        {},
        | UsersActionTypes
        | StationsActionTypes
        | AllocationActionType
        | CampaignsActionType
        | BusinessesActionTypes
    >,
    input: RequestInfo,
    init?: RequestInit | undefined
): Promise<any> => {
    // try {
    //     const authenticated = await fetch(settings.API.BASE_URL + settings.API.API_PATH + settings.API_END_POINTS.INFO,
    //         {
    //             headers: init ? init.headers : {}
    //         })

    //     if (!authenticated.ok) {
    //         dispatch(setUserLoggedOut())
    //     }
    // } catch {

    // }

    const response = await fetch(input, init);

    if (response.ok) {
        try {
            return await response.json();
        } catch (error) {
            return null;
        }
    }

    //TODO add error fetching function parameter

    if (response.status === 401) {
        return dispatch(setUserLoggedOut());
    }

    if (response.status === 400) {
        const data = await response.json();
        if (data.error) dispatch(setUserLoggedOut());
        throw new Error(AuthenticationError.WRONG_CREDENTIALS);
    }

    if (
        response.status === 500 ||
        response.status === 502 ||
        response.status === 503
    ) {
        const data = await response.json();
        if (data.error) dispatch(setUserLoggedOut());
        throw new Error(AuthenticationError.SERVER_ERROR);
    }

    console.error("Request Status: " + response.status, await response.json());
    throw new Error("yolo");
};
