import { User } from "./Authentication";

export interface Customer {
    id: number;
    name: string | null;
    companyName: string;
    phone: string | null;
    email: string | null;
    city: string | null;
    street: string | null;
    zip: string | null;
    createdAt: number;
    createdBy: User;
}

export interface CreateCustomerRequest {
    name: string;
    companyName: string;
    phone: string;
    email: string;
    city: string;
    street: string;
    zip: string;
}

export interface CustomersResponse {
    content: Customer[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface NewCustomerResponse {
    id: number;
    name: string | null;
    companyName: string;
    phone: string | null;
    email: string | null;
    city: string | null;
    street: string | null;
    zip: string | null;
    createdAt: number;
    createdBy: User;
}

export enum NewCustomerRequestStatus {
    WAITING = "WAITING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    NONE = "NONE",
}

export default Customer;
