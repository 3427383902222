import { RequestStatus } from "../../types/Authentication";
import Station, { Location } from "../../types/Station";

export interface StationState {
    stations: Station[];
    stationsById: Station[];
    isFetching: boolean;
    isFetchingById: boolean;
    currentCenter: Location;
    currentRange: number;
    searchString: string;
    searchStringDisplay: string;
    locationSearch: any[] | null;
    centerAddress: string | null;
    currentStationsSelection: Station[];
    requestStatus: null | RequestStatus;
}

export const GET_STATIONS = "GET_STATIONS";
export const GET_SINGLE_STATION = "GET_SINGLE_STATION";
export const IS_FETCHING_STATIONS = "IS_FETCHING_STATIONS";
export const REQUEST_GEO_DATA = "REQUEST_GEO_DATA";
export const RECEIVE_GEO_DATA = "RECEIVE_GEO_DATA";
export const IS_FETCHING_STATIONS_BY_RANGE = "IS_FETCHING_STATIONS_BY_RANGE";
export const RECEIVE_STATIONS_BY_RANGE = "RECEIVE_STATIONS_BY_RANGE";
export const IS_FETCHING_STATIONS_BY_RANGE_PUBLIC =
    "IS_FETCHING_STATIONS_BY_RANGE_PUBLIC";
export const RECEIVE_STATIONS_BY_RANGE_PUBLIC =
    "RECEIVE_STATIONS_BY_RANGE_PUBLIC";
export const IS_FETCHING_STATIONS_BY_ID = "IS_FETCHING_STATIONS_BY_ID";
export const RECEIVE_STATIONS_BY_ID = "RECEIVE_STATIONS_BY_ID";
export const IS_FETCHING_STATIONS_BY_SHELL_ID =
    "IS_FETCHING_STATIONS_BY_SHELL_ID";
export const RECEIVE_STATIONS_BY_SHELL_ID = "RECEIVE_STATIONS_BY_SHELL_ID";
export const IS_FETCHING_ASSIGNED_STATIONS = "IS_FETCHING_ASSIGNED_STATIONS";
export const RECEIVE_ASSIGNED_STATIONS = "RECEIVE_ASSIGNED_STATIONS";
export const SET_CENTER = "SET_CENTER";
export const SET_RANGE = "SET_RANGE";
export const CLEAR_STATE_AFTER_LOGOUT = "CLEAR_STATE_AFTER_LOGOUT";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_SEARCH_STRING_DISPLAY = "SET_SEARCH_STRING_DISPLAY";
export const SET_CENTER_ADDRESS = "SET_CENTER_ADDRESS";
export const SET_CURRENT_SCREEN_STATIONS = "SET_CURRENT_SCREEN_STATIONS";
export const UPDATE_STATION = "UPDATE_STATION";
export const REQUEST_STATUS = "REQUEST_STATUS";

interface UpdateStationsAction {
    type: typeof UPDATE_STATION;
    payload: Station;
}

interface RequestStatusAction {
    type: typeof REQUEST_STATUS;
    payload: RequestStatus | null;
}

interface GetStationsAction {
    type: typeof GET_STATIONS;
    payload: Station[];
}

interface FetchingGetStationsAction {
    type: typeof IS_FETCHING_STATIONS;
}

interface GetSingleStationByShellIdAction {
    type: typeof RECEIVE_STATIONS_BY_SHELL_ID;
    payload: Station[];
}

interface FetchingStationByShellIdAction {
    type: typeof IS_FETCHING_STATIONS_BY_SHELL_ID;
}

interface GetSingleStationAction {
    type: typeof GET_SINGLE_STATION;
    payload: Station;
}

interface FetchingAssignedStations {
    type: typeof IS_FETCHING_ASSIGNED_STATIONS;
}

interface GetAssignedStationAction {
    type: typeof RECEIVE_ASSIGNED_STATIONS;
    payload: Station[];
}

interface GetStationsByRangeAction {
    type: typeof RECEIVE_STATIONS_BY_RANGE;
    payload: Station[];
}

interface FetchingStationsByRangeAction {
    type: typeof IS_FETCHING_STATIONS_BY_RANGE;
}

interface GetStationsByRangePublicAction {
    type: typeof RECEIVE_STATIONS_BY_RANGE_PUBLIC;
    payload: Station[];
}

interface FetchingStationsByRangePublicAction {
    type: typeof IS_FETCHING_STATIONS_BY_RANGE_PUBLIC;
}

interface GetStationsByIdsAction {
    type: typeof RECEIVE_STATIONS_BY_ID;
    payload: Station[];
}

interface FetchingStationsByIdAction {
    type: typeof IS_FETCHING_STATIONS_BY_ID;
}

interface SetRangeAction {
    type: typeof SET_RANGE;
    payload: number;
}

interface SetCenterAction {
    type: typeof SET_CENTER;
    payload: Location;
}

interface SetSearchStringAction {
    type: typeof SET_SEARCH_STRING;
    payload: string;
}

interface SetSearchStringDisplayAction {
    type: typeof SET_SEARCH_STRING_DISPLAY;
    payload: string;
}

interface ClearStationState {
    type: typeof CLEAR_STATE_AFTER_LOGOUT;
}

interface SetCenterAddressAction {
    type: typeof SET_CENTER_ADDRESS;
    payload: string;
}

interface SetScreenStationsAction {
    type: typeof SET_CURRENT_SCREEN_STATIONS;
    payload: Station[];
}

export type StationsActionTypes =
    | GetStationsAction
    | GetSingleStationAction
    | FetchingGetStationsAction
    | GetSingleStationByShellIdAction
    | FetchingStationByShellIdAction
    | GetStationsByRangeAction
    | FetchingStationsByRangeAction
    | GetStationsByRangePublicAction
    | FetchingStationsByRangePublicAction
    | GetStationsByIdsAction
    | FetchingStationsByIdAction
    | GetAssignedStationAction
    | FetchingAssignedStations
    | SetRangeAction
    | SetCenterAction
    | SetSearchStringAction
    | SetSearchStringDisplayAction
    | ClearStationState
    | SetCenterAddressAction
    | SetScreenStationsAction
    | RequestStatusAction
    | UpdateStationsAction;
