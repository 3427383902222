import { ThunkAction, ThunkDispatch } from "redux-thunk";
import settings from "../../settings";
import Customer, {
    CreateCustomerRequest,
    CustomersResponse,
    NewCustomerRequestStatus,
    NewCustomerResponse,
} from "../../types/Customer";
import { fetchAuth, rootState } from "../rootReducer";
import { logoutUser } from "../user/actions";
import {
    CHANGE_CUSTOMER,
    CHANGE_CUSTOMER_REQUEST_STATUS,
    CHANGE_CUSTOMER_RESPONSE,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_REQUEST_STATUS,
    CREATE_NEW_CUSTOMER_RESPONSE,
    CustomersActionType,
    GET_ALL_CUSTOMERS,
    GET_ALL_CUSTOMERS_RESPONSE,
} from "./types";

function getCustomersRequest(): CustomersActionType {
    return {
        type: GET_ALL_CUSTOMERS,
    };
}

function createCustomerRequest(): CustomersActionType {
    return {
        type: CREATE_NEW_CUSTOMER,
    };
}

function changeCustomerRequest(): CustomersActionType {
    return {
        type: CHANGE_CUSTOMER,
    };
}

function setCreateCustomerRequestStatus(
    status: NewCustomerRequestStatus
): CustomersActionType {
    return {
        type: CREATE_NEW_CUSTOMER_REQUEST_STATUS,
        payload: status,
    };
}

export function setChangeCustomerRequestStatus(
    status: NewCustomerRequestStatus
): CustomersActionType {
    return {
        type: CHANGE_CUSTOMER_REQUEST_STATUS,
        payload: status,
    };
}

export function getCustomers(): ThunkAction<
    Promise<void>,
    {},
    {},
    CustomersActionType
> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CustomersActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(getCustomersRequest());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        const response: CustomersResponse = await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.CUSTOMERS +
                "?size=1000",
            {
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        dispatch({
            type: GET_ALL_CUSTOMERS_RESPONSE,
            payload: response.content,
        });
    };
}

export function createNewCustomer(
    parameter: CreateCustomerRequest
): ThunkAction<Promise<void>, {}, {}, CustomersActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CustomersActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(createCustomerRequest());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            dispatch(
                setCreateCustomerRequestStatus(NewCustomerRequestStatus.WAITING)
            );

            const response: NewCustomerResponse = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.CUSTOMERS,
                {
                    method: "POST",
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...parameter,
                    }),
                }
            );

            dispatch(
                setCreateCustomerRequestStatus(NewCustomerRequestStatus.SUCCESS)
            );

            dispatch({
                type: CREATE_NEW_CUSTOMER_RESPONSE,
                payload: response,
            });

            dispatch(getCustomers());
        } catch (e) {
            dispatch(
                setCreateCustomerRequestStatus(NewCustomerRequestStatus.ERROR)
            );
            return;
        }
    };
}

// function fetchCampaignsByStation(): CampaignsActionType {
//     return {
//         type: REQUEST_CAMPAIGNS_BY_STATION,
//     };
// }

// function requestDeleteCampaign(): CampaignsActionType {
//     return {
//         type: REQUEST_DELETE_CAMPAIGN,
//     };
// }

// function requestPutCampaign(): CampaignsActionType {
//     return {
//         type: REQUEST_PUT_CAMPAIGN,
//     };
// }

// function requestNewCampaign(): CampaignsActionType {
//     return {
//         type: REQUEST_NEW_CAMPAIGN,
//     };
// }

// export function setNewCampaignRequestStatus(
//     status: NewCampaignRequestStatus
// ): CampaignsActionType {
//     return {
//         type: SET_NEW_CAMPAIGN_REQUEST_STATUS,
//         payload: status,
//     };
// }

// export function setPutCampaignRequestStatus(
//     status: NewCampaignRequestStatus
// ): CampaignsActionType {
//     return {
//         type: SET_PUT_CAMPAIGN_REQUEST_STATUS,
//         payload: status,
//     };
// }

// function requestApproveCampaign(): CampaignsActionType {
//     return {
//         type: REQUEST_APPROVE_CAMPAIGN,
//     };
// }

// export function clearCampaignsState(): CampaignsActionType {
//     return {
//         type: CLEAR_CAMPAIGN_AFTER_LOGOUT,
//     };
// }

// export function addStationForNewCampaign(
//     station: Station
// ): CampaignsActionType {
//     return {
//         type: ADD_STATION_TO_NEW_CAMPAIGN,
//         payload: station,
//     };
// }

// export function addStationsForNewCampaign(
//     stations: Station[]
// ): CampaignsActionType {
//     return {
//         type: ADD_STATIONS_TO_NEW_CAMPAIGN,
//         payload: stations,
//     };
// }

// export function removeStationForNewCampaign(
//     stationId: Station
// ): CampaignsActionType {
//     return {
//         type: REMOVE_STATION_TO_NEW_CAMPAIGN,
//         payload: stationId,
//     };
// }

// export function setStationOfNewCampaign(
//     stations: Station[]
// ): CampaignsActionType {
//     return {
//         type: SET_STATIONS_FOR_NEW_CAMPAIGN,
//         payload: stations,
//     };
// }

// export function setStationOfCurrentCampaign(
//     stations: Station[]
// ): CampaignsActionType {
//     return {
//         type: SET_STATION_FOR_CURRENT_CAMPAIGN,
//         payload: stations,
//     };
// }

// export function addStationToCurrentCampaign(
//     station: Station
// ): CampaignsActionType {
//     return {
//         type: ADD_STATION_TO_CURRENT_CAMPAIGN,
//         payload: station,
//     };
// }

// export function addStationsToCurrentCampaign(
//     stations: Station[]
// ): CampaignsActionType {
//     return {
//         type: ADD_STATIONS_TO_CURRENT_CAMPAIGN,
//         payload: stations,
//     };
// }

// export function removeStationFromCurrentCampaign(
//     station: Station
// ): CampaignsActionType {
//     return {
//         type: REMOVE_STATION_FROM_CURRENT_CAMPAIGN,
//         payload: station,
//     };
// }

// export function setFilterDateFrom(fromDate: dayjs.Dayjs): CampaignsActionType {
//     return {
//         type: SET_FILTER_FROM_DATE,
//         payload: fromDate,
//     };
// }

// export function setFilterContractDuration(
//     campaignDuration: CampaignDuration
// ): CampaignsActionType {
//     return {
//         type: SET_FILTER_CONTRACT_DURATION,
//         payload: campaignDuration,
//     };
// }

// export function setFilterSlotWeight(slotWeight: number): CampaignsActionType {
//     return {
//         type: SET_FILTER_SLOT_WEIGHT,
//         payload: slotWeight,
//     };
// }

//

// export function getCampaignsByStation(
//     stationId: number
// ): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
//     return async (
//         dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
//         getState: () => {}
//     ): Promise<void> => {
//         dispatch(fetchCampaignsByStation());

//         const state: rootState = getState() as rootState;

//         if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
//             dispatch(logoutUser());
//             return;
//         }

//         const response: CampaignsResponse = await fetchAuth(
//             dispatch,
//             settings.API.BASE_URL +
//                 settings.API.API_PATH +
//                 settings.API_END_POINTS.CAMPAIGNS +
//                 "?size=1000&station=" +
//                 stationId,
//             {
//                 headers: {
//                     Authorization:
//                         "Bearer " +
//                         state.usersReducer.user.authentication.access_token,
//                 },
//             }
//         );

//         dispatch({
//             type: RECEIVE_CAMPAIGNS_BY_STATION,
//             payload: {
//                 stationId: stationId,
//                 campaigns: response.content,
//             },
//         });
//     };
// }

// export function deleteCampaign(
//     campaignId: number
// ): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
//     return async (
//         dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
//         getState: () => {}
//     ): Promise<void> => {
//         dispatch(requestDeleteCampaign());

//         const state: rootState = getState() as rootState;

//         if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
//             dispatch(logoutUser());
//             return;
//         }

//         await fetchAuth(
//             dispatch,
//             settings.API.BASE_URL +
//                 settings.API.API_PATH +
//                 settings.API_END_POINTS.CAMPAIGNS +
//                 "/" +
//                 campaignId,
//             {
//                 method: "DELETE",
//                 headers: {
//                     Authorization:
//                         "Bearer " +
//                         state.usersReducer.user.authentication.access_token,
//                 },
//             }
//         );

//         dispatch({
//             type: RECEIVE_DELETE_CAMPAIGN,
//         });

//         dispatch(getCampaigns());
//     };
// }

export function changeCustomer(
    parameter: Customer
): ThunkAction<Promise<void>, {}, {}, CustomersActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CustomersActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(changeCustomerRequest());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            const response: Customer = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.CUSTOMERS +
                    "/" +
                    parameter.id,
                {
                    method: "PUT",
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        companyName: parameter.companyName,
                        name: parameter.name || "",
                        phone: parameter.phone || "",
                        email: parameter.email || "",
                        street: parameter.street || "",
                        city: parameter.city || "",
                        zip: parameter.zip || "",
                    } as CreateCustomerRequest),
                }
            );

            // dispatch(findStationsByRange('', '' + state.stationsReducer.currentRange, true, state.stationsReducer.currentCenter));

            // dispatch(clearCampaignsState())

            dispatch(getCustomers());

            dispatch({
                type: CHANGE_CUSTOMER_RESPONSE,
                payload: response,
            });

            dispatch(
                setChangeCustomerRequestStatus(NewCustomerRequestStatus.SUCCESS)
            );
        } catch (e) {
            dispatch(
                setChangeCustomerRequestStatus(NewCustomerRequestStatus.ERROR)
            );
            return;
        }
    };
}

// export function approveCampaign(
//     campaign: Campaign,
//     approvedAllocationIds: number[]
// ): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
//     return async (
//         dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
//         getState: () => {}
//     ): Promise<void> => {
//         dispatch(requestApproveCampaign());

//         const state: rootState = getState() as rootState;

//         if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
//             dispatch(logoutUser());
//             return;
//         }

//         await fetchAuth(
//             dispatch,
//             settings.API.BASE_URL +
//                 settings.API.API_PATH +
//                 settings.API_END_POINTS.CAMPAIGNS +
//                 "/" +
//                 campaign.id +
//                 "/approve?" +
//                 approvedAllocationIds
//                     .map((id) => "allocationIds=" + id)
//                     .join("&"),
//             {
//                 method: "PUT",
//                 headers: {
//                     Authorization:
//                         "Bearer " +
//                         state.usersReducer.user.authentication.access_token,
//                 },
//             }
//         );

//         dispatch({
//             type: RECEIVE_APPROVE_CAMPAIGN,
//         });

//         dispatch(getCampaigns());
//     };
// }

export const something = "";
