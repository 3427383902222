import dayjs from "dayjs";
import {
    Anchor,
    Box,
    Button,
    Calendar,
    DropButton,
    Form,
    FormField,
    InfiniteScroll,
    ResponsiveContext,
    Tab,
    Tabs,
    Text,
    TextInput,
} from "grommet";
import { Ascend, Download, MapLocation } from "grommet-icons";
import React, { MouseEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import XLSX from "xlsx";
import { findStationsAndBusinessesByRange } from "../../store/businesses/actions";
import {
    addStationForNewCampaign,
    addStationsForNewCampaign,
    removeStationForNewCampaign,
    setStationOfNewCampaign,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import {
    clearState,
    findStationByShellId,
    findStationsByRange,
    getAllStations,
} from "../../store/stations/actions";
import { User, UserRole } from "../../types/Authentication";
import Buisness from "../../types/Businesses";
import Station, { Region } from "../../types/Station";
import { CsvExporter } from "../../util/export/csvExport";
import BusinessItem from "../MapListItem/BusinessItem";
import ListItem from "../MapListItem/MapListItem";
import CheckBoxWithOwnState from "../MapSubHeadline/CheckBoxWithOwnState";
import NewStationForm from "../NewStationForm";

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

function handleFile(e: React.ChangeEvent<any>, stations: Station[]) {
    var files = e.target.files,
        f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = new Uint8Array(e.target ? (e.target.result as any) : 0);
        var workbook = XLSX.read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];

        if (
            (worksheet["A1"].v !== "id",
            worksheet["B1"].v !== "lng",
            worksheet["C1"].v !== "lat")
        ) {
            alert(
                "File format is incorrect - A1 has to be 'id', B1 'lng' and C1 'lat'."
            );
            return;
        }

        function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = deg2rad(lat2 - lat1); // deg2rad below
            var dLon = deg2rad(lon2 - lon1);
            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) *
                    Math.cos(deg2rad(lat2)) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        }

        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        let customerLocation = XLSX.utils.sheet_to_json(worksheet);

        const custMap = customerLocation.map((cL) => {
            const customerLoc = cL as { id: string; lng: Number; lat: number };
            const sortedStations = stations.reduce((array: any[], station) => {
                const distance = getDistanceFromLatLonInKm(
                    station.latitude,
                    station.longitude,
                    customerLoc.lat,
                    customerLoc.lng
                );
                if (array.length < 3)
                    return [...array, { station: station, dist: distance }];
                return [...array, { station: station, dist: distance }]
                    .sort((a, b) => (a.dist < b.dist ? -1 : 1))
                    .slice(0, 3);
            }, []);
            const sortedStationsAsObject = sortedStations.reduce(
                (acc, station: { station: Station; dist: number }, index) => {
                    return {
                        ...acc,
                        ["Station " + (index + 1) + " OBN"]:
                            station.station.shellId,
                        ["Station " + (index + 1) + " Straße"]:
                            station.station.street,
                        ["Station " + (index + 1) + " PLZ"]:
                            station.station.zipCode,
                        ["Station " + (index + 1) + " Ort"]:
                            station.station.city,
                        ["Station " + (index + 1) + " Distanz"]: Math.round(
                            station.dist
                        ),
                        ["Station " + (index + 1)]: " ",
                    };
                },
                {}
            );
            return {
                ...sortedStationsAsObject,
                ...(cL as { id: string; lng: Number; lat: number }),
            };
        });

        const newWS = XLSX.utils.json_to_sheet(custMap);
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            newWorkbook,
            newWS,
            "Kundenlocation gematched"
        );

        XLSX.writeFile(newWorkbook, "matched.xlsx");

        /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);
}

async function handleFileWithoutLatLng(
    e: React.ChangeEvent<any>,
    stations: Station[]
) {
    var files = e.target.files,
        f = files[0];
    var reader = new FileReader();
    reader.onload = async function (e) {
        var data = new Uint8Array(e.target ? (e.target.result as any) : 0);
        var workbook = XLSX.read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];

        if (
            (worksheet["A1"].v !== "id",
            worksheet["B1"].v !== "city",
            worksheet["C1"].v !== "street",
            worksheet["D1"].v !== "plz")
        ) {
            alert(
                "File format is incorrect - A1 has to be 'id', B1 'lng' and C1 'lat'."
            );
            return;
        }

        function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
            var R = 6371; // Radius of the earth in km
            var dLat = deg2rad(lat2 - lat1); // deg2rad below
            var dLon = deg2rad(lon2 - lon1);
            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) *
                    Math.cos(deg2rad(lat2)) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        }

        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        async function getLatLngForArray(addresses: string[]) {
            const mapRequestUrl = "https://geocode.ism-hi-light.de";

            const gmapLocationRespJson: { lat: number; lng: number }[] = await (
                await fetch(mapRequestUrl, {
                    method: "POST",
                    body: JSON.stringify({
                        addresses: addresses,
                    }),
                    headers: {
                        Authorization: "!Array.isArray(wrong)",
                        "Content-Type": "application/json",
                    },
                })
            )
                .json()
                .then((json) => {
                    return json;
                });

            return gmapLocationRespJson;
        }

        let customerLocation = XLSX.utils.sheet_to_json(worksheet);

        const custLocStringArray = customerLocation.map((cL) => {
            const location = cL as {
                id: string;
                city: string;
                street: string;
                plz: string;
            };
            return (
                "" + location.plz + " " + location.city + " " + location.street
            );
        });

        const geocodedArray = await getLatLngForArray(custLocStringArray);

        const combinedArray = customerLocation.map((cL, i) => {
            const location = cL as {
                id: string;
                city: string;
                street: string;
                plz: string;
            };

            return {
                ...location,
                ...geocodedArray[i],
            };
        });

        const custMap = combinedArray.map((locationObject) => {
            const sortedStations = stations.reduce((array: any[], station) => {
                const distance = getDistanceFromLatLonInKm(
                    station.latitude,
                    station.longitude,
                    locationObject.lat,
                    locationObject.lng
                );
                if (array.length < 3)
                    return [...array, { station: station, dist: distance }];
                return [...array, { station: station, dist: distance }]
                    .sort((a, b) => (a.dist < b.dist ? -1 : 1))
                    .slice(0, 3);
            }, []);
            const sortedStationsAsObject = sortedStations.reduce(
                (acc, station: { station: Station; dist: number }, index) => {
                    return {
                        ...acc,
                        ["Station " + (index + 1) + " OBN"]:
                            station.station.shellId,
                        ["Station " + (index + 1) + " Straße"]:
                            station.station.street,
                        ["Station " + (index + 1) + " PLZ"]:
                            station.station.zipCode,
                        ["Station " + (index + 1) + " Ort"]:
                            station.station.city,
                        ["Station " + (index + 1) + " Distanz"]: Math.round(
                            station.dist
                        ),
                        ["Station " + (index + 1)]: " ",
                    };
                },
                {}
            );
            return {
                ...sortedStationsAsObject,
                ...locationObject,
            };
        });

        const newWS = XLSX.utils.json_to_sheet(custMap);
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            newWorkbook,
            newWS,
            "Kundenlocation gematched"
        );

        XLSX.writeFile(newWorkbook, "matched.xlsx");

        /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);
}

async function getPhoneForPlaces(
    e: React.ChangeEvent<any>,
    stations: Station[]
) {
    var files = e.target.files,
        f = files[0];
    var reader = new FileReader();
    reader.onload = async function (e) {
        var data = new Uint8Array(e.target ? (e.target.result as any) : 0);
        var workbook = XLSX.read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];

        if (
            (worksheet["A1"].v !== "id",
            worksheet["B1"].v !== "city",
            worksheet["C1"].v !== "street",
            worksheet["D1"].v !== "plz")
        ) {
            alert(
                "File format is incorrect - A1 has to be 'id', B1 'lng' and C1 'lat'."
            );
            return;
        }

        async function getPhoneForArray(addresses: string[]) {
            const mapRequestUrl = "https://geocode.ism-hi-light.de/phone";

            const phoneResp: string[] = await (
                await fetch(mapRequestUrl, {
                    method: "POST",
                    body: JSON.stringify({
                        addresses: addresses,
                    }),
                    headers: {
                        Authorization: "!Array.isArray(wrong)",
                        "Content-Type": "application/json",
                    },
                })
            )
                .json()
                .then((json) => {
                    return json;
                });

            return phoneResp;
        }

        let customerLocation = XLSX.utils.sheet_to_json(worksheet);

        const custLocStringArray = customerLocation.map((cL) => {
            const location = cL as {
                id: string;
                city: string;
                street: string;
                plz: string;
            };
            return (
                "" +
                location.id +
                "" +
                location.plz +
                " " +
                location.city +
                " " +
                location.street
            );
        });

        const phoneArray = await getPhoneForArray(custLocStringArray);

        const combinedArray = customerLocation.map((cL, i) => {
            const location = cL as {
                id: string;
                city: string;
                street: string;
                plz: string;
            };

            return {
                ...location,
                phone: phoneArray[i],
            };
        });

        const newWS = XLSX.utils.json_to_sheet(combinedArray);
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            newWorkbook,
            newWS,
            "Kundentelefonnummern"
        );

        XLSX.writeFile(newWorkbook, "phone.xlsx");

        /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);
}

interface GetAllStationsFunc {
    (string: string): void;
}

interface MapListProps {
    centerAddress: string | null;
    isFetching: boolean;
    initialFilterObject: {};
    stations: Station[];
    stationsForNewCampaign: Station[];
    user: User | null;
    addStationForNewCampaign: (station: Station) => void;
    addStationsForNewCampaign: (stations: Station[]) => void;
    findStationsByRange: (spot: string, range: string) => void;
    findStationByShellId: (shellId?: number) => void;
    getAllStations: () => void;
    handleFilterChange: (functionObject: {
        [key: string]: (station: Station) => boolean;
    }) => void;
    handleSelection(station: Station): any;
    handleShowMap?(showMap: boolean): any;
    handleNewCampaignSelect?: (bool: boolean) => void;
    removeStationForNewCampaign: (station: Station) => void;
    setShowStationDetails?: (any: any) => void;
    setStationOfNewCampaign: (stations: Station[]) => void;
    isFetchingBusinesses: boolean;
    businesses: Buisness[] | null;
    businessSearch: string;
    findStationsAndBusinessesByRange: (
        spot: string,
        range: string,
        businessSearch: string
    ) => void;
    clearState: () => void;
}

interface ListSearchProps {
    centerAddress: string | null;
    initialFilterObject: {};
    sortedList: Station[];
    stations: Station[];
    stationsForNewCampaign: Station[];
    user: User | null;
    addStationsForNewCampaign: (stations: Station[]) => void;
    findStationsByRange: (spot: string, range: string) => void;
    findStationByShellId: (shellId?: number) => void;
    getAllStations: () => void;
    handleFilterChange: (functionObject: {
        [key: string]: (station: Station) => boolean;
    }) => void;
    handleNewCampaignSelect?: (bool: boolean) => void;
    setStationOfNewCampaign: (stations: Station[]) => void;
    findStationsAndBusinessesByRange: (
        spot: string,
        range: string,
        businessSearch: string
    ) => void;
    clearState: () => void;
}

const SearchArea: React.FC<ListSearchProps> = (props) => {
    const [spot, setSpot] = React.useState("");
    const [cityName, setCityName] = React.useState("");
    const [businessSearch, setBusinessSearch] = React.useState("");
    const [shellId, setShellId] = React.useState("");
    const [range, setRange] = React.useState("20");
    const [additionalFiltersOpen, setAdditionalFiltersOpen] =
        React.useState<boolean>(false);
    const [timeoutRef, setTimeoutRef] = React.useState();
    const [openSlider, setOpenSlider] = React.useState<boolean>(true);
    const targetRef: any = React.useRef<HTMLDivElement>();
    const [regionToFilter, setRegionToFilter] = useState<Array<Region>>([]);
    const [cityToFilter, setCityToFilter] = useState<Array<Region>>([]);

    useEffect(() => {
        setSpot(props.centerAddress ? props.centerAddress : "");
    }, [props.centerAddress]);

    useEffect(() => {
        let timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (spot.length > 2) return null;
        }, 300);
    }, [spot]);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    style={{
                        minHeight: "261px",
                        maxHeight: "100%",
                        overflow: "auto",
                    }}
                    pad="small"
                    border="bottom"
                    width={size === "small" ? "100vw" : ""}
                    flex={false}
                    ref={targetRef}
                >
                    <Tabs
                        onActive={(index) => {
                            props.clearState();
                        }}
                    >
                        <Tab title="Adresse">
                            <Form
                                onSubmit={(event) => {
                                    props.findStationsByRange(spot, range);
                                    event.stopPropagation();
                                }}
                            >
                                <FormField name="spot" label="Ort">
                                    <TextInput
                                        value={spot}
                                        onChange={(event) => {
                                            const string = event.target.value;
                                            setSpot(string);
                                            // let timer
                                        }}
                                    />
                                </FormField>
                                <FormField name="range" label="Umkreis in km">
                                    <TextInput
                                        value={range}
                                        onChange={(event) =>
                                            setRange(event.target.value)
                                        }
                                        type="number"
                                        min="1"
                                    ></TextInput>
                                </FormField>
                                <Box border="bottom">
                                    <Box
                                        direction="row"
                                        justify="between"
                                        pad={{ bottom: "small" }}
                                    >
                                        <Anchor
                                            onClick={() =>
                                                setAdditionalFiltersOpen(
                                                    !additionalFiltersOpen
                                                )
                                            }
                                        >
                                            {additionalFiltersOpen
                                                ? "Filter schließen"
                                                : "Bundesländer ..."}
                                        </Anchor>
                                        <Anchor
                                            onClick={() =>
                                                props.findStationsByRange(
                                                    "",
                                                    "20000"
                                                )
                                            }
                                        >
                                            Alle Stationen
                                        </Anchor>
                                    </Box>
                                    {
                                        <Box
                                            height={"small"}
                                            overflow="scroll"
                                            pad="small"
                                            style={{
                                                display: additionalFiltersOpen
                                                    ? "flex"
                                                    : "none",
                                            }}
                                        >
                                            {Object.keys(Region).map(
                                                (region) => (
                                                    <Box
                                                        height="small"
                                                        style={{
                                                            minHeight: "25px",
                                                            maxWidth: "240px",
                                                        }}
                                                    >
                                                        <CheckBoxWithOwnState
                                                            checked={
                                                                regionToFilter.findIndex(
                                                                    (
                                                                        activeRegion
                                                                    ) =>
                                                                        Region[
                                                                            region
                                                                        ] ===
                                                                        activeRegion
                                                                ) > -1
                                                            }
                                                            label={
                                                                Region[region]
                                                            }
                                                            onChangeFunction={(
                                                                checked: boolean
                                                            ) => {
                                                                let regionFilteredBy: Array<Region>;
                                                                if (checked) {
                                                                    regionFilteredBy =
                                                                        regionToFilter.indexOf(
                                                                            Region[
                                                                                region
                                                                            ]
                                                                        ) === -1
                                                                            ? regionToFilter.concat(
                                                                                  Region[
                                                                                      region
                                                                                  ]
                                                                              )
                                                                            : regionToFilter;
                                                                    setRegionToFilter(
                                                                        regionFilteredBy
                                                                    );
                                                                } else {
                                                                    regionFilteredBy =
                                                                        regionToFilter.filter(
                                                                            (
                                                                                regionFiltered
                                                                            ) =>
                                                                                regionFiltered !==
                                                                                Region[
                                                                                    region
                                                                                ]
                                                                        );
                                                                    setRegionToFilter(
                                                                        regionFilteredBy
                                                                    );
                                                                }
                                                                props.handleFilterChange(
                                                                    {
                                                                        ...props.initialFilterObject,
                                                                        stationRegion:
                                                                            (
                                                                                station: Station
                                                                            ) =>
                                                                                regionFilteredBy.length !==
                                                                                0
                                                                                    ? regionFilteredBy.includes(
                                                                                          station.region
                                                                                      )
                                                                                    : true,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    }
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    justify="between"
                                >
                                    <Button
                                        label={
                                            <Text weight={800} color="white">
                                                Suchen
                                            </Text>
                                        }
                                        primary
                                        type="submit"
                                        // fill="horizontal"
                                        margin={{ vertical: "small" }}
                                        // pad='small'
                                    />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Anchor
                                                onClick={() =>
                                                    props.addStationsForNewCampaign(
                                                        props.sortedList
                                                    )
                                                }
                                            >
                                                Alle markieren
                                            </Anchor>
                                        )}
                                    <Text textAlign="end">
                                        {props.stations.length}{" "}
                                        {props.stations.length === 1
                                            ? " Station "
                                            : " Stationen "}{" "}
                                        gefunden
                                    </Text>
                                </Box>
                            </Form>
                            {/* </Box> */}
                        </Tab>
                        <Tab title="Ortssuche">
                            <Form
                                onSubmit={(event) => {
                                    props.getAllStations();
                                    event.stopPropagation();
                                }}
                            >
                                <FormField name="spot" label="Ort">
                                    <TextInput
                                        value={cityName}
                                        onChange={(event) => {
                                            const string = event.target.value;
                                            props.handleFilterChange({
                                                cityFilter: (station) =>
                                                    station.city
                                                        .toLowerCase()
                                                        .search(
                                                            string.toLocaleLowerCase()
                                                        ) > -1,
                                            });
                                            setCityName(string);
                                            // let timer
                                        }}
                                    />
                                </FormField>
                                <Box border="bottom">
                                    <Box
                                        direction="row"
                                        justify="between"
                                        pad={{ bottom: "small" }}
                                    ></Box>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    justify="between"
                                >
                                    <Button
                                        label={
                                            <Text weight={800} color="white">
                                                Suchen
                                            </Text>
                                        }
                                        primary
                                        type="submit"
                                        // fill="horizontal"
                                        margin={{ vertical: "small" }}
                                        // pad='small'
                                    />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Anchor
                                                onClick={() =>
                                                    props.addStationsForNewCampaign(
                                                        props.sortedList
                                                    )
                                                }
                                            >
                                                Alle markieren
                                            </Anchor>
                                        )}
                                    <Text textAlign="end">
                                        {props.stations.length}{" "}
                                        {props.stations.length === 1
                                            ? " Station "
                                            : " Stationen "}{" "}
                                        gefunden
                                    </Text>
                                </Box>
                            </Form>
                            {/* </Box> */}
                        </Tab>
                        {props.user &&
                            props.user.authentication.role !== "WUERTH" && (
                                <Tab title="Shell-ID / OBN">
                                    <Form
                                        onSubmit={(event) => {
                                            props.findStationByShellId(
                                                parseInt(shellId)
                                            );
                                            event.stopPropagation();
                                        }}
                                    >
                                        <Box
                                            direction="column"
                                            justify="between"
                                        >
                                            <FormField
                                                name="spot"
                                                label="Shell-ID / OBN"
                                            >
                                                <TextInput
                                                    value={shellId}
                                                    onChange={(event) => {
                                                        const string =
                                                            event.target.value;
                                                        setShellId(string);
                                                    }}
                                                />
                                            </FormField>
                                            <Box
                                                direction="row"
                                                align="center"
                                                justify="between"
                                            >
                                                <Button
                                                    label={
                                                        <Text
                                                            weight={800}
                                                            color="white"
                                                        >
                                                            Suchen
                                                        </Text>
                                                    }
                                                    primary
                                                    type="submit"
                                                    // fill="horizontal"
                                                    margin={{
                                                        vertical: "small",
                                                    }}
                                                    // pad='small'
                                                />
                                                <Text textAlign="end">
                                                    {props.stations.length}{" "}
                                                    {props.stations.length === 1
                                                        ? " Station "
                                                        : " Stationen "}{" "}
                                                    gefunden
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Form>
                                </Tab>
                            )}
                        {props.user &&
                            props.user.authentication.role !== "WUERTH" && (
                                <Tab title="Vertrieb">
                                    <Form
                                        onSubmit={(event) => {
                                            props.findStationsAndBusinessesByRange(
                                                spot,
                                                range,
                                                businessSearch
                                            );
                                            event.stopPropagation();
                                        }}
                                    >
                                        <FormField name="spot" label="Ort">
                                            <TextInput
                                                value={spot}
                                                onChange={(event) => {
                                                    const string =
                                                        event.target.value;
                                                    setSpot(string);
                                                    // let timer
                                                }}
                                            />
                                        </FormField>
                                        <FormField
                                            name="range"
                                            label="Umkreis in km"
                                        >
                                            <TextInput
                                                value={range}
                                                onChange={(event) =>
                                                    setRange(event.target.value)
                                                }
                                                type="number"
                                                min="1"
                                            ></TextInput>
                                        </FormField>
                                        <FormField
                                            name="businessType"
                                            label="Firmensuche"
                                        >
                                            <TextInput
                                                value={businessSearch}
                                                onChange={(event) =>
                                                    setBusinessSearch(
                                                        event.target.value
                                                    )
                                                }
                                                type="text"
                                                min="1"
                                            ></TextInput>
                                        </FormField>
                                        <Box
                                            direction="row"
                                            align="center"
                                            justify="between"
                                        >
                                            <Button
                                                label={
                                                    <Text
                                                        weight={800}
                                                        color="white"
                                                    >
                                                        Suchen
                                                    </Text>
                                                }
                                                primary
                                                type="submit"
                                                // fill="horizontal"
                                                margin={{ vertical: "small" }}
                                                // pad='small'
                                            />
                                        </Box>
                                    </Form>
                                    {/* </Box> */}
                                </Tab>
                            )}
                        {props.user &&
                            props.user.authentication.role !== "WUERTH" && (
                                <Tab title="Location-Matcher">
                                    <Anchor
                                        onClick={() =>
                                            props.findStationsByRange(
                                                "",
                                                "20000"
                                            )
                                        }
                                    >
                                        Alle Stationen
                                    </Anchor>
                                    <br />
                                    <hr />
                                    Locations mit GeoDaten
                                    <span style={{ marginLeft: "5px" }} />
                                    <input
                                        type="file"
                                        onChange={(event) =>
                                            handleFile(event, props.stations)
                                        }
                                    />
                                    <br />
                                    <hr />
                                    Locations ohne GeoDaten
                                    <span style={{ marginLeft: "5px" }} />
                                    <input
                                        type="file"
                                        onChange={(event) =>
                                            handleFileWithoutLatLng(
                                                event,
                                                props.stations
                                            )
                                        }
                                    />
                                    <br />
                                    <hr />
                                    Telefonnummernsuche
                                    <span style={{ marginLeft: "5px" }} />
                                    <input
                                        type="file"
                                        onChange={(event) =>
                                            getPhoneForPlaces(
                                                event,
                                                props.stations
                                            )
                                        }
                                    />
                                    <hr />
                                </Tab>
                            )}
                        <Tab title="Neue Station">
                            <NewStationForm />
                        </Tab>
                    </Tabs>

                    {props.stationsForNewCampaign.length > 0 &&
                        props.user &&
                        (props.user.authentication.role === UserRole.ADMIN ||
                            props.user.authentication.role ===
                                UserRole.VIEW_ONLY) &&
                        props.handleNewCampaignSelect && (
                            <Box
                                direction="row"
                                justify="end"
                                background="white"
                                border="bottom"
                                flex={false}
                            >
                                <Box direction="row" pad="small">
                                    <Text margin="small" weight="bold">
                                        {props.stationsForNewCampaign.length}
                                        {props.stationsForNewCampaign.length ===
                                        1
                                            ? " Station "
                                            : " Stationen "}
                                        ausgewählt
                                    </Text>
                                    <Button
                                        label="Auftrag anlegen"
                                        color="success"
                                        primary
                                        disabled={
                                            props.stationsForNewCampaign
                                                .length === 0
                                        }
                                        onClick={() => {
                                            props.handleNewCampaignSelect &&
                                                props.handleNewCampaignSelect(
                                                    true
                                                );
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const MapList: React.FC<MapListProps> = (props) => {
    useEffect(() => {
        // Update the document title using the browser API
        props.clearState();
    }, []);

    const sortedList = props.stationsForNewCampaign
        .concat(props.stations)
        .filter(
            (station, index, array) =>
                array.findIndex(
                    (stationIndex) => stationIndex.id === station.id
                ) === index
        )
        .sort((stationA, stationB) => {
            const Aselected = props.stationsForNewCampaign.find(
                (station) => stationA.id === station.id
            );
            const Bselected = props.stationsForNewCampaign.find(
                (station) => stationB.id === station.id
            );

            if (Aselected && Bselected) {
                if (stationA.shellId > stationB.shellId) return 1;
                return -1;
            }

            if (Aselected) return -1;
            if (Bselected) return 1;

            if (stationA.shellId > stationB.shellId) return 1;
            return -1;
        });
    console.log(props, sortedList);
    const mapListRef: any = React.createRef();

    const findStationsByRange = (spot: string, range: string) => {
        props.findStationsByRange(spot, range);
        props.setShowStationDetails && props.setShowStationDetails(null);
    };

    const findStationById = (shellId?: number) => {
        if (shellId) {
            props.findStationByShellId(shellId);
        } else {
            props.findStationByShellId();
        }
        props.setShowStationDetails && props.setShowStationDetails(null);
    };

    const [electricityBillStartDate, setElectricityBillStartDate] =
        React.useState<dayjs.Dayjs>(
            dayjs()
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(1, "month")
        );

    const [electricityBillEndDate, setElectricityBillEndDate] =
        React.useState<dayjs.Dayjs>(
            dayjs()
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(1, "month")
        );

    const [carWashBillStartDate, setCarWashBillStartDate] =
        React.useState<dayjs.Dayjs>(
            dayjs()
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(1, "month")
        );

    const [carWashBillEndDate, setCarWashBillEndDate] =
        React.useState<dayjs.Dayjs>(
            dayjs()
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(1, "month")
        );

    const [closedElectricityStartWindow, setClosedElectricityStartWindow] =
        useState<boolean>(true);
    const [closedElectricityEndWindow, setClosedElectricityEndWindow] =
        useState<boolean>(true);

    const [closedCarWashBillStartWindow, setClosedCarWashBillStartWindow] =
        useState<boolean>(true);
    const [closedCarWashBillEndWindow, setClosedCarWashBillEndWindow] =
        useState<boolean>(true);

    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box width={size === "small" ? "100vw" : ""}>
                    {
                        <SearchArea
                            clearState={props.clearState}
                            centerAddress={
                                props.centerAddress ? props.centerAddress : ""
                            }
                            user={props.user}
                            stations={props.stations}
                            sortedList={sortedList}
                            findStationsByRange={findStationsByRange}
                            findStationByShellId={findStationById}
                            getAllStations={props.getAllStations}
                            stationsForNewCampaign={
                                props.stationsForNewCampaign
                            }
                            handleNewCampaignSelect={
                                props.handleNewCampaignSelect
                            }
                            initialFilterObject={props.initialFilterObject}
                            handleFilterChange={props.handleFilterChange}
                            addStationsForNewCampaign={
                                props.addStationsForNewCampaign
                            }
                            setStationOfNewCampaign={
                                props.setStationOfNewCampaign
                            }
                            findStationsAndBusinessesByRange={
                                props.findStationsAndBusinessesByRange
                            }
                        />
                    }
                    {/* {props.isFetching && <img src={Spinner2} alt="test" />} */}
                    {(props.isFetching || props.isFetchingBusinesses) && (
                        <Box
                            align="center"
                            justify="center"
                            margin={{ top: "4rem" }}
                        >
                            <div className="loadingio-spinner-spinner-g73zrpl2wr5">
                                <div className="ldio-xie7sipo009">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </Box>
                    )}
                    {!props.isFetching &&
                        !props.isFetchingBusinesses &&
                        props.stations && (
                            <Box height="100%" overflow="auto" width="100%">
                                <Box overflow="scroll" ref={mapListRef}>
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Anchor
                                                onClick={() =>
                                                    props.setStationOfNewCampaign(
                                                        []
                                                    )
                                                }
                                                margin={{ left: "small" }}
                                            >
                                                Alle zurücksetzen
                                            </Anchor>
                                        )}
                                    <InfiniteScroll
                                        items={
                                            props.businesses !== null
                                                ? props.businesses
                                                : sortedList
                                        }
                                    >
                                        {(item, index) => {
                                            return (
                                                <Box
                                                    flex={false}
                                                    key={index}
                                                    margin={
                                                        sortedList.length ===
                                                        index + 1
                                                            ? {
                                                                  bottom: "2rem",
                                                                  horizontal:
                                                                      "small",
                                                                  top: "small",
                                                              }
                                                            : "small"
                                                    }
                                                >
                                                    {props.businesses ===
                                                        null && (
                                                        <Box
                                                            pad={"large"}
                                                            flex={false}
                                                            background="white"
                                                            onClick={() =>
                                                                props.stationsForNewCampaign.find(
                                                                    (station) =>
                                                                        station.shellId ===
                                                                        item.shellId
                                                                )
                                                                    ? props.removeStationForNewCampaign(
                                                                          item
                                                                      )
                                                                    : props.addStationForNewCampaign(
                                                                          item
                                                                      )
                                                            }
                                                            style={
                                                                props.stationsForNewCampaign.findIndex(
                                                                    (station) =>
                                                                        station.shellId ===
                                                                        item.shellId
                                                                ) !== -1
                                                                    ? {
                                                                          boxShadow:
                                                                              "0 0 5px #09b0ac",
                                                                          padding:
                                                                              "small",
                                                                          margin: "1px 1px 1px ",
                                                                          border: "3px solid #09b0ac",
                                                                          cursor: "url(minus_icon_mouse_small.png) 12 12, auto",
                                                                      }
                                                                    : {
                                                                          boxShadow:
                                                                              "unset",
                                                                          border: "grey solid 1px",
                                                                          cursor: "url(plus_icon_mouse_small.png) 12 12, auto",
                                                                          padding:
                                                                              size ===
                                                                              "large"
                                                                                  ? ""
                                                                                  : "15px",
                                                                      }
                                                            }
                                                        >
                                                            <ListItem
                                                                lastSelectedItem={
                                                                    props
                                                                        .stationsForNewCampaign
                                                                        .length ===
                                                                    index + 1
                                                                }
                                                                key={
                                                                    item.shellId
                                                                }
                                                                station={item}
                                                                onClick={(
                                                                    event: MouseEvent<
                                                                        | HTMLAnchorElement
                                                                        | MouseEvent
                                                                    >
                                                                ) => {
                                                                    event.stopPropagation();
                                                                    props.handleSelection(
                                                                        item
                                                                    );
                                                                }}
                                                            ></ListItem>
                                                        </Box>
                                                    )}
                                                    {props.businesses !==
                                                        null &&
                                                        !props.isFetchingBusinesses && (
                                                            <Box
                                                                pad={"large"}
                                                                flex={false}
                                                                background="white"
                                                                style={{
                                                                    border: "grey solid 1px",
                                                                    padding:
                                                                        size ===
                                                                        "large"
                                                                            ? ""
                                                                            : "15px",
                                                                }}
                                                            >
                                                                <BusinessItem
                                                                    key={
                                                                        item.shellId
                                                                    }
                                                                    business={
                                                                        item
                                                                    }
                                                                ></BusinessItem>
                                                            </Box>
                                                        )}
                                                    {props
                                                        .stationsForNewCampaign
                                                        .length ===
                                                        index + 1 && (
                                                        <Box
                                                            border="top"
                                                            margin="medium"
                                                            style={{
                                                                minHeight:
                                                                    "2px",
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            );
                                        }}
                                    </InfiniteScroll>
                                </Box>
                            </Box>
                        )}
                    {size === "small" && (
                        <Button
                            style={{
                                position: "fixed",
                                bottom: 10,
                                right: "25%",
                                width: "50%",
                                height: "40px",
                                backgroundColor: "white",
                            }}
                            label="Zur Karte"
                            color="accent-1"
                            icon={<MapLocation />}
                            onClick={() =>
                                props.handleShowMap && props.handleShowMap(true)
                            }
                        />
                    )}
                    <Button
                        style={{
                            position: "absolute",
                            bottom: 10,
                            left: 10,
                            backgroundColor: "white",
                            height: "40px",
                        }}
                        color="accent-1"
                        label={<Ascend color="accent-1" />}
                        onClick={() => (mapListRef.current.scrollTop = 0)}
                    />

                    {props.user && props.user.authentication.role !== "WUERTH" && (
                        <DropButton
                            style={{
                                position: "absolute",
                                bottom: 10,
                                left: 100,
                                backgroundColor: "white",
                                height: "40px",
                            }}
                            dropAlign={{ top: "top", right: "right" }}
                            dropContent={
                                <Box pad="large">
                                    <Anchor
                                        onClick={() =>
                                            CsvExporter.parseStationArrayToCsv(
                                                (props.stationsForNewCampaign
                                                    .length > 0
                                                    ? props.stationsForNewCampaign
                                                    : props.stations
                                                ).sort((station1, station2) =>
                                                    station1.shellId <
                                                    station2.shellId
                                                        ? -1
                                                        : 1
                                                )
                                            )
                                        }
                                        style={{ maxWidth: "50%" }}
                                    >
                                        <Download color="accent-1" /> Stationen
                                    </Anchor>
                                    <br />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Anchor
                                                onClick={() =>
                                                    CsvExporter.parseStationArrayToCsv(
                                                        (props
                                                            .stationsForNewCampaign
                                                            .length > 0
                                                            ? props.stationsForNewCampaign
                                                            : props.stations
                                                        ).sort(
                                                            (
                                                                station1,
                                                                station2
                                                            ) =>
                                                                station1.shellId <
                                                                station2.shellId
                                                                    ? -1
                                                                    : 1
                                                        ),
                                                        true,
                                                        props.user
                                                    )
                                                }
                                                style={{ maxWidth: "50%" }}
                                            >
                                                <Download color="accent-1" />{" "}
                                                Stationen mit Kunden
                                            </Anchor>
                                        )}
                                    <br />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Anchor
                                                onClick={() =>
                                                    CsvExporter.parseStationArrayCostsToCsv(
                                                        (props
                                                            .stationsForNewCampaign
                                                            .length > 0
                                                            ? props.stationsForNewCampaign
                                                            : props.stations
                                                        ).sort(
                                                            (
                                                                station1,
                                                                station2
                                                            ) =>
                                                                station1.shellId <
                                                                station2.shellId
                                                                    ? -1
                                                                    : 1
                                                        )
                                                    )
                                                }
                                                style={{ maxWidth: "50%" }}
                                            >
                                                <Download color="accent-1" />{" "}
                                                Kosten
                                            </Anchor>
                                        )}
                                    <br />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Box direction="row">
                                                <DropButton
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }}
                                                    label={
                                                        "von: " +
                                                        electricityBillStartDate.format(
                                                            "DD.MM.YYYY"
                                                        )
                                                    }
                                                    dropAlign={{
                                                        top: "bottom",
                                                        right: "right",
                                                    }}
                                                    open={
                                                        !closedElectricityStartWindow
                                                    }
                                                    onOpen={() => {
                                                        setClosedElectricityStartWindow(
                                                            false
                                                        );
                                                        setClosedElectricityEndWindow(
                                                            true
                                                        );
                                                    }}
                                                    onClose={() =>
                                                        setClosedElectricityStartWindow(
                                                            true
                                                        )
                                                    }
                                                    dropContent={
                                                        <Box
                                                            pad="large"
                                                            background="light-2"
                                                        >
                                                            von:
                                                            <Calendar
                                                                size="medium"
                                                                date={dayjs(
                                                                    electricityBillStartDate
                                                                ).toISOString()}
                                                                onSelect={(
                                                                    date
                                                                ) => {
                                                                    setElectricityBillStartDate(
                                                                        dayjs(
                                                                            date
                                                                        )
                                                                            .hour(
                                                                                0
                                                                            )
                                                                            .minute(
                                                                                0
                                                                            )
                                                                            .second(
                                                                                0
                                                                            )
                                                                    );
                                                                    setClosedElectricityStartWindow(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <DropButton
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }}
                                                    label={
                                                        "bis: " +
                                                        electricityBillEndDate.format(
                                                            "DD.MM.YYYY"
                                                        )
                                                    }
                                                    dropAlign={{
                                                        top: "bottom",
                                                        right: "right",
                                                    }}
                                                    open={
                                                        !closedElectricityEndWindow
                                                    }
                                                    onOpen={() => {
                                                        setClosedElectricityEndWindow(
                                                            false
                                                        );
                                                        setClosedElectricityStartWindow(
                                                            true
                                                        );
                                                    }}
                                                    onClose={() =>
                                                        setClosedElectricityEndWindow(
                                                            true
                                                        )
                                                    }
                                                    dropContent={
                                                        <Box
                                                            pad="large"
                                                            background="light-2"
                                                        >
                                                            bis:
                                                            <Calendar
                                                                size="medium"
                                                                date={dayjs(
                                                                    electricityBillEndDate
                                                                ).toISOString()}
                                                                onSelect={(
                                                                    date
                                                                ) => {
                                                                    setElectricityBillEndDate(
                                                                        dayjs(
                                                                            date
                                                                        )
                                                                            .hour(
                                                                                0
                                                                            )
                                                                            .minute(
                                                                                0
                                                                            )
                                                                            .second(
                                                                                0
                                                                            )
                                                                    );
                                                                    setClosedElectricityEndWindow(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                />

                                                <Anchor
                                                    onClick={() =>
                                                        CsvExporter.calculateElectricityForStationArrayAndPeriodToCsv(
                                                            props.stations
                                                                .filter(
                                                                    (
                                                                        station: Station
                                                                    ) =>
                                                                        station.plannedConstructionDate &&
                                                                        station.plannedConstructionDate <
                                                                            electricityBillEndDate.valueOf()
                                                                )
                                                                .sort(
                                                                    (
                                                                        station1,
                                                                        station2
                                                                    ) =>
                                                                        station1.shellId <
                                                                        station2.shellId
                                                                            ? -1
                                                                            : 1
                                                                ),
                                                            electricityBillEndDate,
                                                            electricityBillStartDate
                                                        )
                                                    }
                                                >
                                                    <Download color="accent-1" />{" "}
                                                    Stromkosten
                                                </Anchor>
                                            </Box>
                                        )}
                                    <br />
                                    {props.user &&
                                        (props.user.authentication.role ===
                                            UserRole.ADMIN ||
                                            props.user.authentication.role ===
                                                UserRole.VIEW_ONLY) && (
                                            <Box direction="row">
                                                <DropButton
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }}
                                                    label={
                                                        "von: " +
                                                        carWashBillStartDate.format(
                                                            "DD.MM.YYYY"
                                                        )
                                                    }
                                                    dropAlign={{
                                                        top: "bottom",
                                                        right: "right",
                                                    }}
                                                    open={
                                                        !closedCarWashBillStartWindow
                                                    }
                                                    onOpen={() => {
                                                        setClosedCarWashBillStartWindow(
                                                            false
                                                        );
                                                        setClosedCarWashBillEndWindow(
                                                            true
                                                        );
                                                    }}
                                                    onClose={() =>
                                                        setClosedCarWashBillStartWindow(
                                                            true
                                                        )
                                                    }
                                                    dropContent={
                                                        <Box
                                                            pad="large"
                                                            background="light-2"
                                                        >
                                                            von:
                                                            <Calendar
                                                                size="medium"
                                                                date={dayjs(
                                                                    carWashBillStartDate
                                                                ).toISOString()}
                                                                onSelect={(
                                                                    date
                                                                ) => {
                                                                    setCarWashBillStartDate(
                                                                        dayjs(
                                                                            date
                                                                        )
                                                                            .hour(
                                                                                0
                                                                            )
                                                                            .minute(
                                                                                0
                                                                            )
                                                                            .second(
                                                                                0
                                                                            )
                                                                    );
                                                                    setClosedCarWashBillStartWindow(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <DropButton
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                    }}
                                                    label={
                                                        "bis: " +
                                                        carWashBillEndDate.format(
                                                            "DD.MM.YYYY"
                                                        )
                                                    }
                                                    dropAlign={{
                                                        top: "bottom",
                                                        right: "right",
                                                    }}
                                                    open={
                                                        !closedCarWashBillEndWindow
                                                    }
                                                    onOpen={() => {
                                                        setClosedCarWashBillEndWindow(
                                                            false
                                                        );
                                                        setClosedCarWashBillStartWindow(
                                                            true
                                                        );
                                                    }}
                                                    onClose={() =>
                                                        setClosedCarWashBillEndWindow(
                                                            true
                                                        )
                                                    }
                                                    dropContent={
                                                        <Box
                                                            pad="large"
                                                            background="light-2"
                                                        >
                                                            bis:
                                                            <Calendar
                                                                size="medium"
                                                                date={dayjs(
                                                                    carWashBillEndDate
                                                                ).toISOString()}
                                                                onSelect={(
                                                                    date
                                                                ) => {
                                                                    setCarWashBillEndDate(
                                                                        dayjs(
                                                                            date
                                                                        )
                                                                            .hour(
                                                                                0
                                                                            )
                                                                            .minute(
                                                                                0
                                                                            )
                                                                            .second(
                                                                                0
                                                                            )
                                                                    );
                                                                    setClosedCarWashBillEndWindow(
                                                                        true
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                {/* <Select
                                                options={monthNames}
                                                value={
                                                    monthNames[
                                                        carWashBillDate.get(
                                                            "month"
                                                        ) - 1
                                                    ]
                                                }
                                                onChange={({ option }) => {
                                                    setCarWashBillDate(
                                                        carWashBillDate.set(
                                                            "month",
                                                            monthNames.indexOf(
                                                                option
                                                            ) + 1
                                                        )
                                                    );
                                                }}
                                            /> */}
                                                {/* <Select
                                                options={[
                                                    "2020",
                                                    "2021",
                                                    "2022",
                                                ]}
                                                value={carWashBillDate
                                                    .get("year")
                                                    .toString()}
                                                onChange={({ option }) =>
                                                    setCarWashBillDate(
                                                        carWashBillDate.set(
                                                            "year",
                                                            option
                                                        )
                                                    )
                                                }
                                            /> */}
                                                <Anchor
                                                    onClick={() =>
                                                        CsvExporter.parseStationArrayToShellCostCsv(
                                                            (props
                                                                .stationsForNewCampaign
                                                                .length > 0
                                                                ? props.stationsForNewCampaign
                                                                : props.stations
                                                            ).sort(
                                                                (
                                                                    station1,
                                                                    station2
                                                                ) =>
                                                                    station1.shellId <
                                                                    station2.shellId
                                                                        ? -1
                                                                        : 1
                                                            ),
                                                            carWashBillEndDate,
                                                            carWashBillStartDate
                                                        )
                                                    }
                                                >
                                                    <Download color="accent-1" />{" "}
                                                    Shell Kosten Shop
                                                </Anchor>
                                            </Box>
                                        )}
                                </Box>
                            }
                            color="accent-1"
                            label={<Download color="accent-1" />}
                        />
                    )}
                    <a id="download_hidden" />
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        // stations: state.stationsReducer.stations,
        isFetching: state.stationsReducer.isFetching,
        user: state.usersReducer.user,
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        centerAddress: state.stationsReducer.centerAddress,
        isFetchingBusinesses: state.businessReducer.isFetching,
        businesses: state.businessReducer.buisnesses,
        businessSearch: state.businessReducer.searchString,
    };
};

const mapDispatchToProps = {
    findStationsByRange,
    findStationByShellId,
    getAllStations,
    addStationForNewCampaign,
    addStationsForNewCampaign,
    removeStationForNewCampaign,
    setStationOfNewCampaign,
    findStationsAndBusinessesByRange,
    clearState,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapList);
