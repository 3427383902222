import dayjs from "dayjs";
import {
    Box,
    Calendar,
    CheckBox,
    DropButton,
    Heading,
    RadioButtonGroup,
    ResponsiveContext,
} from "grommet";
import { Filter, Group, History, Schedule, Split } from "grommet-icons";
import React, { Dispatch, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    setFilterContractDuration,
    setFilterDateFrom,
    setFilterSlotWeight,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import {
    findStationsByRange,
    getAllStations,
} from "../../store/stations/actions";
import { groupToAssignedMap, UserRole } from "../../types/Authentication";
import { CampaignDuration, slotWeightToPackage } from "../../types/Campaign";
import Station, {
    Location,
    OutdoorScreenVersion,
    StationStatus,
    StationStatusArray,
    StationStatusTextToStatus,
} from "../../types/Station";
import CheckBoxWithOwnState from "./CheckBoxWithOwnState";

const occupancies: {
    [key: string]: {
        label: string;
        filterFunction: (station: Station) => boolean;
    };
} = {
    both: {
        label: "belegt u. leer",
        filterFunction: (station: Station) => true,
    },
    withCustomers: {
        label: "belegt",
        filterFunction: (station: Station) =>
            station.freeSlotsInPeriodCount < 5,
    },
    withoutCustomers: {
        label: "leer",
        filterFunction: (station: Station) =>
            station.freeSlotsInPeriodCount === 5,
    },
};

const MapSubHeadline: React.FC<any> = (props: any) => {
    const [closedCalendarWindow, setClosedCalendarWindow] = useState(true);
    const [closedDurationWindow, setClosedDurationWindow] = useState(true);
    const [closedStationFilterWindow, setClosedStationFilterWindow] =
        useState(true);
    const [closedSlotWeightWindow, setClosedSlotWeightWindow] = useState(true);
    const [closedSalesWindow, setClosedSalesWindow] = useState(true);
    const [filterNoAllocation, setFilterNoAllocation] = useState(false);
    const [filterSomeAllocation, setFilterSomeAllocation] = useState(false);
    const [filterOwnSales, setFilterOwnSales] = useState(false);
    const [filterSMM, setFilterSMM] = useState(false);
    const [filterSalesStringArray, setFilterSalesStringArray]: [
        any,
        Dispatch<any>
    ] = useState();
    const [filterFunctionObject, setFilterFunctionObject] = useState<any>({
        stationStatus: (station: Station) =>
            [
                StationStatus.CONSTRUCTED,
                StationStatus.PLANNING_PHASE,
                StationStatus.CONSTRUCTION_SCHEDULED,
                StationStatus.APPROVAL_PROCESS,
            ].includes(station.status) ||
            [StationStatus.CONSTRUCTED].length === 0,
    });

    const [statusToFilter, setStatusToFilter] = useState<Array<StationStatus>>(
        []
    );

    const [occupancy, setOccupancy] = useState<{
        label: string;
        filterFunction: (station: Station) => boolean;
    }>(occupancies.both);

    const [filterEightyFiveInch, setFilterEightyFiveInch] =
        useState<boolean>(false);
    const [filterSeventyFiveInch, setFilterSeventyFiveInch] =
        useState<boolean>(false);

    useEffect(() => {
        if (props.handleFilterChange)
            props.handleFilterChange(filterFunctionObject);
    }, [filterFunctionObject]);

    useEffect(() => {
        if (!filterSalesStringArray) setFilterSalesStringArray([]);
        if (filterSalesStringArray && filterSalesStringArray.length > 0) {
            setFilterFunctionObject({
                ...filterFunctionObject,
                salesPartner: (station: Station) =>
                    filterSalesStringArray.findIndex(
                        (partner) => station.assignedTo === partner
                    ) !== -1,
            });
        }
        if (filterSalesStringArray && filterSalesStringArray.length === 0) {
            setFilterFunctionObject({
                ...filterFunctionObject,
                salesPartner: (station: Station) => true,
            });
        }
    }, [filterSalesStringArray]);

    const durationValues = [
        "2 Monate",
        "6 Monate",
        "12 Monate",
        "24 Monate",
        "36 Monate",
    ];
    const durationValueObject = {
        [CampaignDuration.TWO_MONTHS]: durationValues[0],
        [CampaignDuration.SIX_MONTHS]: durationValues[1],
        [CampaignDuration.TWELVE_MONTHS]: durationValues[2],
        [CampaignDuration.TWENTY_FOUR_MONTHS]: durationValues[3],
        [CampaignDuration.THIRTY_SIX_MONTHS]: durationValues[4],
    };

    const stationLookup = (
        address: string = "Berlin",
        range: string,
        skipGeoSearch?: boolean,
        center?: Location
    ) => {
        setClosedCalendarWindow(true);
        setClosedDurationWindow(true);
        setClosedSlotWeightWindow(true);
        // if (props.user.authentication.role === UserRole.EXTERNAL_SALES) {
        //     props.getAllStations();
        //     return;
        // }
        props.findStationsByRange(address, range, skipGeoSearch, center);
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    tag="header"
                    direction={size === "small" ? "column" : "row"}
                    align="center"
                    justify="between"
                    background="rgba(20, 148, 145, 0.4536)"
                    pad={{
                        vertical: size === "small" ? "medium" : "small",
                        horizontal: "medium",
                    }}
                    elevation="medium"
                    gridArea="header"
                    flex={false}
                    width={size === "small" ? "100vw" : ""}
                >
                    {props.user && props.user.authentication.role !== "WUERTH" && (
                        <Box direction="row" align="center">
                            {props.fromDate && (
                                <DropButton
                                    style={{
                                        color: "black",
                                        fontWeight: "bold",
                                    }}
                                    label={
                                        "Ab: " +
                                        dayjs(props.fromDate).format(
                                            "DD.MM.YYYY"
                                        )
                                    }
                                    icon={<Schedule color="black" />}
                                    dropAlign={{
                                        top: "bottom",
                                        right: "right",
                                    }}
                                    open={!closedCalendarWindow}
                                    onOpen={() =>
                                        setClosedCalendarWindow(false)
                                    }
                                    onClose={() =>
                                        setClosedCalendarWindow(true)
                                    }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    props.fromDate
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    props.setFilterDateFrom(
                                                        dayjs(date)
                                                            .hour(0)
                                                            .minute(0)
                                                            .second(0)
                                                    );
                                                    stationLookup(
                                                        "",
                                                        props.range,
                                                        true,
                                                        props.center
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                    // onCli
                                />
                            )}
                            <DropButton
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                label={
                                    "Laufzeit: " +
                                    durationValueObject[props.contractDuration]
                                }
                                dropAlign={{ top: "bottom", right: "right" }}
                                icon={<History color="black" />}
                                margin={{ left: "1rem" }}
                                open={!closedDurationWindow}
                                onOpen={() => setClosedDurationWindow(false)}
                                onClose={() => setClosedDurationWindow(true)}
                                dropContent={
                                    <Box pad="large" background="light-2">
                                        <RadioButtonGroup
                                            name="doc"
                                            options={durationValues}
                                            value={
                                                durationValueObject[
                                                    props.contractDuration
                                                ]
                                            }
                                            onChange={(event) => {
                                                props.setFilterContractDuration(
                                                    Object.keys(
                                                        durationValueObject
                                                    ).filter((key) => {
                                                        return (
                                                            durationValueObject[
                                                                key
                                                            ] ===
                                                            event.target.value
                                                        );
                                                    })[0]
                                                );
                                                stationLookup(
                                                    "",
                                                    props.range,
                                                    true,
                                                    props.center
                                                );
                                            }}
                                        />
                                    </Box>
                                }
                            />
                            <DropButton
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                label={
                                    "Slotbelegung: " +
                                    slotWeightToPackage(props.slotWeight)
                                }
                                dropAlign={{ top: "bottom", right: "right" }}
                                icon={<Split color="black" />}
                                margin={{ left: "1rem" }}
                                open={!closedSlotWeightWindow}
                                onOpen={() => setClosedSlotWeightWindow(false)}
                                onClose={() => setClosedSlotWeightWindow(true)}
                                dropContent={
                                    <Box pad="large" background="light-2">
                                        <RadioButtonGroup
                                            name="doc"
                                            options={[
                                                {
                                                    disabled: false,
                                                    id: slotWeightToPackage(1),
                                                    // name: slotWeightToPackage(props.slotWeight),
                                                    value: "1",
                                                    label: slotWeightToPackage(
                                                        1
                                                    ),
                                                },
                                                {
                                                    disabled: false,
                                                    id: slotWeightToPackage(2),
                                                    // name: slotWeightToPackage(props.slotWeight),
                                                    value: "2",
                                                    label: slotWeightToPackage(
                                                        2
                                                    ),
                                                },
                                            ]}
                                            value={props.slotWeight.toString()}
                                            onChange={(event) => {
                                                props.setFilterSlotWeight(
                                                    parseFloat(
                                                        event.target.value
                                                    )
                                                );
                                                stationLookup(
                                                    "",
                                                    props.range,
                                                    true,
                                                    props.center
                                                );
                                            }}
                                        />
                                    </Box>
                                }
                            />
                            {props.user &&
                                (props.user.authentication.role ===
                                    UserRole.ADMIN ||
                                    props.user.authentication.role ===
                                        UserRole.VIEW_ONLY) && (
                                    <DropButton
                                        style={{
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                        label={"Vetrieb"}
                                        dropAlign={{
                                            top: "bottom",
                                            right: "right",
                                        }}
                                        icon={<Group color="black" />}
                                        margin={{ left: "1rem" }}
                                        open={!closedSalesWindow}
                                        onOpen={() =>
                                            setClosedSalesWindow(false)
                                        }
                                        onClose={() =>
                                            setClosedSalesWindow(true)
                                        }
                                        dropContent={
                                            <Box
                                                pad="large"
                                                background="light-2"
                                            >
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                    "ISM Deniz" ||
                                                                string ===
                                                                    "ISM Deniz / Serdar" ||
                                                                string ===
                                                                    "ISM" ||
                                                                string ===
                                                                    "Hubert"
                                                        ) !== -1
                                                    }
                                                    label="Eigenvertrieb"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "ISM Deniz",
                                                                    "ISM Deniz / Serdar",
                                                                    "ISM",
                                                                    "Hubert",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                            "ISM Deniz" &&
                                                                        string !==
                                                                            "ISM Deniz / Serdar" &&
                                                                        string !==
                                                                            "ISM" &&
                                                                        string !==
                                                                            "Hubert"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                "SalesElevator"
                                                        ) !== -1
                                                    }
                                                    label="SalesElevator"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "SalesElevator",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                        "SalesElevator"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                "CompriMedia"
                                                        ) !== -1
                                                    }
                                                    label="CompriMedia"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "CompriMedia",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                        "CompriMedia"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                    "André" ||
                                                                string ===
                                                                    "Harry Lötterle" ||
                                                                string ===
                                                                    "Cristian W. Köhler" ||
                                                                string ===
                                                                    "Marco Lattuca" ||
                                                                string ===
                                                                    "Christian Mehltretter"
                                                        ) !== -1
                                                    }
                                                    label="André"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "André",
                                                                    "Harry Lötterle",
                                                                    "Cristian W. Köhler",
                                                                    "Marco Lattuca",
                                                                    "Christian Mehltretter",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                            "André" &&
                                                                        string !==
                                                                            "Harry Lötterle" &&
                                                                        string !==
                                                                            "Cristian W. Köhler" &&
                                                                        string !==
                                                                            "Marco Lattuca" &&
                                                                        string !==
                                                                            "Christian Mehltretter"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                "AmbientMedia-TV"
                                                        ) !== -1
                                                    }
                                                    label="AmbientMedia-TV"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "AmbientMedia-TV",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                        "AmbientMedia-TV"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                "uze! Mobility GmbH"
                                                        ) !== -1
                                                    }
                                                    label="uze! Mobility GmbH"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "uze! Mobility GmbH",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                        "uze! Mobility GmbH"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckBox
                                                    checked={
                                                        filterSalesStringArray &&
                                                        [
                                                            ...filterSalesStringArray,
                                                        ].findIndex(
                                                            (string) =>
                                                                string ===
                                                                "Nicht zugewiesen"
                                                        ) !== -1
                                                    }
                                                    label="Nicht zugewiesen"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.checked
                                                        ) {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                    "Nicht zugewiesen",
                                                                ]
                                                            );
                                                        } else {
                                                            setFilterSalesStringArray(
                                                                [
                                                                    ...filterSalesStringArray,
                                                                ].filter(
                                                                    (string) =>
                                                                        string !==
                                                                        "Nicht zugewiesen"
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        }
                                    />
                                )}
                            <DropButton
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                                label={
                                    "Stationsfilter: " +
                                    [
                                        statusToFilter.length < 4
                                            ? "Status"
                                            : null,
                                        Object.keys(
                                            filterFunctionObject
                                        ).findIndex(
                                            (key) =>
                                                key === "emptyStations" ||
                                                key === "notEmptyStations"
                                        ) > -1
                                            ? "Vermarktung"
                                            : null,
                                    ]
                                        .filter((item) => item !== null)
                                        .join(", ")
                                }
                                dropAlign={{ top: "bottom", right: "right" }}
                                icon={<Filter color="black" />}
                                margin={{ left: "1rem" }}
                                open={!closedStationFilterWindow}
                                onOpen={() =>
                                    setClosedStationFilterWindow(false)
                                }
                                onClose={() =>
                                    setClosedStationFilterWindow(true)
                                }
                                dropContent={
                                    <Box
                                        pad="large"
                                        background="light-2"
                                        direction="column"
                                    >
                                        <Box direction="row">
                                            <Box margin={{ right: "small" }}>
                                                <Heading level={5}>
                                                    Status:
                                                </Heading>
                                                {StationStatusArray.map(
                                                    (status) => (
                                                        <CheckBoxWithOwnState
                                                            label={status}
                                                            checked={
                                                                statusToFilter.indexOf(
                                                                    StationStatusTextToStatus(
                                                                        status
                                                                    )
                                                                ) !== -1
                                                            }
                                                            onChangeFunction={(
                                                                checked: boolean
                                                            ) => {
                                                                let statusFilteredBy: Array<StationStatus>;
                                                                if (checked) {
                                                                    statusFilteredBy =
                                                                        statusToFilter.indexOf(
                                                                            StationStatusTextToStatus(
                                                                                status
                                                                            )
                                                                        ) === -1
                                                                            ? statusToFilter.concat(
                                                                                  StationStatusTextToStatus(
                                                                                      status
                                                                                  )
                                                                              )
                                                                            : statusToFilter;

                                                                    setStatusToFilter(
                                                                        statusFilteredBy
                                                                    );
                                                                } else {
                                                                    statusFilteredBy =
                                                                        statusToFilter.filter(
                                                                            (
                                                                                statusFiltered
                                                                            ) =>
                                                                                statusFiltered !==
                                                                                StationStatusTextToStatus(
                                                                                    status
                                                                                )
                                                                        );

                                                                    setStatusToFilter(
                                                                        statusFilteredBy
                                                                    );
                                                                }
                                                                setFilterFunctionObject(
                                                                    {
                                                                        ...filterFunctionObject,
                                                                        stationStatus:
                                                                            (
                                                                                station: Station
                                                                            ) =>
                                                                                statusFilteredBy.includes(
                                                                                    station.status
                                                                                ) ||
                                                                                statusFilteredBy.length ===
                                                                                    0,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Heading level={5}>
                                                Vermarktung:
                                            </Heading>
                                            <RadioButtonGroup
                                                name="occupancies"
                                                value={occupancy.label}
                                                onChange={(event) => {
                                                    const objectKey =
                                                        Object.keys(
                                                            occupancies
                                                        ).find(
                                                            (key) =>
                                                                occupancies[key]
                                                                    .label ===
                                                                event.target
                                                                    .value
                                                        );
                                                    if (objectKey === undefined)
                                                        return;
                                                    setOccupancy(
                                                        occupancies[objectKey]
                                                    );
                                                    setFilterFunctionObject({
                                                        ...filterFunctionObject,
                                                        occupancies:
                                                            occupancies[
                                                                objectKey
                                                            ].filterFunction,
                                                    });
                                                }}
                                                options={Object.keys(
                                                    occupancies
                                                ).map(
                                                    (key) =>
                                                        occupancies[key].label
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <Heading level={5}>
                                                Individual:
                                            </Heading>
                                            <CheckBox
                                                checked={filterOwnSales}
                                                label="Meine Stationen"
                                                onChange={(event) => {
                                                    setFilterOwnSales(
                                                        event.target.checked
                                                    );
                                                    if (event.target.checked) {
                                                        setFilterFunctionObject(
                                                            {
                                                                ...filterFunctionObject,
                                                                myStations: (
                                                                    station: Station
                                                                ) =>
                                                                    station.assignedTo ===
                                                                    groupToAssignedMap.get(
                                                                        props
                                                                            .user
                                                                            .authentication
                                                                            .group
                                                                    ),
                                                            }
                                                        );
                                                        return;
                                                    }
                                                    setFilterFunctionObject(
                                                        Object.keys(
                                                            filterFunctionObject
                                                        )
                                                            .filter(
                                                                (key) =>
                                                                    key !==
                                                                    "myStations"
                                                            )
                                                            .reduce(
                                                                (acc, key) => {
                                                                    return {
                                                                        ...acc,
                                                                        [key]: filterFunctionObject[
                                                                            key
                                                                        ],
                                                                    };
                                                                },
                                                                {}
                                                            )
                                                    );
                                                }}
                                            />
                                            <CheckBox
                                                checked={filterSeventyFiveInch}
                                                label='75"'
                                                onChange={(event) => {
                                                    setFilterSeventyFiveInch(
                                                        event.target.checked
                                                    );

                                                    setFilterFunctionObject({
                                                        ...filterFunctionObject,
                                                        screenSize: event.target
                                                            .checked
                                                            ? (
                                                                  station: Station
                                                              ) =>
                                                                  station.outdoorScreenVersion ===
                                                                      OutdoorScreenVersion.SEVENTY_FIVE_INCH ||
                                                                  (filterEightyFiveInch
                                                                      ? station.outdoorScreenVersion ===
                                                                        OutdoorScreenVersion.EIGHTY_FIVE_INCH
                                                                      : false)
                                                            : (
                                                                  station: Station
                                                              ) =>
                                                                  filterEightyFiveInch
                                                                      ? station.outdoorScreenVersion ===
                                                                        OutdoorScreenVersion.EIGHTY_FIVE_INCH
                                                                      : true,
                                                    });
                                                    return;

                                                    setFilterFunctionObject({
                                                        ...filterFunctionObject,
                                                        screenSize: (
                                                            station: Station
                                                        ) => {
                                                            if (
                                                                event.target
                                                                    .checked
                                                            ) {
                                                                return (
                                                                    station.outdoorScreenVersion ===
                                                                        OutdoorScreenVersion.SEVENTY_FIVE_INCH ||
                                                                    (filterEightyFiveInch
                                                                        ? station.outdoorScreenVersion ===
                                                                          OutdoorScreenVersion.EIGHTY_FIVE_INCH
                                                                        : false)
                                                                );
                                                            }
                                                            return filterEightyFiveInch
                                                                ? station.outdoorScreenVersion ===
                                                                      OutdoorScreenVersion.EIGHTY_FIVE_INCH
                                                                : false;
                                                        },
                                                    });
                                                    return;
                                                }}
                                            />
                                            <CheckBox
                                                checked={filterEightyFiveInch}
                                                label='85"'
                                                onChange={(event) => {
                                                    setFilterEightyFiveInch(
                                                        event.target.checked
                                                    );

                                                    setFilterFunctionObject({
                                                        ...filterFunctionObject,
                                                        screenSize: event.target
                                                            .checked
                                                            ? (
                                                                  station: Station
                                                              ) =>
                                                                  station.outdoorScreenVersion ===
                                                                      OutdoorScreenVersion.EIGHTY_FIVE_INCH ||
                                                                  (filterSeventyFiveInch
                                                                      ? station.outdoorScreenVersion ===
                                                                        OutdoorScreenVersion.SEVENTY_FIVE_INCH
                                                                      : false)
                                                            : (
                                                                  station: Station
                                                              ) =>
                                                                  filterSeventyFiveInch
                                                                      ? station.outdoorScreenVersion ===
                                                                        OutdoorScreenVersion.SEVENTY_FIVE_INCH
                                                                      : true,
                                                    });
                                                    return;
                                                    if (event.target.checked) {
                                                        return;
                                                    }
                                                    setFilterFunctionObject(
                                                        Object.keys(
                                                            filterFunctionObject
                                                        )
                                                            .filter(
                                                                (key) =>
                                                                    key !==
                                                                    "screenSize"
                                                            )
                                                            .reduce(
                                                                (acc, key) => {
                                                                    return {
                                                                        ...acc,
                                                                        [key]: filterFunctionObject[
                                                                            key
                                                                        ],
                                                                    };
                                                                },
                                                                {}
                                                            )
                                                    );
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                }
                            />
                            {/* {
                            <Box margin={{ left: "1rem" }}>
                                <CheckBox
                                    checked={filterNoAllocation}
                                    label="Leere Stationen"
                                    onChange={event => {
                                        setFilterNoAllocation(
                                            event.target.checked
                                        );
                                        if (event.target.checked) {
                                            setFilterFunctionObject({
                                                ...filterFunctionObject,
                                                emptyStations: (
                                                    station: Station
                                                ) =>
                                                    station.freeSlotsInPeriodCount ===
                                                    5
                                            });
                                            return;
                                        }
                                        setFilterFunctionObject(
                                            Object.keys(filterFunctionObject)
                                                .filter(
                                                    key =>
                                                        key !== "emptyStations"
                                                )
                                                .reduce((acc, key) => {
                                                    return {
                                                        ...acc,
                                                        [key]:
                                                            filterFunctionObject[
                                                                key
                                                            ]
                                                    };
                                                }, {})
                                        );
                                    }}
                                />
                            </Box>
                        }
                        {
                            <Box margin={{ left: "1rem" }}>
                                <CheckBox
                                    checked={filterSomeAllocation}
                                    label="Mit Kunde"
                                    onChange={event => {
                                        setFilterSomeAllocation(
                                            event.target.checked
                                        );
                                        if (event.target.checked) {
                                            setFilterFunctionObject({
                                                ...filterFunctionObject,
                                                notEmptyStations: (
                                                    station: Station
                                                ) =>
                                                    station.freeSlotsInPeriodCount <
                                                    5
                                            });
                                            return;
                                        }
                                        setFilterFunctionObject(
                                            Object.keys(filterFunctionObject)
                                                .filter(
                                                    key =>
                                                        key !==
                                                        "notEmptyStations"
                                                )
                                                .reduce((acc, key) => {
                                                    return {
                                                        ...acc,
                                                        [key]:
                                                            filterFunctionObject[
                                                                key
                                                            ]
                                                    };
                                                }, {})
                                        );
                                    }}
                                />
                            </Box>
                        }
                        {
                            <Box margin={{ left: "1rem" }}>
                                <CheckBox
                                    checked={filterOwnSales}
                                    label="Meine Stationen"
                                    onChange={event => {
                                        setFilterOwnSales(event.target.checked);
                                        if (event.target.checked) {
                                            setFilterFunctionObject({
                                                ...filterFunctionObject,
                                                myStations: (
                                                    station: Station
                                                ) =>
                                                    station.assignedTo ===
                                                    groupToAssignedMap.get(
                                                        props.user
                                                            .authentication
                                                            .group
                                                    )
                                            });
                                            return;
                                        }
                                        setFilterFunctionObject(
                                            Object.keys(filterFunctionObject)
                                                .filter(
                                                    key => key !== "myStations"
                                                )
                                                .reduce((acc, key) => {
                                                    return {
                                                        ...acc,
                                                        [key]:
                                                            filterFunctionObject[
                                                                key
                                                            ]
                                                    };
                                                }, {})
                                        );
                                    }}
                                />
                            </Box>
                        } */}
                        </Box>
                    )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        fromDate: state.campaignReducer.filterDateFrom,
        contractDuration: state.campaignReducer.filterContractDuration,
        slotWeight: state.campaignReducer.filterSlotWeight,
        range: state.stationsReducer.currentRange,
        center: state.stationsReducer.currentCenter,
        user: state.usersReducer.user,
    };
};

const mapDispatchToProps = {
    setFilterDateFrom,
    setFilterContractDuration,
    setFilterSlotWeight,
    findStationsByRange,
    getAllStations,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapSubHeadline);
