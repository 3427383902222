import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";
import React from "react";
import { connect } from "react-redux";
import settings from "../../settings";
import {
    addStationForNewCampaign,
    removeStationForNewCampaign,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import { User } from "../../types/Authentication";
import Buisness from "../../types/Businesses";
import HubspotIcon from "../Icons/HubspotIcon";

interface BusinessItemProps {
    business: Buisness;
    user: User | null;
}

const BusinessItem: React.FC<BusinessItemProps> = (props) => {
    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box>
                    <Box direction="row">
                        <Box
                            align="start"
                            style={{ minWidth: "65%" }}
                            pad={"small"}
                            direction="column"
                            justify="start"
                            width="65%"
                            overflow="dotted"
                        >
                            <Heading
                                truncate
                                level="3"
                                margin={{
                                    top: "10px",
                                    bottom: "0",
                                }}
                                style={{ maxWidth: "100%" }}
                            >
                                {props.business.name}
                            </Heading>
                            <Text textAlign="start">
                                {props.business.vicinity}
                            </Text>
                        </Box>
                        <Box
                            gridArea="right"
                            align="center"
                            style={{ textOverflow: "dotted" }}
                            pad="small"
                            direction="column"
                            justify="end"
                            width="35%"
                            margin={{ top: "small" }}
                        >
                            <Button
                                onClick={(event) => {
                                    // ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name,rating,formatted_phone_number&key=YOUR_API_KEY
                                    const businessRequestUrl =
                                        "https://maps.googleapis.com/maps/api/place/details/json?place_id=" +
                                        props.business.place_id +
                                        "&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";
                                    fetch(
                                        settings.MAP_PROXY_URL.BASE_URL +
                                            "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                                            encodeURIComponent(
                                                businessRequestUrl
                                            )
                                    )
                                        .then((rs) => rs.json())
                                        .then((json) => {
                                            console.log(json);
                                            const body = JSON.stringify({
                                                properties: {
                                                    city: json.result.address_components.find(
                                                        (components) =>
                                                            components.types.includes(
                                                                "locality"
                                                            )
                                                    ).long_name,
                                                    domain: json.result.website,
                                                    industry:
                                                        json.result.types[0],
                                                    name: json.result.name,
                                                    phone: json.result
                                                        .international_phone_number,
                                                    country:
                                                        json.result.address_components.find(
                                                            (components) =>
                                                                components.types.includes(
                                                                    "country"
                                                                )
                                                        ).long_name,
                                                    address:
                                                        json.result.address_components.find(
                                                            (components) =>
                                                                components.types.includes(
                                                                    "route"
                                                                )
                                                        ).long_name +
                                                        " " +
                                                        json.result.address_components.find(
                                                            (components) =>
                                                                components.types.includes(
                                                                    "street_number"
                                                                )
                                                        ).long_name,
                                                    zip: json.result.address_components.find(
                                                        (components) =>
                                                            components.types.includes(
                                                                "postal_code"
                                                            )
                                                    ).long_name,
                                                },
                                            });
                                            console.log(body);
                                            fetch(
                                                settings.MAP_PROXY_URL
                                                    .BASE_URL +
                                                    "?auth=veryc0olpAsSworDwithalotofleTters&url=" +
                                                    encodeURIComponent(
                                                        "https://api.hubapi.com/crm/v3/objects/companies"
                                                    ),
                                                {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                    body: body,
                                                }
                                            );
                                        });
                                }}
                                label="zu Hubspot"
                                icon={<HubspotIcon size="medium" />}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        user: state.usersReducer.user,
    };
};

const mapDispatchToProps = {
    addStationForNewCampaign,
    removeStationForNewCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessItem);
