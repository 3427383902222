import Station from "../../types/Station";
import {
    CLEAR_STATE_AFTER_LOGOUT,
    GET_SINGLE_STATION,
    GET_STATIONS,
    IS_FETCHING_STATIONS,
    IS_FETCHING_STATIONS_BY_ID,
    IS_FETCHING_STATIONS_BY_RANGE,
    IS_FETCHING_STATIONS_BY_RANGE_PUBLIC,
    IS_FETCHING_STATIONS_BY_SHELL_ID,
    RECEIVE_ASSIGNED_STATIONS,
    RECEIVE_STATIONS_BY_ID,
    RECEIVE_STATIONS_BY_SHELL_ID,
    REQUEST_STATUS,
    SET_CENTER,
    SET_CENTER_ADDRESS,
    SET_CURRENT_SCREEN_STATIONS,
    SET_RANGE,
    StationsActionTypes,
    StationState,
    UPDATE_STATION,
} from "./types";

const initialState: StationState = {
    stations: [],
    stationsById: [],
    isFetching: false,
    isFetchingById: false,
    currentCenter: {
        lat: 50.877,
        lng: 10.278,
    },
    currentRange: 20,
    searchString: "",
    searchStringDisplay: "",
    locationSearch: null,
    centerAddress: null,
    currentStationsSelection: [],
    requestStatus: null,
};

export function stationsReducer(
    state = initialState,
    action: StationsActionTypes
): StationState {
    switch (action.type) {
        case IS_FETCHING_STATIONS:
        case IS_FETCHING_STATIONS_BY_RANGE:
        case IS_FETCHING_STATIONS_BY_RANGE_PUBLIC:
        case IS_FETCHING_STATIONS_BY_SHELL_ID:
            return {
                ...state,
                isFetching: true,
                stationsById: [],
            };
        case IS_FETCHING_STATIONS_BY_ID:
            return {
                ...state,
                isFetchingById: true,
                stationsById: [],
            };
        case GET_STATIONS:
            return {
                ...state,
                stations: [
                    // ...state.stations,
                    ...action.payload,
                ],
                isFetching: false,
            };
        case GET_SINGLE_STATION:
            return {
                ...state,
                stations: [
                    ...state.stations.filter(
                        (station) => station.shellId !== action.payload.shellId
                    ),
                    action.payload,
                ],
            };
        case RECEIVE_STATIONS_BY_ID:
            return {
                ...state,
                stationsById: [...action.payload],
                isFetchingById: false,
            };
        case RECEIVE_ASSIGNED_STATIONS:
            return {
                ...state,
                stations: [...action.payload],
                isFetching: false,
            };
        case RECEIVE_STATIONS_BY_SHELL_ID:
            console.log("RECEIVE_STATIONS_BY_SHELL_ID", action.payload);
            return {
                ...state,
                stations: [
                    // ...state.stations,
                    ...action.payload,
                ],
                isFetching: false,
            };
        case SET_CENTER:
            return {
                ...state,
                currentCenter: action.payload,
            };
        case SET_RANGE:
            return {
                ...state,
                currentRange: action.payload,
            };
        case SET_CENTER_ADDRESS:
            return {
                ...state,
                centerAddress: action.payload,
            };
        case SET_CURRENT_SCREEN_STATIONS:
            return {
                ...state,
                currentStationsSelection: action.payload,
            };
        case CLEAR_STATE_AFTER_LOGOUT:
            return initialState;
        case REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.payload,
            };
        case UPDATE_STATION:
            const exchangeStationIfExists = (array: Station[]) => {
                if (
                    array.findIndex(
                        (station) => station.shellId === action.payload.shellId
                    ) >= 0
                ) {
                    return [
                        ...array.filter(
                            (station) =>
                                station.shellId !== action.payload.shellId
                        ),
                        action.payload,
                    ];
                }
                return array;
            };
            return {
                ...state,
                stations: [
                    ...state.stations.filter(
                        (station) => station.shellId !== action.payload.shellId
                    ),
                    action.payload,
                ],
                currentStationsSelection: exchangeStationIfExists(
                    state.currentStationsSelection
                ),
                stationsById: exchangeStationIfExists(state.stationsById),
            };
        default:
            return state;
    }
}
