import {
    Box,
    Button,
    FormField,
    ResponsiveContext,
    Select,
    TextArea,
    TextInput,
} from "grommet";
import { Close, Edit, Undo } from "grommet-icons";
import React, { useState } from "react";

interface EditableFormFieldProps {
    type: "text" | "number" | "select" | "textArea" | undefined;
    options?: string[] | object[];
    min?: string;
    step?: string;
    labelKey?: string;
    value: string;
    propsValue: string;
    placeholder: string;
    name: string;
    label: string;
    disabled: boolean;
    onChangeFunction: (partialObject: any) => void;
    valueParser: (value: string) => any;
}

const EditableFormField: React.FC<EditableFormFieldProps> = (props) => {
    // const isMarkedForNewCampaign = props.stationsForNewCampaign.find(station => props.campa.shellId === station.shellId)

    const [fieldDisabled, setFieldDisabled] = useState(true);

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box direction="row" align="start" fill="horizontal">
                    <FormField
                        name={props.name}
                        label={props.label}
                        style={{ width: size === "small" ? "90%" : "30rem" }}
                    >
                        {!props.type ||
                            (props.type === "text" && (
                                <TextInput
                                    value={props.value}
                                    disabled={fieldDisabled || props.disabled}
                                    type={props.type}
                                    onChange={(event) =>
                                        props.onChangeFunction({
                                            [props.name]: props.valueParser(
                                                event.target.value
                                            ),
                                        })
                                    }
                                />
                            ))}
                        {props.type === "number" && (
                            <TextInput
                                value={props.value}
                                disabled={fieldDisabled || props.disabled}
                                type={props.type}
                                min={props.min}
                                step={props.step}
                                onChange={(event) =>
                                    props.onChangeFunction({
                                        [props.name]: props.valueParser(
                                            event.target.value
                                        ),
                                    })
                                }
                            />
                        )}
                        {props.type === "select" && (
                            <Select
                                options={props.options || []}
                                labelKey={props.labelKey}
                                disabled={fieldDisabled || props.disabled}
                                value={props.value}
                                onChange={({ option }) => {
                                    console.log(option);
                                    props.onChangeFunction({
                                        [props.name]: props.valueParser(
                                            option.value
                                                ? option.value.toString()
                                                : option
                                        ),
                                    });
                                }}
                            />
                        )}
                        {props.type === "textArea" && (
                            <TextArea
                                disabled={fieldDisabled || props.disabled}
                                value={props.value}
                                onChange={(...args: any[]) => {
                                    props.onChangeFunction({
                                        [props.name]: props.valueParser(
                                            args[0].target.value
                                        ),
                                    });
                                }}
                                placeholder={props.placeholder}
                            />
                        )}
                    </FormField>

                    <Button
                        icon={
                            fieldDisabled ? (
                                <Edit
                                    color={props.disabled ? "grey" : "warning"}
                                />
                            ) : (
                                <Close color="danger" />
                            )
                        }
                        onClick={() => setFieldDisabled(!fieldDisabled)}
                        disabled={props.disabled}
                    />
                    <Button
                        disabled={
                            fieldDisabled || props.value === props.propsValue
                        }
                        icon={
                            fieldDisabled ||
                            props.value === props.propsValue ? (
                                <Undo color="grey" />
                            ) : (
                                <Undo color="success" />
                            )
                        }
                        onClick={() =>
                            props.onChangeFunction({
                                [props.name]: props.valueParser(
                                    props.propsValue
                                ),
                            })
                        }
                    />
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default EditableFormField;
