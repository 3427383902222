import dayjs from "dayjs";
import { Box, Heading, Text, TextArea } from "grommet";
import { StatusDisabled, StatusGood } from "grommet-icons";
import React from "react";
import Station, {
    StationStatus,
    StationStatusToText,
} from "../../types/Station";
import ResourcePlanner from "../ResourcePlanner";

interface StationDetailsOverviewViewProps {
    station: Station;
}

const StationDetailsOverviewView: React.FC<StationDetailsOverviewViewProps> = (
    props: StationDetailsOverviewViewProps
) => {
    return (
        <Box>
            <Box
                border="bottom"
                pad="medium"
                direction="row"
                justify="between"
                align="start"
                height="300px"
                style={{ minHeight: "300px" }}
            >
                <Box pad="small" width="30%">
                    <Heading level={3} size="small">
                        Adresse
                    </Heading>
                    <Text style={{ marginBottom: "4rem" }}>
                        {props.station.street}
                        <br />
                        {props.station.zipCode} {props.station.city}
                    </Text>
                </Box>

                <Box pad="small" width="30%">
                    <Box direction="row">
                        <Heading level={3} size="small">
                            Status
                        </Heading>
                        <Box margin={{ left: "medium", top: "1em" }}>
                            {props.station.status ===
                            StationStatus.CONSTRUCTED ? (
                                <StatusGood color="success" />
                            ) : (
                                <StatusDisabled color="grey" />
                            )}
                        </Box>
                    </Box>

                    <Text
                        style={{
                            paddingBottom: "1.4rem",
                        }}
                    >
                        {StationStatusToText(props.station.status) +
                            (props.station.plannedConstructionDate
                                ? " - " +
                                  (props.station.status ===
                                  StationStatus.CONSTRUCTED
                                      ? "seit "
                                      : "Livegang ") +
                                  dayjs(
                                      props.station.plannedConstructionDate
                                  ).format("DD.MM.YYYY")
                                : "")}
                    </Text>
                </Box>
                <Box pad="small" width="30%">
                    <Heading level={3} size="small">
                        Sonstige Informationen
                    </Heading>
                    <Box height="small" fill="horizontal">
                        <TextArea
                            style={{
                                width: "100%",
                                overflow: "scroll",
                            }}
                            plain
                            resize={false}
                            disabled
                            value={"" + props.station.additionalInfo}
                            fill
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                background="white"
                pad="medium"
                height="420px"
                style={{
                    maxHeight: "420px",
                    minHeight: "420px",
                }}
            >
                {/* {props.station.city}

                {props.allocations
                    && props.allocations[props.station.id]
                    && props.allocations[props.station.id].map((allocation: any, index: number) => <span key={index}>{allocation.start} - {allocation.end}</span>)} */}

                <ResourcePlanner
                    // resources={props.allocations[props.station.id]}
                    station={props.station}
                />
            </Box>
        </Box>
    );
};

export default StationDetailsOverviewView;
