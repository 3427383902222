import { Button, ResponsiveContext } from "grommet";
import { List } from "grommet-icons";
import React, { Component } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    withGoogleMap,
    withScriptjs,
} from "react-google-maps";
import { connect } from "react-redux";
import { getAllocationsForStationByDate } from "../../store/allocations/actions";
import { rootState } from "../../store/rootReducer";
import { groupToAssignedMap, User, UserRole } from "../../types/Authentication";
import Buisness from "../../types/Businesses";
import Station, { Location } from "../../types/Station";
import BusinessItem from "../MapListItem/BusinessItem";
import ListItem from "../MapListItem/MapListItem";

interface MapState {
    infoOpen: number[];
}

interface MapProps {
    center: Location;
    range: number;
    stations: Station[];
    buisnesses: Buisness[];
    stationsForNewCampaign: Station[];
    handleStationSelect: (station: Station) => void;
    handleShowMap?: (showMap: boolean) => void;
    user: User | null;
}

class ResourcerMap extends Component<MapProps> {
    state: MapState;
    myRef: any;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            infoOpen: [],
        };
    }

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 11,
    };

    static markerColorMap = new Map([
        ["default", "/red_marker.png"],
        ["defaultAdded", "/green_marker.png"],
        ["André", "/light_purple_marker.png"],
        ["Christian Mehltretter", "/light_purple_marker.png"],
        ["Harry Lötterle", "/light_purple_marker.png"],
        ["Marco Lattuca", "/light_purple_marker.png"],
        ["Cristian W. Köhler", "/light_purple_marker.png"],
        ["Compri", "/light_blue_marker.png"],
        ["CompriMedia", "/light_blue_marker.png"],
        ["SMM", "/yellow_marker.png"],
        ["SalesElevator", "/yellow_marker.png"],
        ["ISM Deniz", "/ism_marker.png"],
        ["ISM Deniz / Serdar", "/ism_marker.png"],
        ["ISM Hubert", "/ism_marker.png"],
        ["Hubert", "/ism_marker.png"],
        ["ISM", "/ism_marker.png"],
        ["Eigenvertrieb", "/ism_marker.png"],
        ["Nicht zugewiesen", "/dark_blue_marker.png"],
        ["Edgar UAM", "/orange_marker.png"],
        ["uze! Mobility GmbH", "/orange_marker.png"],
        ["AmbientMedia-TV", "/orange_marker.png"],
        ["Systemline", "/pink_marker.png"],
        ["attached", "/light_blue_marker.png"],
    ]);

    // static groupToAssignedMap = new Map([
    //     ["SMM", "SMM"],
    //     ["COMPRI", "Compri"],
    //     ["Ambient-TV", "Ambient-TV"],
    //     ["André", "André"],
    // ]);

    MapContainer: any = withScriptjs(
        withGoogleMap(() => {
            return (
                <GoogleMap
                    zoom={
                        this.props.user &&
                        this.props.user.authentication.role ===
                            UserRole.EXTERNAL_SALES
                            ? 11
                            : (this.props.range < 50
                                  ? 3 +
                                    4 * Math.E ** (-0.03 * this.props.range + 1)
                                  : -0.015 * this.props.range + 11) < 2
                            ? 6
                            : this.props.range < 50
                            ? 3 + 4 * Math.E ** (-0.03 * this.props.range + 1)
                            : -0.015 * this.props.range + 11
                    }
                    center={this.props.center}
                    ref={this.myRef}
                >
                    <Marker
                        icon={"/ism_pin.png"}
                        key={"searchPin"}
                        position={this.props.center}
                    />
                    {this.props.stations.map((element: Station) => {
                        let icon = ResourcerMap.markerColorMap.get("default");
                        if (
                            this.props.stationsForNewCampaign.find(
                                (station) => element.shellId === station.shellId
                            )
                        ) {
                            icon =
                                ResourcerMap.markerColorMap.get("defaultAdded");
                        } else if (
                            this.props.user &&
                            (this.props.user.authentication.role ===
                                UserRole.ADMIN ||
                                this.props.user.authentication.role ===
                                    UserRole.VIEW_ONLY)
                        ) {
                            icon = ResourcerMap.markerColorMap.get(
                                element.assignedTo
                            );
                        } else if (
                            this.props.user &&
                            this.props.user.authentication.group &&
                            groupToAssignedMap.get(
                                this.props.user.authentication.group
                            ) &&
                            element.assignedTo ===
                                groupToAssignedMap.get(
                                    this.props.user.authentication.group
                                )
                        ) {
                            icon = ResourcerMap.markerColorMap.get("ISM");
                        } else if (
                            this.props.user &&
                            this.props.user.authentication.group &&
                            groupToAssignedMap.get(
                                this.props.user.authentication.group
                            )
                        ) {
                            icon =
                                ResourcerMap.markerColorMap.get(
                                    "Nicht zugewiesen"
                                );
                        }

                        const markerInfoOpen = this.state.infoOpen.find(
                            (shellId) => element.shellId === shellId
                        )
                            ? true
                            : false;

                        return (
                            <Marker
                                icon={icon}
                                key={element.shellId}
                                position={{
                                    lat: element.latitude as number,
                                    lng: element.longitude as number,
                                }}
                                onClick={() =>
                                    this.setState({
                                        infoOpen: [element.shellId],
                                    })
                                } //...this.state.infoOpen
                            >
                                {markerInfoOpen && (
                                    <InfoWindow
                                        onCloseClick={() =>
                                            this.setState({
                                                infoOpen:
                                                    this.state.infoOpen.filter(
                                                        (id) =>
                                                            element.shellId !==
                                                            id
                                                    ),
                                            })
                                        }
                                    >
                                        <ListItem
                                            lastSelectedItem={false}
                                            key={element.shellId}
                                            station={element}
                                            onClick={() =>
                                                this.props.handleStationSelect(
                                                    element
                                                )
                                            }
                                        />
                                    </InfoWindow>
                                )}
                            </Marker>
                        );
                    })}
                    {this.props.buisnesses !== null &&
                        this.props.buisnesses.map((element: Buisness) => {
                            let icon =
                                ResourcerMap.markerColorMap.get("default");
                            const markerInfoOpen = this.state.infoOpen.find(
                                (id) => element.place_id === id
                            )
                                ? true
                                : false;
                            return (
                                <Marker
                                    icon={icon}
                                    key={element.place_id}
                                    position={{
                                        lat: element.geometry.location
                                            .lat as number,
                                        lng: element.geometry.location
                                            .lng as number,
                                    }}
                                    onClick={() =>
                                        this.setState({
                                            infoOpen: [element.place_id],
                                        })
                                    } //...this.state.infoOpen
                                >
                                    {markerInfoOpen && (
                                        <InfoWindow
                                            onCloseClick={() =>
                                                this.setState({
                                                    infoOpen:
                                                        this.state.infoOpen.filter(
                                                            (id) =>
                                                                element.place_id !==
                                                                id
                                                        ),
                                                })
                                            }
                                        >
                                            <BusinessItem
                                                key={element.place_id}
                                                business={element}
                                            />
                                        </InfoWindow>
                                    )}
                                </Marker>
                            );
                        })}
                    {
                        <ResponsiveContext.Consumer>
                            {(size) =>
                                size === "small" && (
                                    <Button
                                        style={{
                                            position: "fixed",
                                            bottom: 10,
                                            right: "25%",
                                            width: "50%",
                                            backgroundColor: "white",
                                        }}
                                        label="Zur Liste"
                                        color="accent-1"
                                        icon={<List />}
                                        onClick={() =>
                                            this.props.handleShowMap &&
                                            this.props.handleShowMap(false)
                                        }
                                    />
                                )
                            }
                        </ResponsiveContext.Consumer>
                    }
                </GoogleMap>
            );
        })
    );

    render() {
        return (
            // Important! Always set the container height explicitly
            <this.MapContainer
                googleMapURL={
                    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE"
                }
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                // stations={props.stations}
                center={this.props.center}
                range={
                    this.props.range < 50
                        ? 3 + 4 * Math.E ** (-0.03 * this.props.range + 1)
                        : -0.015 * this.props.range + 11
                }
            />
        );
    }
}

const mapStateToProps = (state: rootState) => {
    return {
        // stations: state.stationsReducer.stations,
        user: state.usersReducer.user,
        loggedIn: state.usersReducer.loggedIn,
        center: state.stationsReducer.currentCenter,
        range: state.stationsReducer.currentRange,
        isFetchingAllocations: state.allocationReducer.isFetching,
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        buisnesses: state.businessReducer.buisnesses,
    };
};

const mapDispatchToProps = {
    getAllocationsForStationByDate,
    //getSingleStation
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcerMap);
