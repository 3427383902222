export interface Station {
    id: number;
    shellId: number;
    longitude: number | null;
    latitude: number | null;
    assignedTo: string;
    street: string;
    zipCode: string;
    city: string;
    region: Region;
    freeSlotsInPeriod: FreeSlot[];
    freeSlotsInPeriodCount: number;
    plannedConstructionDate: number | null;
    activationDate: number | null;
    status: StationStatus;
    additionalInfo: string | null;
    constructionCost: number | null;
    constructionCostOffer: number | null;
    electricityCostsPerMonth: number | null;
    outdoorScreenVersion: OutdoorScreenVersion | null;
    indoorScreenVersion: IndoorScreenVersion | null;
    outdoorScreenCost: number | null;
    indoorScreenCost: number | null;
    otherHardwareCost: number | null;
    measurementFees: number | null;
    approvalFees: number | null;
    smallElectronicsCost: number | null;
    otherCosts: number | null;
    carWashCosts: number | null;
    backShopCosts: number | null;
    additionalCosts: number | null;
    cityApprovalCosts: number | null;
    carWashPlayerAmount: number | null;
    backShopPlayerAmount: number | null;
}

export enum Region {
    Baden_Wuerttemberg = "Baden-Württemberg",
    Bayern = "Bayern",
    Berlin = "Berlin",
    Brandenburg = "Brandenburg",
    Bremen = "Bremen",
    Hamburg = "Hamburg",
    Hessen = "Hessen",
    Mecklenburg_Vorpommern = "Mecklenburg-Vorpommern",
    Niedersachsen = "Niedersachsen",
    Nordrhein_Westfalen = "Nordrhein-Westfalen",
    Rheinland_Pfalz = "Rheinland-Pfalz",
    Saarland = "Saarland",
    Sachsen = "Sachsen",
    Sachsen_Anhalt = "Sachsen-Anhalt",
    Schleswig_Holstein = "Schleswig-Holstein",
    Thueringen = "Thüringen",
    other = "Baden-Wuerttemberg",
}

export interface Location {
    lat: number;
    lng: number;
}

export interface FreeSlot {
    slot: number;
    free: number;
}

export enum IndoorScreenVersion {
    HANGING_INDOOR = "HANGING_INDOOR",
    STANDING_INDOOR = "STANDING_INDOOR",
    INSIDE_ONLY = "INSIDE_ONLY",
    OUTSIDE_ONLY = "OUTSIDE_ONLY",
    NONE = "NONE",
}

export const IndoorScreenVersionToText = (
    version: IndoorScreenVersion | null
): string => {
    switch (version) {
        case IndoorScreenVersion.HANGING_INDOOR:
            return "Indoor hängend";
        case IndoorScreenVersion.STANDING_INDOOR:
            return "Indoor stehend";
        case IndoorScreenVersion.INSIDE_ONLY:
            return "Einfach - nach innen";
        case IndoorScreenVersion.OUTSIDE_ONLY:
            return "Einfach - nach außen";
        case IndoorScreenVersion.NONE:
            return "kein Indoor";
        default:
            return "nicht bekannt";
    }
};

export const IndoorScreenVersionTextToEnum = (
    version: string
): IndoorScreenVersion | null => {
    switch (version) {
        case "Indoor hängend":
            return IndoorScreenVersion.HANGING_INDOOR;
        case "Indoor stehend":
            return IndoorScreenVersion.STANDING_INDOOR;
        case "Einfach - nach innen":
            return IndoorScreenVersion.INSIDE_ONLY;
        case "Einfach - nach außen":
            return IndoorScreenVersion.OUTSIDE_ONLY;
        case "kein Indoor":
            return IndoorScreenVersion.NONE;
        default:
            return null;
    }
};

export const IndoorScreenOptionsArray = [
    IndoorScreenVersionToText(IndoorScreenVersion.HANGING_INDOOR),
    IndoorScreenVersionToText(IndoorScreenVersion.STANDING_INDOOR),
    IndoorScreenVersionToText(IndoorScreenVersion.INSIDE_ONLY),
    IndoorScreenVersionToText(IndoorScreenVersion.OUTSIDE_ONLY),
    IndoorScreenVersionToText(IndoorScreenVersion.NONE),
];

export enum OutdoorScreenVersion {
    EIGHTY_FIVE_INCH = "EIGHTY_FIVE_INCH",
    SEVENTY_FIVE_INCH = "SEVENTY_FIVE_INCH",
}

export const OutdoorScreenVersionToText = (
    version: OutdoorScreenVersion | null
): string => {
    switch (version) {
        case OutdoorScreenVersion.EIGHTY_FIVE_INCH:
            return '85"';
        case OutdoorScreenVersion.SEVENTY_FIVE_INCH:
            return '75"';
        default:
            return "nicht bekannt";
    }
};

export const OutdoorScreenVersionTextToEnum = (
    version: string
): OutdoorScreenVersion | null => {
    switch (version) {
        case '75"':
            return OutdoorScreenVersion.SEVENTY_FIVE_INCH;
        case '85"':
            return OutdoorScreenVersion.EIGHTY_FIVE_INCH;
        default:
            return null;
    }
};

export const OutdoorScreenOptionsArray = [
    OutdoorScreenVersionToText(OutdoorScreenVersion.SEVENTY_FIVE_INCH),
    OutdoorScreenVersionToText(OutdoorScreenVersion.EIGHTY_FIVE_INCH),
];

export enum StationStatus {
    PLANNING_PHASE = "PLANNING_PHASE",
    APPROVAL_PROCESS = "APPROVAL_PROCESS",
    CONSTRUCTION_SCHEDULED = "CONSTRUCTION_SCHEDULED",
    CONSTRUCTED = "CONSTRUCTED",
    ABORTED = "ABORTED",
}

export const StationStatusToText = (status: StationStatus): string => {
    switch (status) {
        case StationStatus.PLANNING_PHASE:
            return "Planungsphase";
        case StationStatus.APPROVAL_PROCESS:
            return "Freigabephase";
        case StationStatus.CONSTRUCTION_SCHEDULED:
            return "Aufbauphase";
        case StationStatus.CONSTRUCTED:
            return "Station Live";
        case StationStatus.ABORTED:
            return "Aufbau storniert";
        default:
            return "nicht bekannt";
    }
};

export const StationStatusTextToStatus = (status: string): StationStatus => {
    switch (status) {
        case "Planungsphase":
            return StationStatus.PLANNING_PHASE;
        case "Freigabephase":
            return StationStatus.APPROVAL_PROCESS;
        case "Aufbauphase":
            return StationStatus.CONSTRUCTION_SCHEDULED;
        case "Station Live":
            return StationStatus.CONSTRUCTED;
        case "Aufbau storniert":
            return StationStatus.ABORTED;
        default:
            return StationStatus.PLANNING_PHASE;
    }
};

export const StationStatusArray = [
    StationStatusToText(StationStatus.PLANNING_PHASE),
    StationStatusToText(StationStatus.APPROVAL_PROCESS),
    StationStatusToText(StationStatus.CONSTRUCTION_SCHEDULED),
    StationStatusToText(StationStatus.CONSTRUCTED),
    StationStatusToText(StationStatus.ABORTED),
];

export default Station;
