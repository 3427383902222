import { Box, Button, Text } from 'grommet';
import { Login } from 'grommet-icons';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { rootState } from '../../store/rootReducer';
import { logoutUser } from '../../store/user/actions';

const LoginScreen: React.FC = (props: any) => {

    return (
        <Box
            align='center'
            pad='large'
            justify='center'
            direction='column'
        >
            {!props.user.loggedIn && <Box
                align='center'
                justify='center'
                direction='column'
            >
                <Text
                    margin={{ bottom: '3rem' }}
                >
                    Sie sind nicht mehr angemeldet.
                </Text>

                <Link to='/login'>
                    <Button
                        label='Login'
                        color='accent-1'
                        icon={<Login />}
                    />
                </Link>

            </Box>}
            {props.user.loggedIn && <Button onClick={() => props.logoutUser()} primary label="Abmelden" />}

        </Box>
    );
}

const mapStateToProps = (state: rootState) => {
    return {
        user: state.usersReducer
    }
}

const mapDispatchToProps = {
    logoutUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
