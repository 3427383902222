import dayjs from "dayjs";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import settings from "../../settings";
import { RequestStatus } from "../../types/Authentication";
import { CampaignDurationInt } from "../../types/Campaign";
import Station, { Location } from "../../types/Station";
import { addStationForNewCampaign } from "../campaign/actions";
import { CampaignsActionType } from "../campaign/types";
import { fetchAuth, rootState } from "../rootReducer";
import { logoutUser } from "../user/actions";
import {
    CLEAR_STATE_AFTER_LOGOUT,
    GET_STATIONS,
    IS_FETCHING_STATIONS,
    IS_FETCHING_STATIONS_BY_ID,
    IS_FETCHING_STATIONS_BY_RANGE,
    IS_FETCHING_STATIONS_BY_RANGE_PUBLIC,
    IS_FETCHING_STATIONS_BY_SHELL_ID,
    RECEIVE_ASSIGNED_STATIONS,
    RECEIVE_STATIONS_BY_ID,
    RECEIVE_STATIONS_BY_SHELL_ID,
    REQUEST_STATUS,
    SET_CENTER,
    SET_CENTER_ADDRESS,
    SET_CURRENT_SCREEN_STATIONS,
    SET_RANGE,
    StationsActionTypes,
    UPDATE_STATION,
} from "./types";
import { NewStationProps } from "../../elements/NewStationForm";

function fetchAllStations(): StationsActionTypes {
    return {
        type: IS_FETCHING_STATIONS,
    };
}

// function fetchAssignedStations(): StationsActionTypes {
//     return {
//         type: IS_FETCHING_ASSIGNED_STATIONS
//     }
// }

function fetchStationsById(): StationsActionTypes {
    return {
        type: IS_FETCHING_STATIONS_BY_ID,
    };
}

function fetchRangedStations(): StationsActionTypes {
    return {
        type: IS_FETCHING_STATIONS_BY_RANGE,
    };
}

function fetchRangedStationsPublic(): StationsActionTypes {
    return {
        type: IS_FETCHING_STATIONS_BY_RANGE_PUBLIC,
    };
}

function fetchStationByShellId(): StationsActionTypes {
    return {
        type: IS_FETCHING_STATIONS_BY_SHELL_ID,
    };
}

export function clearState(): StationsActionTypes {
    return {
        type: CLEAR_STATE_AFTER_LOGOUT,
    };
}

function setCenter(center: Location): StationsActionTypes {
    return {
        type: SET_CENTER,
        payload: center,
    };
}

function setCenterAddress(centerAddress: string): StationsActionTypes {
    return {
        type: SET_CENTER_ADDRESS,
        payload: centerAddress,
    };
}

export function setScreenStations(stations: Station[]): StationsActionTypes {
    return {
        type: SET_CURRENT_SCREEN_STATIONS,
        payload: stations,
    };
}

function setRange(range: string): StationsActionTypes {
    return {
        type: SET_RANGE,
        payload: parseInt(range),
    };
}

function isFetchingRequestStatus(): StationsActionTypes {
    return {
        type: REQUEST_STATUS,
        payload: RequestStatus.FETCHING,
    };
}

function successRequestStatus(): StationsActionTypes {
    return {
        type: REQUEST_STATUS,
        payload: RequestStatus.SUCCESS,
    };
}

function errorRequestStatus(): StationsActionTypes {
    return {
        type: REQUEST_STATUS,
        payload: RequestStatus.ERROR,
    };
}

export function resetRequestStatus(): StationsActionTypes {
    return {
        type: REQUEST_STATUS,
        payload: null,
    };
}

export function createNewStation(station: NewStationProps) {
    return async (
        dispatch: ThunkDispatch<{}, {}, StationsActionTypes>,
        getState: () => {}
    ): Promise<void> => {
        debugger;
        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        dispatch(isFetchingRequestStatus());

        await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.STATIONS,
            {
                method: "POST",
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(station),
            }
        );

        dispatch(successRequestStatus());
    };
}

export function updateStation(
    station: Station
): ThunkAction<Promise<void>, {}, {}, StationsActionTypes> {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            StationsActionTypes | CampaignsActionType
        >,
        getState: () => {}
    ): Promise<void> => {
        // dispatch(fetchAssignedStations())

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        dispatch(isFetchingRequestStatus());

        await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.STATIONS +
                "/" +
                station.id,
            {
                method: "PUT",
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(station),
            }
        );

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        if (
            state.campaignReducer.stationsForNewCampaign.find(
                (newCampaignStation) =>
                    newCampaignStation.shellId === station.shellId
            )
        ) {
            dispatch(addStationForNewCampaign(station));
        }

        dispatch({
            type: UPDATE_STATION,
            payload: station,
        });

        dispatch(successRequestStatus());
    };
}

export function getAllStations(): ThunkAction<
    Promise<void>,
    {},
    {},
    StationsActionTypes
> {
    return async (
        dispatch: ThunkDispatch<{}, {}, StationsActionTypes>,
        getState: () => {}
    ): Promise<void> => {
        // dispatch(fetchAssignedStations())

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        const response = await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.STATIONS +
                "?" +
                "&usagePeriodStart=" +
                dayjs(state.campaignReducer.filterDateFrom).valueOf() +
                "&usagePeriod=" +
                CampaignDurationInt[
                    state.campaignReducer.filterContractDuration
                ],
            {
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        dispatch({
            type: RECEIVE_ASSIGNED_STATIONS,
            payload: response,
        });
    };
}

export function getStationsById(
    idArray: number[]
): ThunkAction<Promise<void>, {}, {}, StationsActionTypes> {
    return async (
        dispatch: ThunkDispatch<{}, {}, StationsActionTypes>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(fetchStationsById());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            const response = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.STATIONS +
                    "?" +
                    idArray.map((id) => "ids=" + id).join("&"),
                {
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                    },
                }
            );
            console.log(response);
            if (
                state.usersReducer.user === null ||
                !state.usersReducer.loggedIn
            ) {
                dispatch(logoutUser());
                return;
            }

            dispatch({
                type: RECEIVE_STATIONS_BY_ID,
                payload: response,
            });
            dispatch(setScreenStations(response));
        } catch (e) {
            // dispatch(setAuthError(e.message))
            return;
        }
    };
}

export function findStationByShellId(
    shellId?: number
): ThunkAction<Promise<void>, {}, {}, StationsActionTypes> {
    return async (
        dispatch: ThunkDispatch<{}, {}, StationsActionTypes>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(fetchStationByShellId());

        dispatch(setRange("10"));

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            const stationData = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.STATIONS +
                    (shellId ? "?shellId=" + shellId : ""),
                {
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                    },
                }
            );

            console.log(stationData);
            if (!stationData[0]) {
                dispatch({
                    type: RECEIVE_STATIONS_BY_SHELL_ID,
                    payload: [],
                });
                return;
            }

            dispatch(
                setCenter({
                    lat: stationData[0].latitude,
                    lng: stationData[0].longitude,
                })
            );
            console.log(stationData);
            dispatch({
                type: RECEIVE_STATIONS_BY_SHELL_ID,
                payload: stationData,
            });
        } catch (e) {
            return;
        }
    };
}

export function findStationsByRange(
    address: string = "Berlin",
    range: string,
    skipGeoSearch?: boolean,
    center?: Location
): ThunkAction<Promise<void>, {}, {}, StationsActionTypes> {
    return async (
        dispatch: ThunkDispatch<{}, {}, StationsActionTypes>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(fetchRangedStations());

        dispatch(setRange(range));

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            try {
                const mapRequestUrl =
                    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                    encodeURI("deutschland " + address) +
                    "&language=de&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";

                const gmapLocationRespJson = await (
                    await fetch(mapRequestUrl)
                ).json();

                if (gmapLocationRespJson.status === "OK") {
                    const lat =
                            gmapLocationRespJson.results[0].geometry.location
                                .lat,
                        lng =
                            gmapLocationRespJson.results[0].geometry.location
                                .lng,
                        centerAddress =
                            gmapLocationRespJson.results[0].formatted_address;

                    dispatch(setCenter({ lat: lat, lng: lng }));

                    dispatch(setCenterAddress(centerAddress));

                    const stationsData: any = await fetch(
                        settings.API.BASE_URL +
                            settings.API.API_PATH +
                            settings.API_END_POINTS.STATIONS_BY_RANGE_PUBLIC +
                            "?" +
                            "latitude=" +
                            encodeURI(lat) +
                            "&longitude=" +
                            encodeURI(lng) +
                            "&range=" +
                            range
                    ).then((res) => res.json());

                    dispatch({
                        type: GET_STATIONS,
                        payload: stationsData,
                    });
                }
                return;
            } catch (e) {
                // dispatch(setAuthError(e.message))
                return;
            }
        }

        try {
            if (!skipGeoSearch) {
                const mapRequestUrl =
                    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                    encodeURI("deutschland " + address) +
                    "&language=de&key=AIzaSyAON7CsmK8V6TNfP7ggm8beVvnp-RvQxWE";

                const gmapLocationRespJson = await (
                    await fetch(mapRequestUrl)
                ).json();

                if (gmapLocationRespJson.status === "OK") {
                    const lat =
                            gmapLocationRespJson.results[0].geometry.location
                                .lat,
                        lng =
                            gmapLocationRespJson.results[0].geometry.location
                                .lng,
                        centerAddress =
                            gmapLocationRespJson.results[0].formatted_address;

                    dispatch(setCenter({ lat: lat, lng: lng }));

                    dispatch(setCenterAddress(centerAddress));

                    const stationsData = await fetchAuth(
                        dispatch,
                        settings.API.BASE_URL +
                            settings.API.API_PATH +
                            settings.API_END_POINTS.STATIONS_BY_RANGE +
                            "?" +
                            "latitude=" +
                            encodeURI(lat) +
                            "&longitude=" +
                            encodeURI(lng) +
                            "&range=" +
                            range +
                            "&usagePeriodStart=" +
                            dayjs(
                                state.campaignReducer.filterDateFrom
                            ).valueOf() +
                            "&usagePeriod=" +
                            CampaignDurationInt[
                                state.campaignReducer.filterContractDuration
                            ],
                        {
                            headers: {
                                Authorization:
                                    "Bearer " +
                                    state.usersReducer.user.authentication
                                        .access_token,
                            },
                        }
                    );

                    dispatch({
                        type: GET_STATIONS,
                        payload: stationsData,
                    });
                }
                return;
            }

            if (skipGeoSearch && center) {
                dispatch(setCenter(center));

                const stationsData = await fetchAuth(
                    dispatch,
                    settings.API.BASE_URL +
                        settings.API.API_PATH +
                        settings.API_END_POINTS.STATIONS_BY_RANGE +
                        "?" +
                        "latitude=" +
                        encodeURI("" + center.lat) +
                        "&longitude=" +
                        encodeURI("" + center.lng) +
                        "&range=" +
                        range +
                        "&usagePeriodStart=" +
                        dayjs(state.campaignReducer.filterDateFrom).valueOf() +
                        "&usagePeriod=" +
                        CampaignDurationInt[
                            state.campaignReducer.filterContractDuration
                        ],
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                state.usersReducer.user.authentication
                                    .access_token,
                        },
                    }
                );

                dispatch({
                    type: GET_STATIONS,
                    payload: stationsData,
                });
            }
        } catch (e) {
            // dispatch(setAuthError(e.message))
            return;
        }
    };
}
