export interface UserAuthenticationResponse {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    role: UserRole;
    group: string;
    scope: string;
    authorities: Authority[];
}

export interface UserAuthenticationErrorResponse {
    error: string;
    error_description: string;
}

interface Authority {
    authority: string;
}

export interface User {
    username: string;
    authentication: UserAuthenticationResponse;
}

export enum UserRole {
    ADMIN = "ADMIN",
    TELE_SALES = "TELE_SALES",
    EXTERNAL_SALES = "EXTERNAL_SALES",
    VIEW_ONLY = "VIEW_ONLY",
    WUERTH = "WUERTH",
}

export enum AuthenticationError {
    FORBIDDEN = "FORBIDDEN",
    SERVER_ERROR = "SERVER_ERROR",
    WRONG_CREDENTIALS = "WRONG_CREDENTIALS",
    NONE = "NONE",
}

export enum RequestStatus {
    FETCHING = "FETCHING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export const groupToAssignedMap = new Map([
    ["SMM", "SalesElevator"],
    ["COMPRI", "CompriMedia"],
    ["Ambient-TV", "AmbientMedia-TV"],
    ["uze! Mobility GmbH", "uze! Mobility GmbH"],
    ["ANDRE", "André"],
    ["MEHLTRETTER", "Christian Mehltretter"],
    ["LATTUCA", "Marco Lattuca"],
    ["LOETTERLE", "Harry Lötterle"],
    ["KOEHLER", "Cristian W. Köhler"],
]);
