import dayjs from "dayjs";
import {
    Box,
    Button,
    Calendar,
    DropButton,
    FormField,
    Heading,
    RadioButtonGroup,
    ResponsiveContext,
    Select,
    TextArea,
    TextInput,
} from "grommet";
import { Checkmark, Close } from "grommet-icons";
import React, { ReactNode, useState } from "react";

interface EditableFormFieldProps {
    type:
        | "text"
        | "number"
        | "select"
        | "textArea"
        | "datepicker"
        | "radio"
        | undefined;
    options?:
        | string[]
        | {
              disabled?: boolean | undefined;
              id?: string | undefined;
              label?: ReactNode;
              value: string;
          }[];
    min?: number;
    step?: string;
    value: string | number;
    propsValue: string | number;
    placeholder: string | number;
    name: string;
    label: string;
    onChangeFunction: (partialObject: any) => void;
    valueParser: (value: string) => any;
}

export const EditableStationFormField: React.FC<EditableFormFieldProps> = (
    props
) => {
    // const isMarkedForNewCampaign = props.stationsForNewCampaign.find(station => props.campa.shellId === station.shellId)

    const [closedCalendarWindow, setClosedCalendarWindow] = useState(true);

    let Timer;

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    direction="row"
                    align="start"
                    fill="horizontal"
                    style={{
                        marginBottom: "1rem",
                    }}
                    // height="medium"
                >
                    {!props.type ||
                        (props.type === "radio" && (
                            <Box>
                                <Heading level="4">{props.label}</Heading>
                                <RadioButtonGroup
                                    name={props.name}
                                    options={props.options || []}
                                    value={props.value.toString()}
                                    onChange={(event) =>
                                        props.onChangeFunction({
                                            [props.name]: props.valueParser(
                                                event.target.value
                                            ),
                                        })
                                    }
                                />
                            </Box>
                        ))}
                    {props.type !== "radio" && (
                        <FormField
                            name={props.name}
                            label={props.label}
                            // style={{
                            // width: size === "small" ? "90%" : "30rem",
                            //     border: "0px",
                            // }}
                        >
                            {!props.type ||
                                (props.type === "text" && (
                                    <TextInput
                                        value={props.value}
                                        type={props.type}
                                        onChange={(event) =>
                                            props.onChangeFunction({
                                                [props.name]: props.valueParser(
                                                    event.target.value
                                                ),
                                            })
                                        }
                                    />
                                ))}

                            {props.type === "number" && (
                                <TextInput
                                    value={props.value}
                                    type={props.type}
                                    min={props.min}
                                    lang="de-DE"
                                    step={props.step}
                                    onChange={(event) =>
                                        props.onChangeFunction({
                                            [props.name]: props.valueParser(
                                                event.target.value
                                            ),
                                        })
                                    }
                                />
                            )}
                            {props.type === "select" && (
                                <Select
                                    options={props.options || []}
                                    value={props.value.toString()}
                                    onChange={({ option }) =>
                                        props.onChangeFunction({
                                            [props.name]: props.valueParser(
                                                option
                                            ),
                                        })
                                    }
                                />
                            )}
                            {props.type === "textArea" && (
                                <TextArea
                                    // options={props.options || []}
                                    value={props.value.toString()}
                                    onChange={(event) => {
                                        props.onChangeFunction({
                                            [props.name]: props.valueParser(
                                                event.target.value
                                            ),
                                        });

                                        // const args2 = args;
                                        // clearTimeout(Timer);
                                        // Timer = setTimeout(() => {
                                        //     console.log(event.target.value);
                                        // }, 500);
                                    }}
                                    placeholder={props.placeholder.toString()}
                                />
                            )}

                            {props.type === "datepicker" && (
                                <DropButton
                                    style={{
                                        paddingLeft: "0.6rem",
                                        paddingBottom: "0.6rem",
                                        fontWeight: "bold",
                                    }}
                                    margin={{ top: "0.5rem" }}
                                    label={
                                        props.value
                                            ? dayjs(
                                                  parseInt(
                                                      props.value.toString()
                                                  )
                                              ).format("DD.MM.YYYY")
                                            : "nicht gesetzt"
                                    }
                                    dropAlign={{
                                        top: "bottom",
                                        right: "right",
                                    }}
                                    open={!closedCalendarWindow}
                                    onOpen={() =>
                                        setClosedCalendarWindow(false)
                                    }
                                    onClose={() =>
                                        setClosedCalendarWindow(true)
                                    }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    props.value
                                                        ? parseInt(
                                                              props.value.toString()
                                                          )
                                                        : new Date()
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    props.onChangeFunction({
                                                        [props.name]: props.valueParser(
                                                            dayjs(date)
                                                                .valueOf()
                                                                .toString()
                                                        ),
                                                    });
                                                }}
                                            />
                                            <Box
                                                direction="row"
                                                justify="between"
                                            >
                                                <Button
                                                    icon={
                                                        <Checkmark color="success" />
                                                    }
                                                    onClick={() =>
                                                        setClosedCalendarWindow(
                                                            true
                                                        )
                                                    }
                                                />
                                                <Button
                                                    icon={
                                                        <Close color="danger" />
                                                    }
                                                    onClick={() => {
                                                        setClosedCalendarWindow(
                                                            true
                                                        );
                                                        props.onChangeFunction({
                                                            [props.name]: props.valueParser(
                                                                props.propsValue.toString()
                                                            ),
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                />

                                // <TextInput
                                //     value={props.value}
                                //     type={props.type}
                                //     min={props.min}
                                //     step={props.step}
                                //     onChange={event =>
                                //         props.onChangeFunction({
                                //             [props.name]: props.valueParser(
                                //                 event.target.value
                                //             )
                                //         })
                                //     }
                                // />
                            )}
                        </FormField>
                    )}
                    {/* <Button
                        icon={<Undo color="success" />}
                        onClick={() => {
                            console.log("test", props.propsValue.toString());
                            props.onChangeFunction({
                                [props.name]: props.valueParser(
                                    props.propsValue.toString()
                                ),
                            });
                        }}
                    /> */}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

export default EditableStationFormField;
