import { ThunkAction, ThunkDispatch } from "redux-thunk";
import settings from "../../settings";
import { AuthenticationError } from "../../types/Authentication";
import { clearAllocationsAfterLogout } from "../allocations/actions";
import { AllocationActionType } from "../allocations/types";
import { clearCampaignsState } from "../campaign/actions";
import { CampaignsActionType } from "../campaign/types";
import { fetchAuth, rootState } from "../rootReducer";
import { clearState } from "../stations/actions";
import { StationsActionTypes } from "../stations/types";
import {
    RECEIVE_LOGIN,
    RECEIVE_LOGOUT,
    REQUEST_LOGIN,
    REQUEST_LOGOUT,
    SET_ERROR,
    UsersActionTypes,
} from "./types";

function requestLogin(): UsersActionTypes {
    return {
        type: REQUEST_LOGIN,
    };
}

export function setAuthError(error: AuthenticationError): UsersActionTypes {
    return {
        type: SET_ERROR,
        payload: error,
    };
}

export function requestLogout(): UsersActionTypes {
    return {
        type: REQUEST_LOGOUT,
    };
}

export function setUserLoggedOut(): UsersActionTypes {
    return {
        type: RECEIVE_LOGOUT,
    };
}

export function loginUser(
    target: HTMLFormElement
): ThunkAction<Promise<void>, {}, {}, UsersActionTypes> {
    return async (
        dispatch: ThunkDispatch<{}, {}, UsersActionTypes>
    ): Promise<void> => {
        dispatch(setAuthError(AuthenticationError.NONE));

        const formData: FormData = new FormData(target);
        formData.append("client_id", "ism");
        formData.append("client_secret", "ism");
        formData.append("grant_type", "password");

        dispatch(requestLogin());

        try {
            const data = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.OAUTH_TOKEN,
                {
                    method: "POST",
                    body: formData,
                }
            );

            dispatch({
                type: RECEIVE_LOGIN,
                payload: {
                    authentication: data,
                    username: formData.get("username") as string,
                },
            });
        } catch (e) {
            console.log(e.message);
            dispatch(setAuthError(AuthenticationError.SERVER_ERROR));
            return;
        }
    };
}

export function logoutUser(): ThunkAction<
    Promise<void>,
    {},
    {},
    UsersActionTypes | StationsActionTypes
> {
    return async (
        dispatch: ThunkDispatch<
            {},
            {},
            | UsersActionTypes
            | StationsActionTypes
            | AllocationActionType
            | CampaignsActionType
        >,
        getState: () => {}
    ): Promise<void> => {
        const state: rootState = getState() as rootState;

        dispatch(requestLogout());

        try {
            if (
                state.usersReducer.user === null ||
                !state.usersReducer.loggedIn
            ) {
                dispatch(clearState());
                dispatch(clearAllocationsAfterLogout());
                dispatch(clearCampaignsState());
                dispatch(setUserLoggedOut());
                return;
            }

            await fetch(
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.LOGOUT,
                {
                    method: "DELETE",
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                    },
                }
            );

            dispatch(clearState());
            dispatch(clearAllocationsAfterLogout());
            dispatch(clearCampaignsState());
            dispatch(setUserLoggedOut());
        } catch (e) {
            dispatch(clearState());
            dispatch(clearAllocationsAfterLogout());
            dispatch(clearCampaignsState());
            dispatch(setUserLoggedOut());
            return;
        }
    };
}
