import {
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Layer,
    ResponsiveContext,
    Text,
    TextInput,
} from "grommet";
import { Close, LinkPrevious, Save } from "grommet-icons";
import React, { useState } from "react";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import { connect } from "react-redux";
import { createNewCustomer } from "../../store/customers/actions";
import { rootState } from "../../store/rootReducer";
import { User, UserRole } from "../../types/Authentication";
import {
    CreateCustomerRequest,
    NewCustomerRequestStatus,
} from "../../types/Customer";

interface NewCustomerFormProps {
    isFetchingCustomer: boolean;
    putCustomerStatus: NewCustomerRequestStatus | null;
    user: User | null;
    closeLayer: (bool: boolean) => void;
    createNewCustomer: (newCustomer: CreateCustomerRequest) => any;
    //   putCampaign: (campaign: Campaign) => void;
    //   getStationsById: (stationIds: number[]) => void;
    //   getAllocationsForStationsByDate: (parameter: {
    //     stationIds: number[];
    //     end: number;
    //     start: number;
    //   }) => void;
    //   setPutCampaignRequestStatus: (status: NewCampaignRequestStatus) => void;
    //   setScreenStations: (stations: Station[]) => void;
    //   setStationOfCurrentCampaign: (stations: Station[]) => void;
}

const NewCustomerForm: React.FC<NewCustomerFormProps> = (props) => {
    const [newCustomer, setNewCustomer] = useState<CreateCustomerRequest>({
        companyName: "",
        name: "",
        city: "",
        street: "",
        phone: "",
        email: "",
        zip: "",
    });
    const [confirmUpdateWindow, setConfirmUpdateWindow] = useState(false);

    const userDisabled =
        props.user &&
        (props.user.authentication.role === UserRole.ADMIN ||
            props.user.authentication.role === UserRole.VIEW_ONLY)
            ? true
            : false;

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    pad="small"
                    width={"90%"}
                    justify="center"
                    align="center"
                    fill="horizontal"
                    overflow="scroll"
                    style={{ display: "block" }}
                >
                    <Box margin="medium" justify="center" align="center">
                        <Heading level={3}>Neuen Kunden anlegen</Heading>
                        <Form
                            onSubmit={() => {
                                console.log(newCustomer);
                                setConfirmUpdateWindow(true);
                                props.createNewCustomer({
                                    ...newCustomer,
                                });
                            }}
                            value={newCustomer}
                            style={{ width: "90%" }}
                        >
                            <FormField
                                name="companyName"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                error={
                                    newCustomer.companyName !== ""
                                        ? ""
                                        : "Angabe wird benötigt"
                                }
                                required={true}
                            >
                                <TextInput
                                    name="companyName"
                                    placeholder="Firma"
                                    value={newCustomer.companyName}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            companyName: event.target.value,
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                name="name"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="name"
                                    placeholder="Ansprechpartner"
                                    value={newCustomer.name}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            name: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>
                            <FormField
                                name="phone"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="phone"
                                    placeholder="Tel."
                                    value={newCustomer.phone}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            phone: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>
                            <FormField
                                name="email"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="email"
                                    placeholder="E-Mail"
                                    value={newCustomer.email}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            email: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>
                            <FormField
                                name="street"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="street"
                                    placeholder="Straße"
                                    value={newCustomer.street}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            street: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>
                            <FormField
                                name="zip"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="zip"
                                    placeholder="PLZ"
                                    value={newCustomer.zip}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            zip: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>
                            <FormField
                                name="city"
                                margin={{ bottom: "medium" }}
                                style={{ minHeight: "60px" }}
                                required={false}
                            >
                                <TextInput
                                    name="city"
                                    placeholder="Stadt"
                                    value={newCustomer.city}
                                    onChange={(event) =>
                                        setNewCustomer({
                                            ...newCustomer,
                                            city: event.target.value,
                                        })
                                    }
                                    required={false}
                                />
                            </FormField>

                            <Button
                                disabled={newCustomer.companyName === ""}
                                label="Speichern"
                                primary
                                type="submit"
                                margin={{ vertical: "small" }}
                                icon={<Save color="white" />}
                                color="success"
                                onClick={() => console.log(newCustomer)}
                            />

                            <Button
                                label="Abbrechen"
                                color="danger"
                                primary
                                icon={<Close color="white" />}
                                fill="horizontal"
                                margin={{ vertical: "small" }}
                                onClick={() => props.closeLayer(false)}
                            />
                        </Form>
                    </Box>
                    {confirmUpdateWindow &&
                        (props.putCustomerStatus !==
                            NewCustomerRequestStatus.WAITING ||
                            props.isFetchingCustomer) && (
                            <Layer
                                onEsc={() => {
                                    setConfirmUpdateWindow(false);
                                    // props.setPutCampaignRequestStatus(
                                    //     NewCampaignRequestStatus.NONE
                                    // );
                                }}
                                onClickOutside={() => {
                                    setConfirmUpdateWindow(false);
                                    // props.setPutCampaignRequestStatus(
                                    //     NewCampaignRequestStatus.NONE
                                    // );
                                }}
                                full={false}
                            >
                                <Box
                                    pad="large"
                                    align="center"
                                    justify="center"
                                >
                                    {props.putCustomerStatus ===
                                        NewCustomerRequestStatus.SUCCESS && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                margin={{ vertical: "medium" }}
                                                textAlign="center"
                                            >
                                                Kunde wurde erfolgreich
                                                angelegt.
                                            </Text>
                                            <Box
                                                align="center"
                                                justify="center"
                                            >
                                                <Button
                                                    label="Zur Kundenübersicht"
                                                    icon={<LinkPrevious />}
                                                    onClick={() => {
                                                        props.closeLayer &&
                                                            props.closeLayer(
                                                                false
                                                            );
                                                        // props.setPutCampaignRequestStatus(
                                                        //     NewCampaignRequestStatus.NONE
                                                        // );
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {props.putCustomerStatus ===
                                        NewCustomerRequestStatus.ERROR && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                color="danger"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Das sollte nicht passieren!
                                            </Text>
                                            <Text
                                                textAlign="center"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Ein Fehler ist aufgetreten.
                                                Bitte versuchen Sie es erneuet
                                                oder kontaktieren Sie unseren
                                                Service.
                                            </Text>

                                            <Button
                                                label="Zurück zur Kampagne"
                                                icon={<LinkPrevious />}
                                                onClick={() => {
                                                    setConfirmUpdateWindow(
                                                        false
                                                    );
                                                    // props.setPutCampaignRequestStatus(
                                                    //     NewCampaignRequestStatus.NONE
                                                    // );
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {props.isFetchingCustomer && (
                                        <div className="loadingio-spinner-spinner-g73zrpl2wr5">
                                            <div className="ldio-xie7sipo009">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            </Layer>
                        )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        // customer: state.customerReducer.currentCustomer,
        isFetchingCustomer: state.customerReducer.isFetching,
        putCustomerStatus: state.customerReducer.newCustomerRequestStatus,
        user: state.usersReducer.user,
    };
};

const mapDispatchToProps = {
    createNewCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerForm);
