import { rootState } from './rootReducer';

export const loadState = (): rootState | undefined => {
    try {
        const serializedState = localStorage.getItem('ism_resourcer_state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: rootState) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('ism_resourcer_state', serializedState);
    } catch {
        // ignore write errors
    }
};