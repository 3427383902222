import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import settings from '../../settings';
import { fetchAuth, rootState } from '../rootReducer';
import { logoutUser } from '../user/actions';
import { AllocationActionType, CLEAR_ALLOCATIONS_AFTER_LOGOUT, RECEIVE_ALLOCATIONS, RECEIVE_ALLOCATIONS_FOR_MULTIPLE, REQUEST_ALLOCATIONS } from './types';


function fetchAllocations(): AllocationActionType {
    return {
        type: REQUEST_ALLOCATIONS
    }
}

export function clearAllocationsAfterLogout(): AllocationActionType {
    return {
        type: CLEAR_ALLOCATIONS_AFTER_LOGOUT
    }
}

export function getAllocationsForStationByDate(parameter: { stationId: number, start: number, end: number }): ThunkAction<Promise<void>, {}, {}, AllocationActionType> {
    return async (dispatch: ThunkDispatch<{}, {}, AllocationActionType>, getState: () => {}): Promise<void> => {
        dispatch(fetchAllocations())

        const state: rootState = getState() as rootState

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser())
            return
        };

        const response = await fetchAuth(dispatch, settings.API.BASE_URL + settings.API.API_PATH + settings.API_END_POINTS.ALLOCATIONS_BY_ID_AND_DATE + '?stationId=' + parameter.stationId + '&start=' + parameter.start + '&end=' + parameter.end, {
            headers: {
                'Authorization': 'Bearer ' + state.usersReducer.user.authentication.access_token
            }
        }
        );

        dispatch({
            type: RECEIVE_ALLOCATIONS,
            payload: {
                allocations: response,
                stationId: parameter.stationId
            }
        })
    }
}

export function getAllocationsForStationsByDate(parameter: { stationIds: number[], start: number, end: number }): ThunkAction<Promise<void>, {}, {}, AllocationActionType> {
    return async (dispatch: ThunkDispatch<{}, {}, AllocationActionType>, getState: () => {}): Promise<void> => {
        dispatch(fetchAllocations())

        const state: rootState = getState() as rootState

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser())
            return
        };

        const response = await fetchAuth(dispatch, settings.API.BASE_URL + settings.API.API_PATH + settings.API_END_POINTS.ALLOCATIONS_BY_IDS_AND_DATE + '?' + parameter.stationIds.map(id => 'stationIds=' + id).join('&') + '&start=' + parameter.start + '&end=' + parameter.end, {
            headers: {
                'Authorization': 'Bearer ' + state.usersReducer.user.authentication.access_token
            }
        });

        dispatch({
            type: RECEIVE_ALLOCATIONS_FOR_MULTIPLE,
            payload: response
        })
    }
}
