import { AuthenticationError } from "../../types/Authentication";
import {
    RECEIVE_LOGIN,
    RECEIVE_LOGOUT,
    REQUEST_LOGIN,
    REQUEST_LOGOUT,
    SET_ERROR,
    UsersActionTypes,
    UserState
} from "./types";

const initialState: UserState = {
    user: null,
    loggedIn: false,
    error: AuthenticationError.NONE,
    fetchingUserAction: false,
    lastAction: 0,
    storeVersion: "0.0.2"
};

export function usersReducer(
    state = initialState,
    action: UsersActionTypes
): UserState {
    switch (action.type) {
        case REQUEST_LOGIN:
            return {
                ...state,
                fetchingUserAction: true
            };
        case RECEIVE_LOGIN:
            return {
                ...state,
                user: {
                    authentication: action.payload.authentication,
                    username: action.payload.username
                },
                loggedIn: true,
                fetchingUserAction: false
            };
        case REQUEST_LOGOUT:
            return {
                ...state,
                fetchingUserAction: true
            };
        case RECEIVE_LOGOUT:
            return {
                ...state,
                loggedIn: false,
                fetchingUserAction: false,
                user: null
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchingUserAction: false
            };
        default:
            return state;
    }
}
