import dayjs from "dayjs";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import settings from "../../settings";
import Campaign, {
    CampaignDuration,
    CampaignsResponse,
    NewCampaignRequestStatus,
    NewCampaignResponse,
    PartialNewCampaign,
} from "../../types/Campaign";
import Station from "../../types/Station";
import { fetchAuth, rootState } from "../rootReducer";
import { findStationsByRange } from "../stations/actions";
import { logoutUser } from "../user/actions";
import {
    ADD_STATIONS_TO_CURRENT_CAMPAIGN,
    ADD_STATIONS_TO_NEW_CAMPAIGN,
    ADD_STATION_TO_CURRENT_CAMPAIGN,
    ADD_STATION_TO_NEW_CAMPAIGN,
    CampaignsActionType,
    CLEAR_CAMPAIGN_AFTER_LOGOUT,
    POST_NEW_CAMPAIGN,
    RECEIVE_APPROVE_CAMPAIGN,
    RECEIVE_CAMPAIGNS,
    RECEIVE_CAMPAIGNS_BY_STATION,
    RECEIVE_DELETE_CAMPAIGN,
    RECEIVE_PUT_CAMPAIGN,
    REMOVE_STATION_FROM_CURRENT_CAMPAIGN,
    REMOVE_STATION_TO_NEW_CAMPAIGN,
    REQUEST_APPROVE_CAMPAIGN,
    REQUEST_CAMPAIGNS,
    REQUEST_CAMPAIGNS_BY_STATION,
    REQUEST_DELETE_CAMPAIGN,
    REQUEST_NEW_CAMPAIGN,
    REQUEST_PUT_CAMPAIGN,
    SET_FILTER_CONTRACT_DURATION,
    SET_FILTER_FROM_DATE,
    SET_FILTER_SLOT_WEIGHT,
    SET_NEW_CAMPAIGN_REQUEST_STATUS,
    SET_PUT_CAMPAIGN_REQUEST_STATUS,
    SET_STATIONS_FOR_NEW_CAMPAIGN,
    SET_STATION_FOR_CURRENT_CAMPAIGN,
} from "./types";

function fetchCampaigns(): CampaignsActionType {
    return {
        type: REQUEST_CAMPAIGNS,
    };
}

function fetchCampaignsByStation(): CampaignsActionType {
    return {
        type: REQUEST_CAMPAIGNS_BY_STATION,
    };
}

function requestDeleteCampaign(): CampaignsActionType {
    return {
        type: REQUEST_DELETE_CAMPAIGN,
    };
}

function requestPutCampaign(): CampaignsActionType {
    return {
        type: REQUEST_PUT_CAMPAIGN,
    };
}

function requestNewCampaign(): CampaignsActionType {
    return {
        type: REQUEST_NEW_CAMPAIGN,
    };
}

export function setNewCampaignRequestStatus(
    status: NewCampaignRequestStatus
): CampaignsActionType {
    return {
        type: SET_NEW_CAMPAIGN_REQUEST_STATUS,
        payload: status,
    };
}

export function setPutCampaignRequestStatus(
    status: NewCampaignRequestStatus
): CampaignsActionType {
    return {
        type: SET_PUT_CAMPAIGN_REQUEST_STATUS,
        payload: status,
    };
}

function requestApproveCampaign(): CampaignsActionType {
    return {
        type: REQUEST_APPROVE_CAMPAIGN,
    };
}

export function clearCampaignsState(): CampaignsActionType {
    return {
        type: CLEAR_CAMPAIGN_AFTER_LOGOUT,
    };
}

export function addStationForNewCampaign(
    station: Station
): CampaignsActionType {
    return {
        type: ADD_STATION_TO_NEW_CAMPAIGN,
        payload: station,
    };
}

export function addStationsForNewCampaign(
    stations: Station[]
): CampaignsActionType {
    return {
        type: ADD_STATIONS_TO_NEW_CAMPAIGN,
        payload: stations,
    };
}

export function removeStationForNewCampaign(
    stationId: Station
): CampaignsActionType {
    return {
        type: REMOVE_STATION_TO_NEW_CAMPAIGN,
        payload: stationId,
    };
}

export function setStationOfNewCampaign(
    stations: Station[]
): CampaignsActionType {
    return {
        type: SET_STATIONS_FOR_NEW_CAMPAIGN,
        payload: stations,
    };
}

export function setStationOfCurrentCampaign(
    stations: Station[]
): CampaignsActionType {
    return {
        type: SET_STATION_FOR_CURRENT_CAMPAIGN,
        payload: stations,
    };
}

export function addStationToCurrentCampaign(
    station: Station
): CampaignsActionType {
    return {
        type: ADD_STATION_TO_CURRENT_CAMPAIGN,
        payload: station,
    };
}

export function addStationsToCurrentCampaign(
    stations: Station[]
): CampaignsActionType {
    return {
        type: ADD_STATIONS_TO_CURRENT_CAMPAIGN,
        payload: stations,
    };
}

export function removeStationFromCurrentCampaign(
    station: Station
): CampaignsActionType {
    return {
        type: REMOVE_STATION_FROM_CURRENT_CAMPAIGN,
        payload: station,
    };
}

export function setFilterDateFrom(fromDate: dayjs.Dayjs): CampaignsActionType {
    return {
        type: SET_FILTER_FROM_DATE,
        payload: fromDate,
    };
}

export function setFilterContractDuration(
    campaignDuration: CampaignDuration
): CampaignsActionType {
    return {
        type: SET_FILTER_CONTRACT_DURATION,
        payload: campaignDuration,
    };
}

export function setFilterSlotWeight(slotWeight: number): CampaignsActionType {
    return {
        type: SET_FILTER_SLOT_WEIGHT,
        payload: slotWeight,
    };
}

export function getCampaigns(): ThunkAction<
    Promise<void>,
    {},
    {},
    CampaignsActionType
> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(fetchCampaigns());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        const response: CampaignsResponse = await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.CAMPAIGNS +
                "?size=1000",
            {
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        dispatch({
            type: RECEIVE_CAMPAIGNS,
            payload: response.content,
        });
    };
}

export function getCampaignsByStation(
    stationId: number
): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(fetchCampaignsByStation());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        const response: CampaignsResponse = await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.CAMPAIGNS +
                "?size=1000&station=" +
                stationId,
            {
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        dispatch({
            type: RECEIVE_CAMPAIGNS_BY_STATION,
            payload: {
                stationId: stationId,
                campaigns: response.content,
            },
        });
    };
}

export function deleteCampaign(
    campaignId: number
): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(requestDeleteCampaign());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.CAMPAIGNS +
                "/" +
                campaignId,
            {
                method: "DELETE",
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        dispatch({
            type: RECEIVE_DELETE_CAMPAIGN,
        });

        dispatch(getCampaigns());
    };
}

export function putCampaign(
    parameter: Campaign
): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(requestPutCampaign());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            console.log(parameter.status);
            await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.CAMPAIGNS +
                    "/" +
                    parameter.id,
                {
                    method: "PUT",
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        start: parameter.start,
                        offerDate: parameter.offerDate || 0,
                        spotCreationByCustomer:
                            parameter.spotCreationByCustomer || false,
                        allocations: parameter.allocations,
                        name: parameter.name,
                        duration: parameter.duration,
                        bonusDuration: parameter.bonusDuration,
                        pricePerMonth: parameter.pricePerMonth,
                        salesRep: parameter.salesRep,
                        slotWeight: parameter.slotWeight,
                        paymentInterval: parameter.paymentInterval,
                        customerId: parameter.customer.id,
                        status: parameter.status,
                        terminationDate: parameter.terminationDate,
                    } as PartialNewCampaign),
                }
            );

            // dispatch(findStationsByRange('', '' + state.stationsReducer.currentRange, true, state.stationsReducer.currentCenter));

            // dispatch(clearCampaignsState())

            dispatch(getCampaigns());

            dispatch({
                type: RECEIVE_PUT_CAMPAIGN,
                payload: NewCampaignRequestStatus.SUCCESS,
            });
        } catch (e) {
            dispatch(
                setPutCampaignRequestStatus(NewCampaignRequestStatus.FAILURE)
            );
            return;
        }
    };
}

export function approveCampaign(
    campaign: Campaign,
    approvedAllocationIds: number[]
): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(requestApproveCampaign());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        await fetchAuth(
            dispatch,
            settings.API.BASE_URL +
                settings.API.API_PATH +
                settings.API_END_POINTS.CAMPAIGNS +
                "/" +
                campaign.id +
                "/approve?" +
                approvedAllocationIds
                    .map((id) => "allocationIds=" + id)
                    .join("&"),
            {
                method: "PUT",
                headers: {
                    Authorization:
                        "Bearer " +
                        state.usersReducer.user.authentication.access_token,
                },
            }
        );

        dispatch({
            type: RECEIVE_APPROVE_CAMPAIGN,
        });

        dispatch(getCampaigns());
    };
}

export function createNewCampaign(
    parameter: PartialNewCampaign
): ThunkAction<Promise<void>, {}, {}, CampaignsActionType> {
    return async (
        dispatch: ThunkDispatch<{}, {}, CampaignsActionType>,
        getState: () => {}
    ): Promise<void> => {
        dispatch(requestNewCampaign());

        const state: rootState = getState() as rootState;

        if (state.usersReducer.user === null || !state.usersReducer.loggedIn) {
            dispatch(logoutUser());
            return;
        }

        try {
            const response: NewCampaignResponse = await fetchAuth(
                dispatch,
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.CAMPAIGNS,
                {
                    method: "POST",
                    headers: {
                        Authorization:
                            "Bearer " +
                            state.usersReducer.user.authentication.access_token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...parameter,
                    }),
                }
            );

            dispatch(
                findStationsByRange(
                    "",
                    "" + state.stationsReducer.currentRange,
                    true,
                    state.stationsReducer.currentCenter
                )
            );

            dispatch(clearCampaignsState());

            dispatch(
                setNewCampaignRequestStatus(NewCampaignRequestStatus.SUCCESS)
            );

            dispatch({
                type: POST_NEW_CAMPAIGN,
                payload: response,
            });
        } catch (e) {
            dispatch(
                setNewCampaignRequestStatus(NewCampaignRequestStatus.FAILURE)
            );
            return;
        }
    };
}
