import dayjs from 'dayjs';
import { throttle } from 'lodash';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import { loadState, saveState } from './store/localStorage';
import { rootReducer } from './store/rootReducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

let store;

if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const localStorage = loadState()

    store = localStorage !== undefined
        && localStorage.usersReducer.lastAction >= dayjs().subtract(6, 'hour').valueOf()
        && localStorage.usersReducer.storeVersion === '0.0.2'
        ? createStore(
            rootReducer,
            localStorage,
            composeEnhancers(
                applyMiddleware(
                    ReduxThunk,
                    logger
                )
            )
        ) : createStore(
            rootReducer,
            composeEnhancers(
                applyMiddleware(
                    ReduxThunk,
                    logger
                )
            )
        )


    store.subscribe(throttle(() => {
        saveState({
            ...store.getState(),
            usersReducer: {
                ...store.getState().usersReducer,
                lastAction: dayjs().valueOf()
            }
        });
    }, 1000));

} else {

    const localStorage = loadState()

    store = localStorage !== undefined
        && localStorage.usersReducer.lastAction >= dayjs().subtract(6, 'hour').valueOf()
        ? createStore(
            rootReducer,
            localStorage,
            compose(
                applyMiddleware(
                    ReduxThunk,
                )
            )
        ) : createStore(
            rootReducer,
            compose(
                applyMiddleware(
                    ReduxThunk,
                )
            )
        );


    store.subscribe(throttle(() => {
        saveState({
            ...store.getState(),
            usersReducer: {
                ...store.getState().usersReducer,
                lastAction: dayjs().valueOf()
            }
        });
    }, 1000));

}


export default store;