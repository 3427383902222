import dayjs from "dayjs";
import {
    CampaignDuration,
    NewCampaignRequestStatus,
} from "../../types/Campaign";
import Station from "../../types/Station";
import {
    ADD_STATIONS_TO_NEW_CAMPAIGN,
    ADD_STATION_TO_CURRENT_CAMPAIGN,
    ADD_STATION_TO_NEW_CAMPAIGN,
    CampaignsActionType,
    CampaignState,
    CLEAR_CAMPAIGN_AFTER_LOGOUT,
    RECEIVE_CAMPAIGNS,
    RECEIVE_CAMPAIGNS_BY_STATION,
    RECEIVE_PUT_CAMPAIGN,
    REMOVE_STATION_FROM_CURRENT_CAMPAIGN,
    REMOVE_STATION_TO_NEW_CAMPAIGN,
    REQUEST_CAMPAIGNS,
    REQUEST_CAMPAIGNS_BY_STATION,
    REQUEST_NEW_CAMPAIGN,
    REQUEST_PUT_CAMPAIGN,
    SET_FILTER_CONTRACT_DURATION,
    SET_FILTER_FROM_DATE,
    SET_FILTER_SLOT_WEIGHT,
    SET_NEW_CAMPAIGN_REQUEST_STATUS,
    SET_PUT_CAMPAIGN_REQUEST_STATUS,
    SET_STATIONS_FOR_NEW_CAMPAIGN,
    SET_STATION_FOR_CURRENT_CAMPAIGN,
} from "./types";

const initialState: CampaignState = {
    campaigns: [],
    campaignsForStation: {
        stationId: null,
        campaigns: [],
    },
    stationsForNewCampaign: [],
    stationsForCurrentCampaign: [],
    isFetching: false,
    filterDateFrom: dayjs(),
    filterContractDuration: CampaignDuration.TWELVE_MONTHS,
    filterSlotWeight: 2,
    newCampaignRequestStatus: NewCampaignRequestStatus.NONE,
    putCampaignRequestStatus: NewCampaignRequestStatus.NONE,
};

export function campaignReducer(
    state = initialState,
    action: CampaignsActionType
): CampaignState {
    switch (action.type) {
        case RECEIVE_CAMPAIGNS:
            return {
                ...state,
                isFetching: false,
                campaigns: action.payload,
            };
        case REQUEST_CAMPAIGNS:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_CAMPAIGNS_BY_STATION:
            return {
                ...state,
                isFetching: false,
                campaignsForStation: action.payload,
            };
        case REQUEST_CAMPAIGNS_BY_STATION:
            return {
                ...state,
                isFetching: true,
            };
        case REQUEST_NEW_CAMPAIGN:
            return {
                ...state,
                isFetching: true,
            };
        case REQUEST_PUT_CAMPAIGN:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_PUT_CAMPAIGN:
            return {
                ...state,
                isFetching: false,
                putCampaignRequestStatus: action.payload,
            };
        case ADD_STATION_TO_NEW_CAMPAIGN:
            return {
                ...state,
                stationsForNewCampaign: [
                    ...state.stationsForNewCampaign.filter(
                        (station) => station.shellId !== action.payload.shellId
                    ),
                    action.payload,
                ],
            };
        case ADD_STATIONS_TO_NEW_CAMPAIGN:
            return {
                ...state,
                stationsForNewCampaign: Array.from(
                    new Set<Station>([
                        ...state.stationsForNewCampaign,
                        ...action.payload,
                    ])
                ),
            };
        case REMOVE_STATION_TO_NEW_CAMPAIGN:
            return {
                ...state,
                stationsForNewCampaign: state.stationsForNewCampaign.filter(
                    (station) => station.shellId !== action.payload.shellId
                ),
            };
        case ADD_STATION_TO_CURRENT_CAMPAIGN:
            return {
                ...state,
                stationsForCurrentCampaign: [
                    ...state.stationsForCurrentCampaign,
                    action.payload,
                ],
            };
        case REMOVE_STATION_FROM_CURRENT_CAMPAIGN:
            return {
                ...state,
                stationsForCurrentCampaign: state.stationsForCurrentCampaign.filter(
                    (station) => station.shellId !== action.payload.shellId
                ),
            };
        case SET_STATION_FOR_CURRENT_CAMPAIGN:
            return {
                ...state,
                stationsForCurrentCampaign: action.payload,
            };
        case SET_STATIONS_FOR_NEW_CAMPAIGN:
            return {
                ...state,
                stationsForNewCampaign: action.payload,
            };
        case SET_FILTER_FROM_DATE:
            return {
                ...state,
                filterDateFrom: action.payload,
            };
        case SET_FILTER_CONTRACT_DURATION:
            return {
                ...state,
                filterContractDuration: action.payload,
            };
        case SET_FILTER_SLOT_WEIGHT:
            return {
                ...state,
                filterSlotWeight: action.payload,
            };
        case SET_NEW_CAMPAIGN_REQUEST_STATUS:
            return {
                ...state,
                newCampaignRequestStatus: action.payload,
                isFetching: false,
            };
        case SET_PUT_CAMPAIGN_REQUEST_STATUS:
            return {
                ...state,
                putCampaignRequestStatus: action.payload,
                isFetching: false,
            };
        case CLEAR_CAMPAIGN_AFTER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
