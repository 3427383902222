import { AllocationActionType, AllocationState, CLEAR_ALLOCATIONS_AFTER_LOGOUT, RECEIVE_ALLOCATIONS, RECEIVE_ALLOCATIONS_FOR_MULTIPLE, REQUEST_ALLOCATIONS } from './types';

const initialState: AllocationState = {
    allocations: [],
    isFetching: false,
    allocationsForIds: []
}

export function allocationReducer(
    state = initialState,
    action: AllocationActionType
): AllocationState {
    switch (action.type) {
        case RECEIVE_ALLOCATIONS:
            return {
                ...state,
                isFetching: false,
                allocations: {
                    ...state.allocations,
                    [action.payload.stationId]: action.payload.allocations
                }
            }
        case RECEIVE_ALLOCATIONS_FOR_MULTIPLE:
            return {
                ...state,
                isFetching: false,
                allocationsForIds: action.payload
            }
        case REQUEST_ALLOCATIONS:
            return {
                ...state,
                isFetching: true
            }
        case CLEAR_ALLOCATIONS_AFTER_LOGOUT:
            return initialState;
        default:
            return state
    }
}