import {
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Layer,
    ResponsiveContext,
    Text,
} from "grommet";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { rootState } from "../../store/rootReducer";
import { loginUser, setAuthError } from "../../store/user/actions";
import { UserState } from "../../store/user/types";
import { AuthenticationError } from "../../types/Authentication";

interface LoginProps {
    userState: UserState;
    loginUser: any;
    setAuthError: (error: AuthenticationError) => any;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        const target = event.target as HTMLFormElement;

        props.loginUser(target);
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box justify="center" direction="column" align="center">
                    <Heading level={2}>Login</Heading>
                    {props.userState.loggedIn && (
                        <Text size="large">
                            Sie haben sich erfolgreich angemeldet.
                            <Redirect to="/map" />
                        </Text>
                    )}
                    {!props.userState.loggedIn && (
                        <Box
                            background="white"
                            pad="medium"
                            margin={{ top: "medium" }}
                            width={size === "small" ? "100%" : "20rem"}
                        >
                            {props.userState.error ===
                                AuthenticationError.WRONG_CREDENTIALS && (
                                <Text
                                    color="danger"
                                    margin={{ bottom: "medium" }}
                                >
                                    Nutzername oder Passwort ist falsch.
                                </Text>
                            )}
                            {props.userState.error ===
                                AuthenticationError.SERVER_ERROR && (
                                <Layer
                                    onEsc={() =>
                                        props.setAuthError(
                                            AuthenticationError.NONE
                                        )
                                    }
                                    onClickOutside={() =>
                                        props.setAuthError(
                                            AuthenticationError.NONE
                                        )
                                    }
                                >
                                    <Text
                                        color="danger"
                                        margin={{ bottom: "medium" }}
                                    >
                                        Da lief etwas schief.
                                    </Text>
                                </Layer>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <FormField
                                    name="username"
                                    label="Benutzername"
                                ></FormField>
                                <FormField
                                    name="password"
                                    label="Passwort"
                                    type="password"
                                ></FormField>
                                <Button
                                    type="submit"
                                    primary
                                    label="Anmelden"
                                    margin={{ top: "medium" }}
                                ></Button>
                            </Form>
                        </Box>
                    )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        userState: state.usersReducer,
    };
};

const mapDispatchToProps = {
    loginUser,
    setAuthError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
