import { AllocationsForId } from "../../types/Resource";

export interface AllocationState {
    allocations: any
    allocationsForIds: AllocationsForId[]
    isFetching: boolean
};

export const REQUEST_ALLOCATIONS = 'REQUEST_ALLOCATIONS';
export const RECEIVE_ALLOCATIONS = 'RECEIVE_ALLOCATIONS';
export const RECEIVE_ALLOCATIONS_FOR_MULTIPLE = 'RECEIVE_ALLOCATIONS_FOR_MULTIPLE';
export const CLEAR_ALLOCATIONS_AFTER_LOGOUT = 'CLEAR_ALLOCATIONS_AFTER_LOGOUT';
// export const IS_FETCHING_ALLOCATIONS = 'IS_FETCHING_ALLOCATIONS';

interface RequestAllocationsAction {
    type: typeof REQUEST_ALLOCATIONS
};

interface ClearStateAfterLogoutAction {
    type: typeof CLEAR_ALLOCATIONS_AFTER_LOGOUT
}

interface ReceiveAllocationsState {
    type: typeof RECEIVE_ALLOCATIONS
    payload: AllocationsForId
};

interface ReceiveAllocationsForMultipleAction {
    type: typeof RECEIVE_ALLOCATIONS_FOR_MULTIPLE
    payload: AllocationsForId[]
};

export type AllocationActionType = RequestAllocationsAction
    | ReceiveAllocationsState
    | ClearStateAfterLogoutAction
    | ReceiveAllocationsForMultipleAction;
