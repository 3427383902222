import { RequestStatus } from "../../types/Authentication";
import Buisness from "../../types/Businesses";
import { Location } from "../../types/Station";

export interface BusinessState {
    buisnesses: Buisness[] | null;
    isFetching: boolean;
    currentCenter: Location;
    currentRange: number;
    searchString: string;
    searchStringDisplay: string;
    locationSearch: any[] | null;
    placesNearbySearch: any[] | null;
    centerAddress: string | null;
    requestStatus: null | RequestStatus;
}

export const GET_BUSINESSES = "GET_BUSINESSES";
export const IS_FETCHING = "IS_FETCHING";
export const RECEIVE_BUSINESSES = "RECEIVE_BUSINESSES";
export const SET_CENTER = "SET_CENTER";
export const SET_RANGE = "SET_RANGE";
export const CLEAR_STATE_AFTER_LOGOUT = "CLEAR_STATE_AFTER_LOGOUT";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_SEARCH_STRING_DISPLAY = "SET_SEARCH_STRING_DISPLAY";
export const SET_CENTER_ADDRESS = "SET_CENTER_ADDRESS";
export const SET_CURRENT_SCREEN_STATIONS = "SET_CURRENT_SCREEN_STATIONS";

interface FetchBusinessesAction {
    type: typeof IS_FETCHING;
}

interface SetRangeAction {
    type: typeof SET_RANGE;
    payload: number;
}

interface SetCenterAction {
    type: typeof SET_CENTER;
    payload: Location;
}

interface GetBusinessesAction {
    type: typeof GET_BUSINESSES;
    payload: Buisness[];
}

interface SetSearchStringAction {
    type: typeof SET_SEARCH_STRING;
    payload: string;
}

interface SetSearchStringDisplayAction {
    type: typeof SET_SEARCH_STRING_DISPLAY;
    payload: string;
}

interface ClearStationState {
    type: typeof CLEAR_STATE_AFTER_LOGOUT;
}

interface SetCenterAddressAction {
    type: typeof SET_CENTER_ADDRESS;
    payload: string;
}

export type BusinessesActionTypes =
    | FetchBusinessesAction
    | SetRangeAction
    | GetBusinessesAction
    | SetCenterAction
    | SetSearchStringAction
    | SetSearchStringDisplayAction
    | ClearStationState
    | SetCenterAddressAction;
