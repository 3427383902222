import { AuthenticationError, User, UserAuthenticationResponse } from '../../types/Authentication';

export interface UserState {
    user: User | null
    loggedIn: boolean
    error: AuthenticationError
    fetchingUserAction: boolean
    lastAction: number
    storeVersion: string
};

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const SET_ERROR = 'SET_ERROR';

interface LoginAction {
    type: typeof REQUEST_LOGIN,
};

interface LogoutAction {
    type: typeof REQUEST_LOGOUT,
};

interface SetError {
    type: typeof SET_ERROR,
    payload: AuthenticationError
};

interface AuthenticateAction {
    type: typeof RECEIVE_LOGIN
    payload: {
        authentication: UserAuthenticationResponse
        username: string
    }
};

interface LoggedOutAction {
    type: typeof RECEIVE_LOGOUT
};

export type UsersActionTypes = LoginAction | AuthenticateAction | LogoutAction | LoggedOutAction | SetError;
