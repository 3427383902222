import dayjs from "dayjs";
import { Box, Heading, Text, TextArea } from "grommet";
import React from "react";
import { User } from "../../types/Authentication";
import Station, {
    IndoorScreenVersionToText,
    OutdoorScreenVersionToText,
    StationStatus,
    StationStatusToText,
} from "../../types/Station";

interface StationDetailsCostsViewProps {
    station: Station;
    user: User | null;
}

const StationDetailsCostsView: React.FC<StationDetailsCostsViewProps> = (
    props: StationDetailsCostsViewProps
) => {
    return (
        <Box pad="medium" direction="row" justify="between" fill={true}>
            <Box width="medium">
                <Heading level={3} size="small">
                    Vertrieb
                </Heading>
                <Box>
                    <Box>
                        <Text
                            style={{
                                fontWeight: "bold",
                                paddingBottom: "0.4rem",
                            }}
                        >
                            Vertrieb:
                        </Text>
                        <Text
                            style={{
                                paddingBottom: "1.4rem",
                            }}
                        >
                            {props.station.assignedTo}
                        </Text>
                    </Box>
                </Box>
                <Box>
                    <Text
                        style={{
                            fontWeight: "bold",
                            paddingBottom: "0.4rem",
                        }}
                    >
                        Status:
                    </Text>
                    <Text
                        style={{
                            paddingBottom: "1.4rem",
                        }}
                    >
                        {StationStatusToText(props.station.status) +
                            (props.station.plannedConstructionDate
                                ? " - " +
                                  (props.station.status ===
                                  StationStatus.CONSTRUCTED
                                      ? "seit "
                                      : "Livegang ") +
                                  dayjs(
                                      props.station.plannedConstructionDate
                                  ).format("DD.MM.YYYY")
                                : "")}
                    </Text>
                </Box>
            </Box>
            <Box width="medium">
                <Heading level={3} size="small">
                    Konfiguration
                </Heading>
                <Box>
                    <Text
                        style={{
                            fontWeight: "bold",
                            paddingBottom: "0.4rem",
                        }}
                    >
                        Monitor Außen:
                    </Text>
                    <Text
                        style={{
                            paddingBottom: "1.4rem",
                        }}
                    >
                        {OutdoorScreenVersionToText(
                            props.station.outdoorScreenVersion
                        )}
                    </Text>
                </Box>
                <Box>
                    <Text
                        style={{
                            fontWeight: "bold",
                            paddingBottom: "0.4rem",
                        }}
                    >
                        Monitor Innen:
                    </Text>
                    <Text
                        style={{
                            paddingBottom: "1.4rem",
                        }}
                    >
                        {IndoorScreenVersionToText(
                            props.station.indoorScreenVersion
                        )}
                    </Text>
                </Box>

                <Box
                    style={{
                        width: "100%",
                    }}
                >
                    <Text
                        style={{
                            fontWeight: "bold",
                            paddingBottom: "0.4rem",
                        }}
                    >
                        Sonstige Informationen:
                    </Text>
                    <Box height="small" fill="horizontal">
                        <TextArea
                            style={{
                                width: "100%",
                                overflow: "scroll",
                            }}
                            plain
                            resize={false}
                            disabled
                            value={"" + props.station.additionalInfo}
                            fill
                        />
                    </Box>
                </Box>
            </Box>
            <Box width="medium">
                <Heading level={3} size="small">
                    Kosten
                </Heading>

                <Box direction="row" justify="between" width="100%">
                    <Box>
                        <Box>
                            <Text
                                style={{
                                    fontWeight: "bold",
                                    paddingBottom: "0.4rem",
                                }}
                            >
                                Aufbaukosten:
                            </Text>
                            <Text
                                style={{
                                    paddingBottom: "1.4rem",
                                }}
                            >
                                {(
                                    (props.station.constructionCost || 0) +
                                    (props.station.smallElectronicsCost || 0) +
                                    (props.station.otherCosts || 0) +
                                    (props.station.outdoorScreenCost || 0) +
                                    (props.station.indoorScreenCost || 0) +
                                    (props.station.otherHardwareCost || 0) +
                                    (props.station.measurementFees || 0) +
                                    (props.station.approvalFees || 0)
                                ).toFixed(2) + " €" || "nicht hinterlegt"}
                            </Text>
                        </Box>

                        <Box>
                            <Text
                                style={{
                                    fontWeight: "bold",
                                    paddingBottom: "0.4rem",
                                }}
                            >
                                Aufbaukosten Angebot:
                            </Text>
                            <Text
                                style={{
                                    paddingBottom: "1.4rem",
                                }}
                            >
                                {props.station.constructionCostOffer ||
                                    "nicht hinterlegt"}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default StationDetailsCostsView;
