import dayjs from "dayjs";
import { Box, DataTable, Heading, Meter, Text } from "grommet";
import React from "react";
import {
    campaignToPercent,
    RowObject,
} from "../../screens/campaigns/Campaigns";
import Campaign, { CampaignDurationInt } from "../../types/Campaign";
import Station from "../../types/Station";

interface StationDetailsSalesViewProps {
    station: Station;
    campaigns: Campaign[];
}

const StationDetailsSalesView: React.FC<StationDetailsSalesViewProps> = (
    props: StationDetailsSalesViewProps
) => {
    const campaignObject = props.campaigns.reduce((object, campaign) => {
        return {
            ...object,
            [campaign.id]: {
                ...campaign,
                allocations: campaign.allocations.filter(
                    (allocation) => allocation.stationId === props.station.id
                ),
            },
        };
    }, {});

    const campaignToCampaignAndAllocationArray = (campaigns: Campaign[]) => {
        const wholeArray: RowObject[] = [];
        const now = dayjs();

        campaigns.forEach((campaign) => {
            campaign.allocations
                .filter(
                    (allocation) => allocation.stationId === props.station.id
                )
                .forEach((allocation) => {
                    const percentageCalc = () => {
                        const daysInAllocation = dayjs(allocation.end).diff(
                            dayjs(allocation.start),
                            "day"
                        );
                        const daysPassedInAllocation = now.diff(
                            dayjs(allocation.start),
                            "day"
                        );

                        if (
                            daysInAllocation <= 0 ||
                            daysPassedInAllocation <= 0
                        ) {
                            return 0;
                        }

                        return (
                            (daysPassedInAllocation / daysInAllocation) * 100
                        );
                    };

                    const percentage = percentageCalc();

                    wholeArray.push({
                        ...allocation,
                        name: campaign.name,
                        customerName: campaign.customer.companyName,
                        campaignIdString: "" + campaign.id,
                        start: allocation.start,
                        duration: campaign.duration,
                        percentage: percentage,
                        amountOfAllocations:
                            campaignObject[campaign.id].allocations.length,
                        background: "grey",
                        city: allocation.station.city,
                        shellId: allocation.station.shellId,
                        street: allocation.station.street,
                        zipCode: allocation.station.zipCode,
                        offerDate: campaign.offerDate,
                        spotCreatedByCustomer: campaign.spotCreationByCustomer,
                    });
                });
        });

        return wholeArray;
    };

    return (
        <Box>
            <Box
                border="bottom"
                pad="medium"
                // align="start"
                // height="300px"
                // style={{ minHeight: "300px" }}
            >
                <Heading level={3} size="small">
                    Kunden
                </Heading>
                <Box pad="small" overflow="scroll">
                    <DataTable
                        columns={[
                            {
                                property: "id",
                                header: undefined,
                                primary: true,
                                render: (rowObject: RowObject) => null,
                            },

                            {
                                property: "name",
                                header: "AB-Nr", //<Text>{(!searchObject || !searchObject.name) ? 'Auftragsnummer' : 'Suche: ' + searchObject.name}</Text>,
                                align: "start",
                                search: true,
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject && rowObject.name
                                                ? rowObject.name
                                                : campaignObject[
                                                      rowObject.campaignIdString
                                                  ].name}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "customerName",
                                header: "Kunde",
                                align: "start",
                                search: true,
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject && rowObject.customerName
                                                ? rowObject.customerName
                                                : campaignObject[
                                                      rowObject.campaignIdString
                                                  ].customerName}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "offerDate",
                                header: "AB-Datum",
                                align: "center",
                                render: (rowObject: RowObject) => {
                                    return (
                                        <Box pad="small">
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    dayjs(
                                                        rowObject.offerDate
                                                    ).format("DD.MM.YYYY")}
                                                {!rowObject.end &&
                                                    dayjs(
                                                        campaignObject[
                                                            rowObject
                                                                .campaignIdString
                                                        ].offerDate
                                                    ).format("DD.MM.YYYY")}
                                            </Text>
                                        </Box>
                                    );
                                },
                                sortable: true,
                            },
                            {
                                property: "start",
                                header: "Start",
                                align: "center",
                                render: (rowObject: RowObject) => {
                                    return (
                                        <Box pad="small">
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    dayjs(
                                                        rowObject.start
                                                    ).format("DD.MM.YYYY")}
                                                {!rowObject.end &&
                                                    dayjs(
                                                        campaignObject[
                                                            rowObject
                                                                .campaignIdString
                                                        ].start
                                                    ).format("DD.MM.YYYY")}
                                            </Text>
                                        </Box>
                                    );
                                },
                                sortable: true,
                            },
                            {
                                property: "duration",
                                header: "Laufzeit",
                                align: "center",
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject &&
                                                rowObject.end &&
                                                CampaignDurationInt[
                                                    rowObject.duration
                                                ]}
                                            {!rowObject.end &&
                                                CampaignDurationInt[
                                                    campaignObject[
                                                        rowObject
                                                            .campaignIdString
                                                    ].duration
                                                ]}{" "}
                                            Monate
                                        </Text>
                                    </Box>
                                ),
                                sortable: false,
                            },
                            // {
                            //     property: "status",
                            //     header: "Freigabe",
                            //     align: "center",
                            //     render: (rowObject: RowObject) => {
                            //         return (
                            //             <Box pad="small">
                            //                 <Text>
                            //                     {!rowObject.end &&
                            //                         (campaignObject[
                            //                             rowObject.campaignIdString
                            //                         ].status ===
                            //                         AllocationStatus.APPROVED
                            //                             ? "Freigegeben"
                            //                             : "Nicht freigegeben")}
                            //                     {rowObject &&
                            //                         rowObject.end &&
                            //                         (rowObject.status ===
                            //                         AllocationStatus.APPROVED
                            //                             ? "Freigegeben"
                            //                             : "Nicht freigegeben")}
                            //                 </Text>
                            //             </Box>
                            //         );
                            //     },
                            // },
                            {
                                property: "percentage",
                                header: "Status",
                                align: "center",
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        {!rowObject.end && (
                                            <Meter
                                                values={[
                                                    {
                                                        value: campaignToPercent(
                                                            campaignObject[
                                                                rowObject
                                                                    .campaignIdString
                                                            ]
                                                        ),
                                                        label: "sixty",
                                                    },
                                                ]}
                                                thickness="small"
                                                size="small"
                                            />
                                        )}
                                        {rowObject && rowObject.end && (
                                            <Meter
                                                values={[
                                                    {
                                                        value:
                                                            rowObject.percentage,
                                                        label: "sixty",
                                                    },
                                                ]}
                                                thickness="small"
                                                size="small"
                                            />
                                        )}
                                    </Box>
                                ),
                            },
                        ]}
                        data={campaignToCampaignAndAllocationArray(
                            props.campaigns
                        )}
                        sortable
                        resizeable={true}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default StationDetailsSalesView;
