import dayjs from "dayjs";
import {
    Box,
    Button,
    Calendar,
    CheckBox,
    DropButton,
    Form,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    ResponsiveContext,
    Select,
    Text,
} from "grommet";
import { Add, Close, LinkPrevious, Save, Trash, Undo } from "grommet-icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import { connect } from "react-redux";
import { getAllocationsForStationsByDate } from "../../store/allocations/actions";
import {
    addStationForNewCampaign,
    putCampaign,
    removeStationForNewCampaign,
    setPutCampaignRequestStatus,
    setStationOfCurrentCampaign,
} from "../../store/campaign/actions";
import { getCustomers } from "../../store/customers/actions";
import { rootState } from "../../store/rootReducer";
import {
    getStationsById,
    setScreenStations,
} from "../../store/stations/actions";
import { User, UserRole } from "../../types/Authentication";
import Campaign, {
    CampaignDuration,
    CampaignPaymentInterval,
    CampaignStatus,
    NewCampaignRequestStatus,
    SalesRepArray,
    slotWeightToPackage,
} from "../../types/Campaign";
import Customer from "../../types/Customer";
import Allocation, { AllocationsForId } from "../../types/Resource";
import Station from "../../types/Station";
import StationList from "../StationList/StationList";
import EditableFormField from "./EditableFormField";

interface CampaignDetailsProps {
    campaign: Campaign;
    customers: Customer[];
    isFetchingAllocations: boolean;
    isFetchingCampaigns: boolean;
    allocationsForStations: AllocationsForId[];
    stationsForCampaign: Station[];
    stationsForCurrentCampaign: Station[];
    stations: Station[];
    putAllocationsStatus: NewCampaignRequestStatus;
    user: User | null;
    closeLayer?: any;
    putCampaign: (campaign: Campaign) => void;
    getCustomers: () => any;
    getStationsById: (stationIds: number[]) => void;
    getAllocationsForStationsByDate: (parameter: {
        stationIds: number[];
        end: number;
        start: number;
    }) => void;
    setPutCampaignRequestStatus: (status: NewCampaignRequestStatus) => void;
    setScreenStations: (stations: Station[]) => void;
    setStationOfCurrentCampaign: (stations: Station[]) => void;
}

const CampaignDetails: React.FC<CampaignDetailsProps> = (props) => {
    const [currentCampaignState, setCurrentCampaignState] = useState(
        props.campaign
    );
    const [searchingCustomer, setSearchingCustomer] = useState<boolean>(false);
    const [searchingCustomerQuery, setSearchingCustomerQuery] =
        useState<string>("");
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
    const [closedCalendarWindow, setClosedCalendarWindow] = useState(true);
    const [closedDurationWindow, setClosedDurationWindow] = useState(true);
    const [allocationDistributionInvalid, setAllocationDistributionInvalid] =
        useState<Array<number>>([]);
    const [spotCreationByCustomer, setSpotCreationByCustomer] =
        useState<boolean>(false);
    const [closedAddStationWindow, setClosedAddStationWindow] = useState(true);
    const [closedABCalendarWindow, setClosedABCalendarWindow] = useState(true);
    const [confirmUpdateWindow, setConfirmUpdateWindow] = useState(false);
    const [openWindow, setOpenWindow] = useState<string>("");

    const campaignDurationToIntMap = {
        [CampaignDuration.TWO_MONTHS]: 2,
        [CampaignDuration.SIX_MONTHS]: 6,
        [CampaignDuration.TWELVE_MONTHS]: 12,
        [CampaignDuration.TWENTY_FOUR_MONTHS]: 24,
        [CampaignDuration.THIRTY_SIX_MONTHS]: 36,
    };

    const userDisabled =
        props.user &&
        props.user.authentication.role !== UserRole.ADMIN &&
        props.user.authentication.role !== UserRole.VIEW_ONLY
            ? true
            : false;

    useEffect(() => {
        props.getCustomers();
    }, []);

    useEffect(() => {
        const filterContentPartners = props.customers.filter(
            (c) =>
                c.companyName
                    .toLowerCase()
                    .indexOf(searchingCustomerQuery.toLowerCase()) >= 0
        );

        setTimeout(() => {
            setSearchingCustomer(false);
            setFilteredCustomers(filterContentPartners);
        }, 500);
    }, [searchingCustomer, searchingCustomerQuery]);

    useEffect(() => {
        setAllocationDistributionInvalid([]);
        const stationIds: number[] = props.campaign.allocations
            .map((allocation: Allocation) => {
                return allocation.stationId;
            })
            .filter((number, index, array) => array.indexOf(number) === index);

        props.getStationsById(stationIds);

        props.allocationsForStations &&
            props.allocationsForStations.forEach(
                (allocationsForId: AllocationsForId) => {
                    const allocationsNumberForStation =
                        allocationsForId.allocations.filter((allocation) => {
                            if (
                                allocation.campaignId ===
                                currentCampaignState.id
                            ) {
                                return false;
                            }

                            if (
                                allocation.start <=
                                    currentCampaignState.start &&
                                allocation.end >= currentCampaignState.start
                            ) {
                                return true;
                            }

                            const currentCampaignEnd = dayjs(
                                currentCampaignState.start
                            )
                                .add(
                                    campaignDurationToIntMap[
                                        currentCampaignState.duration
                                    ],
                                    "month"
                                )
                                .valueOf();

                            if (
                                allocation.start <= currentCampaignEnd &&
                                allocation.end >= currentCampaignEnd
                            ) {
                                return true;
                            }

                            if (
                                allocation.start >=
                                    currentCampaignState.start &&
                                allocation.end <= currentCampaignEnd
                            ) {
                                return true;
                            }

                            return false;
                        }).length;
                    if (allocationsNumberForStation === 5)
                        setAllocationDistributionInvalid([
                            ...allocationDistributionInvalid,
                            allocationsForId.stationId,
                        ]);
                }
            );
    }, [props.allocationsForStations]);

    const checkAllocationAvailability = (newCampaignState) => {
        setCurrentCampaignState({ ...newCampaignState });
        props.getAllocationsForStationsByDate({
            stationIds: newCampaignState.allocations.map(
                (allocation) => allocation.stationId
            ),
            start: newCampaignState.start,
            end: dayjs(newCampaignState.start)
                .add(
                    campaignDurationToIntMap[newCampaignState.duration],
                    "month"
                )
                .add(newCampaignState.bonusDuration, "month")
                .valueOf(),
        });
    };

    const handleAddStationsToCurrentCampaign = () => {
        setClosedAddStationWindow(true);

        setCurrentCampaignState({
            ...currentCampaignState,
            allocations: currentCampaignState.allocations.concat(
                props.stationsForCurrentCampaign.map((station) => {
                    const firstFreeSlot = station.freeSlotsInPeriod.find(
                        (slot) => slot.free >= currentCampaignState.slotWeight
                    );

                    if (!firstFreeSlot) throw "slots full";

                    return {
                        ...currentCampaignState.allocations[0],
                        stationId: station.id,
                        slotNumber: firstFreeSlot.slot,
                    };
                })
            ),
        });

        props.setScreenStations(
            props.stationsForCampaign.concat(
                props.stationsForCurrentCampaign as Station[]
            )
        );
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    pad="small"
                    width={"90%"}
                    justify="center"
                    align="center"
                    fill="horizontal"
                    overflow="scroll"
                    style={{ display: "block" }}
                >
                    <Box margin="medium" justify="center" align="center">
                        <Heading level={3}>
                            Auftrag{" "}
                            {currentCampaignState.customer.companyName +
                                (currentCampaignState.name
                                    ? " - " + currentCampaignState.name
                                    : "")}
                        </Heading>
                        <Form
                            onSubmit={() => {
                                setConfirmUpdateWindow(true);
                                console.log(currentCampaignState.status);
                                props.putCampaign(currentCampaignState);
                            }}
                            style={{ width: "90%" }}
                        >
                            <EditableFormField
                                type="text"
                                value={currentCampaignState.name.toString()}
                                propsValue={props.campaign.name.toString()}
                                placeholder="Auftragsnummer"
                                name="name"
                                label="Auftragsnummer"
                                disabled={userDisabled}
                                onChangeFunction={(partialObject) =>
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                    })
                                }
                                valueParser={(value) => value}
                            />
                            {/* <EditableFormField
                                type="text"
                                value={currentCampaignState.customerName.toString()}
                                propsValue={props.campaign.customerName.toString()}
                                placeholder="Kunde"
                                name="customerName"
                                label="Kunde"
                                disabled={userDisabled}
                                onChangeFunction={(partialObject) =>
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                    })
                                }
                                valueParser={(value) => value}
                            /> */}
                            <Box
                                direction="row"
                                align="start"
                                fill="horizontal"
                            >
                                <FormField
                                    name={"companyName"}
                                    label={"Kunde"}
                                    style={{
                                        width:
                                            size === "small" ? "90%" : "30rem",
                                    }}
                                >
                                    <Select
                                        value={currentCampaignState.customer}
                                        placeholder="Kunde auswählen"
                                        searchPlaceholder="Kunden Suche"
                                        emptySearchMessage="Kunde nicht gefunden"
                                        labelKey="companyName"
                                        disabled={userDisabled}
                                        options={
                                            filteredCustomers.length > 0
                                                ? filteredCustomers
                                                : props.customers
                                        }
                                        onChange={(partialObject) => {
                                            setCurrentCampaignState({
                                                ...currentCampaignState,
                                                customer: partialObject.value,
                                            });
                                        }}
                                        onSearch={(query) => {
                                            setSearchingCustomer(true);
                                            setSearchingCustomerQuery(query);
                                        }}
                                    />
                                </FormField>
                            </Box>

                            <EditableFormField
                                type="select"
                                value={slotWeightToPackage(
                                    currentCampaignState.slotWeight
                                )}
                                propsValue={slotWeightToPackage(
                                    props.campaign.slotWeight
                                )}
                                placeholder="Slotbelegung"
                                name="slotWeight"
                                label="Slotbelegung"
                                disabled={userDisabled}
                                options={[
                                    {
                                        disabled: false,
                                        // name: slotWeightToPackage(props.slotWeight),
                                        value: 1,
                                        label: slotWeightToPackage(1),
                                    },
                                    {
                                        disabled: false,
                                        // name: slotWeightToPackage(props.slotWeight),
                                        value: 2,
                                        label: slotWeightToPackage(2),
                                    },
                                ]}
                                valueParser={(value) =>
                                    parseFloat(value as string)
                                }
                                onChangeFunction={(partialObject) => {
                                    checkAllocationAvailability({
                                        ...currentCampaignState,
                                        ...partialObject,
                                        allocations:
                                            currentCampaignState.allocations.map(
                                                (allocation) => {
                                                    return {
                                                        ...allocation,
                                                        slotWeight:
                                                            partialObject.slotWeight,
                                                    };
                                                }
                                            ),
                                    });
                                }}
                                labelKey="label"
                                valueKey="value"
                                // valueParser={value => parseInt(value)}
                            />

                            <EditableFormField
                                type="number"
                                min="0"
                                step="0.01"
                                value={currentCampaignState.pricePerMonth.toString()}
                                propsValue={props.campaign.pricePerMonth.toString()}
                                placeholder="xxx,xx €"
                                name="pricePerMonth"
                                label="€ / Monat"
                                disabled={userDisabled}
                                onChangeFunction={(partialObject) =>
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                    })
                                }
                                valueParser={(value) =>
                                    parseFloat(value as string)
                                }
                            />
                            <EditableFormField
                                type="number"
                                min="0"
                                value={currentCampaignState.bonusDuration.toString()}
                                propsValue={props.campaign.bonusDuration.toString()}
                                placeholder="Bonus Monate"
                                name="bonusDuration"
                                label="Bonus Monate"
                                disabled={userDisabled}
                                onChangeFunction={(partialObject) => {
                                    checkAllocationAvailability({
                                        ...currentCampaignState,
                                        ...partialObject,
                                        start: currentCampaignState.start,
                                        allocations:
                                            currentCampaignState.allocations.map(
                                                (allocation) => {
                                                    return {
                                                        start: currentCampaignState.start,
                                                        end: dayjs(
                                                            currentCampaignState.start
                                                        )
                                                            .add(
                                                                campaignDurationToIntMap[
                                                                    currentCampaignState
                                                                        .duration
                                                                ],
                                                                "month"
                                                            )
                                                            .add(
                                                                partialObject.bonusDuration,
                                                                "month"
                                                            )
                                                            .valueOf(),
                                                        slotNumber:
                                                            allocation.slotNumber,
                                                        stationId:
                                                            allocation.stationId,
                                                    };
                                                }
                                            ),
                                    });
                                }}
                                valueParser={(value) =>
                                    parseInt(value as string)
                                }
                            />
                            <EditableFormField
                                type="select"
                                value={currentCampaignState.paymentInterval}
                                propsValue={props.campaign.paymentInterval}
                                placeholder="Abrechnungsperiode"
                                name="paymentInterval"
                                label="Abrechnungsperiode"
                                disabled={userDisabled}
                                options={[
                                    {
                                        val: CampaignPaymentInterval.AT_ONCE,
                                        lab: "einmalig",
                                    },
                                    {
                                        val: CampaignPaymentInterval.YEARLY,
                                        lab: "jährlich",
                                    },
                                    {
                                        val: CampaignPaymentInterval.HALF_YEARLY,
                                        lab: "halbjährlich",
                                    },
                                    {
                                        val: CampaignPaymentInterval.QUARTERLY,
                                        lab: "vierteljährlich",
                                    },
                                    {
                                        val: CampaignPaymentInterval.MONTHLY,
                                        lab: "monatlich",
                                    },
                                ]}
                                labelKey="lab"
                                valueKey="val"
                                onChangeFunction={(partialObject) => {
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                    });
                                }}
                                valueParser={(value) => {
                                    const assertedValue = value as {
                                        val: string;
                                        lab: string;
                                    };
                                    return assertedValue.val;
                                }}
                            />
                            <EditableFormField
                                type="select"
                                value={currentCampaignState.salesRep.toString()}
                                propsValue={props.campaign.salesRep.toString()}
                                options={SalesRepArray}
                                placeholder="Vertrieb"
                                name="salesRep"
                                label="Vertrieb"
                                disabled={userDisabled}
                                onChangeFunction={(partialObject) =>
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                    })
                                }
                                valueParser={(value) => value}
                            />
                            <EditableFormField
                                type="select"
                                value={currentCampaignState.status.toString()}
                                propsValue={props.campaign.status.toString()}
                                placeholder="Status"
                                name="status"
                                label="Kampagnen Status"
                                disabled={userDisabled}
                                options={Object.keys(CampaignStatus).map(
                                    (key) => key
                                )}
                                valueParser={(value) => value}
                                onChangeFunction={(partialObject) => {
                                    if (
                                        partialObject.status !==
                                        CampaignStatus.TERMINATED
                                    ) {
                                        setCurrentCampaignState({
                                            ...currentCampaignState,
                                            ...partialObject,
                                            terminationDate: null,
                                        });
                                        return;
                                    }
                                    setCurrentCampaignState({
                                        ...currentCampaignState,
                                        ...partialObject,
                                        terminationDate: dayjs().valueOf(),
                                    });
                                }}
                            />
                            {currentCampaignState.status ===
                                CampaignStatus.TERMINATED && (
                                <DropButton
                                    label={
                                        "Gekündigt am: " +
                                        dayjs(
                                            currentCampaignState.terminationDate ===
                                                null
                                                ? dayjs()
                                                : currentCampaignState.terminationDate
                                        ).format("DD.MM.YYYY")
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    open={openWindow === "terminationDate"}
                                    onOpen={() => {
                                        setOpenWindow("terminationDate");
                                    }}
                                    onClose={() => setOpenWindow("")}
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    currentCampaignState.terminationDate ===
                                                        null
                                                        ? dayjs()
                                                        : currentCampaignState.terminationDate
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    setCurrentCampaignState({
                                                        ...currentCampaignState,
                                                        terminationDate: dayjs(
                                                            date
                                                        )
                                                            .hour(0)
                                                            .minute(0)
                                                            .second(0)
                                                            .valueOf(),
                                                    });

                                                    setOpenWindow("");
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                            )}
                            <Box
                                margin={{ vertical: "large" }}
                                direction="row"
                                align="center"
                                justify="between"
                            >
                                <DropButton
                                    label={
                                        "AB Datum: " +
                                        dayjs(
                                            currentCampaignState.offerDate <
                                                1000
                                                ? dayjs()
                                                : currentCampaignState.offerDate
                                        ).format("DD.MM.YYYY")
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    open={openWindow === "offerDate"}
                                    onOpen={() => {
                                        setOpenWindow("offerDate");
                                    }}
                                    onClose={() => setOpenWindow("")}
                                    // open={!closedABCalendarWindow}
                                    // onOpen={() => {
                                    //     setClosedDurationWindow(true);
                                    //     setClosedCalendarWindow(true);
                                    //     setClosedABCalendarWindow(false);
                                    // }}
                                    // onClose={() =>
                                    //     setClosedABCalendarWindow(true)
                                    // }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    currentCampaignState.offerDate <
                                                        1000
                                                        ? dayjs()
                                                        : currentCampaignState.offerDate
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    setCurrentCampaignState({
                                                        ...currentCampaignState,
                                                        offerDate: dayjs(date)
                                                            .hour(0)
                                                            .minute(0)
                                                            .second(0)
                                                            .valueOf(),
                                                    });

                                                    setClosedCalendarWindow(
                                                        true
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                                <DropButton
                                    margin={{ left: "1rem" }}
                                    label={
                                        "Ausspielung ab: " +
                                        dayjs(
                                            currentCampaignState.start
                                        ).format("DD.MM.YYYY")
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    open={openWindow === "start"}
                                    onOpen={() => {
                                        setOpenWindow("start");
                                    }}
                                    onClose={() => setOpenWindow("")}
                                    // open={!closedCalendarWindow}
                                    // onOpen={() => {
                                    //     setClosedDurationWindow(true);
                                    //     setClosedABCalendarWindow(true);
                                    //     setClosedCalendarWindow(false);
                                    // }}
                                    // onClose={() =>
                                    //     setClosedCalendarWindow(true)
                                    // }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    currentCampaignState.start
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    const newStartDate = dayjs(
                                                        date
                                                    )
                                                        .hour(0)
                                                        .minute(0)
                                                        .second(0)
                                                        .valueOf();
                                                    checkAllocationAvailability(
                                                        {
                                                            ...currentCampaignState,
                                                            start: newStartDate,
                                                            allocations:
                                                                currentCampaignState.allocations.map(
                                                                    (
                                                                        allocation
                                                                    ) => {
                                                                        return {
                                                                            start: newStartDate,
                                                                            end: dayjs(
                                                                                newStartDate
                                                                            )
                                                                                .add(
                                                                                    campaignDurationToIntMap[
                                                                                        currentCampaignState
                                                                                            .duration
                                                                                    ],
                                                                                    "month"
                                                                                )
                                                                                .add(
                                                                                    currentCampaignState.bonusDuration,
                                                                                    "month"
                                                                                )
                                                                                .valueOf(),
                                                                            slotNumber:
                                                                                allocation.slotNumber,
                                                                            stationId:
                                                                                allocation.stationId,
                                                                        };
                                                                    }
                                                                ),
                                                        }
                                                    );
                                                    setClosedCalendarWindow(
                                                        true
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                                <DropButton
                                    margin={{ horizontal: "1rem" }}
                                    label={
                                        "Laufzeit: " +
                                        campaignDurationToIntMap[
                                            currentCampaignState.duration
                                        ] +
                                        " Monate"
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    open={openWindow === "duration"}
                                    onOpen={() => {
                                        setOpenWindow("duration");
                                    }}
                                    onClose={() => setOpenWindow("")}
                                    // open={!closedDurationWindow}
                                    // //   margin={{ bottom: "large" }}
                                    // onOpen={() => {
                                    //     setClosedDurationWindow(false);
                                    //     setClosedCalendarWindow(true);
                                    //     setClosedABCalendarWindow(true);
                                    // }}
                                    // onClose={() =>
                                    //     setClosedDurationWindow(true)
                                    // }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <RadioButtonGroup
                                                name="doc"
                                                options={Object.keys(
                                                    campaignDurationToIntMap
                                                ).map((key) => {
                                                    return {
                                                        value: key,
                                                        label:
                                                            "" +
                                                            campaignDurationToIntMap[
                                                                key
                                                            ] +
                                                            " Monate",
                                                    };
                                                })}
                                                value={
                                                    currentCampaignState.duration
                                                }
                                                onChange={(event) => {
                                                    checkAllocationAvailability(
                                                        {
                                                            ...currentCampaignState,
                                                            duration:
                                                                event.target
                                                                    .value,
                                                            allocations:
                                                                currentCampaignState.allocations.map(
                                                                    (
                                                                        allocation
                                                                    ) => {
                                                                        return {
                                                                            start: dayjs(
                                                                                currentCampaignState.start
                                                                            ).valueOf(),
                                                                            end: dayjs(
                                                                                currentCampaignState.start
                                                                            )
                                                                                .add(
                                                                                    campaignDurationToIntMap[
                                                                                        event
                                                                                            .target
                                                                                            .value
                                                                                    ],
                                                                                    "month"
                                                                                )
                                                                                .valueOf(),
                                                                            slotNumber:
                                                                                allocation.slotNumber,
                                                                            stationId:
                                                                                allocation.stationId,
                                                                        };
                                                                    }
                                                                ),
                                                        }
                                                    );
                                                    setClosedDurationWindow(
                                                        true
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                                <CheckBox
                                    label={"Selbstanlieferung"}
                                    checked={
                                        currentCampaignState.spotCreationByCustomer
                                            ? currentCampaignState.spotCreationByCustomer
                                            : false
                                    }
                                    onChange={(event) => {
                                        setCurrentCampaignState({
                                            ...currentCampaignState,
                                            spotCreationByCustomer:
                                                event.target.checked,
                                        });
                                    }}
                                />
                            </Box>

                            <Box
                                overflow="scroll"
                                margin={{ bottom: "large" }}
                                style={{ maxHeight: "150px" }}
                                border="all"
                                pad="small"
                                // height="300px"
                            >
                                {props.stationsForCampaign.map(
                                    (station, index) => (
                                        <Box
                                            margin={{ vertical: "medium" }}
                                            key={station.id}
                                            direction="column"
                                            justify="between"
                                            align="center"
                                            alignContent="center"
                                            height="medium"
                                        >
                                            <Box direction="row">
                                                <Box>
                                                    <Text
                                                        textAlign="center"
                                                        color={
                                                            allocationDistributionInvalid &&
                                                            allocationDistributionInvalid.indexOf(
                                                                station.id
                                                            ) !== -1
                                                                ? "danger"
                                                                : "neutral"
                                                        }
                                                    >
                                                        {index + 1}.{" "}
                                                        {station.city +
                                                            ", " +
                                                            station.zipCode +
                                                            " - " +
                                                            station.street}{" "}
                                                        {allocationDistributionInvalid &&
                                                        allocationDistributionInvalid.indexOf(
                                                            station.id
                                                        ) !== -1
                                                            ? " - Station ist für diesen Zeitraum bereits belegt"
                                                            : ""}
                                                    </Text>
                                                </Box>
                                                <Button
                                                    icon={
                                                        <Add
                                                            color="warning"
                                                            onClick={() => {
                                                                setCurrentCampaignState(
                                                                    {
                                                                        ...currentCampaignState,
                                                                        allocations:
                                                                            currentCampaignState.allocations.filter(
                                                                                (
                                                                                    allocation
                                                                                ) =>
                                                                                    allocation.stationId !==
                                                                                    station.id
                                                                            ),
                                                                    }
                                                                );
                                                                props.setScreenStations(
                                                                    props.stationsForCampaign.filter(
                                                                        (
                                                                            stationToFilter
                                                                        ) =>
                                                                            stationToFilter.shellId !==
                                                                            station.shellId
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Button
                                                    icon={
                                                        <Trash
                                                            color="warning"
                                                            onClick={() => {
                                                                setCurrentCampaignState(
                                                                    {
                                                                        ...currentCampaignState,
                                                                        allocations:
                                                                            currentCampaignState.allocations.filter(
                                                                                (
                                                                                    allocation
                                                                                ) =>
                                                                                    allocation.stationId !==
                                                                                    station.id
                                                                            ),
                                                                    }
                                                                );
                                                                props.setScreenStations(
                                                                    props.stationsForCampaign.filter(
                                                                        (
                                                                            stationToFilter
                                                                        ) =>
                                                                            stationToFilter.shellId !==
                                                                            station.shellId
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            {/* {currentCampaignState.allocations.filter(())} */}
                                        </Box>
                                    )
                                )}
                                <Box
                                    pad={{ vertical: "xsmall" }}
                                    key={"addStationButton"}
                                    align="center"
                                >
                                    <Button
                                        label="Station hinzufügen"
                                        color="success"
                                        icon={<Add color="success" />}
                                        margin={{ vertical: "small" }}
                                        onClick={() => {
                                            props.setStationOfCurrentCampaign(
                                                []
                                            );
                                            setClosedAddStationWindow(false);
                                        }}
                                    />
                                </Box>
                            </Box>
                            {!closedAddStationWindow && (
                                <Layer
                                    onEsc={(event) => {
                                        setClosedAddStationWindow(true);
                                        event.stopPropagation();
                                    }}
                                    onClickOutside={() =>
                                        setClosedAddStationWindow(true)
                                    }
                                >
                                    <Box pad="medium">
                                        <StationList
                                            stations={props.stations.filter(
                                                (station) =>
                                                    currentCampaignState.allocations.findIndex(
                                                        (campaignStation) =>
                                                            campaignStation.stationId ===
                                                            station.id
                                                    ) === -1
                                            )}
                                            handleSelection={() => {
                                                handleAddStationsToCurrentCampaign();
                                            }}
                                            handleAddStationSelect={() => {
                                                handleAddStationsToCurrentCampaign();
                                            }}
                                        />

                                        <Box
                                            pad={{
                                                horizontal: "large",
                                                top: "5px",
                                            }}
                                            width="medium"
                                            align="center"
                                            margin="auto"
                                        >
                                            <Button
                                                onClick={() => {
                                                    setClosedAddStationWindow(
                                                        true
                                                    );
                                                }}
                                                color="danger"
                                                label="Schließen"
                                            />
                                        </Box>
                                    </Box>
                                </Layer>
                            )}
                            <Box
                                direction="row"
                                justify="between"
                                margin={{ top: "medium" }}
                            >
                                <Button
                                    label="Undo"
                                    primary
                                    color="warning"
                                    margin={{ vertical: "small" }}
                                    disabled={_.isEqual(
                                        { ...currentCampaignState },
                                        props.campaign
                                    )}
                                    icon={<Undo color="white" />}
                                    onClick={() =>
                                        checkAllocationAvailability(
                                            props.campaign
                                        )
                                    }
                                />

                                <Button
                                    disabled={
                                        currentCampaignState.customer
                                            .companyName === "" ||
                                        (allocationDistributionInvalid &&
                                            allocationDistributionInvalid.length >
                                                0) ||
                                        _.isEqual(
                                            { ...currentCampaignState },
                                            props.campaign
                                        )
                                    }
                                    label="Speichern"
                                    primary
                                    type="submit"
                                    margin={{ vertical: "small" }}
                                    icon={<Save color="white" />}
                                    color="success"
                                />
                            </Box>

                            <Button
                                label="Abbrechen"
                                color="danger"
                                primary
                                icon={<Close color="white" />}
                                fill="horizontal"
                                margin={{ vertical: "small" }}
                                onClick={() =>
                                    props.closeLayer && props.closeLayer(null)
                                }
                            />
                        </Form>
                    </Box>
                    {confirmUpdateWindow &&
                        (props.putAllocationsStatus !==
                            NewCampaignRequestStatus.NONE ||
                            props.isFetchingCampaigns ||
                            props.isFetchingAllocations) && (
                            <Layer
                                onEsc={() => {
                                    setConfirmUpdateWindow(false);
                                    props.setPutCampaignRequestStatus(
                                        NewCampaignRequestStatus.NONE
                                    );
                                }}
                                onClickOutside={() => {
                                    setConfirmUpdateWindow(false);
                                    props.setPutCampaignRequestStatus(
                                        NewCampaignRequestStatus.NONE
                                    );
                                }}
                                full={false}
                            >
                                <Box
                                    pad="large"
                                    align="center"
                                    justify="center"
                                >
                                    {props.putAllocationsStatus ===
                                        NewCampaignRequestStatus.SUCCESS && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                margin={{ vertical: "medium" }}
                                                textAlign="center"
                                            >
                                                Kampagne wurde erfolgreich
                                                geändert.
                                            </Text>
                                            <Box
                                                align="center"
                                                justify="center"
                                            >
                                                <Button
                                                    label="Zur Kampagnenübersicht"
                                                    icon={<LinkPrevious />}
                                                    onClick={() => {
                                                        props.closeLayer &&
                                                            props.closeLayer(
                                                                null
                                                            );
                                                        props.setPutCampaignRequestStatus(
                                                            NewCampaignRequestStatus.NONE
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {props.putAllocationsStatus ===
                                        NewCampaignRequestStatus.FAILURE && (
                                        <Box
                                            align="center"
                                            direction="column"
                                            justify="center"
                                        >
                                            <Text
                                                color="danger"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Das sollte nicht passieren!
                                            </Text>
                                            <Text
                                                textAlign="center"
                                                margin={{ vertical: "medium" }}
                                            >
                                                Ein Fehler ist aufgetreten.
                                                Bitte versuchen Sie es erneuet
                                                oder kontaktieren Sie unseren
                                                Service.
                                            </Text>

                                            <Button
                                                label="Zurück zur Kampagne"
                                                icon={<LinkPrevious />}
                                                onClick={() => {
                                                    setConfirmUpdateWindow(
                                                        false
                                                    );
                                                    props.setPutCampaignRequestStatus(
                                                        NewCampaignRequestStatus.NONE
                                                    );
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {props.isFetchingAllocations && (
                                        <div className="loadingio-spinner-spinner-g73zrpl2wr5">
                                            <div className="ldio-xie7sipo009">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            </Layer>
                        )}
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        isFetchingAllocations: state.allocationReducer.isFetching,
        isFetchingCampaigns: state.campaignReducer.isFetching,
        allocationsForStations: state.allocationReducer.allocationsForIds,
        stationsForCampaign: state.stationsReducer.currentStationsSelection,
        stationsForCurrentCampaign:
            state.campaignReducer.stationsForCurrentCampaign,
        putAllocationsStatus: state.campaignReducer.putCampaignRequestStatus,
        user: state.usersReducer.user,
        stations: state.stationsReducer.stations,
        customers: state.customerReducer.customers,
    };
};

const mapDispatchToProps = {
    removeStationForNewCampaign,
    addStationForNewCampaign,
    putCampaign,
    getAllocationsForStationsByDate,
    getStationsById,
    setPutCampaignRequestStatus,
    setScreenStations,
    setStationOfCurrentCampaign,
    getCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails);
