import Customer, { NewCustomerRequestStatus } from "../../types/Customer";

export interface CustomerState {
    customers: Customer[];
    currentCustomer: Customer | null;
    isFetching: boolean;
    newCustomerRequestStatus: NewCustomerRequestStatus | null;
    changeCustomerRequestStatus: NewCustomerRequestStatus | null;
}

export const CREATE_NEW_CUSTOMER = "CREATE_NEW_CUSTOMER";
export const CREATE_NEW_CUSTOMER_RESPONSE = "CREATE_NEW_CUSTOMER_RESPONSE";
export const CREATE_NEW_CUSTOMER_REQUEST_STATUS =
    "CREATE_NEW_CUSTOMER_REQUEST_STATUS";
export const CHANGE_CUSTOMER = "CHANGE_CUSTOMER";
export const CHANGE_CUSTOMER_RESPONSE = "CHANGE_CUSTOMER_RESPONSE";
export const CHANGE_CUSTOMER_REQUEST_STATUS =
    "CHANGE_CUSTOMER_REQUEST_STATUS";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_CUSTOMERS_RESPONSE = "GET_ALL_CUSTOMERS_RESPONSE";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const GET_CUSTOMER_BY_ID_RESPONSE = "GET_CUSTOMER_BY_ID_RESPONSE";

interface CreateNewCustomersAction {
    type: typeof CREATE_NEW_CUSTOMER;
}

interface ResponseCreateNewCustomersAction {
    type: typeof CREATE_NEW_CUSTOMER_RESPONSE;
    payload: Customer;
}

interface ResponseCreateNewCustomersStatusAction {
    type: typeof CREATE_NEW_CUSTOMER_REQUEST_STATUS;
    payload: NewCustomerRequestStatus;
}

interface ChangeCustomersAction {
    type: typeof CHANGE_CUSTOMER;
}

interface ResponseChangeCustomersAction {
    type: typeof CHANGE_CUSTOMER_RESPONSE;
    payload: Customer;
}

interface ResponseChangeCustomersStatusAction {
    type: typeof CHANGE_CUSTOMER_REQUEST_STATUS;
    payload: NewCustomerRequestStatus;
}

interface RequestGetCustomersAction {
    type: typeof GET_ALL_CUSTOMERS;
}

interface ResponseGetCustomersAction {
    type: typeof GET_ALL_CUSTOMERS_RESPONSE;
    payload: Customer[];
}

interface RequestGetCustomerByIdAction {
    type: typeof GET_CUSTOMER_BY_ID;
}

interface ResponseGetCustomerByIdAction {
    type: typeof GET_CUSTOMER_BY_ID_RESPONSE;
    payload: Customer;
}

export type CustomersActionType =
    | CreateNewCustomersAction
    | ResponseCreateNewCustomersAction
    | ResponseCreateNewCustomersStatusAction
    | ChangeCustomersAction
    | ResponseChangeCustomersAction
    | ResponseChangeCustomersStatusAction
    | RequestGetCustomersAction
    | ResponseGetCustomersAction
    | RequestGetCustomerByIdAction
    | ResponseGetCustomerByIdAction;
