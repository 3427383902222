import Customer from "./Customer";
import Allocation, { PartialNewAllocation } from "./Resource";
import Station from "./Station";

export interface CampaignAllocation extends Allocation {
    station: Station;
}

export enum SalesRep {
    ANDRE = "André",
    COMPRI = "CompriMedia",
    SMM = "SalesElevator",
    ISM = "ISM",
    MEHLTRETTER = "Christian Mehltretter",
    LATTUCA = "Marco Lattuca",
    LOETTERLE = "Harry Lötterle",
    KOEHLER = "Cristian W. Köhler",
    AMBIENT = "AmbientMedia-TV",
    UZE = "uze! Mobility GmbH",
    NONE = "Nicht zugewiesen",
}

export const SalesRepArray = [
    SalesRep.ANDRE,
    SalesRep.COMPRI,
    SalesRep.SMM,
    SalesRep.ISM,
    SalesRep.MEHLTRETTER,
    SalesRep.LATTUCA,
    SalesRep.LOETTERLE,
    SalesRep.KOEHLER,
    SalesRep.AMBIENT,
    SalesRep.UZE,
    SalesRep.NONE,
];

export enum CampaignPaymentInterval {
    AT_ONCE = "AT_ONCE",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    HALF_YEARLY = "HALF_YEARLY",
    YEARLY = "YEARLY",
}

export interface Campaign {
    allocations: CampaignAllocation[];
    duration: CampaignDuration;
    bonusDuration: number;
    pricePerMonth: number;
    salesRep: SalesRep;
    id: number;
    name: string;
    start: number;
    offerDate: number;
    spotCreationByCustomer: boolean | null;
    status: CampaignStatus;
    createdBy: {
        email: string;
        name: string;
    };
    createdAt: number;
    slotWeight: number;
    paymentInterval: CampaignPaymentInterval;
    customer: Customer;
    terminationDate: number | null;
}

export const slotWeightToPackage = (slotWeight: number): string => {
    switch (slotWeight) {
        case 1:
            return "Comfort";
        default:
            return "Premium";
    }
};

export interface PartialNewCampaign {
    start: number;
    offerDate: number;
    spotCreationByCustomer: boolean;
    duration: CampaignDuration;
    bonusDuration: number;
    pricePerMonth: number;
    salesRep: SalesRep;
    name: string;
    status: CampaignStatus;
    customerId: number;
    slotWeight: number;
    allocations: PartialNewAllocation[];
    paymentInterval: CampaignPaymentInterval;
    terminationDate: number | null;
}

export enum CampaignDuration {
    TWO_MONTHS = "TWO_MONTHS",
    SIX_MONTHS = "SIX_MONTHS",
    TWELVE_MONTHS = "TWELVE_MONTHS",
    TWENTY_FOUR_MONTHS = "TWENTY_FOUR_MONTHS",
    THIRTY_SIX_MONTHS = "THIRTY_SIX_MONTHS",
    FOURTY_EIGHT_MONTHS = "FOURTY_EIGHT_MONTHS",
    NONE = "NONE",
}

export enum CampaignDurationInt {
    TWO_MONTHS = 2,
    SIX_MONTHS = 6,
    TWELVE_MONTHS = 12,
    TWENTY_FOUR_MONTHS = 24,
    THIRTY_SIX_MONTHS = 36,
    FOURTY_EIGHT_MONTHS = 48,
    NONE = 0,
}

export enum CampaignStatus {
    CREATED = "CREATED",
    TERMINATED = "TERMINATED",
    // APPROVED = "APPROVED",
    LIVE = "LIVE",
}

export interface CampaignsResponse {
    content: Campaign[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    // pageable: {
    //     "offset": 0,
    //     "pageNumber": 0,
    //     "pageSize": 0,
    //     "paged": true,
    //     "sort": {
    //         "empty": true,
    //         "sorted": true,
    //         "unsorted": true
    //     },
    //     "unpaged": true
    // },
    size: number;
    // "sort": {
    //     "empty": true,
    //     "sorted": true,
    //     "unsorted": true
    // },
    totalElements: number;
    totalPages: number;
}

export interface NewCampaignResponse {
    allocations: Allocation[];
    duration: CampaignDuration;
    id: number;
    name: string;
    start: number;
    offerDate: number;
    spotCreationByCustomer: boolean;
    status: CampaignStatus;
    createdAt: number;
    bonusDuration: number;
    pricePerMonth: number;
    salesRep: SalesRep;
    terminationDate: number | null;
}

export enum NewCampaignRequestStatus {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    NONE = "NONE",
    LIVE = "LIVE",
}

export default Campaign;
