import dayjs from "dayjs";
import xlsx from "xlsx";
import settings from "../../settings/index";
import { User } from "../../types/Authentication";
import Campaign, {
    CampaignDuration,
    CampaignDurationInt,
    CampaignPaymentInterval,
} from "../../types/Campaign";
import Allocation from "../../types/Resource";
import Station, {
    IndoorScreenVersionToText,
    OutdoorScreenVersion,
    OutdoorScreenVersionToText,
    Region,
    StationStatusToText,
} from "../../types/Station";

const numberToGermanLocale = (number: number): string => {
    return number.toLocaleString("de-DE", { minimumSignificantDigits: 4 });
};

const regionToNielsen = (region: Region) => {
    switch (region) {
        case Region.Bremen:
        case Region.Hamburg:
        case Region.Niedersachsen:
        case Region.Schleswig_Holstein:
            return "I";
        case Region.Nordrhein_Westfalen:
            return "II";
        case Region.Hessen:
        case Region.Rheinland_Pfalz:
        case Region.Saarland:
            return "IIIa";
        case Region.Baden_Wuerttemberg:
            return "IIIb";
        case Region.Bayern:
            return "IV";
        case Region.Berlin:
            return "V";
        case Region.Brandenburg:
        case Region.Mecklenburg_Vorpommern:
        case Region.Sachsen_Anhalt:
            return "VI";
        case Region.Sachsen:
        case Region.Thueringen:
            return "VII";
        default:
            return "N/A";
    }
};
export class CsvExporter {
    static parseStationArrayToCsv = async (
        array: Station[],
        // csv: boolean = false,
        withCustomers?: boolean,
        user?: User | null
    ) => {
        const dataArray = array.map((station: Station) => {
            return {
                shellId: station.shellId.toString(),
                region: station.region.toString(),
                nielsen: regionToNielsen(station.region),
                street: station.street.toString(),
                zipCode: station.zipCode.toString(),
                city: station.city.toString(),
                lng: station.longitude ? station.longitude.toString() : "",
                lat: station.latitude ? station.latitude.toString() : "",
                outdoorScreenVersion: OutdoorScreenVersionToText(
                    station.outdoorScreenVersion
                ),
                indoorScreenVersion: IndoorScreenVersionToText(
                    station.indoorScreenVersion
                ),
                status: StationStatusToText(station.status),
                freeSlotsInPeriodCount: station.freeSlotsInPeriodCount
                    .toString()
                    .replace(".", ","),
                info: station.additionalInfo ? station.additionalInfo : "",
            };
        });

        /* add to workbook */
        if (!withCustomers) {
            const worksheet = xlsx.utils.json_to_sheet(
                [
                    {
                        shellId: "Shell ID",
                        region: "BL",
                        nielsen: "Nielsen",
                        street: "Straße",
                        zipCode: "PLZ",
                        city: "Stadt",
                        lng: "Lng",
                        lat: "Lat",
                        outdoorScreenVersion: "Bildschirm außen",
                        indoorScreenVersion: "Bildschirm innen",
                        status: "Status",
                        freeSlotsInPeriodCount: "Freie Slots",
                        info: "Sonst. Info",
                    },
                ].concat(dataArray),
                { skipHeader: true }
            );

            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, worksheet, "Stationen");

            /* generate an XLSX file */
            xlsx.writeFile(wb, "stationen.xlsx");
            return;
        }

        const arrayWithCustomers = await array.map(async (station: Station) => {
            const allocArray: Allocation[] = await fetch(
                settings.API.BASE_URL +
                    settings.API.API_PATH +
                    settings.API_END_POINTS.ALLOCATIONS_BY_ID_AND_DATE +
                    "?stationId=" +
                    station.id +
                    "&start=" +
                    dayjs().valueOf() +
                    "&end=" +
                    dayjs().add(6, "month").valueOf(),
                {
                    headers: {
                        Authorization:
                            "Bearer " +
                            (user ? user.authentication.access_token : ""),
                    },
                }
            ).then((res) => res.json());

            return {
                ...{
                    shellId: station.shellId.toString(),
                    region: station.region.toString(),
                    nielsen: regionToNielsen(station.region),
                    street: station.street.toString(),
                    zipCode: station.zipCode.toString(),
                    city: station.city.toString(),
                    outdoorScreenVersion: OutdoorScreenVersionToText(
                        station.outdoorScreenVersion
                    ),
                    indoorScreenVersion: IndoorScreenVersionToText(
                        station.indoorScreenVersion
                    ),
                    status: StationStatusToText(station.status),
                    freeSlotsInPeriodCount: station.freeSlotsInPeriodCount
                        .toString()
                        .replace(".", ","),
                },
                ...allocArray.reduce((obj, item, index) => {
                    return {
                        ...obj,
                        ["customer" + index]:
                            (item.slotWeight === 1 ? "C" : "P") +
                            " - " +
                            item.campaignName +
                            " - " +
                            item.customerName,
                    };
                }, {}),
            };
        });
        Promise.all(arrayWithCustomers).then((completed) => {
            const worksheet = xlsx.utils.json_to_sheet(
                completed,
                {
                    // header: [
                    //     "Shell ID",
                    //     "BL",
                    //     "Nielsen",
                    //     "Straße",
                    //     "PLZ",
                    //     "Stadt",
                    //     "Bildschirm",
                    //     "Status",
                    //     "Freie Slots",
                    // ],
                }
                // [
                //     {
                //         ...{
                //             shellId: "Shell ID",
                //             region: "BL",
                //             nielsen: "Nielsen",
                //             street: "Straße",
                //             zipCode: "PLZ",
                //             city: "Stadt",
                //             outdoorScreenVersion: "Bildschirm",
                //             status: "Status",
                //             freeSlotsInPeriodCount: "Freie Slots",
                //         },
                //         ...arrayWithCustomers.reduce((obj, item, index) => {
                //             return {
                //                 ...obj,
                //                 ["customer" + index]: "Kunde " + index,
                //             };
                //         }, {}),
                //     },
                // ].concat(arrayWithCustomers),
                // { skipHeader: false }
            );

            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, worksheet, "Stationen");

            /* generate an XLSX file */
            xlsx.writeFile(wb, "stationen_mit_kunden.xlsx");
            return;
        });

        // Promise.all(arrayWithCustomers).then((completed) => {
        //     csvString += completed
        //         .map((object) => {
        //             return (
        //                 object.station.shellId +
        //                 "," +
        //                 object.station.street +
        //                 "," +
        //                 object.station.zipCode +
        //                 "," +
        //                 object.station.city +
        //                 "," +
        //                 OutdoorScreenVersionToText(
        //                     object.station.outdoorScreenVersion
        //                 ) +
        //                 "," +
        //                 StationStatusToText(object.station.status) +
        //                 ',"' +
        //                 (object.customers[0]
        //                     ? (object.customers[0].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[0].campaignName +
        //                       " - " +
        //                       object.customers[0].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[1]
        //                     ? (object.customers[1].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[1].campaignName +
        //                       " - " +
        //                       object.customers[1].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[2]
        //                     ? (object.customers[2].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[2].campaignName +
        //                       " - " +
        //                       object.customers[2].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[3]
        //                     ? (object.customers[3].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[3].campaignName +
        //                       " - " +
        //                       object.customers[3].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[4]
        //                     ? (object.customers[4].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[4].campaignName +
        //                       " - " +
        //                       object.customers[4].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[5]
        //                     ? (object.customers[5].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[5].campaignName +
        //                       " - " +
        //                       object.customers[5].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[6]
        //                     ? (object.customers[6].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[6].campaignName +
        //                       " - " +
        //                       object.customers[6].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[7]
        //                     ? (object.customers[7].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[7].campaignName +
        //                       " - " +
        //                       object.customers[7].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[8]
        //                     ? (object.customers[8].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[8].campaignName +
        //                       " - " +
        //                       object.customers[8].customerName
        //                     : "") +
        //                 '","' +
        //                 (object.customers[9]
        //                     ? (object.customers[9].slotWeight === 1
        //                           ? "C"
        //                           : "P") +
        //                       " - " +
        //                       object.customers[9].campaignName +
        //                       " - " +
        //                       object.customers[9].customerName
        //                     : "") +
        //                 '"'
        //             );
        //         })
        //         .join("\n");
        //     const hiddenElement = document.getElementById("download_hidden");
        //     if (hiddenElement) {
        //         hiddenElement.setAttribute("href", encodeURI(csvString));
        //         hiddenElement.setAttribute("target", "_blank");
        //         hiddenElement.setAttribute("download", "stationen_kunden.csv");
        //         hiddenElement.click();
        //     }
        // });

        return;
    };

    static parseStationArrayCostsToCsv = (array: Station[]) => {
        const dataArray = array
            .filter((station: Station) => {
                return (
                    // station.constructionCostOffer &&
                    // station.constructionCostOffer > 0 &&
                    // station.outdoorScreenCost &&
                    // station.outdoorScreenCost > 0 &&
                    // station.indoorScreenCost &&
                    // station.indoorScreenCost > 0 &&
                    // station.otherCosts &&
                    // station.otherCosts > 0 &&
                    // station.constructionCost &&
                    // station.constructionCost > 0
                    true
                );
            })
            .map((station: Station) => {
                const realConstructionCost =
                    (station.constructionCost || 0) +
                    (station.smallElectronicsCost || 0) +
                    (station.otherCosts || 0) +
                    (station.outdoorScreenCost || 0) +
                    (station.indoorScreenCost || 0) +
                    (station.otherHardwareCost || 0) +
                    (station.measurementFees || 0) +
                    (station.approvalFees || 0);

                const klostermann =
                    (station.constructionCostOffer || 0) +
                    (station.outdoorScreenCost || 0) +
                    (station.indoorScreenCost || 0) +
                    (station.otherHardwareCost || 0) +
                    (station.measurementFees || 0) +
                    (station.approvalFees || 0);

                const ism = (outdoorVersion: OutdoorScreenVersion): number => {
                    if (
                        outdoorVersion === OutdoorScreenVersion.EIGHTY_FIVE_INCH
                    ) {
                        return 27344.56;
                    }
                    return 19986.56;
                };

                return {
                    shellId: station.shellId.toString(),
                    region: station.region.toString(),
                    street: station.street.toString(),
                    zipCode: station.zipCode.toString(),
                    city: station.city.toString(),
                    activationDate: station.plannedConstructionDate
                        ? dayjs(station.plannedConstructionDate).format(
                              "DD.MM.YYYY"
                          )
                        : "N/A",
                    outdoorScreenVersion: OutdoorScreenVersionToText(
                        station.outdoorScreenVersion
                    ),
                    realConstructionCost: station.constructionCost
                        ? numberToGermanLocale(realConstructionCost)
                        : "",
                    klostermannCost: station.constructionCost
                        ? numberToGermanLocale(klostermann)
                        : "",
                    plannedCost: numberToGermanLocale(
                        ism(
                            station.outdoorScreenVersion ||
                                OutdoorScreenVersion.SEVENTY_FIVE_INCH
                        )
                    ),
                    differencePlanReality: station.constructionCost
                        ? numberToGermanLocale(
                              ism(
                                  station.outdoorScreenVersion ||
                                      OutdoorScreenVersion.SEVENTY_FIVE_INCH
                              ) - realConstructionCost
                          )
                        : "",
                    insurance:
                        station.outdoorScreenVersion ===
                        OutdoorScreenVersion.SEVENTY_FIVE_INCH
                            ? "19.000,00"
                            : "24.000,00",
                    indoorScreenVersion: IndoorScreenVersionToText(
                        station.indoorScreenVersion
                    ),
                    playersBackShop:
                        station.backShopPlayerAmount !== null
                            ? station.backShopPlayerAmount.toString()
                            : "0",
                    playersCarWash:
                        station.carWashPlayerAmount !== null
                            ? station.carWashPlayerAmount.toString()
                            : "0",
                };
            });
        const worksheet = xlsx.utils.json_to_sheet(
            [
                {
                    shellId: "Shell ID",
                    region: "BL",
                    street: "Straße",
                    zipCode: "PLZ",
                    city: "Stadt",
                    activationDate: "Aufbaudatum",
                    outdoorScreenVersion: "Bildschirm",
                    realConstructionCost: "Kosten Aufbau",
                    klostermannCost: "Angebot Klostermann",
                    plannedCost: "ISM Planzahl",
                    differencePlanReality: "Delta Kosten",
                    insurance: "Versicherung",
                    indoorScreenVersion: "IDS",
                    playersBackShop: "Player BackShop",
                    playersCarWash: "Player CarWash",
                },
            ].concat(dataArray),
            { skipHeader: true }
        );

        /* add to workbook */

        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, worksheet, "Kosten Aufbau");

        /* generate an XLSX file */
        xlsx.writeFile(wb, "stationen_aufbaukosten.xlsx");
        return;
    };

    static calculateElectricityForStationArrayAndPeriodToCsv = (
        array: Station[],
        endDateObject: dayjs.Dayjs,
        startDateObject: dayjs.Dayjs | null = null
    ) => {
        const startDate = startDateObject
            ? startDateObject
            : dayjs(endDateObject).subtract(1, "month");

        const daysInMonth: number = endDateObject.diff(startDate, "day");

        const kwhPerDay = (outdoorScreenVersion): number => {
            if (startDate.get("year") === endDateObject.get("year")) {
                return outdoorScreenVersion ===
                    OutdoorScreenVersion.EIGHTY_FIVE_INCH
                    ? settings.FIXED_PRICES.ELECTRICITY[startDate.get("year")]
                          .KWH_PER_DAY.EIGHTY_FIVE_INCH
                    : settings.FIXED_PRICES.ELECTRICITY[startDate.get("year")]
                          .KWH_PER_DAY.SEVENTY_FIVE_INCH;
            }
            return 0;
        };

        const arrayForDownload = array.map((station: Station) => {
            let daysRunningInMonth = 0;

            if (
                station.plannedConstructionDate &&
                station.plannedConstructionDate > startDate.valueOf()
            ) {
                daysRunningInMonth =
                    endDateObject.diff(station.plannedConstructionDate, "day") +
                    1;
            } else {
                daysRunningInMonth = daysInMonth + 1;
            }

            const kwhpd = kwhPerDay(station.outdoorScreenVersion);

            const pricePerMonth =
                daysRunningInMonth *
                kwhpd *
                settings.FIXED_PRICES.ELECTRICITY[startDate.get("year")]
                    .EURO_PER_KWH;

            const baseObject = {
                shellId: station.shellId.toString(),
                region: station.region.toString(),
                street: station.street.toString(),
                zipCode: station.zipCode.toString(),
                city: station.city.toString(),
                outdoorScreenVersion: OutdoorScreenVersionToText(
                    station.outdoorScreenVersion
                ),
                constructionDate: station.plannedConstructionDate
                    ? dayjs(station.plannedConstructionDate).format(
                          "DD.MM.YYYY"
                      )
                    : "TBD",
                daysRunningInMonth: daysRunningInMonth.toString(),
                price_per_kwh:
                    numberToGermanLocale(
                        settings.FIXED_PRICES.ELECTRICITY[startDate.get("year")]
                            .EURO_PER_KWH
                    ) + "€",
                kwh_per_day: numberToGermanLocale(kwhpd),
                totalKwh: numberToGermanLocale(kwhpd * daysRunningInMonth),
                priceForMonth: numberToGermanLocale(pricePerMonth) + "€",
                costISM: numberToGermanLocale(pricePerMonth * 0.7) + "€",
                costShell: numberToGermanLocale(pricePerMonth * 0.3) + "€",
            };

            return baseObject;
        });

        const totalCost = arrayForDownload.reduce((total, object) => {
            return (
                total +
                parseInt(object.daysRunningInMonth) *
                    kwhPerDay(object.outdoorScreenVersion) *
                    settings.FIXED_PRICES.ELECTRICITY[startDate.get("year")]
                        .EURO_PER_KWH
            );
        }, 0);

        // "data:text/csv;charset=utf-8,Shell-Id,BL,Nielsen,Straße,PLZ,Stadt,Screen,Status,Freie Slots\n" +

        const worksheet = xlsx.utils.json_to_sheet(
            [
                {
                    shellId: "Shell ID",
                    region: "BL",
                    street: "Straße",
                    zipCode: "PLZ",
                    city: "Stadt",
                    outdoorScreenVersion: "Bildschirm",
                    constructionDate: "Aufbaudatum",
                    daysRunningInMonth: "Tage im Monat",
                    price_per_kwh: "Preis pro kWh",
                    kwh_per_day: "kWh pro Tag",
                    totalKwh: "kWh gesamt",
                    priceForMonth: "Stromkosten gesamt",
                    costISM: "Anteil ISM",
                    costShell: "Anteil Shell",
                },
            ]
                .concat(arrayForDownload)
                .concat([
                    {
                        shellId: "Anzahl " + arrayForDownload.length,
                        region: "",
                        street: "",
                        zipCode: "",
                        city: "",
                        outdoorScreenVersion: "",
                        constructionDate: "",
                        daysRunningInMonth: "",
                        price_per_kwh: "",
                        kwh_per_day: "",
                        totalKwh: "",
                        priceForMonth: "",
                        costISM: numberToGermanLocale(totalCost * 0.7) + "€",
                        costShell: numberToGermanLocale(totalCost * 0.3) + "€",
                    },
                ]),
            { skipHeader: true }
        );

        /* add to workbook */

        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, worksheet, "Stromkosten");

        /* generate an XLSX file */
        xlsx.writeFile(
            wb,
            "abrechnung_strom_" + startDate.format("MM_YYYY") + ".xlsx"
        );
        return;
    };

    static parseStationArrayToShellCostCsv = (
        array: Station[],
        endDateObject: dayjs.Dayjs,
        startDateObject: dayjs.Dayjs
    ) => {
        const alreadyBilledArray = [
            1004, 1044, 1058, 1088, 1093, 1095, 1109, 1122, 1134, 1140, 1178,
            1179, 1188, 1197, 1228, 1293, 1299, 1325, 1345, 1347, 1408, 150,
            153, 1551, 156, 1567, 160, 163, 165, 1666, 1756, 186, 19, 1944,
            1966, 1984, 1988, 2049, 2053, 21, 2100, 2123, 2131, 2156, 2165, 219,
            22, 2204, 221, 2213, 2223, 2240, 2243, 2260, 2285, 2311, 2321, 2356,
            2361, 2365, 25, 2519, 273, 286, 3068, 3146, 3154, 317, 33, 3374,
            338, 3394, 3400, 3434, 344, 35, 354, 3595, 3621, 3622, 3623, 3627,
            3628, 3629, 3639, 3645, 3649, 3650, 3653, 3662, 3663, 3665, 3666,
            3669, 3671, 3674, 3686, 3693, 3696, 3697, 383, 386, 394, 411, 417,
            42, 420, 424, 4402, 453, 476, 490, 491, 5136, 530, 5402, 5497, 5659,
            5673, 5684, 5687, 5688, 596, 6092, 6192, 660, 6726, 674, 6740, 675,
            6768, 6769, 6792, 6800, 6803, 6828, 6841, 6843, 6844, 6845, 6867,
            72, 744, 7496, 7507, 751, 7517, 7526, 7528, 7533, 7535, 7536, 7540,
            7541, 7548, 756, 758, 7627, 7683, 7685, 7707, 7709, 7716, 7725,
            7730, 7733, 774, 7741, 7743, 7749, 7753, 7755, 778, 786, 793, 7961,
            80, 807, 812, 816, 818, 849, 869, 885, 9100, 9113, 9156, 9304, 9368,
            941, 9436, 949, 9497, 960, 97, 986, 993,
        ];

        const filteredArray = array
            .filter(
                (station) => alreadyBilledArray.indexOf(station.shellId) === -1
            )
            .filter(
                (station) =>
                    station.plannedConstructionDate &&
                    startDateObject.valueOf() <
                        station.plannedConstructionDate &&
                    station.plannedConstructionDate < endDateObject.valueOf()
            );

        const totalCostBackShop = filteredArray.reduce(
            (total, object) => total + (object.backShopCosts || 0),
            0
        );

        const totalCostCarWash = filteredArray.reduce(
            (total, object) => total + (object.carWashCosts || 0),
            0
        );

        const totalCosts = totalCostBackShop + totalCostCarWash;

        const dataArray =
            // "data:text/csv;charset=utf-8,Shell-Id,BL,Nielsen,Straße,PLZ,Stadt,Screen,Status,Freie Slots\n" +
            filteredArray.map((station: Station) => {
                return {
                    shellId: station.shellId.toString(),
                    region: station.region.toString(),
                    street: station.street.toString(),
                    zipCode: station.zipCode.toString(),
                    city: station.city.toString(),
                    constructionDate:
                        station.plannedConstructionDate !== null
                            ? dayjs(station.plannedConstructionDate).format(
                                  "DD.MM.YYYY"
                              )
                            : "N/A",
                    backShopCosts:
                        numberToGermanLocale(station.backShopCosts || 0) + "€",
                    carWashCosts:
                        numberToGermanLocale(station.carWashCosts || 0) + "€",
                    totalStationCostShell:
                        numberToGermanLocale(
                            (station.backShopCosts || 0) +
                                (station.carWashCosts || 0)
                        ) + "€",
                    playersBackShop:
                        station.backShopPlayerAmount !== null
                            ? station.backShopPlayerAmount.toString()
                            : "0",
                    playersCarWash:
                        station.carWashPlayerAmount !== null
                            ? station.carWashPlayerAmount.toString()
                            : "0",
                };
            });
        const worksheet = xlsx.utils.json_to_sheet(
            [
                {
                    shellId: "Shell ID",
                    region: "BL",
                    street: "Straße",
                    zipCode: "PLZ",
                    city: "Stadt",
                    constructionDate: "Aufbaudatum",
                    backShopCosts: "Kosten Backshop",
                    carWashCosts: "Kosten CarWash",
                    totalStationCostShell: "Kosten gesamt",
                    playersBackShop: "Player BackShop",
                    playersCarWash: "Player CarWash",
                },
            ]
                .concat(dataArray)
                .concat({
                    shellId: "Anzahl " + filteredArray.length,
                    region: "",
                    street: "",
                    zipCode: "",
                    city: "",
                    constructionDate: "",
                    backShopCosts:
                        numberToGermanLocale(totalCostBackShop) + "€",
                    carWashCosts: numberToGermanLocale(totalCostCarWash) + "€",
                    totalStationCostShell:
                        numberToGermanLocale(totalCosts) + "€",
                    playersBackShop: "",
                    playersCarWash: "",
                }),
            { skipHeader: true }
        );

        /* add to workbook */

        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, worksheet, "Aufbaukosten Shell");

        /* generate an XLSX file */
        xlsx.writeFile(wb, "backshop_carwash_kosten.xlsx");
        return;

        // const csvContent =
        //     "data:text/csv;charset=utf-8,Shell-Id,Straße,PLZ,Stadt,Kosten Backshop,Kosten CarWash,Kosten gesamt\n" +
        //     filteredArray
        //         .map((station: Station) => {
        //             return (
        //                 station.shellId +
        //                 "," +
        //                 station.street +
        //                 "," +
        //                 station.zipCode +
        //                 "," +
        //                 station.city +
        //                 "," +
        //                 (station.backShopCosts ? station.backShopCosts : "0") +
        //                 "," +
        //                 '"' +
        //                 parseFloat(
        //                     (station.carWashCosts
        //                         ? station.carWashCosts
        //                         : 0
        //                     ).toFixed(2)
        //                 ).toLocaleString("de-DE") +
        //                 '"' +
        //                 "," +
        //                 ((station.backShopCosts || 0) +
        //                     (station.carWashCosts || 0))
        //             );
        //         })
        //         .join("\n") +
        //     "\nAnzahl Stationen " +
        //     filteredArray.length +
        //     ",,,," +
        //     totalCostBackShop.toFixed(2) +
        //     "," +
        //     totalCostCarWash.toFixed(2) +
        //     "," +
        //     '"' +
        //     parseFloat(totalCosts.toFixed(2)).toLocaleString("de-DE") +
        //     '"';

        // const hiddenElement = document.getElementById("download_hidden");
        // if (hiddenElement) {
        //     hiddenElement.setAttribute("href", encodeURI(csvContent));
        //     hiddenElement.setAttribute("target", "_blank");
        //     hiddenElement.setAttribute(
        //         "download",
        //         "backshop_carwash_kosten.csv"
        //     );
        //     hiddenElement.click();
        // }
    };

    static parseCampaignArrayToCSV = (array: Campaign[]) => {
        const filteredArray = array.filter(
            (station) => true
            // station.plannedConstructionDate &&
            // startDate.valueOf() < station.plannedConstructionDate &&
            // station.plannedConstructionDate < endDateObject.valueOf()
        );

        const dataArray =
            // "data:text/csv;charset=utf-8,Shell-Id,BL,Nielsen,Straße,PLZ,Stadt,Screen,Status,Freie Slots\n" +
            filteredArray.map((campaign: Campaign) => {
                const startDate = dayjs(campaign.start);
                const terminationDate = campaign.terminationDate
                    ? dayjs(campaign.terminationDate)
                    : null;
                return {
                    name: campaign.name,
                    customerName: campaign.customer.companyName,
                    salesRep: campaign.salesRep.toString(),
                    contractVolume: numberToGermanLocale(
                        parseFloat(
                            (
                                CampaignDurationInt[campaign.duration] *
                                campaign.pricePerMonth
                            ).toFixed(2)
                        )
                    ),
                    pricePerMonth: campaign.pricePerMonth.toString(),
                    paymentInterval:
                        CampaignPaymentInterval[
                            campaign.paymentInterval
                        ].toString(),
                    start: startDate.format("DD.MM.YYYY"),
                    terminationDate: terminationDate
                        ? terminationDate.format("DD.MM.YYYY")
                        : "",
                    slotWeight: campaign.slotWeight.toString(),
                    duration: CampaignDurationInt[campaign.duration].toString(),
                    bonusDuration: campaign.bonusDuration.toString(),
                };
            });
        const worksheet = xlsx.utils.json_to_sheet(
            [
                {
                    name: "Auftragsnummer",
                    customerName: "Kunde",
                    salesRep: "Vertrieb",
                    contractVolume: "Auftragsvolumen (exkl. Spoterstellung)",
                    pricePerMonth: "pricePerMonth",
                    paymentInterval: "paymentInterval",
                    start: "start",
                    terminationDate: "terminationDate",
                    slotWeight: "slotWeight",
                    duration: "duration",
                    bonusDuration: "bonusDuration",
                },
            ].concat(dataArray),
            { skipHeader: true }
        );

        /* add to workbook */

        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, worksheet, "Kampagnen");

        /* generate an XLSX file */
        xlsx.writeFile(wb, "kampagnen.xlsx");
        return;

        // const csvContent =
        //     "data:text/csv;charset=utf-8,Auftragsnummer,Kunde,Vertrieb,Auftragsvolumen (exkl. Spoterstellung)\n" +
        //     filteredArray
        //         .map((campaign: Campaign) => {
        //             return (
        //                 campaign.name +
        //                 "," +
        //                 '"' +
        //                 campaign.customerName +
        //                 '"' +
        //                 "," +
        //                 campaign.salesRep +
        //                 "," +
        //                 '"' +
        //                 parseFloat(
        //                     (
        //                         CampaignDurationInt[campaign.duration] *
        //                         campaign.pricePerMonth
        //                     ).toFixed(2)
        //                 ).toLocaleString("de-DE") +
        //                 '"'
        //             );
        //         })
        //         .join("\n");

        // const hiddenElement = document.getElementById("download_hidden");
        // if (hiddenElement) {
        //     hiddenElement.setAttribute("href", encodeURI(csvContent));
        //     hiddenElement.setAttribute("target", "_blank");
        //     hiddenElement.setAttribute("download", "kampagnen.csv");
        //     hiddenElement.click();
        // }
    };

    static filterCampaignForAutoExtending = (array: Campaign[]) => {
        const filteredArray = array.filter((campaign) => {
            const startDate = dayjs(campaign.start);
            const durationInt =
                CampaignDurationInt[campaign.duration] + campaign.bonusDuration;
            const endDate = startDate.add(durationInt, "month");

            if (
                campaign.duration !== CampaignDuration.TWO_MONTHS &&
                campaign.duration !== CampaignDuration.SIX_MONTHS &&
                dayjs().diff(startDate, "month") > 3 &&
                endDate.diff(dayjs(), "month") < 3 &&
                (campaign.terminationDate === null ||
                    dayjs(endDate).diff(campaign.terminationDate, "month") < 3)
            ) {
                return true;
            } else if (
                campaign.duration === CampaignDuration.SIX_MONTHS &&
                dayjs().diff(startDate, "week") > 4 &&
                endDate.diff(dayjs(), "week") < 4 &&
                (campaign.terminationDate === null ||
                    dayjs(endDate).diff(campaign.terminationDate, "week") < 4)
            ) {
            }
            return false;
        });

        const dataArray =
            // "data:text/csv;charset=utf-8,Shell-Id,BL,Nielsen,Straße,PLZ,Stadt,Screen,Status,Freie Slots\n" +
            filteredArray.map((campaign: Campaign) => {
                const startDate = dayjs(campaign.start);
                const durationInt =
                    CampaignDurationInt[campaign.duration] +
                    campaign.bonusDuration;
                const endDate = startDate.add(durationInt, "month");
                return {
                    name: campaign.name,
                    customerName: campaign.customer.companyName,
                    salesRep: campaign.salesRep,
                    startDate: startDate.format("DD.MM.YYYY"),
                    duration: CampaignDurationInt[campaign.duration].toString(),
                    bonusMonths: campaign.bonusDuration.toString(),
                    endDate: endDate.format("DD.MM.YYYY"),
                };
            });
        const worksheet = xlsx.utils.json_to_sheet(
            [
                {
                    name: "Auftragsnummer",
                    customerName: "Kunde",
                    salesRep: "Vertrieb",
                    startDate: "1. Ausspielung",
                    duration: "Laufzeit in Monaten",
                    bonusMonths: "Bonusmonate",
                    endDate: "Verlängert sich am",
                },
            ].concat(dataArray),
            { skipHeader: true }
        );

        /* add to workbook */

        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, worksheet, "Verlängerer");

        /* generate an XLSX file */
        xlsx.writeFile(wb, "kampagnen_verlängerer.xlsx");
        return;

        // const csvContent =
        //     "data:text/csv;charset=utf-8,Auftragsnummer,Kunde,Vertrieb,Auftragsvolumen (exkl. Spoterstellung)\n" +
        //     filteredArray
        //         .map((campaign: Campaign) => {
        //             return (
        //                 campaign.name +
        //                 "," +
        //                 '"' +
        //                 campaign.customerName +
        //                 '"' +
        //                 "," +
        //                 campaign.salesRep +
        //                 "," +
        //                 '"' +
        //                 parseFloat(
        //                     (
        //                         CampaignDurationInt[campaign.duration] *
        //                         campaign.pricePerMonth
        //                     ).toFixed(2)
        //                 ).toLocaleString("de-DE") +
        //                 '"'
        //             );
        //         })
        //         .join("\n");

        // const hiddenElement = document.getElementById("download_hidden");
        // if (hiddenElement) {
        //     hiddenElement.setAttribute("href", encodeURI(csvContent));
        //     hiddenElement.setAttribute("target", "_blank");
        //     hiddenElement.setAttribute("download", "kampagnen.csv");
        //     hiddenElement.click();
        // }
    };
}
