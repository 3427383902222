import dayjs, { Dayjs } from "dayjs";
import {
    Box,
    Button,
    Calendar,
    CheckBox,
    DropButton,
    FormField,
    Heading,
    Layer,
    RadioButtonGroup,
    ResponsiveContext,
    Select,
    Text,
    TextInput,
} from "grommet";
import { LinkPrevious } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    addStationForNewCampaign,
    createNewCampaign,
    setFilterContractDuration,
    setFilterDateFrom,
    setNewCampaignRequestStatus,
} from "../../store/campaign/actions";
import { getCustomers } from "../../store/customers/actions";
import { rootState } from "../../store/rootReducer";
import {
    findStationsByRange,
    getAllStations,
} from "../../store/stations/actions";
import { User, UserRole } from "../../types/Authentication";
import {
    CampaignDuration,
    CampaignPaymentInterval,
    CampaignStatus,
    NewCampaignRequestStatus,
    PartialNewCampaign,
    SalesRep,
    SalesRepArray,
    slotWeightToPackage,
} from "../../types/Campaign";
import Customer from "../../types/Customer";
import { PartialNewAllocation } from "../../types/Resource";
import Station, { Location } from "../../types/Station";

interface StationDetailsProps {
    customers: Customer[];
    getCustomers: () => any;
    stationsForNewCampaign: Station[];
    createNewCampaign: (campaign: PartialNewCampaign) => void;
    handleNewCampaignSelect: (select: boolean) => void;
    startDate: dayjs.Dayjs;
    contractDuration: CampaignDuration;
    range: string | number;
    center: Location;
    user: User | null;
    newCampaignRequestStatus: NewCampaignRequestStatus;
    isFetchingNewCampaing: boolean;
    setFilterDateFrom: (date: dayjs.Dayjs) => void;
    setFilterContractDuration: (duration: CampaignDuration) => void;
    findStationsByRange: (
        address: string,
        range: string,
        skipGeoSearch?: boolean,
        center?: Location
    ) => void;
    getAllStations: () => void;
    setNewCampaignRequestStatus: (status: NewCampaignRequestStatus) => void;
}

const NewCampaignForm: React.FC<StationDetailsProps> = (props) => {
    const [campaignName, setCampaignName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [searchingCustomer, setSearchingCustomer] = useState<boolean>(false);
    const [
        searchingCustomerQuery,
        setSearchingCustomerQuery,
    ] = useState<string>("");
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
    const [bonusDuration, setBonusDuration] = useState(0);
    const [pricePerMonth, setPricePerMonth] = useState(0);
    const [offerDate, setOfferDate] = useState<number>(dayjs().valueOf());
    const [
        spotCreationByCustomer,
        setSpotCreationByCustomer,
    ] = useState<boolean>(false);
    const [campaignSlotWeight, setCampaignSlotWeight] = useState(2);
    const [salesRep, setSalesRep] = useState(SalesRep.ISM);
    const [campaignPaymentInterval, setCampaignPaymentInterval] = useState({
        val: CampaignPaymentInterval.YEARLY,
        lab: "jährlich",
    });
    const [closedCalendarWindow, setClosedCalendarWindow] = useState(true);
    const [closedABCalendarWindow, setClosedABCalendarWindow] = useState(true);
    const [closedDurationWindow, setClosedDurationWindow] = useState(true);
    const [campaignEnd, setCampaignEnd] = useState<Dayjs>(dayjs());
    const [campaignEndWindow, setCampaignEndWindow] = useState(true);

    const durationValues = [
        "2 Monate",
        "6 Monate",
        "12 Monate",
        "24 Monate",
        "36 Monate",
        "N/A",
    ];
    const durationValueObject = {
        [CampaignDuration.TWO_MONTHS]: durationValues[0],
        [CampaignDuration.SIX_MONTHS]: durationValues[1],
        [CampaignDuration.TWELVE_MONTHS]: durationValues[2],
        [CampaignDuration.TWENTY_FOUR_MONTHS]: durationValues[3],
        [CampaignDuration.THIRTY_SIX_MONTHS]: durationValues[4],
        [CampaignDuration.NONE]: durationValues[5],
    };

    useEffect(() => {
        props.getCustomers();
    }, []);

    useEffect(() => {
        props.setNewCampaignRequestStatus(NewCampaignRequestStatus.NONE);
        return () => {
            props.setNewCampaignRequestStatus(NewCampaignRequestStatus.NONE);
        };
    }, [props.setNewCampaignRequestStatus]);

    useEffect(() => {
        const filterContentPartners = props.customers.filter(
            (c) =>
                c.companyName
                    .toLowerCase()
                    .indexOf(searchingCustomerQuery.toLowerCase()) >= 0
        );

        setTimeout(() => {
            setSearchingCustomer(false);
            setFilteredCustomers(filterContentPartners);
        }, 500);
    }, [searchingCustomer, searchingCustomerQuery]);

    const stationLookup = (
        address: string = "Berlin",
        range: string,
        skipGeoSearch?: boolean,
        center?: Location
    ) => {
        setClosedCalendarWindow(true);
        setClosedDurationWindow(true);
        if (
            props.user &&
            props.user.authentication.role === UserRole.EXTERNAL_SALES
        ) {
            props.getAllStations();
            return;
        }
        props.findStationsByRange(address, range, skipGeoSearch, center);
    };

    const createNewCampaignObject = (): PartialNewCampaign => {
        if (!customer) {
            throw Error;
        }

        //TODO: write mapping function that creates allocations for stations

        const campaignDurationToIntMap = {
            [CampaignDuration.TWO_MONTHS]: 2,
            [CampaignDuration.SIX_MONTHS]: 6,
            [CampaignDuration.TWELVE_MONTHS]: 12,
            [CampaignDuration.TWENTY_FOUR_MONTHS]: 24,
            [CampaignDuration.THIRTY_SIX_MONTHS]: 36,
        };

        const startTimeStamp: number = dayjs(props.startDate).valueOf();
        const endTimeStamp: number =
            props.contractDuration === CampaignDuration.NONE
                ? campaignEnd.valueOf()
                : dayjs(startTimeStamp)
                      .add(
                          campaignDurationToIntMap[props.contractDuration],
                          "month"
                      )
                      .add(bonusDuration, "month")
                      .valueOf();

        const allocations: PartialNewAllocation[] = props.stationsForNewCampaign.map(
            (station: Station): PartialNewAllocation => {
                return {
                    start: startTimeStamp,
                    end: endTimeStamp,
                    stationId: station.id,
                    slotNumber: station.freeSlotsInPeriod.filter(
                        (slot) => campaignSlotWeight <= slot.free
                    )[0].slot,
                    slotWeight: campaignSlotWeight,
                };
            }
        );

        return {
            name: campaignName,
            allocations: allocations,
            start: dayjs(props.startDate).valueOf(),
            offerDate: dayjs(offerDate).valueOf(),
            spotCreationByCustomer: spotCreationByCustomer,
            duration: props.contractDuration,
            bonusDuration: bonusDuration,
            pricePerMonth: pricePerMonth,
            salesRep: salesRep,
            slotWeight: campaignSlotWeight,
            paymentInterval: campaignPaymentInterval.val,
            status: CampaignStatus.CREATED,
            customerId: customer.id,
            terminationDate: null,
        };
    };

    return (
        <ResponsiveContext.Consumer>
            {(size) => (
                <Box
                    pad="small"
                    justify="center"
                    direction="column"
                    align="center"
                    height="100%"
                    background="white"
                >
                    {props.newCampaignRequestStatus !==
                        NewCampaignRequestStatus.NONE && (
                        <Layer
                            onEsc={() =>
                                props.setNewCampaignRequestStatus(
                                    NewCampaignRequestStatus.NONE
                                )
                            }
                            onClickOutside={() =>
                                props.setNewCampaignRequestStatus(
                                    NewCampaignRequestStatus.NONE
                                )
                            }
                            full={false}
                        >
                            <Box
                                // pad='large'
                                align="center"
                                justify="center"
                            >
                                {props.newCampaignRequestStatus ===
                                    NewCampaignRequestStatus.SUCCESS && (
                                    <Box
                                        align="center"
                                        direction="column"
                                        justify="center"
                                        pad="medium"
                                    >
                                        <Text
                                            margin={{ vertical: "medium" }}
                                            textAlign="center"
                                        >
                                            Kampagne wurde erfolgreich erstellt.
                                        </Text>
                                        <Box align="center" justify="center">
                                            <Link to="/campaigns">
                                                <Button
                                                    label="Zur Kampagnenübersicht"
                                                    icon={<LinkPrevious />}
                                                />
                                            </Link>
                                        </Box>
                                    </Box>
                                )}
                                {props.newCampaignRequestStatus ===
                                    NewCampaignRequestStatus.FAILURE && (
                                    <Box
                                        align="center"
                                        direction="column"
                                        justify="center"
                                    >
                                        <Text
                                            color="danger"
                                            margin={{ vertical: "medium" }}
                                        >
                                            Das sollte nicht passieren!
                                        </Text>
                                        <Text
                                            textAlign="center"
                                            margin={{ vertical: "medium" }}
                                        >
                                            Ein Fehler ist aufgetreten. Bitte
                                            versuchen Sie es erneuet oder
                                            kontaktieren Sie unseren Service.
                                        </Text>

                                        <Button
                                            label="Zurück zur Kampagne"
                                            icon={<LinkPrevious />}
                                            onClick={() =>
                                                props.setNewCampaignRequestStatus(
                                                    NewCampaignRequestStatus.NONE
                                                )
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Layer>
                    )}
                    <Box width={"100%"} background="white" pad="medium">
                        <Heading level={3}>Neue Kampagne</Heading>
                    </Box>

                    <Box
                        width={"100%"}
                        background="white"
                        pad="medium"
                        overflow="scroll"
                    >
                        {props.user &&
                            (props.user.authentication.role ===
                                UserRole.ADMIN ||
                                props.user.authentication.role ===
                                    UserRole.VIEW_ONLY) && (
                                <FormField
                                    margin={{ bottom: "medium" }}
                                    style={{ minHeight: "60px" }}
                                    // height="40px"
                                >
                                    <TextInput
                                        placeholder="Auftragsnummer"
                                        value={campaignName}
                                        onChange={(event) =>
                                            setCampaignName(event.target.value)
                                        }
                                    />
                                </FormField>
                            )}
                        {/* <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            error={
                                customerName !== ""
                                    ? ""
                                    : "Angabe wird benötigt"
                            }
                            required={true}
                        >
                            <TextInput
                                placeholder="Kunde"
                                value={customerName}
                                onChange={(event) =>
                                    setCustomerName(event.target.value)
                                }
                            />
                        </FormField> */}
                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            error={
                                customer !== null ? "" : "Angabe wird benötigt"
                            }
                            required={true}
                        >
                            <Select
                                placeholder="Kunde auswählen"
                                searchPlaceholder="Kunden Suche"
                                emptySearchMessage="Kunde nicht gefunden"
                                value={customer}
                                labelKey="companyName"
                                options={
                                    filteredCustomers.length > 0
                                        ? filteredCustomers
                                        : props.customers
                                }
                                onChange={({ option }) => {
                                    setCustomer(option);
                                }}
                                onSearch={(query) => {
                                    setSearchingCustomer(true);
                                    setSearchingCustomerQuery(query);
                                }}
                            />
                        </FormField>

                        <FormField
                            margin={{ bottom: "medium" }}
                            style={{ minHeight: "60px" }}
                            error={
                                campaignSlotWeight > 0
                                    ? ""
                                    : "Angabe wird benötigt"
                            }
                            required={true}
                            label="Slotbelegung"
                        >
                            <Select
                                name="slotWeight"
                                labelKey="label"
                                options={[
                                    {
                                        // disabled: false,
                                        // id: "1",
                                        // name: slotWeightToPackage(props.slotWeight),
                                        value: "1",
                                        label: slotWeightToPackage(1),
                                    },
                                    {
                                        // disabled: false,
                                        // id: "2",
                                        // name: slotWeightToPackage(props.slotWeight),
                                        value: "2",
                                        label: slotWeightToPackage(2),
                                    },
                                ]}
                                value={slotWeightToPackage(campaignSlotWeight)}
                                onChange={(option) => {
                                    setCampaignSlotWeight(
                                        parseFloat(option.value.value)
                                    );
                                }}
                            />
                        </FormField>
                        <FormField
                            error={
                                pricePerMonth >= 0 ? "" : "Angabe wird benötigt"
                            }
                            required={true}
                            style={{ minHeight: "60px" }}
                            label="Preis / Monat €"
                        >
                            <TextInput
                                placeholder="xxx,xx €"
                                value={pricePerMonth}
                                onChange={(event) =>
                                    setPricePerMonth(
                                        parseFloat(event.target.value)
                                    )
                                }
                                type="number"
                                step="0.01"
                                min="0"
                            />
                        </FormField>
                        <FormField
                            required={true}
                            label="Bonus Monate"
                            style={{ minHeight: "60px" }}
                        >
                            <TextInput
                                placeholder="0"
                                value={bonusDuration}
                                onChange={(event) =>
                                    setBonusDuration(
                                        parseInt(event.target.value)
                                    )
                                }
                                type="number"
                                min="0"
                                max="24"
                            />
                        </FormField>
                        <FormField
                            error={
                                Object.values(CampaignPaymentInterval).includes(
                                    campaignPaymentInterval.val
                                )
                                    ? ""
                                    : "Angabe wird benötigt"
                            }
                            required={true}
                            label="Abrechnungsperiode"
                            style={{ minHeight: "60px" }}
                        >
                            <Select
                                name="paymentInterval"
                                labelKey="lab"
                                options={[
                                    {
                                        val: CampaignPaymentInterval.AT_ONCE,
                                        lab: "einmalig",
                                    },
                                    {
                                        val: CampaignPaymentInterval.YEARLY,
                                        lab: "jährlich",
                                    },
                                    {
                                        val:
                                            CampaignPaymentInterval.HALF_YEARLY,
                                        lab: "halbjährlich",
                                    },
                                    {
                                        val: CampaignPaymentInterval.QUARTERLY,
                                        lab: "vierteljährlich",
                                    },
                                    {
                                        val: CampaignPaymentInterval.MONTHLY,
                                        lab: "monatlich",
                                    },
                                ]}
                                value={campaignPaymentInterval}
                                onChange={(option) =>
                                    setCampaignPaymentInterval(option.value)
                                }
                            />
                        </FormField>
                        <FormField
                            margin={{ bottom: "xlarge" }}
                            required={true}
                            style={{ minHeight: "60px" }}
                            label="Vertrieben von"
                        >
                            <Select
                                options={SalesRepArray}
                                value={salesRep}
                                onChange={({ option }) => setSalesRep(option)}
                            />
                        </FormField>

                        <Box
                            direction="row"
                            align="center"
                            margin={{ bottom: "large" }}
                            justify="center"
                        >
                            {
                                <DropButton
                                    label={
                                        "AB Datum: " +
                                        dayjs(offerDate).format("DD.MM.YYYY")
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    open={!closedABCalendarWindow}
                                    onOpen={() => {
                                        setClosedCalendarWindow(true);
                                        setClosedABCalendarWindow(false);
                                        setClosedDurationWindow(true);
                                        setCampaignEndWindow(true);
                                    }}
                                    onClose={() =>
                                        setClosedABCalendarWindow(true)
                                    }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    offerDate
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    setOfferDate(
                                                        dayjs(date)
                                                            .hour(0)
                                                            .minute(0)
                                                            .second(0)
                                                            .valueOf()
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                            }
                            {props.startDate && (
                                <DropButton
                                    label={
                                        "Ausspielung ab: " +
                                        dayjs(props.startDate).format(
                                            "DD.MM.YYYY"
                                        )
                                    }
                                    dropAlign={{
                                        bottom: "top",
                                        right: "right",
                                    }}
                                    margin={{ left: "1rem" }}
                                    open={!closedCalendarWindow}
                                    onOpen={() => {
                                        setClosedCalendarWindow(false);
                                        setClosedABCalendarWindow(true);
                                        setClosedDurationWindow(true);
                                        setCampaignEndWindow(true);
                                    }}
                                    onClose={() =>
                                        setClosedCalendarWindow(true)
                                    }
                                    dropContent={
                                        <Box pad="large" background="light-2">
                                            <Calendar
                                                size="medium"
                                                date={dayjs(
                                                    props.startDate
                                                ).toISOString()}
                                                onSelect={(date) => {
                                                    props.setFilterDateFrom(
                                                        dayjs(date)
                                                            .hour(0)
                                                            .minute(0)
                                                            .second(0)
                                                    );
                                                    stationLookup(
                                                        "",
                                                        "" + props.range,
                                                        true,
                                                        props.center
                                                    );
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                            )}
                            {props.contractDuration &&
                                props.contractDuration ===
                                    CampaignDuration.NONE && (
                                    <DropButton
                                        label={
                                            "Ausspielung bis: " +
                                            dayjs(campaignEnd).format(
                                                "DD.MM.YYYY"
                                            )
                                        }
                                        dropAlign={{
                                            bottom: "top",
                                            right: "right",
                                        }}
                                        margin={{ left: "1rem" }}
                                        open={!campaignEndWindow}
                                        onOpen={() => {
                                            setClosedCalendarWindow(true);
                                            setClosedABCalendarWindow(true);
                                            setClosedDurationWindow(true);
                                            setCampaignEndWindow(false);
                                        }}
                                        onClose={() =>
                                            setCampaignEndWindow(true)
                                        }
                                        dropContent={
                                            <Box
                                                pad="large"
                                                background="light-2"
                                            >
                                                <Calendar
                                                    size="medium"
                                                    date={dayjs(
                                                        props.startDate
                                                    ).toISOString()}
                                                    onSelect={(date) => {
                                                        setCampaignEnd(
                                                            dayjs(date)
                                                                .hour(0)
                                                                .minute(0)
                                                                .second(0)
                                                        );
                                                        stationLookup(
                                                            "",
                                                            "" + props.range,
                                                            true,
                                                            props.center
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        }
                                    />
                                )}
                            <DropButton
                                label={
                                    "Laufzeit: " +
                                    durationValueObject[props.contractDuration]
                                }
                                dropAlign={{ bottom: "top", right: "right" }}
                                margin={{ horizontal: "1rem" }}
                                open={!closedDurationWindow}
                                onOpen={() => {
                                    setClosedCalendarWindow(true);
                                    setClosedABCalendarWindow(true);
                                    setCampaignEndWindow(true);
                                    setClosedDurationWindow(false);
                                }}
                                onClose={() => setClosedDurationWindow(true)}
                                dropContent={
                                    <Box pad="large" background="light-2">
                                        <RadioButtonGroup
                                            name="doc"
                                            options={durationValues}
                                            value={
                                                durationValueObject[
                                                    props.contractDuration
                                                ]
                                            }
                                            onChange={(event) => {
                                                props.setFilterContractDuration(
                                                    Object.keys(
                                                        durationValueObject
                                                    ).filter((key) => {
                                                        return (
                                                            durationValueObject[
                                                                key
                                                            ] ===
                                                            event.target.value
                                                        );
                                                    })[0] as CampaignDuration
                                                );
                                                stationLookup(
                                                    "",
                                                    "" + props.range,
                                                    true,
                                                    props.center
                                                );
                                            }}
                                        />
                                    </Box>
                                }
                            />
                            <CheckBox
                                label={"Selbstanlieferung"}
                                checked={spotCreationByCustomer}
                                onChange={(event) => {
                                    setSpotCreationByCustomer(
                                        event.target.checked
                                    );
                                }}
                            />
                        </Box>
                        {props.stationsForNewCampaign
                            .filter((station) =>
                                props.stationsForNewCampaign.find(
                                    (stationForCampaign) =>
                                        stationForCampaign.shellId ===
                                        station.shellId
                                )
                            )
                            .map((station, index) => {
                                return (
                                    <Box
                                        pad={{ vertical: "xsmall" }}
                                        key={station.shellId}
                                        style={{ minHeight: "30px" }}
                                    >
                                        <Text margin={{ bottom: "medium" }}>
                                            {index + 1}. OBN: {station.shellId}{" "}
                                            - {station.street}, {station.city}
                                        </Text>
                                        {/* <ResourcePlanner station={station} /> */}
                                    </Box>
                                );
                            })}
                    </Box>
                    <Box
                        align="center"
                        justify={size === "small" ? "center" : "between"}
                        direction={size === "small" ? "column" : "row"}
                        margin={{ top: "large" }}
                    >
                        <Button
                            disabled={
                                props.stationsForNewCampaign.length === 0 ||
                                !customer ||
                                pricePerMonth < 0
                            }
                            onClick={() =>
                                props.createNewCampaign(
                                    createNewCampaignObject()
                                )
                            }
                            label="Kampagne erstellen"
                            color="success"
                            margin={"medium"}
                        />
                        <Button
                            onClick={() => props.handleNewCampaignSelect(false)}
                            label="Abbrechen"
                            color="danger"
                        />
                    </Box>
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        customers: state.customerReducer.customers,
        stationsForNewCampaign: state.campaignReducer.stationsForNewCampaign,
        startDate: state.campaignReducer.filterDateFrom,
        contractDuration: state.campaignReducer.filterContractDuration,
        user: state.usersReducer.user,
        center: state.stationsReducer.currentCenter,
        range: state.stationsReducer.currentRange,
        newCampaignRequestStatus:
            state.campaignReducer.newCampaignRequestStatus,
        isFetchingNewCampaing: state.campaignReducer.isFetching,
    };
};

const mapDispatchToProps = {
    addStationForNewCampaign,
    createNewCampaign,
    setFilterDateFrom,
    setFilterContractDuration,
    findStationsByRange,
    getAllStations,
    setNewCampaignRequestStatus,
    getCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCampaignForm);
