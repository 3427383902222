import dayjs from "dayjs";
import { Box, Button, DataTable, Heading, Layer, Meter, Text } from "grommet";
import { Edit, Trash } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CampaignDetails from "../../elements/CampaignDetails";
import {
    approveCampaign,
    deleteCampaign,
    getCampaigns,
} from "../../store/campaign/actions";
import { rootState } from "../../store/rootReducer";
import Campaign, {
    CampaignAllocation,
    CampaignDuration,
    CampaignDurationInt,
} from "../../types/Campaign";
import { CsvExporter } from "../../util/export/csvExport";

interface CampaignProps {
    campaigns: Campaign[];
    getCampaigns: () => {};
    deleteCampaign: (id: number) => {};
    userRole: string;
    userName: string;
}

export interface RowObject extends CampaignAllocation {
    name: string;
    customerName: string;
    campaignIdString: string;
    duration: CampaignDuration;
    percentage: number;
    amountOfAllocations: number;
    background: string;
    city: string | null;
    shellId: number | null;
    zipCode: string | null;
    street: string | null;
    offerDate: number;
    spotCreatedByCustomer: boolean | null;
}

export const campaignToPercent = (campaing: Campaign) => {
    const now = dayjs();

    const allocationPercentageArray = campaing.allocations.map((allocation) => {
        const daysInAllocation = dayjs(allocation.end).diff(
            dayjs(allocation.start),
            "day"
        );
        const daysPassedInAllocation = now.diff(dayjs(allocation.start), "day");

        if (daysInAllocation <= 0 || daysPassedInAllocation <= 0) {
            return 0;
        }

        return (daysPassedInAllocation / daysInAllocation) * 100;
    });

    return (
        allocationPercentageArray.reduce(
            (percentageSum, allocationPercentage) => {
                return percentageSum + allocationPercentage;
            },
            0
        ) / campaing.allocations.length
    );
};

// const AnyReactComponent: React.FC<MapMarker & JSX.IntrinsicElements['div']> = (props) => <img style={{ height: '40px', top: '-40px', left: '-20px', position: 'absolute' }} alt='Map Marker' src={mapMarker} />;

const Campaigns: React.FC<CampaignProps> = (props) => {
    const [showApprovalLayerId, setShowApprovalLayerId] = useState<
        number | null
    >();
    const [showDeleteLayerId, setShowDeleteLayerId] = useState<number | null>();
    const [showEditLayerId, setShowEditLayerId] = useState<number | null>();
    const [expandedGroups, setExpandedGroups] = useState<any>([]);

    useEffect(() => {
        props.getCampaigns();
    }, []);

    const campaignObject =
        props.campaigns &&
        props.campaigns.reduce((object, campaign) => {
            return {
                ...object,
                [campaign.id]: campaign,
            };
        }, {});

    const campaignToCampaignAndAllocationArray = (campaigns: Campaign[]) => {
        const wholeArray: RowObject[] = [];
        const now = dayjs();

        props.campaigns &&
            campaigns.forEach((campaign) => {
                campaign.allocations.forEach((allocation) => {
                    const percentageCalc = () => {
                        const daysInAllocation = dayjs(allocation.end).diff(
                            dayjs(allocation.start),
                            "day"
                        );
                        const daysPassedInAllocation = now.diff(
                            dayjs(allocation.start),
                            "day"
                        );

                        if (
                            daysInAllocation <= 0 ||
                            daysPassedInAllocation <= 0
                        ) {
                            return 0;
                        }

                        return (
                            (daysPassedInAllocation / daysInAllocation) * 100
                        );
                    };

                    const percentage = percentageCalc();

                    wholeArray.push({
                        ...allocation,
                        name: campaign.name,
                        customerName: campaign.customer.companyName,
                        campaignIdString: "" + campaign.id,
                        start: allocation.start,
                        duration: campaign.duration,
                        percentage: percentage,
                        amountOfAllocations:
                            campaignObject[campaign.id].allocations.length,
                        background: "grey",
                        city: allocation.station.city,
                        shellId: allocation.station.shellId,
                        street: allocation.station.street,
                        zipCode: allocation.station.zipCode,
                        offerDate: campaign.offerDate,
                        spotCreatedByCustomer: campaign.spotCreationByCustomer,
                    });
                });
            });

        return wholeArray;
    };

    return (
        <Box pad="large">
            {showEditLayerId && (
                <Layer
                    onEsc={() => setShowEditLayerId(null)}
                    onClickOutside={() => setShowEditLayerId(null)}
                >
                    <CampaignDetails
                        campaign={
                            props.campaigns.filter(
                                (campaign) => showEditLayerId === campaign.id
                            )[0]
                        }
                        closeLayer={setShowEditLayerId}
                    />
                </Layer>
            )}
            {showDeleteLayerId && (
                <Layer
                    onEsc={() => setShowDeleteLayerId(null)}
                    onClickOutside={() => setShowDeleteLayerId(null)}
                >
                    <Box pad="large">
                        <Heading
                            textAlign="center"
                            level={3}
                            margin={{ bottom: "medium" }}
                        >
                            Möchten Sie diese Kampagne wirklich löschen?
                        </Heading>
                        <Box direction="row" justify="between">
                            <Button
                                color="danger"
                                icon={<Trash color="danger" />}
                                label="Löschen"
                                onClick={() => {
                                    props.deleteCampaign(showDeleteLayerId);
                                    setShowDeleteLayerId(null);
                                }}
                            />
                            <Button
                                label="Abbrechen"
                                onClick={() => setShowDeleteLayerId(null)}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
            <Box
                fill="horizontal"
                direction="row"
                margin={{ bottom: "small" }}
                style={{ minHeight: "40px" }}
            >
                <Button
                    onClick={() => {
                        CsvExporter.parseCampaignArrayToCSV(props.campaigns);
                    }}
                    color="black"
                    label="Download"
                    margin={{ right: "small" }}
                />
                <Button
                    onClick={() => {
                        CsvExporter.filterCampaignForAutoExtending(
                            props.campaigns
                        );
                    }}
                    color="black"
                    label="Download Verlängerer"
                />
            </Box>
            <Box overflow="scroll">
                {props.campaigns.length > 0 && (
                    <DataTable
                        columns={[
                            {
                                property: "id",
                                header: undefined,
                                primary: true,
                                render: (rowObject: RowObject) => null,
                            },

                            {
                                property: "name",
                                header: "ANr", //<Text>{(!searchObject || !searchObject.name) ? 'Auftragsnummer' : 'Suche: ' + searchObject.name}</Text>,
                                align: "start",
                                search: true,
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject && rowObject.name
                                                ? rowObject.name
                                                : campaignObject[
                                                      rowObject.campaignIdString
                                                  ].name}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "customerName",
                                header: "Kunde",
                                align: "start",
                                search: true,
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject && rowObject.customerName
                                                ? rowObject.customerName
                                                : campaignObject[
                                                      rowObject.campaignIdString
                                                  ].customerName}
                                        </Text>
                                    </Box>
                                ),
                            },
                            {
                                property: "shellId",
                                align: "center",
                                header: "OBN",
                                render: (rowObject: RowObject) => {
                                    const campaign =
                                        campaignObject[
                                            rowObject.campaignIdString
                                        ];
                                    return (
                                        <Box style={{ textOverflow: "dotted" }}>
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    rowObject.station.shellId}
                                                {!rowObject.end &&
                                                    campaign.allocations &&
                                                    campaign.allocations
                                                        .map(
                                                            (
                                                                allocation,
                                                                index
                                                            ) =>
                                                                index < 2
                                                                    ? "" +
                                                                      allocation
                                                                          .station
                                                                          .shellId
                                                                    : null
                                                        )
                                                        .filter(
                                                            (
                                                                allocationString
                                                            ) =>
                                                                !!allocationString
                                                        )
                                                        .join(", ") + "..."}
                                            </Text>
                                        </Box>
                                    );
                                },
                                search: true,
                            },
                            {
                                property: "zipCode",
                                align: "center",
                                header: "PLZ",
                                render: (rowObject: RowObject) => {
                                    const campaign =
                                        campaignObject[
                                            rowObject.campaignIdString
                                        ];
                                    return (
                                        <Box style={{ textOverflow: "dotted" }}>
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    rowObject.station.zipCode}
                                                {!rowObject.end &&
                                                    campaign.allocations &&
                                                    campaign.allocations
                                                        .map(
                                                            (
                                                                allocation,
                                                                index
                                                            ) =>
                                                                index < 2
                                                                    ? "" +
                                                                      allocation
                                                                          .station
                                                                          .zipCode
                                                                    : null
                                                        )
                                                        .filter(
                                                            (
                                                                allocationString
                                                            ) =>
                                                                !!allocationString
                                                        )
                                                        .join(", ") + "..."}
                                            </Text>
                                        </Box>
                                    );
                                },
                                search: true,
                            },
                            {
                                property: "city",
                                align: "center",
                                header: "Stadt",
                                render: (rowObject: RowObject) => {
                                    const campaign =
                                        campaignObject[
                                            rowObject.campaignIdString
                                        ];
                                    return (
                                        <Box style={{ textOverflow: "dotted" }}>
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    rowObject.station.city}
                                                {!rowObject.end &&
                                                    campaign.allocations &&
                                                    campaign.allocations
                                                        .map(
                                                            (
                                                                allocation,
                                                                index
                                                            ) =>
                                                                index < 2
                                                                    ? "" +
                                                                      allocation
                                                                          .station
                                                                          .city
                                                                    : null
                                                        )
                                                        .filter(
                                                            (
                                                                allocationString
                                                            ) =>
                                                                !!allocationString
                                                        )
                                                        .join(", ") + "..."}
                                            </Text>
                                        </Box>
                                    );
                                },
                                search: true,
                            },
                            {
                                property: "street",
                                align: "center",
                                header: "Straße",
                                render: (rowObject: RowObject) => {
                                    const campaign =
                                        campaignObject[
                                            rowObject.campaignIdString
                                        ];
                                    return (
                                        <Box style={{ textOverflow: "dotted" }}>
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    rowObject.station.street}
                                                {!rowObject.end &&
                                                    campaign.allocations &&
                                                    campaign.allocations
                                                        .map(
                                                            (
                                                                allocation,
                                                                index
                                                            ) =>
                                                                index < 2
                                                                    ? "" +
                                                                      allocation
                                                                          .station
                                                                          .street
                                                                    : null
                                                        )
                                                        .filter(
                                                            (
                                                                allocationString
                                                            ) =>
                                                                !!allocationString
                                                        )
                                                        .join(", ") + "..."}
                                            </Text>
                                        </Box>
                                    );
                                },
                                search: true,
                            },
                            // {
                            //     property: "user",
                            //     align: "center",
                            //     header: "Ersteller",
                            //     render: (rowObject: RowObject) => {
                            //         const campaign =
                            //             campaignObject[rowObject.campaignIdString];
                            //         return (
                            //             <Box style={{ textOverflow: "dotted" }}>
                            //                 <Text>{campaign.createdBy.name}</Text>
                            //             </Box>
                            //         );
                            //     },
                            //     search: true,
                            // },
                            {
                                property: "start",
                                header: "Start",
                                align: "center",
                                render: (rowObject: RowObject) => {
                                    return (
                                        <Box pad="small">
                                            <Text>
                                                {rowObject &&
                                                    rowObject.end &&
                                                    dayjs(
                                                        rowObject.start
                                                    ).format("DD.MM.YYYY")}
                                                {!rowObject.end &&
                                                    dayjs(
                                                        campaignObject[
                                                            rowObject
                                                                .campaignIdString
                                                        ].start
                                                    ).format("DD.MM.YYYY")}
                                            </Text>
                                        </Box>
                                    );
                                },
                                sortable: false,
                            },
                            {
                                property: "duration",
                                header: "Laufzeit",
                                align: "center",
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        <Text>
                                            {rowObject &&
                                                rowObject.end &&
                                                CampaignDurationInt[
                                                    rowObject.duration
                                                ]}
                                            {!rowObject.end &&
                                                CampaignDurationInt[
                                                    campaignObject[
                                                        rowObject
                                                            .campaignIdString
                                                    ].duration
                                                ]}{" "}
                                            Monate
                                        </Text>
                                    </Box>
                                ),
                                sortable: false,
                            },
                            // {
                            //     property: "status",
                            //     header: "Freigabe",
                            //     align: "center",
                            //     render: (rowObject: RowObject) => {
                            //         return (
                            //             <Box pad="small">
                            //                 <Text>
                            //                     {!rowObject.end &&
                            //                         (campaignObject[
                            //                             rowObject.campaignIdString
                            //                         ].status ===
                            //                         AllocationStatus.APPROVED
                            //                             ? "Freigegeben"
                            //                             : "Nicht freigegeben")}
                            //                     {rowObject &&
                            //                         rowObject.end &&
                            //                         (rowObject.status ===
                            //                         AllocationStatus.APPROVED
                            //                             ? "Freigegeben"
                            //                             : "Nicht freigegeben")}
                            //                 </Text>
                            //             </Box>
                            //         );
                            //     },
                            // },
                            {
                                property: "percentage",
                                header: "Status",
                                align: "center",
                                render: (rowObject: RowObject) => (
                                    <Box pad="small">
                                        {!rowObject.end && (
                                            <Meter
                                                values={[
                                                    {
                                                        value: campaignToPercent(
                                                            campaignObject[
                                                                rowObject
                                                                    .campaignIdString
                                                            ]
                                                        ),
                                                        label: "sixty",
                                                    },
                                                ]}
                                                thickness="small"
                                                size="small"
                                            />
                                        )}
                                        {rowObject && rowObject.end && (
                                            <Meter
                                                values={[
                                                    {
                                                        value:
                                                            rowObject.percentage,
                                                        label: "sixty",
                                                    },
                                                ]}
                                                thickness="small"
                                                size="small"
                                            />
                                        )}
                                    </Box>
                                ),
                            },
                            {
                                property: "actions",
                                header: "Bearbeiten",
                                align: "center",
                                render: (rowObject: RowObject) => {
                                    const campaign =
                                        campaignObject[
                                            rowObject.campaignIdString
                                        ];
                                    return (
                                        <Box pad="small" direction="row">
                                            {(!rowObject.end ||
                                                rowObject.amountOfAllocations ===
                                                    1) && (
                                                <Button
                                                    disabled={
                                                        props.userRole !==
                                                            "ADMIN" &&
                                                        campaign.createdBy
                                                            .email !==
                                                            props.userName
                                                    }
                                                    onClick={() => {
                                                        setShowDeleteLayerId(
                                                            campaign.id
                                                        );
                                                    }}
                                                    icon={
                                                        <Trash color="danger" />
                                                    }
                                                />
                                            )}
                                            {/* {(!rowObject.end ||
                                            rowObject.amountOfAllocations ===
                                                1) && (
                                            <Button
                                                disabled={
                                                    props.userRole !==
                                                        "ADMIN" ||
                                                    campaign.status !==
                                                        CampaignStatus.CREATED
                                                }
                                                onClick={() => {
                                                    setShowApprovalLayerId(
                                                        campaign.id
                                                    );
                                                }}
                                                icon={
                                                    <Checkmark color="success" />
                                                }
                                            />
                                        )} */}
                                            {(!rowObject.end ||
                                                rowObject.amountOfAllocations ===
                                                    1) && (
                                                <Button
                                                    disabled={
                                                        props.userRole !==
                                                            "ADMIN" &&
                                                        campaign.createdBy
                                                            .email !==
                                                            props.userName
                                                    }
                                                    onClick={() => {
                                                        setShowEditLayerId(
                                                            campaign.id
                                                        );
                                                    }}
                                                    icon={
                                                        <Edit color="warning" />
                                                    }
                                                />
                                            )}
                                        </Box>
                                    );
                                },
                                sortable: false,
                            },
                        ]}
                        data={campaignToCampaignAndAllocationArray(
                            props.campaigns
                        )}
                        sortable
                        resizeable={true}
                        groupBy={
                            expandedGroups
                                ? {
                                      property: "campaignIdString",
                                      expand: expandedGroups,
                                      onExpand: setExpandedGroups,
                                  }
                                : undefined
                        }
                    />
                )}
            </Box>
            <a id="download_hidden" />
        </Box>
    );
};

const mapStateToProps = (state: rootState) => {
    return {
        campaigns: state.campaignReducer.campaigns,
        userRole: state.usersReducer.user
            ? state.usersReducer.user.authentication.role
            : "",
        userName: state.usersReducer.user
            ? state.usersReducer.user.username
            : "",
    };
};

const mapDispatchToProps = {
    getCampaigns,
    deleteCampaign,
    approveCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
